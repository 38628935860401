.forgotPasswordTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #323234;
  margin-top: 20px;
}

.forgotPasswordResetStyle {
  margin-top: 20px;
  width: 32%;
}

.forgotPasswordResetStyle .forgotPasswordReset {
  width: calc(100% - 8px);
  margin-left: 4px;
  text-transform: none;
}

.forgotPasswordResetStyle .forgotPasswordReset {
  width: calc(100% - 8px);
  margin-left: 4px;
}

.errorMsg {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cb0000;
  margin-top: 8px;
  display: none;
}

.errorMsgEnable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cb0000;
  margin-top: 8px;
  display: block;
}

.username-input-container {
  width: 32% !important;
  display: flex;
  justify-content: center;
  margin: 0;
}

.username-input-container .userNameInput {
  width: 100%;
}

.forgot-password-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.forgot-password-loader-pop-up .forgot-password-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

@media only screen and (max-width: 926px) {
  .username-input-container {
    width: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .username-input-container {
    width: 100% !important;
    padding: 16px;
  }
  .forgot-password-loader-pop-up {
    width: 100%;
  }
  .forgot-password-loader-pop-up .forgot-password-loader {
    height: 150px;
    width: 300px;
  }
}
