.search-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.search-field-container .search-by-facility-id {
  width: 100%;
}

.search-field-container .search-by-facility-submit {
  margin-top: unset;
  padding-top: unset;
  width: 100%;
}

.search-field-container .search-button {
  width: 100%;
  margin-top: 0px;
}

.selectFacilityContainer {
  margin-top: 10px;
}

.selectFacilityContainer .selectFacilitySearch {
  width: 100%;
  max-width: 160px;
}
.selectFacilityContainer .search-by-facility-submit {
  width: 100%;
  max-width: 74px;
  padding-top: 0px;
}
@media only screen and (max-width: 340px) {
  .selectFacilityContainer .selectFacilitySearch {
    max-width: 200px;
  }
}
@media only screen and(min-width: 341px) and (max-width: 600px) {
  .selectFacilityContainer .search-by-facility-submit {
    max-width: 275px;
    margin-left: 28px;
    padding-top: 0px;
  }
  .selectFacilityContainer .selectFacilitySearch {
    width: 80%;
    margin-left: 24px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1020px) {
  .search-field-container .search-by-facility-submit {
    max-width: 275px !important;
  }
}
@media only screen and (width: 768px) {
  .search-field-container .search-by-facility-submit {
    width: 100%;
  }
}
