.order-overview-wound-summary-review-grid-container {
  margin: 0px;
  padding: 0px !important;
  width: 100%;
}

.order-overview-wound-summary-review-grid-container
  .order-overview-wound-summary-review-grid-item {
  margin: 0px;
  padding: 0px !important;
  width: 100%;
}
.order-overview-wound-summary-review-grid-item .wound-image-title {
  margin-top: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 7px;
}
.skelton-container {
  display: flex;
  gap: 10px;
}
.wound-image-box {
  --pseudo-element-display: block !important;
}
.wound-image-div {
  margin: 0px;
  margin-bottom: 3%;
}
.order-overview-wound-summary-review-grid-item .wound-image-hyphen-title {
  margin-top: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 7px;
}
@media only screen and (max-width: 566px) {
  .order-overview-wound-summary-review-grid-item .wound-image-title {
    margin-top: 0%;
    margin-bottom: 3%;
  }
  .order-overview-wound-summary-review-grid-item .wound-image-hyphen-title {
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .wound-image-div {
    margin: 0px;
    margin-bottom: 5%;
  }
}
