.chkBoxMain {
  display: flex;
}

.chkBoxRequiredStar {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: red;
  margin-left: 3px;
  margin-top: 10px;
}
