.my-patient-table {
  margin-top: 20px;
}
.tableDataCentered {
  text-align: center;
}

.circleStatus {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
  display: inline-table;
}
.yellowCircle {
  background-color: #eaca00;
}

.orangeCircle {
  background-color: #ff9649;
}

.redCircle {
  background-color: #cb0000;
}

.greenCircle {
  background-color: #00ac31;
}

.greyCircle {
  background-color: #94949a;
}

.blueCircle {
  background-color: #1e64d0;
}

.purpleCircle {
  background-color: purple;
}
