.exudate-container {
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  width: 100%;
}

.exudate-container .exudate-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.exudate-container .exudate-container-item {
  width: 100%;
}
.exudate-container .exudate-container-item .exudate-select-input {
  max-width: 272px;
  width: 100%;
}

.exudate-container div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  max-width: 272px;
  width: 100%;
}

.exudate-container .placeHolder div.placeHolder {
  border-right: unset;
  height: 22px;
  max-width: 272px;
  width: 100%;
}

.exudate-container .placeHolder .selectIcon {
  top: 10px;
}

@media screen and (max-width: 920px) {
  .exudate-container .exudate-container-item .exudate-select-input {
    max-width: unset;
    width: calc(100% - 16px);
  }

  .exudate-container div.placeHolder {
    max-width: unset;
    width: calc(100% - 16px);
  }

  .exudate-container .placeHolder div.placeHolder {
    max-width: unset;
    width: calc(100% - 16px);
  }
}
