.patient-fin-responsbility {
  margin: 0;
  width: calc(100% - 4px);
}
.patient-fin-responsbility .pfin-parent-div {
  background-color: #f8f8fa;
  margin: 0px 32px 0px 40px;
}
.pfin-parent-div .patient-fin-component-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.patient-fin-component-title .patient-fin-responsbility-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-left: 24px;
  margin-top: 24px;
  min-height: 20px;
}
.pfin-parent-div .content-sec-firs-pfin-responsbility {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 0px;
}

.content-sec-firs-pfin-responsbility .grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.grid-conatiner .grid-item {
  width: 100%;
  height: fit-content;
}
.content-sec-firs-pfin-responsbility .sub-content-div-pfin-responsbility {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}
.content-sec-firs-pfin-responsbility .sub-content-header-title {
  display: flex;
  flex-direction: row;
}
.sub-content-header-title .content-header {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.sub-content-div-pfin-responsbility
  .pfin-inforesponsbility-content-header-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.sub-content-div-pfin-responsbility .pfin-inforesponsbility-content-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}
.sub-content-div-pfin-responsbility .pfin-inforesponsbility-content-value {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}
.patient-fin-responsbility .static-text {
  margin: 30px 32px 0px 40px;
}
.static-text .paragraph-font-style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.static-text .paragraph-font-style.bold {
  font-weight: 700;
}

.border-space {
  height: 30px;
}
