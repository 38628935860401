.service-request-summary-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 104px 48px 16px;
  width: calc(100% - 120px);
}

.service-request-summary-component .service-request-summary-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 40px;
  width: calc(100% - 80px);
}

.service-request-summary-component
  .service-request-summary-header
  .service-request-summary-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0px;
  text-align: left;
  width: fit-content;
}

.service-request-summary-component
  .service-request-summary-header
  .service-request-summary-header-button {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.service-request-summary-component
  .service-request-summary-header
  .service-request-summary-header-button
  .service-request-summary-download-button {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
  width: fit-content;
}

.service-request-summary-component .short-form {
  margin: 0px 40px;
  width: calc(62.78% - 40px);
}

@media only screen and (max-width: 926px) {
  .service-request-summary-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .service-request-summary-component .service-request-summary-header {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }

  .service-request-summary-component
    .service-request-summary-header
    .service-request-summary-title {
    margin: 16px 0px;
  }

  .service-request-summary-component
    .service-request-summary-header
    .service-request-summary-header-button {
    margin: 8px 0px;
  }

  .service-request-summary-component .short-form {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 475px) {
  .service-request-summary-component .service-request-summary-header {
    flex-direction: column;
    margin: 0px 16px 16px;
  }

  .service-request-summary-component
    .service-request-summary-header
    .service-request-summary-title {
    margin: 0px;
    width: 100%;
  }

  .service-request-summary-component
    .service-request-summary-header
    .service-request-summary-header-button {
    margin: 8px 0px;
    justify-content: center;
    width: 100%;
  }

  .service-request-summary-component
    .service-request-summary-header
    .service-request-summary-header-button
    .download-button {
    text-align: center;
    width: 100%;
    padding: 0px;
    margin: 16px 0px;
  }
}

@media only screen and (max-width: 430px) {
  .service-request-summary-component {
    margin: 0px;
    width: 100%;
  }
}
