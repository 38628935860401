.short-form .manage-site-statuses-for-mobile {
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.manage-site-statuses-for-mobile .site-status-card-container {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.manage-site-statuses-for-mobile .site-status-card-container.gray {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.short-form
  .manage-site-statuses-for-mobile
  .site-status-card-container
  .site-status-card {
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: 100%;
}
.site-status-card .site-status-detail-container {
  display: flex;
  flex-direction: column;
  margin: 0px 8px 0px 0px;
  width: 100%;
}

.site-status-card .site-status-detail-container .site-status-detail-message {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px 0px 8px;
  width: 100%;
}

.site-status-card
  .site-status-detail-container
  .site-status-detail-title-and-value {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.site-status-card
  .site-status-detail-container
  .site-status-detail-title-and-value
  .site-status-detail-title {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 88px;
}

.site-status-card
  .site-status-detail-container
  .site-status-detail-title-and-value
  .site-status-detail-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: calc(100% - 88px);
}

.site-status-card .site-status-detail-container-arrow {
  margin: 0px;
  width: fit-content;
}

.manage-site-statuses-for-mobile .no-site-status-div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.manage-site-statuses-for-mobile .no-site-status-div .no-site-status-message {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px;
  width: 100%;
}
