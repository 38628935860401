.deliveryInstructions-input {
  width: 100%;
}
.deliveryContactMain .delivery-contact-component {
  margin-top: 0px;
  width: 100%;
}
.deliveryContactMain
  .delivery-contact-component
  .delivery-contact-component-grid {
  margin-top: 10px;
  width: 100%;
}
.deliveryContactMain
  .delivery-contact-component
  .delivery-contact-component-grid
  .delivery-contact-component-grid-item {
  width: 100%;
}
.delivery-contact-component-grid-view {
  margin-top: 10px;
}
.delivery-information-input {
  width: 100%;
}
.deliveryContactMain {
  margin: 40px 16px 0 0;
}
.deliveryContactHeader {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.deliveryInstructions-input .showDeliveryInstructionError {
  border: 1px solid #d32f2f !important;
  height: 74px !important;
}
.noDeliveryInstructionError {
  border: none;
  height: 74px !important;
}
.deliveryInstructionLabel {
  margin-top: 4px;
}
.deliveryContact-box-container .deliveryContact-grid-container {
  width: 100%;
}
.deliveryContactCheckBox .deliveryContactCheckBoxRoot {
  margin-right: 10px;
  padding: 0;
}

.deliveryContactDescriptionLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #18181a;
  max-width: max-content;
  margin-top: 10px;
  margin-left: 8px;
}

.deliveryContact-box-container .chkBoxMain {
  display: flex;
  margin-bottom: 18px;
  margin-top: 4px;
}

.non-edit-deliveryContact {
  padding: 10px 8px;
  border: 1px solid var(--msdds-gray-10, #e4e4e8);
  min-height: 68px;
  margin-bottom: 12px;
}
.non-edit-deliveryContact-error {
  border: 1px solid var(--msdds-gray-10, #d32f2f);
}

.non-edit-deliveryContact .non-edit-deliveryContact-container {
  margin: 8px 0px 0px;
  padding: 0px;
  width: 100%;
}

.non-edit-deliveryContact
  .non-edit-deliveryContact-container
  .non-edit-deliveryContact-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.non-edit-deliveryContact-container-view {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
  margin: 0px;
  flex-direction: row;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.non-edit-deliveryContact
  .non-edit-deliveryContact-grid-item
  .delivery-contact-default-mode-div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 8px;
}

.non-edit-deliveryContact
  .non-edit-deliveryContact-grid-item
  .delivery-contact-default-mode-div.edit-button {
  align-items: flex-end;
}

.non-edit-deliveryContact-container-view-item {
  width: 50%;
}
.non-edit-deliveryContact-container-view .deliveryContactTitle-view {
  color: #808084;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}
.non-edit-deliveryContact-container-view .deliveryContactValue-view {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}
.edit-deliveryContact {
  padding: 20px;
  border: 1px solid #cb0000;
  height: auto;
  margin-bottom: 12px;
}

.non-edit-deliveryContact .deliveryContactTitle {
  color: #808084;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}
.non-edit-deliveryContact .deliveryContactValue {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}
.non-edit-deliveryContact .editContact {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 12px 8px 0;
  text-align: right;
  text-decoration-line: underline;
}

.edit-deliveryContact .edit-quick-deliveryContact-container {
  display: flex;
  height: 24px;
  padding-bottom: 0px;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 15px;
}
.edit-deliveryContact .cancelContact {
  color: #0049bd;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  cursor: pointer;
  text-decoration-line: underline;
}
.edit-deliveryContact .cancelContactGrid {
  align-self: center;
}
.edit-deliveryContact .updateCancelContact {
  justify-content: right;
  gap: 30px;
  margin-top: 10px;
}
.edit-deliveryContact .updateCancelContact .deliveryContactUpdateButton {
  border: 1px solid #cb0000;
  &:hover {
    border-color: #cb0000;
  }
}
.delivery-contact-form {
  margin-top: 10px;
}
.prod-info-input.prod-info1 .prod-info-select1 {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.prod-info-input.prod-info1 {
  height: 40.125px;
  width: 300px;
}

.deliveryContactMain .delivery-contact-warning-div {
  display: flex;
  flex-direction: row;
  margin: -8px 0 0;
  width: 100%;
}

.deliveryContactMain .delivery-contact-warning-div .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.deliveryContactMain .delivery-contact-warning-div .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.deliveryContactMain
  .delivery-contact-warning-div
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}

@media only screen and (max-width: 600px) {
  .non-edit-deliveryContact {
    height: auto;
    padding: 10px 8px 10px 10px;
  }
  .non-edit-deliveryContact-container-view {
    flex-direction: column;
    gap: 12px;
  }
  .non-edit-deliveryContact-container-view-item {
    align-self: start;
  }
  .prod-info-input.prod-info1 {
    width: 100%;
  }
}
