.productInformation {
  margin-top: 40px;
  width: 100%;
}

.productInformation .contributing-cause-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.productInformation .prodBoxContianer {
  margin-top: 8px;
  width: 100%;
}

.productInformation .prodInfoGridContainer {
  width: 100%;
}

.prodInfoGridContainer .prodInfoGridItem {
  width: 100%;
}

.productInformation .prodInfoRadioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.productInformation .prodInfoOptionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.productInformation .prodInfoOptionRootActive {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.prodInfoGridItem .prodDescLabel {
  white-space: inherit;
}

.productInformation .prodInfoOptiontxt {
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
  font-family: "3MCircularTT";
}

.prod-info-input.prod-info {
  height: 40.125px;
  width: 300px;
}

.prod-info-input.prod-info .prod-info-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.productHeader {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.productInformation-component .productInformation .prodSelectContainer {
  margin-top: 15px;
}

.productInformation-component
  .productInformation
  .prodSelectContainer
  .productName {
  color: #323234;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  white-space: pre-wrap;
  width: 100%;
}

.productInformation-component
  .productInformation
  .prodSelectContainer
  .products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 0px 0px;
  width: calc(100% - 16px);
}

.productInformation-component
  .productInformation
  .prodSelectContainer
  .products
  .productContent {
  margin: 0px 0px 0px 8px;
  font-weight: 400;
  line-height: 18px;
  font-size: 14px;
}

.productInformation-component
  .productInformation
  .prodSelectContainer
  .products
  .productContent
  .dot-with-space {
  margin-right: 8px;
}

.prodInfoRadioRoot .prodInfoOptionRootActive .checkedClass {
  color: #0049bd;
}
.product-info {
  margin-top: 4px;
}
.star-red {
  color: red;
}
@media only screen and (max-width: 920px) {
  .product-info {
    white-space: pre-wrap;
    width: 100%;
  }

  .prod-info-input.prod-info {
    width: calc(100% - 16px);
  }

  .productInformation .prodInfoRadioRoot {
    margin-top: 8px;
  }
}
.prodSelectContainer .products .productInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 8px;
}

@media only screen and (max-width: 600px) {
  .productInformation-component
    .productInformation
    .prodSelectContainer
    .productName {
    width: calc(100% - 16px);
  }

  .productInformation-component
    .productInformation
    .prodSelectContainer
    .products {
    margin: 32px 0px 0px;
  }
}

@media only screen and (max-width: 440px) {
  .product-info {
    width: calc(100% - 16px);
  }
}
.product-confirmation .paperRoot {
  margin: 16px 8px 40px 8px;
  padding: 10px 16px;
  max-width: 400px;
  width: 100%;
}

.product-confirmation .product-confirmation-header {
  font-size: 20px;
  line-height: 28px;
  color: #323234;
  font-weight: 700;
  width: 85%;
}

.product-confirmation .product-confirmation-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 16px 0px;
}

.product-confirmation .product-confirmation-btn {
  width: 100%;
  margin: 4px 0px;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
}
.sup-tag {
  padding-right: 6px;
}

@media screen and (max-width: 920px) {
  .product-confirmation .paperRoot {
    max-width: 450px;
  }
  .product-confirmation {
    zoom: 80%;
  }
}
