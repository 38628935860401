.facility-settings {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 16px 101px 16px;
  width: 100%;
  min-height: calc(100vh - 144px);
}

.facility-settings .facility-settings-component-route-section {
  margin: 26px 40px;
}

.facility-settings .MuiPaper-root {
  box-shadow: none;
  border-radius: 0;
}

.facility-settings .short-form {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 40px;
  width: 70%;
}

.facility-settings .button-group {
  width: 100%;
}

.facility-settings .facility-settings-loader-pop-up {
  height: 250px;
  width: 350px;
}

.facility-settings-loader-pop-up-div {
  display: flex;
  justify-content: center;
  height: 250px;
  margin: 0;
  width: 350px;
}

.facility-settings .facility-settings-success-pop-up {
  height: 196px;
  width: 574px;
}

.facility-settings-success-pop-up-div {
  display: flex;
  justify-content: center;
  height: 196px;
  margin: 0;
  width: 574px;
}

.facility-settings-need-help-div {
  margin: 16px 16px 16px 0px;
  width: 375px;
  min-height: calc(100vh - 244px);
}

@media only screen and (max-width: 600px) {
  .facility-settings {
    width: auto;
    margin: unset;
  }
  .facility-settings .short-form {
    width: auto;
    margin: 0px 16px 0px 16px;
  }
  .facility-settings .facility-settings-component-route-section {
    margin: 26px 16px;
  }
  .facility-settings-need-help-div {
    margin: 16px 0px;
    width: 100%;
    min-height: 0px;
  }
}

@media only screen and (max-width: 574px) {
  .facility-settings .facility-settings-success-pop-up {
    height: fit-content;
    margin: 16px;
    width: calc(100% - 16px);
  }

  .facility-settings-success-pop-up-div {
    height: fit-content;
    margin: 16px;
    width: calc(100% - 32px);
  }
}