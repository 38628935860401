.assesscomorbidities {
  margin-top: 38px;
  width: 560px;
}
.assesscomorbidities-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.assesscomorbidities .assesscomorbidities-header {
  margin-top: 5px;
  width: 100%;
}
.assesscomorbidities .assesscomorbidities-check-block {
  width: 100%;
  margin-top: 10px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.assesscomorbidities .assesscomorbidities-checkbox-item {
  display: inline-block;
  width: 100%;
  margin-left: 12px;
  margin-bottom: 12px;
}
.assesscomorbidities .assesscomorbidities-checkbox {
  margin-right: 10px;
  padding: 0;
}
.assesscomorbidities
  .assesscomorbidities-checkbox
  .assesscomorbidities-checkbox-root {
  margin-right: 10px;
  margin-top: 20px !important;
  padding: 0;
  width: 100%;
}
.assesscomorbidities .assesscomorbidities-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 4px;
}

.assesscomorbidities .assesscomorbidities-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 4px;
}

.assesscomorbidities .assesscomorbidities-checkbox-description-text-disable {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 4px;
}

@media only screen and (max-width: 600px) {
  .assesscomorbidities .assesscomorbidities-check-block {
    column-count: unset;
  }
  .assesscomorbidities {
    width: 100%;
  }
}
