.inpatient-billing-information-main-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.in-patient-order-billing-information-component
  .in-patient-order-billing-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.billingInfoDiv {
  margin-top: 8px;
  margin-bottom: 16px;
}

.billingInfoDiv .billingInfoDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
  width: 100%;
}

.in-patient-order-billing-information-component
  .in-patient-order-billing-information-grid-container
  .in-patient-order-billing-information-grid-item {
  width: 100%;
}

.in-patient-order-billing-information-component
  .in-patient-order-billing-information-grid-container
  .in-patient-order-billing-information-grid-item
  .billing-information-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}
.in-patient-order-billing-information-component
  .in-patient-order-billing-information-grid-container
  .in-patient-order-billing-information-grid-item
  .billing-information-input {
  width: 100%;
}

@media only screen and (max-width: 926px) {
  .in-patient-order-patient-information-component {
    width: 100%;
  }
}
