.in-patient-supply-order-add-button {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: bolder;
  cursor: pointer;
}

.in-patient-supply-order-add-btn-div {
  padding-left: 8px;
}

@media screen and (max-width: 600px) {
  .in-patient-supply-order-add-btn-div {
    padding-left: 0px;
  }
}
