.unlinked-user-table-container {
  border: 1px solid #e4e4e8;
  margin: 0px;
  min-height: 300px;
  overflow: auto;
  width: 100%;
}

.unlinked-user-table-container .unlinked-users-table {
  border-spacing: 0;
  width: 100%;
}

.unlinked-users-table-users-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.unlinked-user-table-container
  .unlinked-users-table
  .unlinked-user-tables-link-to-facility {
  color: #cb0000;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration-line: underline;
  padding: 10px;
  text-transform: capitalize;
}

.unlinked-user-table-container
  .unlinked-users-table
  .unlinked-user-tables-link-to-facility-disabled {
  color: #949ab6;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration-line: underline;
  padding: 10px;
  text-transform: capitalize;
}

.unlinked-user-tables-email {
  color: #0049bd;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
}

.unlinked-user-tables-data {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.unlinked-users-table {
  width: 100%;
  border-spacing: 0;
}

.unlinked-users-table th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 2px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}

th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.unlinked-users-table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.unlinked-users-table tbody tr:first-child td {
  border-top: none;
}

.unlinked-users-table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.unlinked-users-table tbody tr:nth-child(2n) td {
  background: #fff;
}

.unlinked-users-table thead th:nth-child(1) {
  padding: 4px 10px;
  min-width: 100px;
  width: 10%;
}

.unlinked-users-table thead th:nth-child(2) {
  padding: 4px 10px;
  min-width: 100px;
  width: 15%;
}

.unlinked-users-table thead th:nth-child(3) {
  padding: 4px 10px;
  min-width: 100px;
  width: 15%;
}

.unlinked-users-table thead th:nth-child(4) {
  padding: 4px 10px;
  min-width: 100px;
  width: 15%;
}

.unlinked-users-table thead th:nth-child(5) {
  padding: 4px 10px;
  min-width: 100px;
  width: 15%;
}

.unlinked-users-table thead th:nth-child(6) {
  padding: 4px 10px;
  min-width: 150px;
  width: 30%;
}

.unlinked-users-table th.up {
  background-image: url("../../../../../assets/up_arrow.svg");
}

.unlinked-users-table th.down {
  background-image: url("../../../../../assets/down_arrow.svg");
}

.unlinked-users-table th.default {
  background-image: url("../../../../../assets/default.svg");
}

.img {
  background-color: white;
}

.unlinked-user-table-container .facilityDetails .facilityName {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 11px;
}

.unlinked-user-table-container .facilityDetails .facilityAddress {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.unlinked-user-table-container .no-data {
  align-items: center;
  color: #323234;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  height: 300px;
  line-height: 18px;
  text-align-last: center;
}

@media only screen and (max-width: 600px) {
  .unlinked-user-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
