.addTerritoryContainer {
  display: block;
  flex-flow: column;
  padding: 16px 16px 16px 16px;
}
.addTerritoryContainerList .title {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  text-align: left;
  margin-top: 0px;
}
.addTerritoryContainer .title {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  text-align: left;
  margin-top: 0px;
}
.territoryList .selectButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}
.territoryList {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  gap: 10%;
  padding: 10px;
}
.territoryList.gray {
  background-color: #f8f8fa;
}
.body {
  width: 94%;
}
.territoryRegion {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.territoriesDetails {
  font-size: 14px;
}
.addTerritoryMainContainer .changeButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}
.addTerritoryMainContainer {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  padding: 16px 16px 16px 0px;
}
.addTerritoryMainContainer .addTerritoryDetails {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.addTerritoryContainer .noRecords {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  height: 100px;
}
