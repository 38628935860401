.manage-site-statuses-component-container {
  width: 100%;
}

.manage-site-statuses-component-container .manage-site-statuses-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  min-height: calc(100% - 32px);
  width: calc(100% - 72px);
}

.manage-site-statuses-component .manage-site-statuses-component-route-section {
  margin: 24px 24px 16px;
}

.manage-site-statuses-component .manage-site-statuses-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 24px 8px;
  width: calc(100% - 48px);
}

.manage-site-statuses-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  width: calc(100% - 48px);
}

.manage-site-statuses-component
  .short-form
  .manage-site-statuses-header-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-site-statuses-component
  .short-form
  .manage-site-statuses-header-grid-container
  .manage-site-statuses-header-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-site-statuses-header-grid-item .site-status-filters-and-add-new {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  row-gap: 8px;
  width: 100%;
}

.manage-site-statuses-header-grid-item
  .site-status-filters-and-add-new
  .site-status-filters {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0px 6px;
  width: 175px;
}

.manage-site-statuses-header-grid-item
  .site-status-filters-and-add-new
  .add-new-site-status-button {
  align-items: center;
  color: #0049bd;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  margin: 0px;
  text-transform: none;
  text-decoration: underline;
  width: 175px;
}

.short-form .manage-site-statuses-header-grid-item .site-statuses-input {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 42px;
  line-height: 18px;
  width: 100%;
}

.short-form
  .manage-site-statuses-header-grid-item
  .site-statuses-input
  .site-statuses-select {
  align-items: center;
  display: block;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.short-form div.placeholder {
  border-radius: 2px;
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 42px;
  line-height: 18px;
  width: 100%;
}

.short-form .placeholder div.placeholder {
  border-right: unset;
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 20px;
  line-height: 18px;
  width: 100%;
}

.ag-theme-quartz .site-status-edit {
  color: #0049bd;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 10px;
  text-decoration-line: underline;
  text-transform: capitalize;
  width: fit-content;
}

.manage-site-statuses-component-container .manage-site-statuses-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.manage-site-statuses-loader-pop-up .manage-site-statuses-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

@media only screen and (max-width: 926px) {
  .manage-site-statuses-component-container .manage-site-statuses-component {
    margin: 0px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .manage-site-statuses-component-container .manage-site-statuses-component {
    margin: 0px;
    width: 100%;
  }

  .manage-site-statuses-component .short-form {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .manage-site-statuses-header-grid-item .site-status-filters-and-add-new {
    justify-content: flex-start;
    row-gap: 0px;
  }

  .manage-site-statuses-header-grid-item
    .site-status-filters-and-add-new
    .site-status-filters {
    margin: 0px 4px;
    width: calc((100% - 46px) / 2);
  }

  .manage-site-statuses-header-grid-item
    .site-status-filters-and-add-new
    .add-new-site-status-button {
    width: 30px;
  }
}

@media screen and (max-width: 450px) {
  .manage-site-statuses-loader-pop-up .manage-site-statuses-loader {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 414px) {
  .manage-site-statuses-header-grid-item
    .site-status-filters-and-add-new
    .site-status-filters {
    margin: 0px 2px;
    width: calc((100% - 76px) / 2);
  }

  .manage-site-statuses-header-grid-item
    .site-status-filters-and-add-new
    .add-new-site-status-button {
    width: 30px;
  }
}
