.acute-pick-up-request-first-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}

.acute-pick-up-request-first-page-component .short-form {
  margin: 40px;
  width: 62.78%;
}

.acute-pick-up-request-first-page-component
  .acute-pick-up-request-route-section {
  margin-bottom: 26px;
  width: 100%;
}

.acute-pick-up-request-first-page-component .acute-pick-up-request-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  min-height: 24px;
  line-height: 24px;
  width: 100%;
}

@media only screen and (max-width: 926px) {
  .acute-pick-up-request-first-page-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .acute-pick-up-request-first-page-component .short-form {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 600px) {
  .acute-pick-up-request-first-page-component {
    margin: 0px;
    width: 100%;
  }
}
