.end-therapy-component {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.end-therapy-component .end-therapy-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 20px 16px 8px 16px;
  width: calc(100% - 32px);
}

.end-therapy-component .end-therapy-grid-container {
  padding: 0px;
  margin: 16px;
  width: calc(100% - 32px);
}

.end-therapy-grid-container .end-therapy-div-desc {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.end-therapy-component .end-therapy-grid-container .grid-item {
  padding: 0px;
  margin: 0px;
}
.end-therapy-component .end-therapy-grid-container .grid-item-product {
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.end-therapy-grid-container .grid-item .product-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-bottom: 12px;
  width: 100%;
}
.end-therapy-grid-container .grid-item .serialNum-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-bottom: 12px;
  padding-left: 20px;
}

.end-therapy-grid-container .grid-item .product-details .product-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.end-therapy-grid-container .grid-item .serialNum-details .serialNum-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.end-therapy-grid-container .grid-item .product-details .product-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
  margin-bottom: 8px;
}
.end-therapy-grid-container .grid-item .serialNum-details .serialNum-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
  margin-bottom: 8px;
}

.end-therapy-grid-container .grid-item .storage-location-input {
  width: 100%;
  border-radius: 0px;
}

.end-therapy-grid-container
  .grid-item
  .storage-location-input.storage-location
  .storage-location-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.end-therapy-grid-container .therapy-date-container {
  display: flex;
  gap: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.end-therapy-grid-container .therapy-date-container .therapy-title-style {
  border-radius: 0px;
  border: 0px;
}

.end-therapy-grid-container
  .therapy-date-container
  .therapy-title-style
  .therapy-start-date {
  border-radius: 0px;
  border: 0px;
}

.therapy-date-container
  .therapy-title-style
  .therapy-start-date
  .disabled-input {
  color: #9e9e9e;
  background-color: #f5f5f5;
}

.end-therapy-grid-container
  .therapy-date-container
  .therapy-end-date-title-style {
  border-radius: 0px;
  border: 0px;
}

.end-therapy-grid-container .therapy-date-container .therapy-end-date {
  border-radius: 0px;
  border: 0px;
}
.grid-item .footer-button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.grid-item .footer-button .cancel-button {
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.grid-item .footer-button .next-button {
  background-color: #0049bd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.end-therapy-grid-container .update-buttons-container {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}

.update-buttons-container .inventoryUpdate-button-container {
  width: 100%;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .end-therapy-grid-container .update-buttons-container {
    display: flex;
    flex-direction: column;
  }

  .update-buttons-container .inventoryUpdate-button-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .end-therapy-component {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .end-therapy-component {
    margin: 0px 8px;
    width: calc(100% - 16px);
  }
}
