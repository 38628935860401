.problem-details-main-div {
  width: 100%;
  margin-top: 40px;
}
.device-problem-div {
  width: 100%;
  margin-top: 12px;
}
.problem-details-main-div .device-problem-section {
  display: flex;
}
.device-problem-div .problem-details-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
}
.device-problem-div .deviceProblemRadioRoot {
  flex-direction: row;
  gap: 12px;
}
.device-problem-div .problem-details-input-base {
  width: 22%;
}
.device-problem-div .deviceProblemRootActive {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 22%;
  text-wrap: balance;
}
.device-problem-div .deviceProblemOptionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 22%;
  text-wrap: balance;
}
.device-problem-div .deviceProblemOptiontxt {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.device-problem-div .problem-details-error-code-input {
  width: 100%;
}
.other-affected-features .problem-details-error-code-input {
  width: 100%;
}
.date-of-problems-date input.input {
  border-right: 2px;
  height: 22px;
  width: 100%;
}

div .date-of-problems-date .adornedRoot {
  position: absolute;
  right: 18px;
}

.date-of-problems-date .outline {
  border-radius: 1px;
  border: 1px solid #94949a !important;
  width: calc(100% - 18px);
}

.date-of-problems-date.show-error .outline {
  border: 1px solid #cb0000 !important;
}

.date-of-problems-date.no-error.Mui-error .outline {
  border: 1px solid #94949a !important;
}
.device-problem-div .feature-affected-entry-select {
  width: 100%;
}
.device-problem-div .feature-affected-entry-input {
  width: 100%;
}
.placeholder div.placeholder {
  height: 42px;
  border-right: unset;
  height: 20px;
  width: 100%;
}
.device-problem-div .problem-details-input-label {
  width: 22%;
}
.device-problem-div .problem-details-input-base {
  width: 100%;
}
.device-problem-div .date-of-problem-div {
  margin-left: 12px;
}
.device-problem-div .feature-affected-empty-div {
  width: 70%;
}
.device-problem-div .other-feature-affected-div {
  width: 100%;
}
.device-problem-div .problem-details-input-affected-label {
  width: 35%;
}
@media only screen and (max-width: 920px) {
  .device-problem-div .deviceProblemRadioRoot {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .other-affected-features .problem-details-error-code-input {
    width: 100%;
  }
  .device-problem-div .deviceProblemOptiontxt {
    width: 100%;
  }
  .device-problem-div .deviceProblemOptionRoot {
    width: 100%;
  }
  .device-problem-div .deviceProblemRootActive {
    width: 100%;
  }
  .device-problem-div .other-feature-affected-div {
    width: 28%;
  }
  .device-problem-div .problem-details-input-label {
    width: 100%;
  }
  .problem-details-main-div .device-problem-section {
    display: flex;
    flex-direction: column;
  }
  .device-problem-div .date-of-problem-div {
    margin-left: 0;
    margin-top: 10px;
  }
  .problem-details-main-div
    .device-problem-div
    .problem-details-input-affected-label {
    width: 100%;
  }
}
