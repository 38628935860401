.sales-rounding-order-information-div {
  margin: 30px 0;
  width: 100%;
}

.sales-rounding-order-information-div .sales-rounding-order-information-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
}
