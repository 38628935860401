.parent-option {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.parent-option .menu-option {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.parent-option .menu-option .checkbox {
  margin: 0px;
  padding: 0px;
}

.parent-option .child-options {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.parent-option .child-options .menu-option {
  margin: 0px;
  padding-left: 20px;
  width: calc(100% - 20px);
}

.parent-option .child-options .menu-option .checkbox {
  margin: 0px;
  padding: 0px;
}

.checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  min-height: 18px;
  line-height: 18px;
  margin: 0;
  padding: 8px;
  width: 100%;
}

.drop-down-footer-buttons {
  display: flex;
  flex-direction: row;
  padding: 16px;
  width: calc(100% - 32px);
}

.drop-down-footer-buttons .drop-down-footer-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-transform: none;
  text-decoration: underline;
}

.drop-down-footer-buttons .drop-down-footer-button:hover {
  text-decoration-line: underline;
}
