.deliveryAddress-main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.deliveryAddress-main .delivery-address-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.deliveryAddress-main .delivery-address-container .delivery-address-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.delivery-address-container .address-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.delivery-address-container .delivery-instruction-div {
  margin-top: 8px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  max-width: 560px;
}
.delivery-address-container .deliveryAddress-instruction-section {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.delivery-address-container .current-address-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: -9px;
}
.delivery-address-container .chkBoxMain .current-address-chkbox {
  padding: 0;
  margin-right: 16px;
  margin-top: -9px;
}
.delivery-address-container .address-line-container {
  margin-top: 20px;
}
.delivery-address-container .city-state-container {
  margin-top: 8px;
}
.address-line-container .address-line1-item {
  width: 100%;
  max-width: 267px;
  margin-right: 16px;
}
.address-line-container .address-line1 {
  width: 100%;
}
.address-line-container .address-line2-item {
  width: 100%;
  max-width: 270px;
}
.address-line-container .address-line2 {
  width: 100%;
}
.city-state-container .city-item {
  max-width: 267px;
  width: 100%;
  margin-right: 16px;
}
.city-item .city {
  width: 100%;
}
.city-state-container .state-item {
  width: 100%;
  max-width: 128px;
  margin-right: 16px;
}
.city-state-container .state-item .state {
  width: 100%;
  height: 40px;
}
.city-state-container .state-item .muistate-select {
  width: 100%;
  height: 20px;
}
.city-state-container .zipcode-item {
  width: 100%;
  max-width: 128px;
}
.zipcode-item .zipcode {
  width: 100%;
}
.patient-current-address {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
}

.deliveryAddress-main .delivery-address-container .current-address-container {
  margin-top: 27px;
  padding: 0px;
  width: 100%;
}

.current-address-container .address-lines {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
@media screen and (max-width: 920px) {
  .deliveryAddress-main .delivery-address-container .city-state-container {
    display: flex;
    flex-direction: row;
  }
  .address-line-container .address-line1-item {
    max-width: none;
    margin: 0;
  }
  .city-state-container .city-item {
    max-width: none;
    margin: 0;
  }
  .city-state-container .state-item {
    margin: 5px 0 0 0;
    max-width: none;
  }
  .address-line-container .address-line2-item {
    margin-top: 5px;
    max-width: none;
  }
  .city-state-container .zipcode-item {
    margin-top: 5px;
    max-width: none;
  }
}

.supplyOrder-address-state-input {
  height: 42px;
}

.supplyOrder-address-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.supplyOrder-address-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.supplyOrder-address-state-input .supplyOrder-address-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
