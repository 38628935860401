.add-facility-to-user-component {
  display: flex;
  flex-flow: column;
  height: 488px;
  margin: 0 16px;
  width: 574px;
}

.add-facility-to-user-component .title {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  margin: 8px 0px 16px;
  text-align: left;
}

.add-facility-to-user-component .body {
  flex: 1 1 auto;
  margin: 0px;
}

.add-facility-to-user-component .body .facility-container {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
}

.add-facility-to-user-component .body .facility-container.gray {
  background-color: #f8f8fa;
}

.facility-container .facility-address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-address .facility-address-div {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.facility-address-div .selected-facility-checkbox {
  height: 16px;
  margin: 0px 20px 0px 16px;
  padding: 0px;
  width: 16px;
}

.facility-address-div .facility-address-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0;
}

.facility-address-details .facility-name {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.facility-address-details .facility-address-value {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.facility-container .facility-number {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-number .facility-number-div {
  display: flex;
  flex-direction: row;
  margin: 32px 16px 16px;
  gap: 4px;
}

.facility-number-div .facility-number-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.facility-number-div .facility-number-value {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.add-facility-to-user-component .add-facility-buttons {
  bottom: 0px;
  display: flex;
  flex: 0 1 72px;
  flex-direction: row;
  height: 72px;
  margin: 0px;
  width: 100%;
}

.add-facility-buttons .cancelBtn {
  border-radius: 0px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 16px 4px 16px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.add-facility-buttons .addBtn {
  border-radius: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 16px 0px 16px 4px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.add-facility-buttons .addBtn.enabled {
  background-color: #0049bd;
  color: #ffffff;
}
.add-facility-buttons .addBtn.disbaled {
  background-color: #e4e4e8;
  color: #76767a;
}

.add-facility-select-deselect-div {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.add-facility-select-deselect-div .select-all {
  color: #0049bd;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: none;
}

.add-facility-select-deselect-div .select-all:hover {
  text-decoration-line: underline;
}

.add-facility-select-deselect-div .deselect-all {
  color: #cb0000;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: none;
}

.add-facility-select-deselect-div .deselect-all:hover {
  text-decoration-line: underline;
}
