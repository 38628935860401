.in-patient-supply-order-review-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}
.in-patient-supply-order-review-page-component .short-form {
  margin: 40px;
}
.in-patient-supply-order-review-page-component
  .short-form
  .in-patient-supply-order-review-page-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 8px;
  text-align: left;
}
.in-patient-supply-order-review-page-component
  .short-form
  .in-patient-supply-order-review-page-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 8px 0px 32px;
  text-align: left;
}
@media only screen and (max-width: 926px) {
  .in-patient-supply-order-review-page-component {
    margin: 0px;
    width: 100%;
  }
  .in-patient-supply-order-review-page-component .short-form {
    margin: 16px;
  }
}
