.in-patient-supply-order-first-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
  min-height: 600px;
}
.in-patient-supply-order-first-page-component .in-patient-supply-order-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.in-patient-supply-order-first-page-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 40px 40px 16px 40px;
  width: calc(100% - 80px);
}

.short-form .in-patient-supply-order-first-page-product-info-section {
  width: 62%;
}

.short-form .in-patient-supply-order-first-page-product-name-desc {
  margin: 10px 0px;
  width: 100%;
}
.short-form .in-patient-supply-order-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 20px 0px;
  text-align: left;
  width: 100%;
}
.short-form .in-patient-supply-order-link-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.short-form .in-patient-supply-order-link-button {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-right: 24px;
  text-decoration-line: underline;
}

.short-form .in-patient-supply-order-link-main .in-patient-supply-order-button {
  border: 1px solid #0049bd;
  bottom: 40px;
  color: #0049bd;
  position: relative;
  text-transform: none;
}

.dressing-size-dialog {
  min-width: 713px;
  min-height: 397px;
}

.dressing-size-main-container {
  margin-top: 40px;
  padding: 16px 16px 32px 16px;
}

.dressing-size-body-dailog {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.dressingsizebody-dailog {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.dressing-Img {
  width: 100%;
  height: 100%;
}

.in-patient-supply-first-searchbar {
  border-radius: 2px;
  background: #fff;
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 14px;
  gap: 10px;
  width: 100%;
}

.in-patient-supply-first-searchbar .filter-so-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  height: 40px;
  margin-right: 5px;
  width: 100%;
}
.in-patient-supply-first-searchbar .MuiOutlinedInput-root {
  max-height: 42px;
}

.in-patient-supply-first-searchbar .status-input {
  max-height: 42px;
  color: #76767a;
  width: 100%;
}

.in-patient-supply-first-searchbar .status-input .status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
  max-height: 42px;
}

.in-patient-supply-first-searchbar div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.in-patient-supply-first-searchbar .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.inpatient-supply-order-product-column .ag-cell-wrapper {
  width: 100%;
}
.inpatient-supply-amount-column {
  padding-right: 4px;
}

.inpatient-supply-order-product-image {
  height: 54px;
  width: 72px;
  margin-left: 8px;
}

.popper-arrow-container {
  position: relative;
  margin-top: 16px;
}

.popper-arrow-container .popper-arrow-background-box {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.1);
  top: -11px;
  left: auto;
  right: 10%;
  z-index: 1;
}

.popper-arrow-container .popper-arrow-background-box {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.1);
  top: -11px;
  left: auto;
  right: 10%;
  z-index: 1;
}

.popper-arrow-container .popper-arrow-box {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  left: auto;
  right: 10%;
  z-index: 1;
}

.popper-arrow-container .popper-custom-component {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  padding: 16px;
  width: 280px;
  z-index: 100;
}

.in-patient-supply-order-container-popper {
  height: 100%;
  width: 100%;
}

.in-patient-supply-order-container-popper
  .in-patient-supply-order-container-popper-div {
  display: flex;
  justify-content: center;
  margin: 0;
  height: calc(100% - 32px);
  overflow: hidden;
  padding: 16px;
  width: 280px;
}

@media only screen and (max-width: 926px) {
  .short-form .in-patient-supply-order-first-page-product-info-section {
    width: 100%;
  }

  .dressing-size-dialog {
    min-width: unset;
    min-height: unset;
    width: auto;
    height: auto;
  }

  .in-patient-supply-order-first-page-component {
    width: calc(100% - 40px);
  }
}

.in-patient-supply-order-first-page-popup
  .in-patient-supply-order-first-page-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
}
.in-patient-supply-order-first-page-popup .paperRoot {
  width: 430px;
  padding: 16px;
}
.in-patient-supply-order-product-not-available-popup
  .in-patient-supply-order-product-not-available-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 170px;
  margin: auto;
  width: 100%;
}
.in-patient-supply-order-product-not-available-popup .paperRoot {
  width: 320px;
}

@media screen and (max-width: 600px) {
  .in-patient-supply-order-first-page-component {
    margin: 0;
    width: 100%;
  }
  .in-patient-supply-order-first-page-component .short-form {
    margin: 0 0 40px 0;
    width: 100%;
  }

  .short-form .in-patient-supply-order-first-page-product-name-desc {
    margin: 0px 16px;
    width: auto;
  }
  .short-form .in-patient-supply-order-link-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 0 0 16px;
    width: auto;
  }
  .in-patient-supply-order-first-page-component
    .inpatient-supply-order-dropdown {
    margin: 4px 0px 0px 0px;
  }
  .in-patient-supply-order-first-page-component {
    height: 100%;
    margin: 0;
    width: 100%;
  }
  .in-patient-supply-order-first-page-component .short-form {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: calc(100% - 32px);
  }
  .in-patient-supply-order-first-page-component
    .in-patient-supply-first-searchbar
    .inpatient-supply-order-dropdown {
    margin-top: 4px;
  }
  .short-form
    .in-patient-supply-order-link-main
    .in-patient-supply-order-button {
    align-self: flex-end;
    position: relative;
    bottom: 0px;
    margin-top: 8px;
  }
  .short-form .in-patient-supply-order-link-main {
    flex-direction: column;
    align-items: flex-start;
  }
  .short-form
    .in-patient-supply-order-link-main
    .in-patient-supply-order-button {
    align-self: flex-end;
    position: relative;
    bottom: 0px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 450px) {
  .in-patient-supply-order-product-not-available-popup .paperRoot {
    margin: 16px;
    width: 300px;
  }

  .in-patient-supply-order-product-not-available-popup
    .in-patient-supply-order-product-not-available-popup-div {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
  .in-patient-supply-order-first-page-popup .paperRoot {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .in-patient-supply-order-first-page-popup
    .in-patient-supply-order-first-page-popup-div {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}
