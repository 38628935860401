.edit-patient-information-component {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 75%;
}

.edit-patient-information-component .edit-order-patient-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.edit-patient-information-component
  .edit-order-patient-information-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.edit-patient-information-component
  .edit-order-patient-information-grid-container
  .edit-order-patient-information-grid-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.edit-patient-information-component
  .edit-order-patient-information-grid-container
  .edit-order-patient-information-grid-item
  .patient-information-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: calc(100% - 8px);
}

.edit-patient-information-component
  .edit-order-patient-information-grid-container
  .edit-order-patient-information-grid-item
  .patient-information-input {
  width: calc(100% - 8px);
}

.edit-order-patient-information-date-of-birth input.input {
  border-right: 2px;
  width: calc(100% - 8px);
}

div.edit-order-patient-information-date-of-birth {
  width: calc(100% - 8px);
}

div .edit-order-patient-information-date-of-birth .adornedRoot {
  position: absolute;
  right: 18px;
}

.edit-order-patient-information-date-of-birth .outline {
  border-radius: 1px;
  border: 1px solid #94949a !important;
  width: calc(100% - 18px);
}

.edit-order-patient-information-date-of-birth.show-error .outline {
  border: 1px solid #cb0000 !important;
}

.edit-order-patient-information-date-of-birth.no-error.Mui-error .outline {
  border: 1px solid #94949a !important;
}

.edit-patient-information-component .edit-info-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  margin-right: 8px;
  margin-top: 8px;
}
.edit-patient-information-component .edit-info-button-group .edit-button {
  width: 20%;
}
@media only screen and (max-width: 926px) {
  .edit-patient-information-component {
    width: 100%;
  }
  .edit-patient-information-component .edit-info-button-group .edit-button {
    width: 32%;
  }
}
