.wound-assessment-detail-tab-item {
  background: #ffffff;
}
.wound-assessment-detail-tab-item .wound-assessment-detail-tab-parent-div {
  align-items: flex-start;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
.wound-assessment-detail-tab-item
  .wound-assessment-detail-tab-parent-div.bottom {
  margin: 40px 16px 16px 16px;
}
.wound-assessment-detail-tab-parent-div
  .wound-assessment-details-tab-wound-number-heading {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin: 16px 0 0 11px;
}
.wound-assessment-detail-tab-parent-div
  .wound-assessment-details-tab-wound-location {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0 0 16px;
}
.wound-assessment-detail-tab-parent-div
  .wound-assessment-details-tab-wound-type {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 16px;
}
.wound-assessment-detail-tab-parent-div
  .wound-assessment-details-tab-wound-assessed-on {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 16px 16px;
}
.order-supplies-empty-pop-up .paperRoot {
  width: 200px;
  height: 56px;
}

/*-----------Upcoming Assessment-----------------*/
.upcoming-assessment-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 14px;
}

.upcoming-assessment-box-buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  box-sizing: border-box;
  gap: 8px;
  margin-left: 15px;
}
.upcoming-assessment-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #76767a;
  border-radius: 2px;
  background: #ffffff;
  width: 227px;
  height: 45px;
  justify-content: center;
  align-items: center;
}
.upcoming-assessment-box-headingtext {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #76767a;
  text-align: center;
}

/* mobile css */
@media only screen and (max-width: 920px) {
  .upcoming-assessment-box {
    margin: 0;
    margin-bottom: 5px;
  }
  .wound-assessment-detail-tab-container {
    overflow: auto;
    flex-direction: column;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .wound-assessment-detail-tab-container::-webkit-scrollbar {
    display: none;
  }
  .upcoming-assessment-box-buttons-container {
    flex-wrap: unset;
    width: max-content;
  }
}
