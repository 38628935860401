.gridMain {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.quantityStepper {
  margin-top: 19px;
  cursor: pointer;
}

.quantityStepper-disabled {
  margin-top: 19px;
  color: #94949a;
  cursor: default;
}

.gridMain .peelAndPlaceQuantity {
  display: flex;
  justify-content: center;
  min-width: 32px;
  position: relative;
  top: 13px;
}

.gridMain .quantityInput {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 32px;
}
.gridMain .quantityInput .quantityInputRoot {
  padding: 9px 9px 7px 9px;
  text-align: center;
}

.gridMain .QuantityInputOutline {
  height: unset;
}

.gridMain .quantityLabel {
  margin-left: -7px !important;
}
.gridMain .quantityLabelDisabled {
  margin-left: -7px !important;
  display: none;
}
.quantityLabelroot {
  width: 40px;
}
