.site-status-activation-component {
  margin-top: 38px;
  width: 100%;
}

.site-status-activation-component .site-status-activation-header {
  color: #323234;
  font-style: normal;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.site-status-activation-grid-container .site-status-activation-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 8px 0px;
}

.site-status-activation-grid-container .site-status-activation-checkbox-div {
  padding: 12px;
  width: 100%;
}

.activationCheckBox .activationCheckBoxRoot {
  margin-right: 10px;
  padding: 0;
}

.site-status-activation-grid-item .activation-chkBoxDescriptionLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #18181a;
  max-width: max-content;
  margin-top: 10px;
}

@media only screen and (max-width: 920px) {
  .site-status-activation-grid-container .site-status-activation-checkbox-div {
    padding: 12px 0px;
  }
}
