.sendnote-caregiver-name {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.sendnote-selected-main-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  width: 100%;
}
.sendnote-sub-caregiver-container {
  margin: 20px;
}

.sendnote-caregiver-subsection-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.sendnote-caregiver-name-address-header {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
}

.sendnote-subtext-caregiver {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.sendnote-homecareprovider {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}

.sendnote-homecareprovider .sendnote-homecare-provider-label-item {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.sendnote-homecareprovider-main {
  width: 100%;
}

.sendnote-homecareprovider .sendnote-homecareprovider-button {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  text-transform: none;
  width: 100%;
}

.sendnote-homecare-provider-box-container {
  display: flex;
  margin-top: 30px;
}
.sendnote-sub-caregiver-gridMain-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sendnote-homecare-provider-box-container
  .sendnote-homecare-provider-grid-container {
  width: 100%;
  margin-left: 0px;
}

.sendnote-change-home-care-provider-btn .sendnote-hcp-change-provider-btn {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
  /* Needed to fix height/alignment for all resloutions */
  margin-top: -5px;
}
.sendnote-change-home-care-provider-btn
  .sendnote-hcp-change-provider-btn:hover {
  text-decoration: underline;
  background-color: #ffffff;
}

.sendnote-caregiver-address-full {
  width: 150px;
}
@media screen and (max-width: 900px) {
  .sendnote-caregiver-name-address-header-mobile {
    margin-top: 10px;
  }
  .sendnote-address-part .sendnote-caregiver-name-phone-header-mobile {
    display: flex;
    justify-content: start;
  }
  .sendnote-caregiver-name-address-main{
    margin-bottom: 10px;
  }
  
}
@media screen and (max-width: 1200px) {
  .sendnote-caregiver-name-phone-header-mobile {
    display: flex;
    justify-content: center;
  }
}

