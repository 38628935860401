.wound-undermining-main-container {
  width: 620px;
}

.wound-undermining-main-container .undermining {
  width: 100%;
  margin-top: 41px;
}

.undermining-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.wound-undermining-main-container .undermining .undermining-box-container {
  margin-top: 12px;
  width: 100%;
}

.wound-undermining-main-container .undermining .undermining-grid-container {
  width: 100%;
}

.wound-undermining-main-container
  .undermining
  .undermining-grid-container
  .undermining-grid-item-u {
  width: 100%;
}

.undermining .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.undermining .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.undermining .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.undermining .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.undermining .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.depth-input {
  width: 100%;
}

.undermining .clockPosition-input .clockPosition-select {
  height: 12px;
  width: 242px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.undermining .clockPosition-input {
  width: 105px;
}

.undermining .placeHolder .selectIcon {
  top: 10px;
}

.undermining .undermining-box-container div.placeHolder {
  border-radius: 2px;
  width: 100px;
}

.undermining .placeHolder div.placeHolder {
  border-right: unset;
  height: 10px;
  width: 242px;
}

.undermining .MuiSelect-icon {
  top: calc(50% - 0.4em);
}

.undermining .undermining-grid-item-u .text-div {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 9px 0px;
  padding: 0px;
}

.undermining .undermining-grid-item-u .text-div .text {
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

@media only screen and (max-width: 920px) {
  .wound-undermining-main-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .undermining .undermining-grid-item-u .text-div .text {
    margin-top: 12px;
  }
  .undermining .undermining-grid-item-u .text-div {
    margin-top: 13px;
  }

  .wndAssessemntfromToTextMobile {
    top: 15px;
    position: relative;
  }
  .wound-undermining-main-container {
    width: 100%;
  }
  .wound-undermining-component {
    width: 100%;
  }
  .startingPositionGrid {
    margin-left: 28px !important;
  }
  .undermining .undermining-grid-item-u .text-div .text {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .undermining .undermining-grid-item-u .text-div .text {
    margin-left: 0px;
    text-align: left;
  }
  .undermining .undermining-grid-item-u .text-div {
    margin-top: 13px;
  }
}
