.myLists-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  width: 100%;
}

.myLists-component .myLists-component-route-section {
  margin: 26px 40px;
}

.myLists-component .myLists-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}

.myLists-component .myLists-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 40px 0px;
}
.myLists-component .myLists-tab-div {
  width: 100%;
}
.myListMainDiv {
  width: 100%;
  margin-top: 20px;
}
.myLists-tab-div .myLists-scroll-table-header {
  margin-left: 25px;
}
.scrollableTabHeader .myLists-scroll-tabs-textlabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: none;
  width: 25%;
}
.scrollableTabHeader .scroll-tabs-textlabel-select-order-overview {
  color: #0049bd !important;
  font-size: 14px;
  text-transform: none;
  width: 240px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .myLists-component {
    margin: 0px;
    min-height: calc(100vh - 144px);
  }
  .scrollableTabHeader .myLists-scroll-tabs-textlabel {
    width: 70%;
  }
}
