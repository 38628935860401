.pickupConfirm {
  margin-bottom: 54px;
}
.pickupConfirmDesp-summarypage-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  color: #323234;
}
.pickupConfirmDesp-saveOrderDiv {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.pickupConfirmDesp-saveOrderDiv .pickupConfirmDesp-saveOrderBtn {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
}
.pickupConfirmDesp {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.pickupConfirmDesp-short-form {
  display: flex;
  flex-direction: column;
  width: 66.78%;
  padding-top: 37px;
}

.pickupConfirm .pick-up-pending-grid-container {
  margin: 16px 0px 0px;
  padding: 0px;
  width: 100%;
}

.pick-up-pending-grid-container .pick-up-pending-header {
  margin: 0px;
  min-height: 84px;
  padding: 0px;
  width: 100%;
}

.pick-up-pending-header .pick-up-pending-header-note-and-print-button {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
}

.pick-up-pending-header
  .pick-up-pending-header-note-and-print-button
  .pick-up-pending-note {
  color: #1e64d0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.pick-up-pending-header
  .pick-up-pending-header-note-and-print-button
  .pick-up-pending-note-link {
  color: 1e64d0;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-left: 4px;
  text-decoration-line: underline;
}

.pick-up-pending-header .pick-up-pending-video {
  background-color: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: calc(100% - 24px);
}

.pick-up-pending-header .pick-up-pending-video.mobile {
  margin-top: 16px;
}

.pick-up-pending-header
  .pick-up-pending-video
  .pick-up-pending-video-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
}

.pick-up-pending-header
  .pick-up-pending-video
  .pick-up-pending-video-container
  .pick-up-pending-video-poster-div {
  display: flex;
  flex-direction: row;
  height: 60px;
  margin: 0px;
  width: 110px;
}

.pick-up-pending-header
  .pick-up-pending-video
  .pick-up-pending-video-container
  .pick-up-pending-video-poster-div
  .pick-up-pending-video-poster {
  aspect-ratio: 106.8/56.82;
  border: 1px solid #ccccd0;
  border-radius: 4px;
  cursor: pointer;
  margin: 0px;
  object-fit: fill;
}

.pick-up-pending-header .pick-up-pending-video .pick-up-pending-video-text {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 12px;
}

.pick-up-pending-header
  .pick-up-pending-video
  .pick-up-pending-video-text
  .pick-up-pending-video-name-title {
  color: #76767a;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0px 0px 4px;
}

.pick-up-pending-header
  .pick-up-pending-video
  .pick-up-pending-video-text
  .pick-up-pending-video-name {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: 0px;
}

.pickupConfirmDesp-title {
  margin-top: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 7px;
}
.pickupConfirmDesp-rowDiv {
  display: flex;
  flex-direction: row;
}
.pickupConfirmDesp-labelPrintBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 17px;
  margin-bottom: 16px;
}
.pickupConfirm .button-print-label {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  min-height: 40px;
  min-width: 210px;
  text-transform: capitalize;
  width: 15%;
}
.pickupConfirm .reviewpage-ro-no {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 14px;
  cursor: pointer;
  text-decoration-line: underline;
}
.gridContainerRow {
  justify-content: space-between;
  align-content: space-between;
}

.shipmentLabelErrorPopup .paperRoot {
  width: 450px;
}
.getPickupPdf-spinner-loader-pop-up {
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}
.getPickupPdf-spinner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  margin: 0;
  padding: 0;
  width: 300px;
}

@media screen and (max-width: 920px) {
  .pick-up-pending-header
    .pick-up-pending-video
    .pick-up-pending-video-poster-div
    .pick-up-pending-video-poster {
    height: inherit;
    margin-top: 0px;
    object-fit: fill;
    width: 100px;
    margin-left: 11px;
  }
  .pickupConfirmDesp-short-form {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .pickupConfirmDesp-rowDiv {
    flex-direction: column;
  }
}
