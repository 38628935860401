.failure-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 400px;
}

.showTwoLineMessage-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px 0px 16px;
  width: 100%;
}

.failure-pop-up-description {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 16px 20px 16px;
  width: calc(100% - 32px);
}

.showTwoLineMessage-div .failure-pop-up-description-message1 {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 0px;
  width: 100%;
}

.showTwoLineMessage-div .failure-pop-up-description-message2 {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.failure-pop-up-back-button {
  justify-content: center;
  text-transform: none;
  width: 100%;
}

@media only screen and (max-width: 414px) {
  .failure-pop-up {
    max-height: fit-content;
    max-width: 380px;
    width: calc(100% - 32px);
  }
}
