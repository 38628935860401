.pickup-information-review-component {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
}

.pickup-information-review-component .pickup-information-review-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
  height: 20px;
  margin: 0px;
  width: 100%;
}

.pickup-information-review-component
  .pickup-information-review-header
  .pickup-information-review-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  width: fit-content;
}

.pickup-information-review-component
  .pickup-information-review-header
  .pickup-information-review-edit-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  margin: auto 0px;
  text-align: left;
  text-decoration: underline;
  width: fit-content;
}
