.holdOrResumeTherapy {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 584px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 16px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-header .holdOrResumeTherapy-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 8px;
}

.holdOrResumeTherapy
  .holdOrResumeTherapy-header
  .holdOrResumeTherapy-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-date-input-fields {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 16px 16px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-container {
  width: 100%;
}

.holdOrResumeTherapy-container .holdOrResumeTherapy-item {
  width: 100%;
}

.holdOrResumeTherapy .holdOrResumeTherapy-wounds-checkbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 16px 16px;
}

.holdOrResumeTherapy-wounds-checkbox .chkBoxMain {
  margin-left: 10px;
}

.wound-checkbox .wound-checkbox-root {
  margin-right: 10px;
  padding: 0;
}

.wound-checkbox-text {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  min-width: 200px;
}

.wound-checkbox-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  min-width: 200px;
}

.wound-checkbox-measurement {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 44px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-resason-for-hold {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 16px 0 16px 16px;
  width: calc(100% - 16px);
}

.holdOrResumeTherapy-item .reason-for-hold {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.holdOrResumeTherapy-item .reason-for-hold-input {
  width: calc(100% - 16px);
}

.holdOrResumeTherapy-item .reason-for-hold-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: calc(100% - 16px);
}

div.placeholder {
  border-radius: 2px;
  color: #76767a;
}

.placeholder div.placeholder {
  border-right: unset;
  height: 20px;
}

.holdOrResumeTherapy div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: calc(100% - 16px);
}

.holdOrResumeTherapy .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: calc(100% - 16px);
}

.holdOrResumeTherapy .placeHolder .selectIcon {
  top: 10px;
}

.holdOrResumeTherapy .holdOrResumeTherapy-comments {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 0 16px;
}

.holdOrResumeTherapy-comments-input {
  width: 100%;
}

.holdOrResumeTherapy-item .comments {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.holdOrResumeTherapy-item .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.holdOrResumeTherapy-item .textarea-root {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
}

.holdOrResumeTherapy-item .holdOrResumeTherapy-comments-label {
  margin-top: 4px;
}

.holdOrResumeTherapy .submitButton {
  margin: 16px;
  width: calc(100% - 32px);
}

.holdOrResumeTherapy .ErrorMsg {
  padding-top: 2%;
  font-size: 12px;
  color: #d32f2f;
  line-height: 16px;
}

.SendNote {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-decoration: underline;
}
@media screen and (max-width: 600px) {
  .holdOrResumeTherapy {
    width: 100%;
  }
  .holdOrResumeTherapy .holdOrResumeTherapy-date-input-fields {
    margin: 4px 0 16px 16px;
  }
  .holdOrResumeTherapy .holdOrResumeTherapy-resason-for-hold {
    margin: 0px 0 16px 16px;
  }
}
@media screen and (max-width: 340px) {
  .holdOrResumeTherapy {
    width: 97%;
  }
  .holdOrResumeTherapy .holdOrResumeTherapy-date-input-fields {
    margin: 4px 0 16px 16px;
  }
  .holdOrResumeTherapy .holdOrResumeTherapy-resason-for-hold {
    margin: 0px 0 16px 16px;
  }
}
