.productsAndSolutions-main-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: 100%;
  min-height: 600px;
}

.productsAndSolutions-main-component .products-and-solution-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.productsAndSolutions-main-component .carousel-grid-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
}

.productsAndSolutions-products-section .products-loader {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
  width: 100%;
}
.productsAndSolutions-products-section .no-data-found {
  color: #323234;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: 300px;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.carousel-text {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  height: 72px;
  line-break: loose;
  line-height: 36px;
  max-width: 65%;
  min-width: 50%;
  white-space: normal;
  position: relative;
  bottom: 303px;
  padding: 36px;
}

.productsAndSolutions-main-component
  .carousel-grid-container
  .view-product-information-button {
  align-items: center;
  background: #0049bd;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  height: 40px;
  margin: 168px 0px 36px 0px;
  padding: 8px 14px 8px 10px;
  width: 240px;
}

.productsAndSolutions-main-component
  .carousel-grid-container
  .view-product-information-button
  .view-product-information-button-text {
  color: #ffffff;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  text-align: center;
}

.productsAndSolutions-products-section .productsAndSolutions-header {
  margin: 0px 24px 10px 24px;
}

.productsAndSolutions-main-component
  .productsAndSolutions-products-section
  .products-and-solutions-container {
  padding: 15px 24px;
  width: 100%;
}

.products-and-solutions-container .product-grid-item {
  padding: 0;
  width: 100%;
}

.product-grid-item .product-library-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  min-width: 150px;
  padding: 0;
  margin: 0;
  width: 99%;
}

.product-grid-item .product-library-searchbar .search-icon-div {
  height: 100%;
  margin: 0;
}

.product-library-searchbar .search-icon-div .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.product-grid-item .product-library-searchbar .product-library-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.productsAndSolutions-main-component .product-library-category {
  height: 40px;
  margin: 0 12px;
  width: calc(100% - 12px);
}

.productsAndSolutions-main-component .product-library-category-input {
  height: 42px;
  width: 90%;
}

.productsAndSolutions-main-component
  .product-library-category-input
  .product-library-category-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.productsAndSolutions-main-component div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.productsAndSolutions-main-component .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}
.productsAndSolutions-main-component .placeHolder .selectIcon {
  top: 10px;
}

.education-video-library-video-tutorials-section .products-grid-container {
  padding: 15px 24px;
  width: 100%;
}

.products-grid-container .product-grid-item {
  padding: 0;
  width: 100%;
}

.products-grid-container .product-grid-item .product-item-box {
  align-items: center;
  background: #f8f8fa;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
}

.products-grid-container .product-grid-item .product-item-box.right {
  margin: 12px 24px 12px 12px;
  width: calc(100% - 36px);
}

.products-grid-container .product-grid-item .product-item-box.center {
  margin: 12px;
  width: calc(100% - 24px);
}

.products-grid-container .product-grid-item .product-item-box.left {
  margin: 12px 12px 12px 24px;
  width: calc(100% - 36px);
}

.products-grid-container .product-grid-item .product-item-box .product-poster {
  aspect-ratio: auto;
  height: 150px;
  margin: 20px auto;
  object-fit: contain;
  width: 200px;
  zoom: 95%;
}

.products-grid-container .product-grid-item .product-text {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 16px;
  margin: 0 0 20px 0;
  width: calc(100% - 32px);
}

.products-grid-container .product-grid-item .product-text .product-name {
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  margin-top: 0px;
}

@media only screen and (min-width: 1140px) and (max-width: 1287px) {
  .products-grid-container .product-grid-item .product-text {
    min-height: 32px;
  }
}

.pagination-div {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 40px;
}

.pagination-table {
  display: inline-flex;
  margin-top: 4px;
  height: 28px;
}

.product-itemsPerPage {
  display: inline-flex;
  float: left;
  margin-left: 24px;
}

.product-totalCounts {
  display: inline-flex;
  float: right;
  margin-right: 24px;
}

.list {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  justify-content: center;
  list-style-type: none;
  color: #0049bd;
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
}

.list.active {
  border-bottom-color: #0049bd;
  border-bottom: 3px solid;
}

.numberSpace {
  padding: 2px;
}

.pageNumbers {
  justify-content: center;
}

.handleButton {
  background-color: white;
  border: none;
  color: #0049bd;
}

button {
  border: none;
  background-color: transparent;
}
.numberInside {
  padding: 50px;
  cursor: pointer;
}

.pagination-div .pagination-table .page-number-button {
  color: #0049bd;
  min-width: 30px;
  font-weight: 700;
}

.pagination-div .pagination-table .page-number-button.disabled {
  color: grey;
  min-width: 30px;
}

.pagination-div .pagination-table .page-number-button.active {
  border-radius: 0;
  border-bottom: 3px solid;
}

.product-dropdown {
  width: 70px;
  height: 35px;
}

.product-dropdown .dropDown {
  margin-top: 2px;
  font-size: 12px;
  margin-right: 1px;
  padding-left: 1px;
}

.product-dropdown .pagination-dropdown {
  width: 0px;
}

.products-item-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.products-mobile-grid-container {
  display: block;
  flex-direction: column;
}
.products-mobile-grid-container .product-grid-item {
  border: 1px solid #e4e4e8;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.products-mobile-grid-container .product-grid-item .product-item-box {
  display: block;
  align-items: center;
  background: #f8f8fa;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  height: calc(100% - 24px);
}

.products-mobile-grid-container .product-grid-item .product-item-box.right {
  width: calc(100% - 0px);
}

.products-mobile-grid-containerr .product-grid-item .product-item-box.center {
  width: calc(100% - 36px);
}

.products-mobile-grid-container .product-grid-item .product-item-box.left {
  width: calc(100% - 0px);
}

.products-mobile-grid-container
  .product-grid-item
  .product-item-box
  .product-poster {
  aspect-ratio: 100.65/100.07;
  height: 100.65px;
  margin: 9px;
  object-fit: fill;
  width: 100.07px;
}

.products-mobile-grid-container .product-grid-item .product-text {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-height: 0px;
}

.products-mobile-grid-container .product-grid-item .product-text .product-name {
  font-size: 10px;
  line-height: 16px;
  text-align: left;
}
.products-mobile-grid-container .product-grid-item {
  position: relative;
  height: 50%;
}

@media screen and (max-width: 436px) {
  .pagination-table {
    top: 10px !important;
    left: 19%;
  }
  .pagination-table-single {
    top: 24px !important;
    left: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .pagination-table {
    position: relative;
    margin-right: 0px !important;
    top: 29px;
  }
  .products-mobile-grid-container
    .product-grid-item
    .product-text
    .product-name {
    position: absolute;
    bottom: 29%;
    width: unset;
    overflow-wrap: anywhere;
    margin-right: 20px;
  }
  .products-item-desc {
    width: 109px;
  }
  .carousel-text {
    bottom: 68px !important;
  }
  .product-totalCounts {
    margin-right: 0px;
  }
  .product-itemsPerPage {
    margin-left: 0px;
  }
  .products-mobile-grid-container .product-grid-item .product-text {
    min-height: 24px;
    width: 100%;
  }
  .products-mobile-grid-container .product-grid-item .product-item-box {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (max-width: 926px) {
  .carousel-text {
    position: absolute;
    bottom: 68px;
    min-width: 90%;
    font-size: 20px;
    line-height: 24px;
    margin-left: 15px;
    margin-bottom: 2px;
    padding: 0px;
  }
  .productsAndSolutions-main-component
    .carousel-grid-container
    .view-product-information-button {
    width: 168px;
    margin-top: 81px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .productsAndSolutions-main-component
    .carousel-grid-container
    .view-product-information-button
    .view-product-information-button-text {
    font-size: 12px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 1199px) {
  .productsAndSolutions-main-component {
    margin: 0px;
  }
  .productsAndSolutions-main-component .product-library-category-input {
    width: 100.2%;
  }
  .productsAndSolutions-main-component .product-library-category {
    margin: 0px;
    width: 100%;
  }
  .product-grid-item .product-library-searchbar {
    width: 100%;
  }
  .pagination-table {
    margin-right: 32px;
  }
}

.product-images .product-thumbnail-image .thumbnail-image {
  margin-left: auto;
  margin-right: auto;
}
.product-bannerStyle {
  width: 100%;
  cursor: pointer;
  min-height: 100%;
}
@media only screen and (min-width: 600px) and (max-width: 1280px) {
  .products-grid-container
    .product-grid-item
    .product-item-box
    .product-poster {
    width: 150px;
  }
}
@media only screen and (width: 667px) {
  .products-grid-container
    .product-grid-item
    .product-item-box
    .product-poster {
    width: 125px;
  }
}
