.profile-cancel {
  padding: 16px 16px 24px 16px;
}
.profile-cancel .confirmation {
  font-size: 20px;
  line-height: 24px;
  padding-right: 55px;
  margin-top: 0;
  margin-bottom: 24px;
}

.profile-cancel .sub-text {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
}

.profile-cancel .stayBtn, .profile-cancel .leaveBtn {
  width: 100%;;
}

.profile-cancel .stayBtn {
  margin-bottom: 8px;
}