.site-status-type-component {
  margin-top: 38px;
  width: 100%;
}

.site-status-type-component .site-status-type-header {
  color: #323234;
  font-style: normal;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.site-status-type-grid-container .site-status-type-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 8px 0px;
}

.site-status-type-grid-container .site-status-type-radio-button-div {
  display: flex;
  flex-direction: row;
  margin: 14px 0px 0px 0px;
  padding: 0px 0px 0px 16px;
}

.site-status-type-radio-button-div .site-status-type-radio-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.site-status-type-grid-container .site-status-type-radio-description-div {
  padding: 0px 0px 0px 44px;
}

.site-status-type-radio-description-div .site-status-type-radio-description {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 0px 8px 0px 0px;
  text-align: left;
}

.site-status-type-radio-description-div .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.site-status-type-radio-description-div .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.site-status-type-radio-description-div .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

@media only screen and (max-width: 920px) {
  .site-status-type-grid-container .site-status-type-radio-button-div {
    padding: 0px;
  }
  .site-status-type-grid-container .site-status-type-radio-description-div {
    padding: 0px 0px 0px 28px;
  }
}
