.treatmentRegimen {
  margin: 0px 0px 0px 0px;
  width: 116%;
}

.treatmentRegimen .treatmentRegimen-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
}

.treatmentRegimen .treatmentRegimen-istreatmentRegimenPresent {
  margin-top: 16px;
}

.treatmentRegimen .treatmentRegimen-box-container {
  width: 100%;
}

.treatmentRegimen .treatmentRegimen-grid-container {
  width: 100%;
}

.treatmentRegimen-grid-container .treatmentRegimen-grid-item {
  width: 100%;
}

.treatmentRegimen .treatmentRegimen-treatment-regimen-header {
  margin-top: 16px;
  width: 100%;
}

.treatmentRegimen .treatmentRegimen-treatment-regimen {
  width: 100%;
  margin-top: 25px;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.treatmentRegimen .treatmentRegimen-checkbox-with-textbox {
  display: inline-block;
  width: 100%;
  margin-left: 12px;
  margin-bottom: 12px;
}

.treatmentRegimen .treatmentRegimen-checkbox {
  margin-right: 10px;
  padding: 0;
}

.treatmentRegimen .treatmentRegimen-checkbox .treatmentRegimen-checkbox-root {
  margin-right: 10px;
  margin-top: 20px !important;
  padding: 0;
  width: 100%;
}

.treatmentRegimen .treatmentRegimen-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 7px;
}

.treatmentRegimen .treatmentRegimen-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 7px;
}

.treatmentRegimen .treatmentRegimen-checkbox-description-text-disable {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 200px;
  width: max-content;
  margin-top: 7px;
}

.treatmentRegimen .treatmentRegimen-input {
  height: 25px;
  margin-bottom: 16px;
  margin-left: 25px;
  margin-top: 14px;
  width: 75%;
}
@media only screen and (max-width: 600px) {
  .treatmentRegimen {
    width: 100%;
  }
  .treatmentRegimen .treatmentRegimen-treatment-regimen {
    column-count: unset;
  }
}
