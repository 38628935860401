.view-report-component-container {
  width: 100%;
  margin: 16px 56px 16px 56px;
}
.view-report-component-container .select-a-facility-back-div {
  margin-left: -9px;
}
.view-report-component-container .select-a-facility-back {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0049bd;
}
.view-report-component-container .view-report-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: calc(100% - 32px);
  width: calc(100% - 72px);
}

.view-report-component .view-report-component-route-section {
  margin: 24px 24px 16px;
}

.view-report-component .view-report-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 40px 40px 0px 24px;
  width: calc(100% - 48px);
}

.view-report-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  width: calc(100% - 48px);
}
.short-form .scroll-tab-div {
  background-color: #ffffff;
  border-bottom: none;
}
.view-report-component .short-form .reports-sub-header {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}
.reports-sub-header .sub-header {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 10px;
}
.reports-sub-header .sub-header .superscript-style {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.reports-sub-header .sub-header-desc {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  width: 669px;
}
.territoryView-reports {
  min-height: 0px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.territoryView-reports .territoryView-reports-select .MuiInputBase-input {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  border-radius: 0px;
  border: 0px solid #94949a;
}
.territoryView-reports .territoryView-reports-input {
  width: 280px;
  border-radius: 0px;
  height: 42px;
}
.scrollableTabHeader .scroll-tabs-textlabel {
  width: 155px;
}
.scrollableTabHeader .scroll-tabs-textlabel-select {
  width: 177px;
  text-transform: none;
  font-weight: 700;
}
@media only screen and (max-width: 926px) {
  .view-report-component-container {
    width: 100%;
    margin: unset;
  }
  .reports-sub-header .sub-header {
    display: unset;
  }
  .view-report-component-container .select-a-facility-back-div {
    margin-left: unset;
    background-color: #fff;
  }
  .reports-sub-header .sub-header-desc {
    width: unset;
  }
  .view-report-component .view-report-header {
    margin-top: 20px;
  }
  .territoryView-reports .territoryView-reports-input {
    width: 99%;
    border-radius: 0px;
    height: 42px;
  }
  .view-report-component-container .view-report-component {
    width: 100%;
  }
  .scrollableTabHeader .scroll-tabs-textlabel {
    width: auto;
  }
  .scrollableTabHeader .scroll-tabs-textlabel-select {
    width: auto;
  }
  .view-report-component .short-form {
    margin: 16px 8px;
    width: calc(100% - 16px);
  }
}
@media only screen and (min-width: 926px) {
  .reports-sub-header .sub-header .superscript-style {
    top: -4px;
  }
}
