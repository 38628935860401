.success-snack-bar .success-snack-bar-content {
  background-color: #e3f7e8;
  color: #007713;
  display: flex;
  align-items: center;
}
.success-snack-bar-content .snackbar-content-message {
  display: flex;
  justify-content: center;
}
.success-snack-bar-content .snackbar-success-check-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
