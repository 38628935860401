.autoCompleteMain .autoCompleteRoot {
  border: none !important;
}

.autoCompleteMain {
  height: 42px;
}
.autoCompleteMain .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
