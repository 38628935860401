.alertInPageContainerInPage {
  margin-left: 2px;
}
.alertInPageContainerInPage .site-status-banners-component {
  margin-top: 0px;
  margin-bottom: 0px;
}

.errorPopupSiteStatus .paperRoot {
  width: 386px;
  height: auto;
}

@media only screen and (max-width: 540px) {
  .alertInPageContainerInPage {
    margin: 0px 2px 2px 2px;
  }
}
@media only screen and (max-width: 414px) {
  .errorPopupSiteStatus .paperRoot {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}
@media print {
  .alertInPageContainerInPage .site-status-banners-component {
    display: none;
  }
}
