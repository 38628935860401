.pod-esign-component-main {
  padding: 10px 0 10px 42px;
  width: 100%;
}

.pod-esign-component-main .pod-esign-edit-component {
  padding: 20px 0px 0px 18px;
  width: 100%;
}

.pod-esign-edit-component .pod-esign-patient-edit-details-container {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 16px;
  border: 1px solid #e4e4e8;
  width: 100%;
}

.pod-esign-edit-component .pod-esign-patient-grid-item {
  width: 100%;
}

.pod-esign-edit-component .pod-esign-edit-patient-div {
  width: 100%;
}

.pod-esign-edit-component .pod-esign-edit-patient-input {
  width: 100%;
}

.pod-esign-edit-component .pod-esign-edit-close-icon {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.pod-esign-edit-component .pod-send-esign-button-grid {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}

.pod-esign-component-main .pod-esign-component {
  width: 100%;
}

.pod-esign-component .pod-esign-patient-details-no-email-container {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 16px;
  border: 1px solid #cb0000;
  gap: 8px;
  width: 100%;
}
.pod-esign-component .pod-esign-patient-details-container {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 16px;
  border: 1px solid #e4e4e8;
  gap: 8px;
  width: 100%;
}
.pod-esign-component .pod-esign-patient-grid-item {
  width: 100%;
}
.pod-esign-component .pod-esign-label {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 12px 0 0 0;
  text-align: left;
}
.pod-esign-component .pod-esign-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  text-align: left;
  word-wrap: break-word;
}

.pod-esign-component .pod-esign-value-no-email {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0;
  text-align: left;
  color: #cb0000;
}

.pod-esign-component .pod-esign-patient-email-grid-item {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.pod-esign-component .pod-esign-email-div {
  width: 100%;
}

.pod-esign-component .pod-esign-edit-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #0049bd;
  padding: 10px;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
  justify-content: flex-end;
}

.pod-esign-component .pod-send-esign-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.pod-esign-component .pod-esign-success-grid {
  display: flex;
  align-items: center;
}

.pod-esign-component .pod-success {
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pod-esign-component .pod-success-text {
  color: #007713;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 5px;
  text-align: left;
}

.pod-esign-component .pod-success-text .pod-success-icon {
  margin: 0px;
}

.pod-esign-component .pod-esign-failure-grid {
  display: flex;
  flex-direction: row;
  margin-right: 16px;
}

.pod-esign-component .pod-failure-icon {
  display: flex;
  flex-direction: row;
}
.pod-esign-component .pod-failure-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #cb0000;
  margin: 13px 0px 0 5px;
}

.pod-esign-component .pod-send-esign-button-grid {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.pod-esign-component .pod-esign-edit-grid-item {
  display: flex;
  justify-content: end;
}

.pod-esign-component .pod-send-esign-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .pod-container .pod-esign-component-main {
    padding: 0;
    width: 100%;
  }

  .pod-esign-component-main .pod-esign-edit-component {
    padding: 0;
    margin: 10px 0px;
  }

  .pod-esign-edit-component .pod-esign-patient-edit-details-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  .pod-esign-edit-component .pod-esign-patient-grid-item {
    padding: 10px;
    width: 100%;
  }

  .pod-esign-edit-component .pod-esign-edit-close-icon {
    margin: 0px 10px;
    justify-content: end;
  }

  .pod-esign-edit-component .pod-send-esign-button-grid {
    display: flex;
    /* padding-right: 5px; */
    margin: 16px 0px;
    justify-content: center;
  }
  .pod-esign-edit-component .pod-send-esign-button {
    width: 100%;
    margin-right: 8px;
  }
  .pod-esign-component-main .pod-esign-component {
    margin: 0 20px 0 0;
  }
  .pod-esign-component .pod-esign-patient-details-no-email-container {
    width: 100%;
  }
  .pod-esign-component .pod-esign-patient-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .pod-esign-component .pod-esign-patient-grid-item {
    width: 100%;
  }
  .pod-esign-component .pod-esign-patient-email-grid-item {
    width: 90%;
  }
  .pod-esign-component .pod-send-esign-button-grid {
    display: flex;
    padding-right: 20px;
    margin-top: 16px;
  }
  .pod-esign-component .pod-send-esign-button {
    width: 100%;
    margin-right: 8px;
  }
  .pod-esign-component .pod-esign-email-div {
    width: 90%;
  }
  .pod-esign-component .pod-esign-edit-grid-item {
    display: flex;
    justify-content: left;
  }
  .pod-esign-component .pod-esign-edit-button {
    padding: 0;
    justify-content: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 912px) {
  .pod-esign-component .pod-esign-patient-details-container {
    width: 100%;
  }
  .pod-esign-component .pod-esign-patient-details-no-email-container {
    width: 100%;
  }
}
