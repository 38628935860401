@media only screen and (max-width: 780px) {
  /* #okta-sign-in {
    width: 100px;
  } */
  /* #okta-sign-in.auth-container .okta-sign-in-header {
    display: flex;
  } */
}
@media only screen and (max-width: 600px) {
  #okta-sign-in.auth-container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 375px;
  }
  #okta-sign-in .auth-header {
    padding: 0 !important;
    /* display: flex; */
  }
  #okta-sign-in.auth-container .auth-content {
    width: 100%;
    max-width: none;
    margin: 0;
  }
  /* #okta-sign-in.auth-container .okta-sign-in-header {
    display: flex;
  } */
}
@media only screen and (max-width: "503px") {
  #okta-sign-in.auth-container {
    width: 40px;
  }
  #okta-sign-in.auth-container .auth-content {
    width: 210px;
    padding: 10px;
    margin: 0 10px;
    max-width: 210px;
  }
  #okta-sign-in {
    min-width: 210px;
  }
}
