.woundinfo-patientForm {
  background-color: #fff;
  padding: 40px;
  margin-right: 56px;
  margin-bottom: 110px;
}
.woundinfo-patientForm-LandingPage {
  margin-right: unset;
}

.woundinfo-newOrder-container {
  margin: 16px 0px 31px 16px;
}
.woundinfo-newOrder-LandingPage {
  margin: unset;
}

.woundinfo-patientForm .woundinfo-short-form {
  width: 70%;
}

@media only screen and (max-width: 932px) {
  .woundinfo-patientForm {
    margin: 0 0 140px;
    padding: 16px;
  }
}

@media only screen and (max-width: 920px) {
  .woundinfo-newOrder-container {
    margin: 0px 8px;
  }

  .woundinfo-patientForm {
    margin: 0 0 188px;
    padding: 16px;
    width: 100%;
  }

  .woundinfo-patientForm .woundinfo-short-form {
    width: calc(100% - 16px);
  }
}

@media only screen and (max-width: 915px) {
  .woundinfo-patientForm {
    margin: 0 0 176px;
    padding: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .woundinfo-patientForm {
    margin: 0 0 174px;
    padding: 16px;
  }
}

@media only screen and (max-width: 568px) {
  .woundinfo-patientForm {
    margin: 0 0 174px;
    padding: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .woundinfo-patientForm {
    margin: 0 0 194px;
    padding: 16px;
  }
}

@media only screen and (max-width: 353px) {
  .woundinfo-patientForm {
    margin: 0 0 198px;
    padding: 16px;
  }
}

@media only screen and (max-width: 330px) {
  .woundinfo-patientForm {
    margin: 0 0 224px;
    padding: 16px;
  }
}
