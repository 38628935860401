.addSalesPersonMainContainer {
  padding: 16px;
}
.addSalesPersonMainContainer .addSalesPersonTittle {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  text-align: left;
  margin-top: 0px;
}
.containerBody {
  margin-top: 25px;
}
.addSalePersonMainContainer .changeButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
  align-self: center;
}
.addSalePersonMainContainer {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  padding: 16px 16px 16px 0px;
}

.addSalesAssignDatesContainer .assignDatesTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 20px;
}
.addSalesAssignDatesContainer .assignDatesFields {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-top: 10px;
}
.addSalePersonAssignReasonFields {
  margin-top: 8px;
}
.addSalePersonBtnGroup {
  width: 100%;
  margin-top: 32px;
  gap: 20px;
  display: flex;
}
.addSalePersonBtnGroup .addSaleTerritoryCancelBtn {
  width: 50%;
}
.addSalePersonBtnGroup .addSaleTerritoryAddBtn {
  width: 242px;
}
@media only screen and (max-width: 600px) {
  .addSalesPersonMainContainer .addSalesPersonTittle {
    font-size: 16px;
  }
  .name-and-territory .name {
    width: 150px !important;
  }
  .heading-and-values {
    display: unset !important;
  }
  .territory-heading {
    width: 55% !important;
    margin-bottom: 8px;
  }
  .addSalePersonBtnGroup .addSaleTerritoryAddBtn {
    width: 100%;
  }
}
.name-and-territory {
  margin-left: 35px;
}
.name-and-territory .name {
  font-size: 14px;
  margin-bottom: 4px;
  width: 232px;
}
.territory-heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  width: 45%;
}
.heading-and-values {
  display: flex;
  gap: 25px;
}
