.acute-order-pickUp-request-review-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}

.acute-order-pickUp-request-review-page-component .short-form {
  margin: 40px;
  width: calc(100% - 80px);
}

.acute-order-pickUp-request-review-page-component
  .short-form
  .acute-order-pickUp-request-review-page-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 8px;
  text-align: left;
}

.acute-order-pickUp-request-review-page-component
  .short-form
  .acute-order-pickUp-request-page-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 8px 0px 32px;
  text-align: left;
}

@media only screen and (max-width: 926px) {
  .acute-order-pickUp-request-review-page-component {
    margin: 0px;
    width: 100%;
  }

  .acute-order-pickUp-request-review-page-component .short-form {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
