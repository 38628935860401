.woundAssessment-main {
  width: 100%;
  padding: 0px 0px 20px 0px;
}
.woundAssessment-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: 15px;
  line-height: 0px;
}
.woundAssessment-main .woundAssessment-container-main {
  margin-left: 40px;
}
.accr {
  width: 96%;
  margin-left: 1.5%;
}
.btnGridMain {
  display: flex;
  flex-direction: row;
}
.assessed-on-lable {
  font-weight: 400;
  font-size: 12px;
  line-height: 5px;
  color: #76767a;
  margin-top: 6px;
}
.assessdDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 5px;
  color: #323234;
}

.increasedVolume {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #323234;
  margin-top: 11px;
}
.woundAssessment-container.current-therapy-icon-div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 13px;
  margin-top: 23px;
}
.current-therapy-icon-div .icon {
  margin: auto;
}
.greenarrow-image {
  flex-direction: row;
}
.greenarrow-imagevolume {
  background: none;
  height: 20px;
  width: 20px;
  background-color: white;
  margin-left: 60px;
  margin-bottom: -12px;
  margin-top: 6px;
  padding: 7px 4px 0px 20px;
}
.therapy-status {
  font-weight: 400;
  font-size: 12px;
  line-height: 15.5px;
  color: #323234;
  padding: 25px 0px 10px 4px;
}

.accordation-summary {
  flex-direction: row;
}

.link-div {
  margin-top: -48px;
  padding: 0px 0px 0px 215px;
}
.accordation-div {
  width: 97%;
  margin-left: 16px;
  min-height: 30px;
  border: 0.1px solid #e4e4e8;
  height: 50%;
}
.assessedVolume {
  font-weight: 700;
  font-size: 16px;
  line-height: 4px;
  color: #323234;
  width: 30%;
}
.green-oval {
  padding: 27px 0px 0px 7px;
}
.image-viewer_popup {
  width: 100%;
}

.img-pop-up .paperRoot {
  width: 525px;
  height: 400px;
  background-color: #323234;
  border-radius: 7px;
}
.img-pop-up.popupCloseIcon {
  display: none;
}
.image-viewer {
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  font-weight: normal;
}
.image-viewer-align {
  padding: 8px 13px 4px 0px;
  height: 50px;
  width: 50px;
}
.wound-assessment-lable {
  font-size: 14px;
  line-height: 12px;
  color: #ffffff;
  margin-left: 17px;
  margin-top: 11px;
}
.accordion-button {
  pointer-events: none;
}

.accordion-button::after {
  pointer-events: all;
}
.greencircle {
  width: 8px;
  height: 8px;
  background-color: #00ac31;
  border-radius: 50%;
  margin-top: 27px;
  margin-left: 9px;
}
.redcircle {
  width: 8px;
  height: 8px;
  background-color: #cb0000;
  border-radius: 50%;
  margin-top: 27px;
  margin-left: 9px;
}
.yellowcircle {
  width: 8px;
  height: 8px;
  background-color: #eaca00;
  border-radius: 50%;
  margin-top: 27px;
  margin-left: 9px;
}
.hypen {
  padding-left: 7px;
  margin-top: -3px;
}
.assessedDate {
  font-weight: 400;
  font-size: 12px;
  line-height: 0px;
  color: #323234;
}

.expandIcon {
  color: #4949bd;
  height: 15px;
  width: 25px;
  padding-top: 11px;
}
.backgroundStyle.accordation-div {
  background-color: "#323234";
  width: 491px;
  height: 342px;
  margin-left: 10px;
  margin-top: 15px;
  position: "relative";
}
.noChangeVolume {
  font-size: 12px;
  line-height: 6px;
  color: #323234;
  flex-direction: row;
  margin-top: 4px;
}
.increaseDecreseVolume {
  font-size: 12px;
  line-height: 0px;
  color: #323234;
  flex-direction: row;
  margin-top: 3px;
}
.noChangeVolumeAssessd {
  margin-top: 0px;
  font-weight: bold;
  line-height: 0px;
  flex-direction: row;
}
.main-div {
  flex-direction: row;
}
.volumeCalc {
  padding: 2px 0px 0px 0px;
  display: flex;
  font-weight: bold;
  margin-top: 5px;
}
.volumeSpace {
  padding-left: 5px;
}
.arrowSpace {
  padding-left: 5px;
  padding-top: 2px;
}
.hyphen-class {
  margin-top: 0px;
}
.volumecube {
  margin-top: 4px;
  margin-left: 5px;
}
@media only screen and (max-width: 600px) {
  .noChangeVolume {
    line-height: 12px;
  }
  .assessed-on-lable {
    line-height: 14px;
    width: max-content;
  }
  .accr {
    height: 90px;
  }
  .woundAssessment-main {
    width: 90%;
  }
  .increaseDecreseVolume {
    line-height: 12px;
    margin-top: -4px;
  }
  .main-div {
    width: 140px;
  }
  .therapy-status {
    margin-left: 57px;
    width: 58px;
    padding: 0px 0px 0px 0px;
  }
  .greencircle {
    margin-left: 60px;
    margin-top: 49px;
  }
  .redcircle {
    margin-left: 60px;
    margin-top: 49px;
  }
  .yellowcircle {
    margin-left: 60px;
    margin-top: 49px;
  }
  .accordation-div {
    width: 100%;
  }
  .link-div {
    width: 73px;
    padding: 10px 0px 0px 125px;
  }
}
@media only screen and (min-width: 800px) {
  .wound-image-div {
    padding: 0px 0px 9px 0px;
    margin-bottom: 3%;
  }
}

@media only screen and (min-width: 768px) {
  .wound-image-div {
    padding: 0px 0px 9px 0px;
    margin-bottom: 3%;
  }
}

@media only screen and (max-width: 320px) {
  .main-div {
    margin-left: 0px;
  }
  .accr {
    margin-left: 30px;
  }
  .therapy-status {
    margin-left: 36px;
  }
  .greencircle {
    margin-left: 42px;
    margin-top: 49px;
  }
  .redcircle {
    margin-left: 42px;
    margin-top: 49px;
  }
  .yellowcircle {
    margin-left: 42px;
    margin-top: 49px;
  }
}
@media only screen and (max-width: 912px) and (min-width: 820px),
  (max-width: 768px) {
  .therapy-status {
    margin-left: 80px;
    width: 50px;
  }
  .greencircle {
    margin-left: 83px;
  }
  .redcircle {
    margin-left: 83px;
  }
  .yellowcircle {
    margin-left: 83px;
  }
}
@media only screen and (max-width: 600px) {
  .therapy-status {
    margin-left: 50px;
    width: 59px;
    margin-top: 52px;
  }
  .greencircle {
    margin-left: 55px;
    margin-top: 54px;
  }
  .redcircle {
    margin-left: 55px;
    margin-top: 54px;
  }
  .yellowcircle {
    margin-left: 55px;
    margin-top: 54px;
  }
}
@media only screen and (width: 768px) {
  .therapy-status {
    margin-top: 13px;
  }
  .greencircle {
    margin-left: 55px;
    margin-top: 40px;
  }
  .redcircle {
    margin-left: 55px;
    margin-top: 40px;
  }
  .yellowcircle {
    margin-left: 55px;
    margin-top: 40px;
  }
}
