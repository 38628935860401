.insuranceInformationMain .other-detail-container {
  margin-top: 16px;
  width: 100%;
}
.other-detail-container .textarea-root {
  padding: 0;
}

@media only screen and (max-width: 920px) {
  .insuranceInformationMain .other-detail-container {
    width: calc(100% - 16px);
  }
}
