.searchByDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.searchByDetails .first-Container-Search {
  width: 100%;
}
.searchInput {
  width: 81.5%;
  display: flex;
  flex-direction: row;
}
.searchNRByDetails {
  margin-top: 10px;
  width: 100%;
}
.label-genral {
  width: 100%;
}
.grid-search-item .prescriber-last-name{
  width: 100%;
  height: 39px;
}
.grid-search-item .prescriber-first-name{
  width: 100%;
  height: 39px;
}