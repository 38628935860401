.manualHomeCare {
  padding: 16px;
}
.manualHomeCare-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.manualHomeCare-Add {
  width: 100%;
  text-transform: none;
}
.manualHomeCare-Inputs {
  margin-top: 17px;
}
.manualHomeCare-Input {
  width: 100%;
}
.manualHomeCare .homecare-info-input {
  width: 100%;
}

.manualHomeCare .homecare-info-input .homecare-info-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.manualHomeCare div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}
.contactNumber {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #94949a;
  font-size: 14px;
  padding: 11.15px 12px;
}
.contactNumber:focus {
  outline: none;
}
.Mui-error + div .contactNumber {
  border-color: #d32f2f;
}
.manualHomeCare-loading {
  padding: 16px;
  margin-top: 35px;
}

.manualHomeCare-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.addManualHomeCare-state-input {
  height: 42px;
}

.addManualHomeCare-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.addManualHomeCare-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.addManualHomeCare-state-input .addManualHomeCare-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
@media screen and (max-width: 920px) {
  .margin-bottom-grid-item {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .add-manual-homecare-custom-button .manualHomeCare-Add {
    width: 100%;
    font-size: 12px;
  }
}
@media screen and (max-width: 321px) {
  .add-manual-homecare-custom-button .manualHomeCare-Add {
    width: 100%;
    font-size: 12px;
  }
}
