.transfer-patient-component {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-left: 40px;
  width: 100%;
}

.transfer-patient-component .short-form {
  display: flex;
  flex-direction: column;
  width: 62%;
}

.short-form .lastVisitDate input.input {
  border-right: 0;
}

div.lastVisitDate {
  padding-right: 0;
}

.lastVisitDate .outline {
  border-radius: 2px;
}

.lastVisitDate.showError .outline {
  border: 1px solid #d32f2f;
}

.lastVisitDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.short-form .last-visit-date-label {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.short-form .transfer-details-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.transfer-details-div .transfer-details-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.transfer-details-div .transfer-details-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.transfer-patient-grid-container {
  width: 100%;
}

.transfer-patient-grid-container .transfer-patient-grid-item {
  margin-top: -8px;
  width: 100%;
}

.transfer-patient-component .short-form .transfer-patient-input-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.transfer-patient-grid-item .transfer-patient-input {
  width: 100%;
}

.transfer-patient-input .showCommentError {
  border: 1px solid #d32f2f !important;
}

.noCommentError {
  border: none;
}

.facilityPhone {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #94949a;
  font-size: 14px;
  padding: 11.15px 12px;
}

.facilityPhone:focus {
  outline: none;
}

.Mui-error + div .facilityPhone {
  border-color: #d32f2f;
}
/* @media screen and (max-width: 600px) {
  .transfer-patient-component {
    width: 100^;
  }
  .transfer-patient-component {
    margin-left: 30px;
  }
} */

@media screen and (max-width: 600px) {
  .transfer-patient-component .short-form {
    width: 100%;
  }
}
