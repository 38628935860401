.my-patient-filter-grid-container {
  align-items: center;
  background-color: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: row;
  max-width: 99%;
  margin-top: 10px;
  padding: 8px 8px 8px 12px;
}
.my-patient-filter-grid-container .my-patient-filter-grid-item {
  margin: 0;
  padding-right: 8px;
}

.my-patient-filter-grid-item .drop-down-with-multi-options {
  background-color: #fff;
  display: flex;
  height: 42px;
  width: 100%;
}

.drop-down-with-multi-options-paper-props {
  max-height: 316px;
  min-width: 230px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
}

.has-alert-check-box .has-alerts-text {
  align-items: center;
  color: #323234;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  margin-top: 6px;
}
.my-patient-filter-grid-container .apply-filter-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
}
.apply-filter-button .apply-filter-btn {
  border-color: #0049bd;
  color: #0049bd;
  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  height: 32px;
  line-height: 16px;
}

.my-patient-filter-grid-item .drop-down-with-multi-options {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 100%;
}

.my-patient-filter-grid-item .drop-down-with-multi-options-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.my-patient-filter-grid-container .my-patient-filter-close-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.my-patient-filter-grid-container .margin-top {
  margin-top: 8px;
}
.my-patient-filter-grid-container .margin-right {
  margin-right: 6px;
}
@media only screen and (max-width: 600px) {
  .my-patient-filter-grid-container {
    margin: 10px;
    padding: 8px 6px 8px 6px;
    max-width: calc(100% - 18px);
  }
  .my-patient-filter-grid-container .apply-filter-button {
    gap: 0;
  }
}
@media screen and (max-width: 926px) {
  .my-patient-filter-grid-container .has-alert-check-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
