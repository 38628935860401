.proofOfDeliveryUpload .pod-upload-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.proofOfDeliveryUpload .pod-upload-container {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #323234;
  margin: 10px 42px;
  width: 100%;
}

.check-mark-img {
  vertical-align: inherit;
  text-align: right;
  margin-right: 5px;
}

.upload-status {
  line-height: 25px;
}

.pod-upload-doc-container .dropZoneStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 138px;
  background: #f8f8fa;
  cursor: pointer;
  border: 1px dashed #1e64d0;
}

.pod-upload-doc-container .dragAndDrop {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration-line: underline;
  color: #76767a;
}

.pod-upload-doc-container .uploadWidgetCard {
  margin-top: 8px;
  box-sizing: border-box;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin-left: 42px;
}

.pod-upload-doc-container .uploadIconStyle {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.pod-upload-doc-container .pod-upload-desp {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  margin-left: 42px;
}

.pod-upload-doc-container .selectPodBtn {
  text-transform: none;
}
.proofOfDeliveryUpload .pod-upload-title-msg {
  width: 38%;
  padding: 10px 0px 10px;
}

.proofOfDeliveryUpload .print-pod-button {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  text-transform: none;
}

.proofOfDeliveryUpload .pod-selectedFileDiv {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  margin: 20px 0px 20px 42px;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileNameTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  padding: 12px 0px 0px 12px;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-selected-file-div {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  width: 100%;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileDetailCard {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 56px;
  margin: 10px 0px 5px;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileDetailCard .pod-filename {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 100%;
  align-self: center;
  overflow-wrap: break-word;
  margin-left: 5px;
}

.proofOfDeliveryUpload
  .pod-selectedFileDiv
  .pod-fileDetailCard
  .pod-fileStatus {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  color: #323234;
  width: 60%;
  color: #007713;
  align-self: center;
  text-align-last: right;
  margin-top: 4px;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileClose {
  width: 50px;
  align-self: center;
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.proofOfDeliveryUpload
  .pod-selectedFileDiv
  .pod-fileDetailCard
  .pod-fileStatuserror {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  margin-top: 5px;
  color: #323234;
  width: 100%;
  color: #cb0000;
  align-self: center;
  text-align-last: right;
}

.proofOfDeliveryUpload .pod-selectedFileDiv .pod-uploadDocBtn {
  margin-right: 12px;
  margin-bottom: 12px;
  text-align: right;
  text-transform: none;
}

.proofOfDeliveryUpload .pod-success-pop-up {
  height: 196px;
  width: 574px;
  display: flex;
  justify-content: center;
}

.pod-success-pop-up-div {
  display: flex;
  justify-content: center;
  height: 196px;
  margin: 0;
}

.pod-selectedFileDiv .btnUpload {
  width: 150px;
}

.pod-failure-pop-up-div .pod-failure-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 395px;
}

.proofOfDeliveryUpload .print-pod-pdf-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.print-pod-pdf-failure-pop-up .print-pod-pdf-failure-pop-up-div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 435px;
}

@media only screen and (max-width: 600px) {
  .pod-upload-doc-container .dropZoneStyles {
    padding-bottom: 5px;
  }
  .pod-upload-doc-container .dragAndDrop {
    font-weight: 700;
    font-size: 10px;
    margin-bottom: 4px;
    text-align: center;
    text-decoration-line: underline;
    color: #76767a;
  }

  .pod-upload-doc-container .selectPodBtn {
    padding: 0;
    font-size: 8px;
    width: 45%;
  }
  .pod-upload-doc-container .uploadWidgetCard {
    margin: 10px 0;
    width: 100%;
    height: 150px;
  }
  .pod-upload-doc-container .pod-upload-desp {
    margin: 0px;
    width: 100%;
  }
  .pod-upload-container {
    margin-left: 0px;
  }
  .proofOfDeliveryUpload .print-pod-button {
    margin-top: 15px;
    font-size: 8px;
    width: max-content;
  }
  .proofOfDeliveryUpload .pod-selectedFileDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #e4e4e8;
    border-radius: 4px;
    height: 100%;
    margin: 0;
    width: 100%;
  }
  .proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileDetailCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .proofOfDeliveryUpload
    .pod-selectedFileDiv
    .pod-fileDetailCard
    .pod-filename {
    width: 45%;
  }
  .proofOfDeliveryUpload
    .pod-selectedFileDiv
    .pod-fileDetailCard
    .pod-fileStatus {
    width: 50%;
    margin: 0;
    text-align: left;
    text-align-last: auto;
  }
  .proofOfDeliveryUpload
    .pod-selectedFileDiv
    .pod-fileDetailCard
    .pod-fileStatuserror {
    margin: 0;
    text-align-last: left;
  }
  .upload-status {
    line-height: 15px;
  }
  .proofOfDeliveryUpload .pod-upload-container {
    margin: 0;
  }
  .proofOfDeliveryUpload .pod-selectedFileDiv .pod-fileClose {
    margin: 0;
    padding: 10px;
    width: auto;
  }
  .proofOfDeliveryUpload .print-pod-pdf-failure-pop-up {
    margin: auto 16px;
    width: calc(100% - 32px);
  }

  .print-pod-pdf-failure-pop-up .print-pod-pdf-failure-pop-up-div {
    margin: auto 16px;
    max-width: 405px;
    width: calc(100% - 32px);
  }
}
@media only screen and (width: 820px) {
  .proofOfDeliveryUpload .pod-selectedFileDiv {
    width: 460px;
  }
}
@media only screen and (width: 768px) {
  .proofOfDeliveryUpload .pod-selectedFileDiv {
    width: 480px;
  }
  .pod-selectedFileDiv .btnUpload {
    margin-left: 250px;
  }
  .upload-status {
    width: max-content;
  }
  .proofOfDeliveryUpload
    .pod-selectedFileDiv
    .pod-fileDetailCard
    .pod-fileStatus {
    margin-left: 8px;
  }
}
@media only screen and (width: 912px) {
  .proofOfDeliveryUpload .pod-selectedFileDiv {
    width: 480px;
  }
  .pod-selectedFileDiv .btnUpload {
    margin-left: 250px;
  }
  .upload-status {
    width: max-content;
  }
  .proofOfDeliveryUpload
    .pod-selectedFileDiv
    .pod-fileDetailCard
    .pod-fileStatus {
    margin-left: 8px;
  }
}
