.forgotPasswordContainer {
  display: flex;
  padding-top: 140px;
  margin: 0px;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 250px);
  max-height: 816px;
  background: #ffffff;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
    0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.appLogoRecovery {
  margin-top: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lineRecovery {
  width: 1.82px;
  margin: 0 10px;
  background: #b4b4b8;
  height: 45px;
}

.appNameRecovery {
  font-weight: 900;
  font-size: 37.5px;
  line-height: 40px;
  color: #323234;
  margin-top: 8px;
}

.centerLinkRowFlex {
  margin-top: 28px;
  display: flex;
  justify-content: center;
}

.infoText {
  margin-top: 28px;
  width: 32%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #323234;
}

.signupOption {
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.passwordlink {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 160px;
}

.signupNowlinks {
  margin-left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: rgb(30, 100, 208);
  text-decoration-color: rgb(30, 100, 208);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.infoTextLong {
  margin-top: 28px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #323234;
}

.bolder {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
}
.tm-sup {
  font-size: 10px;
}

.link-returnSignIn {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: rgb(30, 100, 208);
  text-decoration-color: rgb(30, 100, 208);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

@media only screen and (max-width: 926px) {
  .infoText {
    width: 50%;
  }
  .forgotPasswordResetStyle {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .infoText {
    width: 95%;
  }
  .forgotPasswordContainer {
    width: calc(100% - 32px);
    padding: 16px;
  }
  .forgotPasswordResetStyle {
    width: 100%;
  }
  .forgotPasswordResetStyle .forgotPasswordReset {
    margin-left: 19px;
    width: calc(100% - 39px);
  }
  .appNameRecovery {
    font-size: 32.5px;
  }
  .appLogoRecovery {
    width: 100%;
  }
  .infoTextLong {
    width: 95%;
  }
}
@media only screen and (max-width: 360px) {
  .appNameRecovery {
    font-size: 24.5px;
    line-height: 30px;
  }
  .appLogoRecovery {
    width: 100%;
  }
  .infoTextLong {
    width: 95%;
  }
}
