.homecareprovider {
  margin-top: 3%;
  width: calc(100% - 16px);
}

.homecareprovider .homecareprovider-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.homecareprovider .homecareprovider-box-container {
  margin-top: 8px;
  width: 100%;
}

.homecareprovider .homecareprovider-grid-container {
  width: 100%;
  margin-left: 0%;
}

.homecareprovider-grid-container .homecareprovider-grid-item {
  width: 100%;
}

.homecareprovider .radioRoot {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 7%;
}

.homecareprovider .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.homecareprovider .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.homecareprovider .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.homecareprovider .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.homecareprovider .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.searchhomecareprovider .paperRoot {
  width: 100%;
  max-width: 574px;
}

.addmanualhomecareprovider .paperRoot {
  width: 100%;
  max-width: 574px;
  max-height: 444px;
  height: 100%;
}

.loader .paperRoot {
  width: 100%;
  max-width: 574px;
  max-height: 224px;
  height: 100%;
}

.homecareprovider-item {
  width: 100%;
}

.homecareprovider-component .homecare-provider-label-item {
  margin-top: 2%;
  width: auto;
  white-space: inherit;
}

.homecareSelectedChangePresBtn .homecare-selected-addOrRemove-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
  margin-bottom: 15px;
  margin-top: 15px;
}

.homecareprovider .homecareprovider-button {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  margin-top: -16px;
  text-transform: none;
  width: calc(100% + 16px);
}

.homecareprovider .caregiver-container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid #e4e4e8;
  margin-top: 16px;
  margin-left: 16px;
  width: 98%;
}

.caregiver-container .caregiver-name-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.caregiver-container .caregiver-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  width: -webkit-fill-available;
}
.caregiver-name-container .change-home-care-provider-btn {
  width: -webkit-fill-available;
}

.change-home-care-provider-btn .hcp-change-provider-btn {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  justify-content: flex-end;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}
.change-home-care-provider-btn .hcp-change-provider-btn:hover {
  text-decoration: underline;
}
.caregiver-container .caregiver-address-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.caregiver-container .address-part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.caregiver-container .address-part.address-div {
  width: 45%;
  height: 100%;
}

.caregiver-container .address-part.facility-type-div {
  width: 30%;
  height: 100%;
}

.address-part .address-header {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.address-part .caregiver-address {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-right: 10px;
  width: 320px;
}

.homecareprovider-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-height: 224px;
}

.changeProviderButton {
  padding: 11px 14px;
  gap: 4px;
  width: 215px;
  height: 40px;
  border-color: #0049bd;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
  color: #0049bd;
  font-weight: 700;
}
.address {
  display: flex;
  flex-direction: column;
}

.homecareprovider .homecareprovider-warning-div {
  display: flex;
  flex-direction: row;
  margin: 4px 0 0 16px;
  width: calc(100% - 16px);
}

.homecareprovider .homecareprovider-warning-div .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.homecareprovider .homecareprovider-warning-div .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.homecareprovider
  .homecareprovider-warning-div
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}

@media only screen and (max-width: 650px) {
  .homecareprovider-component .homecare-provider-label-item {
    margin-top: 10px;
    white-space: pre-wrap;
    width: auto;
    zoom: 95%;
  }
  .caregiver-container .caregiver-address-container {
    display: grid;
  }
  .addmanualhomecareprovider .paperRoot {
    margin: 0 10px 0 10px;
  }
  .searchhomecareprovider .paperRoot {
    margin: 8px;
  }
  .address {
    margin-bottom: 8px;
  }
  .address-part .address-header {
    margin-bottom: 4px;
  }
  .loader .paperRoot {
    max-width: 300px;
  }
}
@media only screen and (max-width: 321px) {
  .addmanualhomecareprovider .paperRoot {
    overflow-x: hidden;
    max-width: 368px;
  }
}
.homeCareProviderErrorPopup .paperRoot {
  width: 444px;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .homeCareProviderErrorPopup .paperRoot {
    margin: 0 4px 0 4px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 920px) {
  .homecareprovider .radioRoot {
    padding-top: 2%;
  }
  .addmanualhomecareprovider .paperRoot {
    height: 90%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 410px) {
  .homecareprovider .caregiver-container {
    width: 95%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 465px) {
  .caregiver-name-container .change-home-care-provider-btn {
    margin-top: 7px;
  }
}
