.internal-manage-profile {
  background-color: #fff;
  width: 77.779%;
}
.internal-manageProfile-loading {
  padding: 16px;
  margin-bottom: 24px;
}
.internal-manageProfile-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}
.internalUser-mp {
  width: 100%;
  min-height: 400px;
}
.internalUser-mp-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  color: #323234;
}
.internalUser-mp-contact {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #323234;
  margin-top: 36px;
}

.internalUserManage-container {
  display: flex;
  flex-direction: row;
}
.internalUserManageAccountNameLable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
}
.internalUserManageAccountName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.internalUserManageAccountEmailLable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.internalUserManageAccountEmailName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.internalUser-mp-contactNo {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 20px;
}
.InterUser-Email-container {
  width: 100%;
  margin-left: 0%;
}
.internalUser-Phone-container {
  width: 100%;
  margin-left: 0px;
}
.internalUser-contact-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.InternalContactData-mp {
  width: 100%;
}
.internalUser-Ma-sub {
  width: 100%;
  flex-direction: row;
  display: flex;
  gap: 80px;
}
.internal-user {
  width: 100%;
}
.internalUser-mp-main {
  width: 100%;
}
.internalUser-MP-page {
  background-color: #fff;
  min-height: calc(100vh - 334px);
}
