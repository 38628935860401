.WoundAssessmentSummaryHeader {
  margin-top: 64px;
}

.orderSuccessPara {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: left;
}

.orderSuccessPara2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 0px;
  text-align: left;
}

.orderSuccessPara3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 0px;
  text-align: left;
}

.orderSummaryBtn .backtoPatientBtn {
  width: 237px;
  margin-bottom: 40px;
  margin-top: 10px;
}

@media only screen and (max-width: 450px) {
  .orderSuccessPara3 {
    text-align: center;
  }

  .orderSuccessPara2 {
    text-align: center;
  }

  .orderSuccessPara {
    text-align: center;
  }
}
