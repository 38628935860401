.new-order-upload-document {
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
  width: 100%;
}

.new-order-upload-document .new-order-upload-document-grid-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.new-order-upload-document
  .new-order-upload-document-grid-container
  .new-order-upload-document-grid-item {
  margin: 0 0 15px 0;
  min-height: 56px;
  padding: 0;
  width: 100%;
}

.new-order-upload-document-grid-container.new-order-upload-document-grid-item
  .new-order-upload-document-filename-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.new-order-upload-document-grid-item .new-order-upload-document-filename-title {
  color: #76767a;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  width: 100%;
}

.new-order-upload-document-grid-item .new-order-upload-document-filename-value {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
  position: relative;
  width: 100%;
}

.new-order-upload-document-grid-item .new-order-document-type-or-status-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .new-order-upload-document-type-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px 12px;
  width: fit-content;
}

.new-order-document-type-or-status-div
  .new-order-upload-document-type-div
  .document-type {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.new-order-document-type-or-status-div
  .new-order-upload-document-type-div
  .new-order-documentType-info-input {
  height: 42px;
  width: 265px;
}

.new-order-documentType-info-input .new-order-documentType-info-select {
  align-items: center;
  display: block;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: calc(100% - 16px);
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: calc(100% - 16px);
}

.placeHolder .selectIcon {
  top: 10px;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .new-order-docUploadfileStatuserror {
  color: #cb0000;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 18px;
  margin: auto 15px;
  position: relative;
  top: 11px;
  width: 100%;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .new-order-docUploadfileCloseSuccess {
  align-items: center;
  cursor: pointer;
  margin: 16px 0px;
  position: relative;
  text-align: right;
  top: 11px;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .new-order-docUploadfileCloseFail {
  cursor: pointer;
  margin: auto;
  position: relative;
  text-align: right;
  top: 11px;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .new-order-upload-error-text {
  font-weight: 400;
}

.new-order-upload-document-grid-item
  .new-order-document-type-or-status-div
  .docUploadfileStatuserror {
  color: #cb0000;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 18px;
  margin: auto 15px;
  position: relative;
  top: 11px;
  width: fit-content;
}

@media only screen and (max-width: 912px) {
  .new-order-upload-document .new-order-upload-document-grid-container {
    gap: 8px;
  }
  .new-order-upload-document-grid-item
    .new-order-upload-document-filename-title {
    margin: 0px 10px;
  }

  .new-order-upload-document-grid-item
    .new-order-upload-document-filename-value {
    margin: 5px 10px 0px 10px;
  }
  .new-order-document-type-or-status-div
    .new-order-upload-document-type-div
    .new-order-documentType-info-input {
    margin: 0px 0px 10px 0px;
    width: 100%;
  }
  .new-order-upload-document-grid-item
    .new-order-document-type-or-status-div
    .new-order-docUploadfileCloseSuccess {
    margin: 24px 0px;
  }

  .new-order-upload-document
    .new-order-upload-document-grid-container
    .new-order-upload-document-grid-item {
    margin: 0;
    min-height: auto;
    height: 100%;
  }
  .new-order-upload-document-grid-item
    .new-order-document-type-or-status-div
    .new-order-upload-document-type-div {
    margin: 0px 8px;
    width: 100%;
  }
  .new-order-upload-document-grid-item
    .new-order-document-type-or-status-div
    .docUploadfileStatuserror {
    top: 0px;
  }
  .new-order-upload-document-grid-item
    .new-order-document-type-or-status-div
    .new-order-docUploadfileCloseFail {
    top: 0px;
  }
}
