.wound-exposed-container {
  display: flex;
  flex-direction: column;
  margin-top: 38px;
}
.wound-exposed-container .wound-exposed-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.wound-exposed-container .wound-exposed-structures-unchecked {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
}

.wound-exposed-container .wound-exposed-structures-checked {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
}
