.sales-rounding-tool-component-container {
  width: 100%;
  margin: 0px 80px 40px 80px;
  height: 100%;
}

.sales-rounding-tool-component-container
  .sales-rounding-tool-container-back-button {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 8px;
  text-transform: none;
}
.sales-rounding-tool-component-container .sales-rounding-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 38px;
  min-height: 700px;
}
.sales-rounding-dashboard-loader-popup .paperRoot {
  width: 300px;
  padding: 20px 0;
}
.sales-rounding-dashboard-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .sales-rounding-tool-component-container {
    margin: 0px;
    padding: 0px;
    background-color: #fff;
  }
  .sales-rounding-tool-component-container .sales-rounding-component {
    padding: 0;
  }
}
