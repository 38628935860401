.unlinked-users-main-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.unlinked-users-main-component .unlinked-users-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.unlinked-users-main-component
  .unlinked-users-header
  .unlinked-users-header-title {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  width: 100%;
}

.unlinked-users-main-component
  .unlinked-users-header
  .unlinked-users-header-description {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 4px 0px 24px;
  width: 100%;
}
.unlinked-requested-add-facility-popup {
  margin: 10px;
}
.unlinked-requested-add-facility-popup .paperRoot {
  display: flex;
  max-width: 574px;
  width: 100%;
  padding: 16px 16px 40px 16px;
}
.unlinked-spinner-loader-div {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.unlinked-spinner-loader-div .spinner-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.spinner-loader .spinner-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
