.manage-territory-component-container {
  width: 100%;
  margin-right: 35px;
}

.manage-territory-component-container .manage-territory-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
  padding-bottom: 60px;
}

.manage-territory-component .manage-territory-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}

.manage-territory-component .manage-territory-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 40px 0px;
}

.manage-territory-component .manage-territory-component-route-section {
  margin: 26px 40px;
}

.manage-territory-component-container .territory-fetch-users-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.territory-fetch-users-loader-pop-up .territory-fetch-users-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.territory-fetch-users-loader-pop-up .territory-fetch-users-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.territory-fetch-users-failure-pop-up
  .territory-fetch-users-failure-pop-up-div {
  height: 165px;
  width: 432px;
}

.manage-territory-component .territory-filter-mng-usr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 0 0 40px;
  width: calc(100% - 72px);
}

.territory-filter-mng-usr .territory-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
  width: 75%;
}

.territory-user-searchbar .territory-search-icon-div {
  height: 100%;
  margin: 0;
}

.territory-user-searchbar .territory-search-icon-div .territory-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.territory-user-searchbar .territory-user-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.territory-filter-mng-usr .territory-user-status-input {
  height: 40px;
  width: 25%;
}

.territory-filter-mng-usr
  .territory-user-status-input
  .territory-user-status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.territory-filter-mng-usr div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 25%;
}

.territory-filter-mng-usr .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.territory-filter-mng-usr .placeHolder .territory-selectIcon {
  top: 10px;
}

.territory-filter-mng-usr .territory-add-new-user-btn {
  align-items: center;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  min-width: 132px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.manage-territory-component .territory-users-table {
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 16px;
  width: calc(100% - 32px);
}

@media screen and (max-width: 600px) {
  .manage-territory-component-container .manage-territory-component {
    margin: 0 16px;
  }

  .manage-territory-component .territory-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .manage-territory-component .territory-filter-mng-usr {
    flex-direction: column;
    margin: 0 0px;
    width: calc(100% - 0px);
  }

  .territory-filter-mng-usr div.placeHolder {
    width: calc(100% - 32px);
  }
  .manage-territory-component .territory-user-searchbar {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .manage-territory-component .territory-search-icon-div {
    height: 100%;
    margin: 0;
  }
  .manage-territory-component .territory-search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 13px;
  }
  .manage-territory-component .territory-user-search-input {
    width: calc(100% - 32px);
  }

  .manage-territory-component .territory-user-status-input {
    height: 40px;
    margin: 0 16px;
    width: calc(100% - 32px);
    font-size: 12px;
  }
  .manage-territory-component .territory-add-new-user-btn {
    justify-content: flex-start;
    margin: 0 16px;
    width: calc(100% - 32px);
  }
  .manage-territory-component .manage-territory-main-section-header {
    margin-left: 12px;
  }
  .manage-territory-component .manage-territory-component-route-section {
    margin: 26px 40px 26px 12px;
  }
  .territory-user-searchbar .territory-user-search-input {
    font-size: 10px;
  }
  .territory-user-searchbar .territory-search-icon-div .territory-search-icon {
    margin: auto 4px;
  }
}
@media screen and (max-width: 912px) {
  .manage-territory-component-container .manage-territory-component {
    margin: 0;
    overflow-x: hidden;
  }
  .manage-territory-component .territory-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .manage-territory-component .territory-user-search-input {
    width: calc(100% - 32px);
  }
}
