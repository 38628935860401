.patientCurrentAddrCheckBox .patientCurrentAddrChkBoxRoot {
  margin-right: 10px;
  padding: 0;
}

.chkBoxDescriptionLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #18181a;
  max-width: max-content;
  margin-top: 10px;
}

.patient-current-address-info .patient-current-address-header {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  width: 100%;
}

.patient-current-address-info .patient-current-address-title-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.patient-current-address-info .patient-current-address-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.patient-current-address-info .patient-current-address-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: calc(100% - 16px);
}

.patient-current-address-info .patient-current-address-box-container {
  margin-top: 8px;
  width: 100%;
}

.patient-current-address-box-container .patient-current-address-grid-container {
  width: 100%;
}

.patient-current-address-grid-container .patient-current-address-input {
  width: 100%;
}

.patientCurrentAddressPhone {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #94949a;
  font-size: 14px;
  padding: 11.15px 12px;
}

.patientCurrentAddressPhone:focus {
  outline: none;
}

.Mui-error + div .patientCurrentAddressPhone {
  border-color: #d32f2f;
}

.patient-current-address-info
  .patient-current-address-input
  .patient-current-address-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.patient-current-address-info div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.patient-current-address-info .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.patient-currentaddress-state-input {
  height: 42px;
}

.patient-currentaddress-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.patient-currentaddress-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.patient-currentaddress-state-input .patient-currentaddress-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
