.salesmngusr-data {
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-bottom: 15px;
}
.contactinfo-main-container {
  margin-left: 36px;
}
.salesManagerUser-Name-Lable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.saleManageUserAccountName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.salesManagerUser-email-lable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.saleManageUserAccountEmailName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.salesManagerUser-contact-lable {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.saleManageUserAccountContactPhone {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
@media only screen and (max-width: 600px) {
  .salesmngusr-data {
    display: unset;
  }
  .salesManagerUser-Name-Lable {
    margin-bottom: 10px;
  }
  .salesManagerUser-email-lable {
    margin-bottom: 10px;
  }
}
