.in-patient-supply-order-list-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 19px 16px;
  width: calc(100% - 40px);
}

.in-patient-supply-order-list-page-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 40px;
}
.in-patient-supply-order-list-page-component
  .short-form
  .in-patient-supply-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.in-patient-supply-searchbar {
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 0;
  margin-bottom: 20px;
  gap: 6px;
  width: 100%;
}
.in-patient-supply-searchbar .filter-In-patientso-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  height: 40px;
  margin-right: 5px;
  width: 100%;
}

.filter-In-patientso-list .MuiInputBase-input {
  text-overflow: ellipsis;
}
.in-patient-supply-searchbar .MuiOutlinedInput-root {
  max-height: 42px;
}
.in-patient-supply-searchbar .search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  max-height: 42px;
}

.in-patient-supply-searchbar .status-input {
  max-height: 42px;
  color: #76767a;
  width: 100%;
}

.in-patient-supply-searchbar .status-input .status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
  max-height: 42px;
}

.in-patient-supply-searchbar div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.in-patient-supply-searchbar .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.in-patient-supply-searchbar .placeHolder .product-selectIcon {
  top: 10px;
}
.ag-grid-div .tableDataCentered {
  text-align: center;
}
.inpatientsupply-orderdrop-down-paper-props {
  max-height: 316px;
  min-width: 230px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
  width: min-content;
}
@media only screen and (max-width: 926px) {
  .in-patient-supply-order-list-page-component {
    margin: 0px;
    width: 100%;
    min-height: 750px;
  }
  .in-patient-supply-order-list-page-component .short-form {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  .ag-grid-div {
    height: auto;
  }
  .in-patient-supply-searchbar {
    gap: 5px;
  }
}
