.cart-item-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cart-item-component .cart-item-details {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cart-item-details .cart-item-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.cart-item-details .cart-item-grid-container .cart-item-grid-item {
  margin: 0px 0px 4px 0px;
  padding: 0px;
  width: 100%;
}

.cart-item-details .cart-item-product-image {
  display: flex;
  flex-direction: column;
  height: 48px;
  margin: auto 16px auto 0px;
  width: 48px;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .cart-item-product-name {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .cart-item-delete-button-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  margin: 0px;
  width: 100%;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .cart-item-delete-button-div
  .cart-item-delete-button {
  color: #cb0000;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  line-height: 16px;
  text-align: left;
  text-transform: none;
  text-decoration-line: underline;
  width: 100%;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .cart-item-delete-button-div
  .cart-item-delete-button.mobile {
  text-decoration-line: none;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .cart-item-stepper-component {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 32px;
  margin: 0px;
  width: 100%;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .stepper-count-input-div {
  border: 1px solid #94949a;
  height: 30px;
  padding: 0px 12px;
  width: 24px;
}

.cart-item-details
  .cart-item-grid-container
  .cart-item-grid-item
  .stepper-count-input-value {
  text-align: center;
}

.cart-item-component .max-cart-items-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cart-item-component .max-cart-items-error-message {
  color: #cb0000;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  text-transform: none;
}
