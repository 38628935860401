.need-help-sales-assistance-main-div {
  width: 100%;
}
.need-help-sales-assistance-main-div .need-help-sales-assistance-container {
  background-color: #ffffff;
  margin: 20px 40px 20px 20px;
  padding: 0 40px 40px 40px;
}
.need-help-sales-assistance-container .need-help-sales-assistance-form {
  width: 100%;
}
.need-help-sales-assistance-container
  .sales-assistance-component-route-section {
  padding-top: 40px;
}

.spinner-popup {
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

@media only screen and (max-width: 920px) {
  .need-help-sales-assistance-main-div .need-help-sales-assistance-container {
    margin: 0;
    padding: 0 20px 20px 20px;
  }
  .need-help-sales-assistance-container .need-help-sales-assistance-form {
    width: 100%;
  }
}
.salesAssistanceSuccessPopup .paperRoot {
  width: 425px;
  padding: 12px;
  margin: 0 12px;
}

.salesAssistanceSuccessPopup .salesAssistanceSuccessPopup-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.salesAssistanceSuccessPopup .salesAssistanceSuccessPopup-content {
  color: #323234;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}

.salesAssistanceSuccessPopup .salesAssistanceSuccessPopup-button {
  margin-top: 14px;
  width: 100%;
  text-transform: none;
}
