.inpatient-product-selection-main-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.inpatient-product-selection-main-div .inpatient-product-grid-div {
  width: 92%;
}
.inpatient-product-selection-main-div .inpatient-product-selection-card-header {
  font-size: 18px;
  font-weight: 700;
}

.inpatient-product-selection-header-title {
  margin-top: 5px;
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
}

.inpatient-product-selection-header-title.error-label {
  color: #cb0000;
}
.productOptionGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 16px;
  margin-top: 12px;
}
.in-patient-solventum-order-product-image {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  height: 48px;
  margin: 0px 8px 0px 0px;
  width: 48px;
}
.productOptionGridItem {
  display: flex;
  color: #76767a;
  border: 1px solid #ccccd0;
  padding: 5px 15px 5px 15px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  min-height: 55px;
  max-height: auto;
}

.productOptionGridItem-selected {
  color: #0049bd;
  border: 1px solid #0049bd;
}

@media only screen and (max-width: 930px) {
  .inpatient-product-selection-main-div {
    width: 100%;
  }
  .inpatient-product-selection-main-div .inpatient-product-grid-div {
    width: 100%;
  }
}
