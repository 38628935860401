.insurance-informantion {
  margin-top: 40px;
  width: 100%;
}

.insurance-informantion .insurance-informantion-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: 22px;
}

.insuranceInformationMain .secondaryBtnGrid {
  margin-bottom: 40px;
  margin-top: 16px;
}

.insurance-informantion .insurance-informantion-grid-container {
  width: 100%;
}
.insurance-informantion-grid-container .insurance-informantion-item {
  width: 100%;
}

.insurance-informantion-item .insurance-type-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.insurance-informantion-grid-container .insurance-informantion-input {
  width: 100%;
}

.insurance-informantion .insurance-informantion-button {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  margin-top: -16px;
  text-transform: none;
  width: 100%;
}

.insurance-informantion
  .insurance-informantion-input
  .insurance-informantion-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.insurance-informantion div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.insurance-informantion .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.insuranceInformationMain .addOrRemove-icon {
  transform: scale(1.5) !important;
}

.insuranceInformationMain .insurance-informantion-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  min-height: 18px;
  text-decoration: underline;
  text-transform: none;
}

.insuranceInformationMain .insurance-informantion-button:hover {
  text-decoration: underline;
}

.insuranceInformationMain .insurance-informantion-button.add-button {
  color: #0049bd;
}

.insuranceInformationMain .insurance-informantion-button.remove-button {
  color: #cb0000;
}

.searchpayer .paperRoot {
  width: 574px;
  height: 144px;
}

.searchpayernotfound .paperRoot {
  width: 574px;
  height: 202px;
}

.searchpayerloading .paperRoot {
  width: 574px;
  height: 380px;
}

.payer-search-loading {
  padding: 16px;
  margin-bottom: 24px;
}

.payer-search-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.payer-search-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 295px;
}
.insurance-informantion-item {
  width: 100%;
}

.payerTypeTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
  margin-top: -9px;
}

.payerTypeDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: -6px;
}
