.inventory-stored-product-main-div .inventory-stored-product-sub-div {
  margin: 24px;
}
.inventory-stored-product-main-div .inventory-storedProduct-btn-div {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 16px;
}
.InventorycomingSoonContainer {
  background-color: rgba(232, 244, 255, 1);
  width: 340px;
  height: 90px;
  padding: 10px;
  margin-left: auto;
  margin-top: inherit;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 25px;
  line-height: 16px;
}
.InventorycomingSoonContainer .heading {
  font-size: 12px;
  padding: 10px 10px 5px 0px;
  color: #0049bd;
  font-weight: 700;
}
.ag-cell-label-container .inventory-bubble-icon {
  margin-left: 4px;
}
.InventorycomingSoonContainer .contentBody {
  font-size: 12px;
  font-weight: 400;
}
.inventory-stored-product-location {
  color: #0049bd;
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.ag-theme-quartz .inventory-stored-product-days {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-theme-quartz .inventory-stored-product-location-name {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-theme-quartz .inventory-stored-missing-asset {
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 415px) {
  .InventorycomingSoonContainer {
    width: auto;
    height: auto;
    margin-left: 25px;
  }
}
@media only screen and (min-width: 415px) and (max-width: 920px) {
  .InventorycomingSoonContainer {
    margin-right: 25px;
  }
}
@media only screen and (width: 768px) {
  .InventorycomingSoonContainer {
    margin-right: 25px;
  }
}
.menu-column-empty-text {
  background-color: #ffffff;
}
.menu-column-empty-text:hover {
  background-color: #ffffff;
}
.ag-theme-quartz .menu-column-menu-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 26px;
}

@media screen and (max-width: 1024px) {
  .ag-theme-quartz .menu-column-menu-btn {
    padding-right: 6px;
  }
}
@media only screen and (max-width: 600px) {
  .inventory-stored-product-main-div .inventory-storedProduct-btn-div {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    height: 42px;
  }
  .inventory-stored-product-main-div
    .inventory-storedProduct-btn-div
    .stored-product-download-btn {
    width: 40%;
  }
  .inventory-stored-product-main-div
    .inventory-storedProduct-btn-div
    .manage-storage-locations-btn {
    height: 42px;
    width: 60%;
  }
}
