.sales-manager-history-table {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
  width: calc(100% - 80px);
}

.sales-manager-history-table .sales-manager-history-table-container {
  border: 1px solid #e4e4e8;
  margin: 0px;
  min-height: 300px;
  overflow: auto;
  width: 100%;
}

.sales-manager-history-table-container .audit-history-table {
  border-spacing: 0;
  width: 100%;
}

.sales-manager-history-table-container .audit-history-table th {
  color: #76767a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 16px;
  padding: 4px 8px;
  position: sticky;
  text-align: left;
  top: 0;
}

.sales-manager-history-table-container .audit-history-table th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.sales-manager-history-table-container .audit-history-table td {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 56px;
  padding: 4px 8px;
}

.sales-manager-history-table-container
  .audit-history-table
  tbody
  tr:first-child
  td {
  border-top: none;
}

.sales-manager-history-table-container
  .audit-history-table
  tbody
  tr:nth-child(n)
  td {
  background: #f8f8fa;
}

.sales-manager-history-table-container
  .audit-history-table
  tbody
  tr:nth-child(2n)
  td {
  background: #fff;
}

.sales-manager-history-table-container
  .audit-history-table
  thead
  th:nth-child(1) {
  width: 10%;
  max-width: 100px;
  background-position: calc(100% - 8px);
}

.sales-manager-history-table-container
  .audit-history-table
  thead
  th:nth-child(2) {
  width: 15%;
  min-width: 172px;
  background-position: calc(100% - 8px);
}

.sales-manager-history-table-container
  .audit-history-table
  thead
  th:nth-child(3) {
  width: 15%;
  min-width: 172px;
  background-position: calc(100% - 8px);
}

.sales-manager-history-table-container
  .audit-history-table
  thead
  th:nth-child(4) {
  width: 65%;
  min-width: 344px;
  background-position: calc(100% - 8px);
}

.sales-manager-history-table-container .audit-history-table th.up {
  background-image: url("../../../../assets/up_arrow.svg");
}

.sales-manager-history-table-container .audit-history-table th.down {
  background-image: url("../../../../assets/down_arrow.svg");
}

.sales-manager-history-table-container .audit-history-table th.default {
  background-image: url("../../../../assets/default.svg");
}

.audit-history-table .audit-history-row {
  min-height: 56px;
}

.audit-history-table .audit-history-date {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 56px;
}

.audit-history-table .audit-history-date.skeleton {
  font-size: 12px;
  padding: 4px;
}

.audit-history-table .audit-history-requester {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 56px;
}

.audit-history-table .audit-history-requester.skeleton {
  font-size: 12px;
  padding: 4px;
}

.audit-history-table .audit-history-sales-person {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 56px;
}

.audit-history-table .audit-history-sales-person.skeleton {
  font-size: 12px;
  padding: 4px;
}

.audit-history-table .audit-history-activity {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 56px;
}

.audit-history-table .audit-history-activity.skeleton {
  font-size: 12px;
  padding: 4px;
}

.sales-manager-history-table .pagination-grid-container {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.sales-manager-history-table .pagination-grid-container .pagination-grid-item {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-title {
  margin: 10px 8px 10px 0px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  line-height: 16px;
  width: 70px;
}

.pagination-grid-item
  .items-per-page-dropdown
  .items-per-page-input
  .items-per-page-select {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: pre;
}

.pagination-grid-item .pagination-number-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 26px;
  margin: 4px 0px;
  width: 100%;
}

.pagination-grid-item .pagination-number-list button.disabled {
  color: grey;
}

.pagination-grid-item .pagination-number-list button {
  color: #0049bd;
}

.pagination-grid-item .pagination-total-counts {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination-grid-item .pagination-total-counts .last-section-right {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
}

@media only screen and (max-width: 600px) {
  .sales-manager-history-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
