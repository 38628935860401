.footerMainDiv {
  width: 100%;
  position: absolute;
}

.footerMainDivNewOrder {
  position: absolute;
  width: 100%;
  bottom: 72px;
}
.footerMainDivErrorPopup {
  display: none;
}

.footerMainDivNewOrder.summary {
  bottom: 0px;
}

.footerForPickUpOrDischargeRequest {
  position: absolute;
  width: 100%;
  bottom: -16px;
}
.footerForPickUpOrDischargeRequest-mpd {
  position: absolute;
  width: 100%;
  bottom: 76px;
}
.footerForSalesRounding {
  position: absolute;
  width: 100%;
}
.footerForConfirmPlacement {
  position: relative;
}

.footerForAddWoundAssessment {
  position: absolute;
  width: 100%;
  bottom: 72px;
}

.footerForAddWoundAssessmentWithoutBottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.footerForPickUpRequest {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.footerForDischargeRequest {
  position: absolute;
  width: 100%;
  bottom: 70px;
}

.footerForInternalUser {
  width: 100%;
  bottom: 80px;
}

.footerSupplyOrderDiv {
  position: absolute;
  width: 100%;
  bottom: 68px;
}

.footerForManageProfile {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.footerForRegistration {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.cms-content-link {
  padding: 10px;
  display: inline;
  border-bottom: 1px;
  height: 20px;
  color: #323234;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.footerForCreateServiceRequest {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 96px;
}

.footerForInpatientOrder {
  bottom: 0px;
  height: 64px;
  position: absolute;
  width: 100%;
}

.footerForAcuteOrderPickUpRequest {
  bottom: 0px;
  height: 64px;
  position: absolute;
  width: 100%;
}

.salesAssistanceFooter {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1125px) {
  .cms-content-link {
    padding: 0px;
  }
}

@media screen and (max-width: 926px) {
  .footerForPickUpOrDischargeRequest {
    position: relative;
    width: 100%;
  }
  .footerForPickUpOrDischargeRequest-mpd {
    position: relative;
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 926px) {
  .footerForPickUpOrDischargeRequest,
  .footerForSalesRounding,
  .footerForAddWoundAssessment,
  .footerForDischargeRequest,
  .footerSupplyOrderDiv {
    position: relative;
    bottom: auto;
  }
}

@media only screen and (max-width: 920px) {
  .footerForPickUpOrDischargeRequest {
    bottom: auto;
    position: relative;
  }
  .footerForPickUpOrDischargeRequest-mpd {
    bottom: auto;
    position: relative;
  }
  .footerForSalesRounding {
    bottom: auto;
    position: relative;
  }

  .footerForManageProfile.manage-profile-iPad-screen {
    bottom: 72px;
  }

  .footerMainDivNewOrder.small-screen {
    bottom: 72px;
  }
}

@media only screen and (min-width: 446px) and (max-width: 959px) {
  .footerForPickUpRequest {
    bottom: 70px;
  }

  .footerForDischargeRequest {
    bottom: 70px;
  }
}

@media only screen and (max-width: 445px) {
  .footerForPickUpRequest.pickupRequest-small-screen {
    bottom: 96px;
  }

  .footerForDischargeRequest.dischargeRequest-small-screen {
    bottom: 96px;
  }

  .footerForManageProfile.manage-profile-small-screen {
    bottom: 72px;
  }
}

@media only screen and (max-width: 350px) {
  .footerForPickUpRequest.pickupRequest-smaller-screen {
    bottom: 135px;
  }
  .footerForDischargeRequest.dischargeRequest-smaller-screen {
    bottom: 145px;
  }

  .footerForManageProfile.manage-profile-smaller-screen {
    bottom: 72px;
  }

  .footerForManageProfile.manage-profile-smaller-screen.internal-user {
    bottom: 48px;
  }

  .footerMainDivNewOrder.smaller-screen {
    bottom: 54px;
  }
}

@media screen and (max-width: 1151px) {
  .footerForAddWoundAssessment {
    position: relative;
    bottom: auto;
  }
}

@media screen and (min-width: 926px) {
  .footerMainDiv {
    position: absolute;
  }
}

@media screen and (max-width: 414px) {
  .footerForAddWoundAssessment {
    height: 234px;
  }
}

@media screen and (min-width: 359px) and (max-width: 419px) {
  .footerForAddWoundAssessment {
    height: 212px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 599px) {
  .footerSupplyOrderDiv {
    position: relative;
    bottom: 15px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 912px) {
  .footerSupplyOrderDiv {
    bottom: 0;
  }
}
