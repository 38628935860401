.in-patient-order-patient-and-product-detail-component {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 16px;
  width: calc(100% - 32px);
  border: 1px solid #e4e4e8;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-patient-or-product-detail {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  margin: 0px;
  width: 100%;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-patient-or-product-detail
  .in-patient-order-patient-detail-patient-name {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  text-align: left;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-patient-or-product-detail
  .in-patient-order-patient-detail-patient-date-of-birth {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-product-detail {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  row-gap: 8px;
  width: 100%;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-product-detail
  .in-patient-order-product-image {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  height: 48px;
  margin: 0px 8px 0px 0px;
  width: 48px;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-product-detail
  .in-patient-order-product-detail-product-to-place {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}

.in-patient-order-patient-and-product-detail-component
  .in-patient-order-patient-detail-grid-container
  .in-patient-order-patient-detail-grid-item
  .in-patient-order-product-detail
  .in-patient-order-product-detail-product-name-and-serial-number {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}
