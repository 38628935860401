.sales-rounding-patient-details-main-div {
  width: 100%;
}

.sales-rounding-patient-details-main-div .sales-rounding-patient-details-name {
  padding-bottom: 40px;
}

.sales-rounding-patient-details-name
  .sales-rounding-patient-details-header-name {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.sales-rounding-patient-details-main-div
  .sales-rounding-patient-information-sub-component {
  width: 68%;
}

.sales-rounding-patient-details-main-div
  .sales-rounding-patient-information-sub-component
  .sales-rounding-patient-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .sales-rounding-patient-details-main-div
    .sales-rounding-patient-information-sub-component {
    width: calc(100% - 20px);
    margin: 16px 14px;
  }
  .sales-rounding-patient-details-main-div
    .sales-rounding-patient-details-back-button {
    padding: 8px 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .sales-rounding-patient-details-main-div
    .sales-rounding-patient-details-back-button
    .sales-rounding-patient-details-back-button-name {
    font-size: 14px;
    color: #323234;
    font-weight: 700;
  }
}
