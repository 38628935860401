.return-stored-product-return-information-div {
  margin-top: 20px;
}
.return-stored-product-return-information-div .return-information-div-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
}
.return-stored-product-return-information-div
  .return-information-grid-container {
  display: flex;
}
.return-stored-product-return-information-div .return-information-grid-item {
  padding-top: 10px !important;
  width: 100%;
}

.return-information-grid-item .return-information-grid-item-input {
  height: 40px;
  width: 100%;
}
.return-information-grid-item
  .return-information-grid-item-input
  .return-information-grid-item-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.return-information-grid-item div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.return-information-grid-item .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}
.return-information-grid-item .textarea-root {
  padding: 0;
}
.return-information-grid-item .helperText {
  margin: 5px 0 0;
  text-align: right;
}
.return-stored-product-return-information-div .MuiInputBase-input {
  border: unset;
}
.return-information-grid-item .radioRoot {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.return-information-grid-item .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}
.return-information-grid-item .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}
.return-information-grid-item .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.return-information-grid-item .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.return-information-grid-item .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.return-information-grid-item .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.return-information-grid-item .storedProductReturnBtnGroup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}
.storedProductReturnBtnGroup .submitRequest {
  width: 100%;
}
.storedProductReturnBtnGroup .cancelRequest {
  width: 100%;
}
@media only screen and (max-width: 450px) {
  .return-information-grid-item .storedProductReturnBtnGroup {
    display: flex;
    flex-direction: column;
  }
  .return-information-grid-item .optionRoot {
    width: 48%;
  }

  .return-information-grid-item .optionRoot-active {
    width: 48%;
  }
  .return-information-grid-item .storedProductReturnBtnGroup {
    gap: 10px;
    margin-top: 22px;
  }
}
