.dailogClassPatient .dailogPaperPatient {
  max-width: 662px;
  min-width: 662px;
  min-height: 378px;
  background-color: white;
}

.confirmationTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  flex: 100%;
}

.closeIcon {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.addPatientIntro {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 8px;
  margin-bottom: 16px;
}

.or {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  text-align: center;
  margin: 4px 0;
}

.dialogSpace {
  padding: 16px;
}

.addPatient {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.dialogSpace .search-patient {
  width: 100%;
  margin-top: 12px;
}
