.wound-assessment-report-component {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px;
  min-height: calc(100vh - 144px);
  width: calc(100% - 32px);
}

.wound-assessment-report-component .wound-assessment-report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 24px;
  width: calc(100% - 48px);
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-route-section {
  margin: 16px 0px;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-title {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 16px 40px 16px 0;
  width: calc(100% - 40px);
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-header-details {
  display: flex;
  flex-direction: row;
  margin: 32px 0px 16px;
  width: 100%;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-header-details
  .wound-assessment-report-order-returned {
  color: #323234;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: auto 0px;
  text-align: left;
  width: 100%;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-header-details
  .wound-assessment-report-download-button-component {
  display: flex;
  justify-content: flex-end;
  min-width: 180px;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-header-details
  .wound-assessment-report-download-button-component
  .wound-assessment-report-download-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0px;
  text-align: center;
}

.wound-assessment-report-component
  .wound-assessment-report-container
  .wound-assessment-report-header
  .wound-assessment-report-header-details
  .wound-assessment-report-download-button-component
  .wound-assessment-report-download-button:disabled {
  color: #76767a;
  cursor: none;
}

.wound-assessment-report-container-popup .paperRoot {
  width: 430px;
  padding: 16px;
}

.wound-assessment-report-container-popup
  .wound-assessment-report-container-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

@media screen and (max-width: 926px) {
  .wound-assessment-report-component {
    margin: 0;
    width: 100%;
  }
}

@media only screen and(max-width: 600px) {
  .wound-assessment-report-component .wound-assessment-report-container {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .wound-assessment-report-component
    .wound-assessment-report-container
    .wound-assessment-report-header
    .wound-assessment-report-header-details {
    display: flex;
    flex-direction: row;
    margin: 24px 0px 16px;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .wound-assessment-report-component .wound-assessment-report-container {
    margin: 8px;
    width: calc(100% - 16px);
  }

  .wound-assessment-report-component
    .wound-assessment-report-container
    .wound-assessment-report-header
    .wound-assessment-report-header-details {
    margin: 16px 0px 16px;
  }

  .wound-assessment-report-container-popup .paperRoot {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .wound-assessment-report-container-popup
    .wound-assessment-report-container-popup-div {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}
