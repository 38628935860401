.wound-tunneling-main-container .tunneling {
  margin-top: 35px;
  width: 580px;
}

.tunneling-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.wound-tunneling-main-container .tunneling .tunneling-box-container {
  margin-top: 12px;
  width: 100%;
}

.wound-tunneling-main-container
  .tunneling
  .tunneling-box-container
  .tunneling-grid-container {
  width: 100%;
}

.wound-tunneling-main-container
  .tunneling
  .tunneling-box-container
  .tunneling-grid-container
  .tunneling-grid-item {
  width: 100%;
}

.tunneling .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.tunneling
  .tunneling-box-container
  .tunneling-grid-container
  .tunneling-grid-item
  .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.tunneling
  .tunneling-box-container
  .tunneling-grid-container
  .tunneling-grid-item
  .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.tunneling
  .tunneling-box-container
  .tunneling-grid-container
  .tunneling-grid-item
  .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.tunneling .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.tunneling .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.tunneling .tunneling-grid-item .depth-input {
  width: 100%;
}

.tunneling .clockPosition-input {
  width: 105px;
}

.tunneling .clockPosition-input .clockPosition-select {
  height: 12px;
  width: 242px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.tunneling .placeHolder .selectIcon {
  top: 10px;
}

.tunneling .tunneling-box-container div.placeHolder {
  border-radius: 2px;
  width: 100%;
}

.tunneling .placeHolder div.placeHolder {
  border-right: unset;
  height: 22px;
  width: 242px;
}

.tunneling .MuiSelect-icon {
  top: calc(50% - 0.4em);
}

.clockText {
  margin-top: 30px;
  margin-left: 13px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-left: 0px;
}

.clockText .text-tunneling {
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .clockText {
    margin-top: 38px;
    margin-left: unset;
  }
}
@media only screen and (max-width: 920px) {
  .wound-tunneling-main-container .tunneling {
    width: 100%;
  }
}
