.makeUnitAvailable-main-component{
    margin: 16px 16px 8px 16px;
}

.makeUnitAvailable-title{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

 .makeUnitAvailable-sub-component{
  margin-left: 8px;
  margin-right: 4px;
 }

.makeUnitAvailable-div-desc{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.makeUnitAvailable-date-main-div{
    margin-top: 10px;
    margin-bottom: 10px;
}

.dateAvailableForPlacement input.input {
    border-right: 0;
  }
  
  div.dateAvailableForPlacement {
    padding-right: 0;
  }
  
  .dateAvailableForPlacement .outline {
    border-radius: 2px;
  }
  
  .dateAvailableForPlacement.showError .outline {
    border: 1px solid #d32f2f;
  }
  
  .dateAvailableForPlacement.noError.Mui-error .outline {
    border: 1px solid #94949a;
  }

 .makeunitAvailable-grid-item{
    margin-top: 10px;
    margin-bottom: 30px;
 }
  

  div.placeholder {
    border-radius: 2px;
    color: #76767a;
  }
  
  .placeholder div.placeholder {
    border-right: unset;
    height: 20px;
  }
  .makeUnitAvailable-main-component
  .makeunitAvailable-grid-item
  .makeUnitAvailable-information-input{
    width: 100%;
  }
  
  .makeUnitAvailable-main-component
  .makeunitAvailable-grid-item
  .makeUnitAvailable-information-input
  .makeUnitAvailable-information-select {
    width: 100%;
    height: 12px;
    display: block;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
  
  .makeUnitAvailable-main-component div.placeHolder {
    border-radius: 2px;
    color: #76767a;
    width: 100%;
  }
  
  .makeUnitAvailable-main-component .placeHolder div.placeHolder {
    border-right: unset;
    height: 20px;
    width: 100%;
  }

  .makeUnitAvailable-main-component .placeHolder .selectIcon {
    top: 10px;
  }
  .MakeunitAvailable-buttons-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 400px) {
    .makeUnitAvailable-title{
        font-size: 18px;
    }
  }