.in-patient-supply-order-tool-bar {
  display: flex;
  flex-direction: column;
  margin: 8px 16px;
  width: calc(100% - 32px);
}

.in-patient-supply-order-tool-bar .in-patient-supply-order-main-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto 0px;
  width: 100%;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-left-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: calc(15% - 16px);
  width: 100%;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-left-container
  .firstButton {
  color: #cb0000;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  margin: 0px;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  width: fit-content;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-left-container
  .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-left-container
  .firstButton:disabled {
  color: #76767a;
  text-decoration-color: #76767a;
  cursor: none;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 64px;
  width: 100%;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container
  .secondButton {
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  margin: 0px 8px 0px 0px;
  min-width: 140px;
  line-height: 18px;
  text-decoration: none;
  width: fit-content;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container
  .secondButton:hover {
  border: 1px solid #0049bd;
  background-color: unset;
  text-decoration: none;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container
  .secondButton:disabled {
  border: 1px solid #76767a;
  color: #76767a;
  cursor: none;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container
  .thirdButton {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  margin: 0px;
  min-width: 192px;
  width: fit-content;
}

.in-patient-supply-order-tool-bar
  .in-patient-supply-order-main-container
  .in-patient-supply-order-right-container
  .thirdButton-disabled {
  background: #e4e4e8;
  color: #76767a;
  min-width: 192px;
}

@media only screen and (max-width: 920px) {
  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-left-container {
    margin-left: 0px;
  }

  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-right-container {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .in-patient-supply-order-tool-bar {
    margin: 0px;
    width: 100%;
  }

  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-left-container
    .firstButton {
    min-width: max-content;
  }

  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-right-container
    .secondButton {
    min-width: fit-content;
  }

  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-right-container
    .thirdButton {
    min-width: 80px;
  }
}

@media only screen and (max-width: 330px) {
  .in-patient-supply-order-tool-bar
    .in-patient-supply-order-main-container
    .in-patient-supply-order-left-container {
    width: 60px;
  }
}
