.user-profile-account-info-component {
  width: 75%;
}

.user-profile-account-info-component .user-profile-account-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.user-profile-account-info-component .user-accountInfo-grid-container {
  padding: 0px;
  width: 100%;
  margin: 0 -8px;
}

.user-profile-account-info-component .user-accountInfo-grid-container .grid-item-width {
  width: 100%;
  padding: 8px;
}

.user-profile-account-info-component .user-accountInfo-grid-container .email-grid-item-width {
  width: 100%;
  padding: 8px;
}

.user-profile-account-info-component .account-info-input {
  width: 100%;
}

.user-profile-account-info-component .user-contact-information-input {
  width: 100%;
}

.user-profile-account-info-component .phone-type-grid-item {
  margin-left: 16px;
}

.user-profile-account-info-component .user-contact-phone-info-div {
  margin: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.user-profile-account-info-component .user-profile-contact-info {
  margin-top: 10px;
  width: 50%;
}

.user-profile-account-info-component .user-profile-email-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #76767a;
}

.user-profile-account-info-component .user-profile-email {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #0049bd;
  cursor: pointer;
}

.user-profile-account-info-component .user-profile-phone-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #76767a;
}

.user-profile-account-info-component .user-profile-phone {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #323234;
}

.user-profile-account-info-component .user-contact-phone-info-grid {
  width: 100%;
}

.user-profile-account-info-component .user-phone-type-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0px;
  width: 100%;
}

.user-profile-account-info-component
  .user-phone-type-container
  .user-radio-group {
  width: 100%;
  display: flex;
}

.user-profile-account-info-component .user-phone-type-container .optiontxt {
  color: #18181a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.user-profile-account-info-component
  .user-phone-type-container
  .user-extension {
  max-width: 80px;
  margin-right: 15px;
  margin-left: 5px;
}

.user-profile-account-info-component
  .user-phone-type-container
  .user-radio-group
  .radioPhoneRoot {
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
}

.user-profile-account-info-component .temp-password-text-grid {
  width: 75%;
  margin: 10px 0 40px 0;
}

.user-profile-account-info-component .temp-password-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

@media only screen and (max-width: 600px) {
  .user-profile-account-info-component {
    width: 100%;
  }
  .user-profile-account-info-component .user-accountInfo-grid-container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .user-accountInfo-grid-container .grid-item-width {
    width: 100%;
    height: 70px;
  }
  .user-profile-account-info-component .account-info-input {
    width: 100%;
  }
  .user-profile-account-info-component .user-contact-information-input {
    width: 100%;
  }
  .user-profile-account-info-component .add-user-phone {
    width: 100%;
  }
  .user-profile-account-info-component .user-contact-phone-info-div {
    display: flex;
    flex-direction: column;
  }
  .user-profile-account-info-component .phone-type-grid-item {
    margin: 10px 0 10px 0;
  }
  .user-profile-account-info-component .user-contact-phone-info-grid {
    width: 100%;
  }
  .user-profile-account-info-component .temp-password-text-grid {
    width: 100%;
  }
  .user-profile-account-info-component .user-profile-contact-info {
    width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .user-profile-account-info-component {
    width: 100%;
  }
  .user-profile-account-info-component .user-contact-information-input {
    width: 100%;
  }
  .user-profile-account-info-component .add-user-phone {
    width: 100%;
  }
  .user-profile-account-info-component .user-contact-phone-info-grid {
    width: 100%;
  }
  .user-profile-account-info-component .temp-password-text-grid {
    width: 100%;
  }
  .user-profile-account-info-component .user-profile-contact-info {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 912px) {
  .user-profile-account-info-component {
    width: 100%;
  }
  .user-profile-account-info-component .user-accountInfo-grid-container {
    width: 95%;
  }
}
