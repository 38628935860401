.maincontainer {
  padding: 16px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  margin: 16px;
}

.mainbox {
  display: flex;
  padding: 34px 187px;
  flex-direction: column;
  align-items: center;
  margin-top: -41px;
}

.parastyle {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.buttoncontainer {
  float: right;
  margin-right: 200px;
  margin-bottom: 64px;
}
.acceptbutton {
  width: 300px;
  padding: 11px 14px;
  left: 4px;
}
.buttoncontainer .acceptbutton {
  text-transform: none;
}

.backbutton {
  width: 160px;
  padding: 11px 24px;
  right: 4px;
}
@media only screen and (max-width: 920px) {
  .mainbox {
    padding: 0px;
  }
  .maincontainer {
    margin: 0px;
  }
  .buttoncontainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    float: none;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
