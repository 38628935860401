.hide-menu-action {
  display: none;
}
.show-menu-action {
  display: visibe;
}
.inventory-menu-option {
  width: 280px;
  padding: 10px 0 10px 22px !important;
}
.inventory-menu-option .MuiMenuItem-root {
  padding: 100px;
}
@media screen and (max-width: 600px) {
  .inventory-menu-option {
    width: 260px;
  }
}
