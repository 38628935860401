.patient-fin-stepper-container {
  margin: 16px 0px 16px 16px;
}
.patient-fin-stepperForm {
  background-color: #fff;
  margin-right: 57px;
  margin-bottom: 0px;
}
.patient-fin-stepperForm .short-form {
  width: 65.56%;
}

@media only screen and (max-width: 600px) {
  .patient-fin-stepper-container {
    margin: 0;
  }
  .patient-fin-stepperForm {
    margin: 0;
  }
}
