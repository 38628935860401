.orderoverview-supply-order-info {
  margin: 0;
  width: calc(100% - 4px);
}

.orderoverview-supply-order-info .dressing-supplies-component-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0px 0 0 0;
  width: 100%;
}

.all-content-div
  .dressing-supplies-component-title
  .orderoverview-supply-order-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  margin-left: 12px;
}

.orderoverview-supply-order-info .all-content-div {
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 16px;
  width: calc(100% - 31px);
}

.orderoverview-supply-order-info .content-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  margin-left: 14px;
  width: 100%;
}

.orderoverview-supply-order-info .content-div-last {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}

.orderoverview-supply-order-info .sub-content-div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}

.sub-content-div .orderoverview-supply-order-info-content-title {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}

.sub-content-div .orderoverview-supply-order-info-content-value {
  display: flex;
  align-items: center;
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 8px;
  min-height: 18px;
  list-style: none;
}

.orderoverview-supply-order-info-content-value .dot-with-space {
  margin-right: 8px;
}
@media only screen and (max-width: 1024px) {
  .sub-content-div .orderoverview-supply-order-info-content-value {
    width: 258px;
  }
  .supplyOrdertracking1 {
    width: 120px;
  }
  .supplyOrdermilestone {
    margin-top: 29px;
  }
}
.sub-content-div .orderoverview-supply-order-info-content-value {
  padding-left: 16px;
  position: relative;
}

.sub-content-div .orderoverview-supply-order-info-content-value::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0px;
}
@media only screen and (max-width: 360px) {
  .sub-content-div .orderoverview-supply-order-info-content-value {
    width: 255px;
  }
}
