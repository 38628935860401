.Update-location-name-div {
  padding: 16px;
}
.Update-location-name-div .update-location-title1 {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
}
.Update-location-name-div .update-location-title2 {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  padding: 16px 16px 16px 0px;
}
.Update-location-name-div .upate-location-input {
  padding: 0px 16px 16px 0px;
}
.update-loc-label {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.Update-location-name-div .update-inputBox {
  padding-bottom: 16px;
  width: 100%;
}
.Update-location-name-div .update-location-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.update-location-buttons .update-location-cancel-btn {
  width: 200px;
}
.update-location-buttons .update-loc-update-btn {
  width: 200px;
}
