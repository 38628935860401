.add-location {
  padding: 16px;
}
.add-location .add-location-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding: 0px 16px 8px 0px;
}
.add-location .add-location-title2 {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 16px;
}
.add-location .add-loc-input {
  width: 100%;
}
.add-locations-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 20px;
}
.add-locations-buttons .add-location-cancel-btn {
  width: 200px;
}
.add-locations-buttons .add-storage-loc-btn {
  width: 200px;
}
@media only screen and (max-width: 600px) {
}
