.submit-proof-of-service-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 16px;
  width: 100%;
  min-height: calc(100vh - 144px);
}

.submit-proof-of-service-component .proofOfService-container-main {
  width: 60%;
  height: auto;
  margin-top: 40px;
  margin-left: 40px;
  flex-direction: row;
  display: flex;
}

.submit-proof-of-service-component .pos-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  width: 100%;
}
.submit-proof-of-service-component .pos-alert-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  width: calc(100% - 16px);
}
.submit-proof-of-service-component .pos-subTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  width: 100%;
}
.submit-proof-of-service-component .pos-paragragh {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  padding: 40px 0px 0px 0px;
  width: calc(100% - 16px);
}
.submit-proof-of-service-component .pos-backToMyPatients-btn {
  margin: 0px 0px 70px 4px;
}
.submit-pod-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}
.pos-paragraph-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 3px;
  margin-right: 3px;
}
.proof-heading {
  display: block;
  margin-left: 3px;
}
.submit-proof-of-service-component .pos-patient-details {
  margin-top: 10px;
  width: 100%;
}

.pos-alert-text-paragraph {
  width: 100%;
}
.pos-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pos-loader-pop-up .pos-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}
.acute_success .acute_success_text {
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
}
.acute_success .acute_success_text .acute_success_text_bold {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

@media only screen and (max-width: 920px) {
  .submit-proof-of-service-component {
    width: 100%;
    margin: 0px;
  }
  .submit-proof-of-service-component .pos-patient-details {
    margin: 16px;
    width: 100%;
  }
  .submit-proof-of-service-component .proofOfService-container-main {
    margin: 20px 0px 0px 0px;
    width: 100%;
  }
  .submit-proof-of-service-component .pos-subTitle-text {
    margin-left: 16px;
  }
  .submit-proof-of-service-component .pos-alert-text {
    display: flex;
    margin: 0px;
    width: 100%;
  }
  .submit-proof-of-service-component .pos-paragragh {
    margin: 10px;
  }
  .pos-paragraph-mobile {
    width: 100%;
  }
  .pos-paragraph-response {
    width: 100%;
  }
  .pos-alert-text-paragraph {
    margin: 16px;
    width: 100%;
  }
  .proofOfDelivery-container-main .patient-details {
    width: 72%;
  }
  .pod-paragragh {
    width: 100%;
  }
  .submit-proof-of-service-component .pos-heading {
    margin-left: 12px;
  }
  .submit-proof-of-service-component .pos-backToMyPatients-btn {
    margin: 10px;
    padding: 20px 0px;
  }

  .acute_success .acute_success_text {
    margin-left: 12px;
  }
}
