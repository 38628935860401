.roles-permission-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 144px);
  padding: 0;
  width: calc(100% - 64px);
}

.roles-permission-component .roles-permission-component-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.roles-permission-component .roles-permission-component-route-section {
  margin: 26px 40px;
  width: calc(100% - 80px);
}

.roles-permission-component .roles-permission-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}

.roles-permission-component .roles-permission-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 40px 0px;
  width: calc(100% - 80px);
}

.roles-permission-component .roles-permission-main-section-header-desc {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 40px;
  width: calc(100% - 80px);
}

.roles-permission-component .register-permission-section {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  width: 100%;
}

.register-permission-section .register-permission-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 0 40px;
  width: calc(100% - 40px);
}

.register-permission-section .register-permission-description {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 40px;
  width: calc(100% - 80px);
}

.register-permission-section .register-permission-component {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.roles-permission-component .update-roles-success-pop-up {
  height: 196px;
  width: 574px;
}

.update-roles-success-pop-up-div {
  display: flex;
  justify-content: center;
  height: 196px;
  margin: 0;
  width: 574px;
}

.roles-permission-component .roles-button-group {
  width: 100%;
}

.roles-permission-component .registered-permissions {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 30px;
}

.roles-permission-component .registered-permissions-grid-container {
  margin: 0;
  width: 70%;
}

.roles-permission-component
  .registered-permissions-grid-container
  .registered-permissions-grid-item {
  margin-top: -8px;
  width: 100%;
}

.roles-permission-component .registered-permissions-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: 10px;
  width: max-content;
}

.roles-permission-component
  .registered-permissions-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  margin-top: 10px;
  width: max-content;
}

.roles-permission-main-section .error-message {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin: 16px 40px;
}

@media only screen and (max-width: 926px) {
  .roles-permission-component {
    margin: 0px;
    min-height: calc(100vh - 144px);
    width: 100%;
  }
  .roles-permission-component .roles-permission-main-section {
    margin: 0px 0px 40px;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .roles-permission-component .roles-permission-component-route-section {
    margin: 26px 20px;
    width: calc(100% - 40px);
  }
  .roles-permission-component .roles-permission-main-section-header {
    margin: 12px 20px;
    width: calc(100% - 40px);
  }
  .roles-permission-component .roles-permission-main-section-header-desc {
    margin: 8px 20px;
    width: calc(100% - 40px);
  }

  .register-permission-section .register-permission-title {
    margin-left: 20px;
  }

  .register-permission-section .register-permission-description {
    margin: 8px 20px;
    width: calc(100% - 40px);
  }

  .roles-permission-component .registered-permissions {
    margin: 0px;
  }
}

@media only screen and (max-width: 574px) {
  .roles-permission-component .update-roles-success-pop-up {
    height: fit-content;
    margin: 16px;
    width: calc(100% - 16px);
  }

  .update-roles-success-pop-up-div {
    height: fit-content;
    margin: 16px;
    width: calc(100% - 32px);
  }
}
