.progressContainer {
  display: flex;
  background-color: #fff;
  margin-top: 1px;
  padding: 0 24px;
  height: 56px;
  align-items: center;
}
.backBtn {
  max-width: 36px !important;
}
.progress {
  display: flex;
  flex-direction: column;
}
.signup {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 8px;
}
.progressBar {
  max-width: 100%;
}
.progress-indicator.progress-root {
  background-color: #f2f2f5;
  border-radius: 12px;
  height: 8px;
}
.progress-stick {
  border-radius: 12px;
}
@media only screen and (max-width: 600px) {
  .progressContainer {
    padding: 0px 16px;
  }
  .progress-indicator.progress-root {
    width: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .progressContainer {
    padding: 0px 16px;
  }
}
