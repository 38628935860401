.registration-form {
  align-items: center;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
  margin: 16px auto 80px;
  width: 48.66%;
}

.registration-form .registration-form-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
}

.registration-form .registration-form-descripton {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.registration-form .form-descripton {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  width: 100%;
}

.registration-form .accountInfo-grid-container {
  width: 100%;
}

.registration-form .contact-information-component {
  width: 100%;
}

.registration-form .password-component {
  width: 100%;
}

div.grecaptcha-badge {
  bottom: 190px !important;
  box-shadow: none !important;
}
#recaptcha-container {
  display: flex;
  align-self: flex-start;
  margin-top: 50px;
}

/* mobile css */
@media only screen and (max-width: 920px) {
  .registration-form {
    margin: 16px auto 152px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .registration-form {
    margin: 16px auto 184px !important;
  }

  div.grecaptcha-badge {
    bottom: 100px !important;
  }
}
  