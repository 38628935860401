.prescriber-search-component {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
}

.prescriber-search-component .prescriber-search-header {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 16px 0px;
  text-align: left;
  width: calc(100% - 32px);
}

.prescriber-search-component .prescriber-search-results {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
