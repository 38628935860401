.site-access-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.site-access-component .site-access-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0px;
}

.site-access-component .site-access-description-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.site-access-description-grid-container .site-access-description-grid-item {
  margin: 0px;
  padding: 0px !important;
  width: 100%;
}
.site-access-description-grid-item .site-access-description-div {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.site-access-description-div .site-access-description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 16px 8px 0;
  width: 100%;
}

.site-access-component .site-access-card-grid-conatainer {
  border: 1px solid #e4e4e8;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-bottom: 12px;
}

.site-access-card-grid-conatainer .site-access-card-grid-item {
  margin: 0px;
  padding: 0px !important;
  width: 100%;
}

.site-access-card-grid-item .edit-facility-address {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}

.edit-facility-address .site-access-edit {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 16px;
  text-align: center;
  text-transform: none;
  text-decoration-line: underline;
  width: fit-content;
}

.edit-facility-address .site-access-edit:disabled {
  color: #76767a;
}

.edit-facility-address .facility-address-div {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
}

.facility-address-div .facility-address {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.edit-facility-address .facility-address .facility-name {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0 8px 0 0;
}

.site-access-card-grid-item .site-access-role-permission-status {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}

.site-access-role-permission-status .role-permission-status {
  display: flex;
  flex-direction: column;
  max-width: 33.33%;
  min-width: 100px;
  margin: 16px;
}

.role-permission-status .site-access-card-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
}

.role-permission-status .site-access-card-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.role-permission-status .site-access-card-value.active {
  color: #00941c;
}

.role-permission-status .site-access-card-value.not-active {
  color: #cb0000;
}

.site-access-description-div .add-facility-div {
  align-items: end;
  display: flex;
  flex-direction: column;
  width: 125px;
}

.add-facility-div .add-facility {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 0px 0;
  text-align: center;
  text-transform: none;
  width: 125px;
}

.add-facility-div .add-facility:disabled {
  color: #76767a;
}

@media only screen and (max-width: 600px) {
  .site-access-component .site-access-card-grid-conatainer {
    display: flex;
    flex-direction: column;
  }
  .site-access-card-grid-item .site-access-role-permission-status {
    justify-content: space-between;
  }
  .site-access-role-permission-status .role-permission-status {
    min-width: 60px;
  }
  .site-access-card-grid-item .edit-facility-address {
    margin-top: 5px;
  }
  .edit-facility-address .site-access-edit {
    margin-left: 0px;
  }
  .edit-facility-address .facility-address-div {
    margin: 0 0 0 25px;
  }
  .site-access-description-grid-item .site-access-description-div {
    flex-direction: column;
  }
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
  .add-facility-div .add-facility {
    margin: 0px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .site-access-role-permission-status .role-permission-status {
    min-width: 70px;
  }
}

@media only screen and (max-width: 912px) {
  .site-access-role-permission-status .role-permission-status {
    min-width: 50px;
  }
}
