.supplyOrder-flow {
  background-color: #f8f8fa;
  display: flex;
  margin: 0px;
  flex-direction: column !important;
  min-height: calc(100vh - 144px);
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .supplyOrderGrid {
    width: 100%;
  }
}
.unlinked-facility-margin {
  margin-inline: 10%;
}
