.showMissingRequiredField {
  display: flex;
  flex-direction: column;
  height: 206px;
  width: 500px;
  justify-content: center;
}
.showMissingRequiredField .showMissingRequiredFieldTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #0049bd;
  flex: none;
  margin: 24px 16px 0px 16px;
}
.showMissingRequiredField .showMissingRequiredField-desp {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin: 8px 16px 26px 16px;
}
.showMissingRequiredField .cancelBtn {
  margin: 0px 16px 16px 16px;
}
@media only screen and (max-width: 600px) {
  .showMissingRequiredField {
    width: 320px;
  }
}
