.prescriptionSentdiv {
  padding: 16px;
}
.success-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #007713;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.success-span {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.prescriberSentBox {
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 24px;
  width: 542px;
  height: 68px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
}
.headers-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.headers-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.email-info {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 30px;
}
.done-button {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .prescriberSentBox {
    width: 100%;
    height: 100%;
    display: block;
  }
}
