.boxOrderStyle .nextBtnNewOrder {
  width: 140px;
  margin-right: 12px;
}
.boxOrderStyle .submitBtnNewOrder {
  width: 140px;
  margin-right: 12px;
}

.prevBtn .previousBtn {
  margin-right: 8px;
  padding-right: 57px;
  padding-left: 57px;
}

.shareOrderGrid .shareOrderButton {
  height: 40px;
  min-width: 140px;
}

.footerGrid .shareOrderGrid {
  margin-left: -18px;
}

.boxOrderStyle .previousBtn {
  width: 140px;
}

.boxOrderStyle .shareOrderButton {
  height: 40px;
  width: unset;
}

.shareOrderImageDisabled {
  position: relative;
  right: 6px;
  bottom: 2px;
  color: gray;
}
.shareOrderImage {
  position: relative;
  right: 6px;
  bottom: 2px;
}
.footer-button-mobile-container .textOrderClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  min-width: 60%;
}
.boxOrderStyle .textOrderClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  min-width: 140px;
}
.boxOrderStyle .vacOrder-sso-textOrderClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  min-width: 140px;
  margin-left: 46px;
}
.errorTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 24px;
}

.cancelOrderPopup .paperRoot {
  width: 383px;
  height: 240px;
}

.nextOrderPopup .paperRoot {
  padding: 32px 24px 32px 24px;
  width: 675px;
}

.next-order-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nextOrderPopup .popupCloseIcon {
  display: none;
}

.btn-back {
  margin-bottom: 8px;
  width: 100%;
}
.next-order-pop-up .nextOrderPopupText {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  margin: 0px;
}

.next-order-pop-up .nextOrderPopupDescText {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 16px 0 24px 0;
}

.next-order-pop-up .nextOrderBtnDiv {
  display: flex;
  flex-direction: row;
  height: 48px;
  margin: 0px;
}

.nextOrderBtnDiv .nextOrderSubmitBtn {
  padding: 14px, 16px, 14px, 16px !important;
  width: 301.5px;
}

.nextOrderBtnDiv .nextOrderPartialButton {
  background-color: #0049bd;
  margin-left: 24px;
  text-transform: none;
  padding: 14px, 16px, 14px, 16px !important;
  width: 301.5px;
}

.orderMaincontainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.footer-btn-group .orderToolBar {
  width: 100%;
  padding: 0;
  min-height: 40px;
}

.orderLeftContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 8px;
  width: 100%;
}

.orderRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
  width: 100%;
}

.boxOrderStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -8px;
}

.exitCancelBtn {
  margin: 16px 16px 16px 16px;
  margin-top: 20px;
}

.exitBtn {
  margin: 0px 16px 10px 16px;
  text-transform: none;
}

.deleteOrderPopup {
  width: 375px;
  max-width: unset;
}

.deleteOrderPopupContent {
  padding: 16px 16px 8px;
}

Title {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
  color: #323234;
}

.deleteOrderDNoBtn {
  margin-top: 12px;
  width: 100%;
  padding: 8px 0px;
}

.deleteOrderDOptBtnNo {
  width: 100%;
}

.deleteOrderDYesBtn {
  width: 100%;
  background-color: #cb0000;
  color: white;
  text-transform: none;
}

.deleteOrderDYesBtn:hover {
  width: 100%;
  background-color: #cb0000;
  color: white;
  text-transform: none;
}

.popUpStyles .paperRoot {
  width: 403px;
  height: auto;
}
.errorTitle-neworderpopupmsg {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 24px;
}

.errorDetail-neworderpopupmsg {
  margin-bottom: 16px;
  color: #323234;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.errorCode-neworderpopupmsg {
  margin-bottom: unset;
}
.footer-button-mobile-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 4px 16px;
  width: calc(100% - 32px);
  justify-content: space-between;
}

.footer-button-mobile-container .footer-button-more-options {
  width: max-content;
}

.footer-button-mobile-container
  .footer-button-more-options
  .footer-button-more-options-btn {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  min-width: 120px;
  text-decoration: underline;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-transform: none;
  text-underline-offset: 4px;
  width: max-content;
}

.footer-button-mobile-container
  .footer-button-more-options
  .footer-button-more-options-btn:hover {
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.footer-button-mobile-container .footer-button-mobile-container-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  margin: 0px;
  width: 100%;
}

.footer-button-mobile-container-div .footer-button-previous {
  width: max-content;
}

.footer-button-mobile-container-div
  .footer-button-previous
  .footer-button-previous-btn {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  min-width: 200px;
  text-transform: none;
  width: 100%;
}

.footer-button-mobile-container-div
  .footer-button-previous
  .footer-button-previous-btn.submit {
  min-width: 150px;
}

.footer-button-mobile-container-div .footer-button-next {
  width: max-content;
}

.footer-button-mobile-container-div
  .footer-button-next
  .footer-button-next-btn {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  min-width: 150px;
  text-transform: none;
  width: 100%;
}

.footer-button-mobile-container-div
  .footer-button-next
  .footer-button-submit-btn {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  min-width: 200px;
  text-transform: none;
  width: 100%;
}

@media only screen and (max-width: 1100px) {
  .orderLeftContainer {
    position: unset;
    left: 0px;
  }

  .orderRightContainer {
    position: unset;
    right: 0px;
  }
}

@media only screen and (max-width: 920px) {
  .nextOrderPopup .paperRoot {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mobile-main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 10px 0px 10px;
  }
  .neworder-footer {
    height: 70px !important;
  }

  .boxOrderStyle .previousBtn {
    width: 140px;
  }
  .popUpStyles .paperRoot {
    width: 380px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nextButton {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .more-options-Button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .previous-button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .more-options-drawer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8px;
  }
  .more-options-drawer .save-and-exit-dragger {
    width: 100%;
  }
  .more-options-drawer .save-dragger {
    width: 100%;
    margin-top: 8px;
  }
  .more-options-drawer .delete-Order-dragger {
    text-decoration: underline;
    text-decoration-color: #cb0000;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #cb0000;
    width: 100%;
  }
  .more-options-drawer .shareOrderButton-drawer {
    width: 100%;
    margin-top: 8px;
  }
  .mobile-main-next-previous-container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .orderMaincontainer {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 600px) {
  .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn {
    min-width: 150px;
  }
  .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-next-btn {
    min-width: 100px;
  }
}

@media only screen and (min-width: 345px) and (max-width: 500px) {
  .footer-button-mobile-container {
    margin: 0 8px 0 0;
    width: calc(100% - 8px);
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn.submit {
    min-width: 80px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn {
    min-width: 125px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-next-btn {
    min-width: 85px;
  }

  .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-submit-btn {
    min-width: 100px;
  }
}

@media only screen and (max-width: 428px) {
  .nextButton .submitBtnNewOrder {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .deleteOrderPopup {
    max-width: 375px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 344px) {
  .footer-button-mobile-container {
    margin: 0 16px 0 0;
    width: calc(100% - 16px);
  }

  .footer-button-mobile-container
    .footer-button-more-options
    .footer-button-more-options-btn {
    min-width: 101px;
  }
  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn.submit {
    min-width: 50px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn {
    min-width: 90px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-next-btn {
    min-width: 60px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-submit-btn {
    min-width: 80px;
  }
}

@media screen and (max-width: 330px) {
  .next-order-pop-up .nextOrderBtnDiv {
    height: auto;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-previous
    .footer-button-previous-btn {
    width: 75px;
  }

  .footer-button-mobile-container
    .footer-button-mobile-container-div
    .footer-button-next
    .footer-button-submit-btn {
    width: 120px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (min-width: 800px) and (max-width: 920px) {
  .footer-button-mobile-container .footer-delete-btn {
    display: flex;
    flex-direction: row;
    margin-right: 30%;
  }
}
