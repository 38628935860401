.prescriber-search {
  padding: 16px;
}

.prescriber-search .prc-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.prescriber-payer-inputs {
  display: flex;
  margin-top: 24px;
}

.prescriber-search-input {
  width: 527px;
}
.prescriber-informantion-item {
  width: 100%;
}

.prescriberSearchLabel .prescriber-informantion-addOrRemove-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
  margin-right: 10px;
}

.pres-so-search-newmail .prescriber-informantion-addOrRemove-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}
.prescriber-search .no-match-text {
  color: #cb0000;
}
.prescriber-search .prescriber-national-serach-button {
  color: #0049bd;
  cursor: pointer;
  display: inline;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}
.prescriberSearchLabel {
  padding: 10px 0;
  margin-top: 8px;
}
.prescriber-search-typography {
  text-align: start;
}
@media screen and (min-width: 600px) {
  .prescriber-search-typography {
    text-align: end;
  }
}
.prescriber-informantion-item .searchPrescriberLabelSec {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}

.search-prescriber-inputs .pres-outlined-input-root {
  width: 100%;
  max-height: 40px;
  margin-right: 8px;
  max-width: 536px;
}

.search-prescriber-inputs .prescriber-search-results-container {
  max-height: 220px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #ccccd0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  position: fixed;
  min-width: 500px;
  z-index: 55;
  margin: 0px 16px;
}

@media screen and (max-width: 450px) {
  .search-prescriber-inputs .prescriber-search-results-container {
    margin: 0;
    min-width: calc(100% - 48px);
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .prescriber-search-results-container .pres-so-search-results {
    align-items: center;
    justify-content: center;
  }
  .pres-so-search-newmail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.prescriber-search-results-container .pres-so-search-results {
  min-height: 18px;
  display: flex;
  padding: 10px 3px;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.prescriber-search-results-container .pres-search-results {
  min-height: 18px;
  display: flex;
  padding: 10px 3px;
  align-items: center;
  cursor: pointer;
}

.prescriberNameList {
  background-color: white;
  font-weight: 700;
  text-decoration: underline;

  font-size: 14px;
}

.prescriberNameDiv {
  margin-right: 8px;
}

.prescriberNPIList {
  background-color: white;
  font-weight: 700;
  text-decoration: underline;
  font-size: 12px;
}

.dropDownPrescriberNPIList {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.dropDownPrescriberNameList {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
}

.prescriber-search-results-container .pres-so-search-nores {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  cursor: default;
  margin-right: 8px;
}

.pres-so-search-newmail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.crossClass {
  cursor: pointer;
}
.prescribersearchErrorPopup .paperRoot {
  width: 413px;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .prescribersearchErrorPopup .paperRoot {
    margin: 0 4px 0 4px;
  }
}
@media only screen and (max-width: 920px) {
  .prescriber-search .no-match-text {
    display: flex;
    flex-direction: column;
  }
}
