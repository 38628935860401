.password-administation {
  width: 100%;
}

.manage-profile-password-header-more-margin {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0 16px 0;
}

.manage-profile-password .password {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

.manage-profile-password .manage-profile-password-title {
  color: #808084;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  padding: 0px;
}

.manage-profile-password .manage-profile-password-value {
  color: #323234;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
}

.manage-profile-password .password-input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 0px;
}

.manage-profile-password .current-password-input {
  margin: 8px 0px;
  padding: 0px;
  width: calc(100% - 8px);
}

.manage-profile-password-input {
  width: 100%;
}

.manage-profile-password .new-password-input {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.manage-profile-password .left-padding-checkbox {
  padding-left: 11px;
}

.manage-profile-password .password-rule-description {
  color: #76767a;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 0 0;
  padding: 0px;
}

.password-component {
  margin-top: -16px;
  width: 100%;
}

.manage-profile-password .password-component-profile {
  width: 100%;
}

.manage-profile-password .checkbox-option-txt {
  cursor: default;
  pointer-events: none;
}

.manage-profile-password .checkbox-option-txt input[type="checkbox"] {
  cursor: pointer;
  pointer-events: all;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .password-admin {
    padding-left: 8px;
  }
}
