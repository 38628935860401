.estimated-arrival-time-component {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  width: 100%;
}

.estimated-arrival-time-component .estimated-arrival-time-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.estimated-arrival-time-component .estimated-arrival-time-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.estimated-arrival-time-component
  .estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item {
  margin: 0px;
  padding: 12px 0px 0px;
  width: 100%;
}

.estimated-arrival-time-component
  .estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item.padding-left {
  padding-left: 8px;
}

.estimated-arrival-time-component
  .estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item.padding-right {
  padding-right: 8px;
}

.estimated-arrival-time-component
  .estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item.padding-right-sm {
  padding-right: 8px;
}

.estimated-arrival-time-component .estimated-arrival-time-header-title {
  margin: 0px;
  white-space: pre-wrap;
  width: 100%;
}

.estimated-arrival-time-component .radioRoot {
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.estimated-arrival-time-component .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.estimated-arrival-time-component .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.estimated-arrival-time-component .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.estimated-arrival-time-component .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.estimated-arrival-time-component .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item
  .estimated-arrival-time-grid-item-title {
  margin: 8px 0px;
}

.estimated-arrival-time-grid-container
  .estimated-arrival-time-grid-item
  .estimated-arrival-time-input {
  width: 100%;
}

.phoneNumber {
  background-color: #fff;
  border: 1px solid #94949a;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 11.15px 12px;
  width: 100%;
}

.phoneNumber:focus {
  outline: none;
}

.Mui-error + div .phoneNumber {
  border-color: #d32f2f;
}

@media screen and (max-width: 430px) {
  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-left {
    padding-left: 0px;
  }

  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-right {
    padding-right: 0px;
  }

  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-left.phoneNumber-and-extension {
    padding-left: 8px;
  }

  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-right.phoneNumber-and-extension {
    padding-right: 8px;
  }

  .estimated-arrival-time-component .optionRoot {
    width: calc(50% - 8px);
  }

  .estimated-arrival-time-component .optionRoot-active {
    width: calc(50% - 8px);
  }

  .estimated-arrival-time-component .optionRoot-error {
    width: calc(50% - 8px);
  }
  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-right-sm {
    padding-right: 0px;
  }
  .estimated-arrival-time-component
    .estimated-arrival-time-grid-container
    .estimated-arrival-time-grid-item.padding-left-sm {
    padding-left: 0px;
  }
}

@media screen and (max-width: 340px) {
  .estimated-arrival-time-component .radioRoot {
    margin: 16px 0;
  }
}
