.resubmission-indicator-detail {
  display: flex;
  flex-direction: column;
  max-width: 542px;
}
.resubmission-indicator-detail .resubmission-spinner {
  align-content: center;
  min-height: 188px;
  width: 350px;
}

.resubmission-indicator-detail .spinner-resubmission-space {
  margin-top: 25px;
}
.resubmission-indicator-detail .resubmission-indicator-header {
  margin: 12px 0px;
  height: 32px;
}
.resubmission-indicator-header .resubmission-indicator-title {
  background-color: #fff0f0;
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0;
  margin-right: 56px;
  padding: 8px 16px;
  width: max-content;
}
.resubmission-indicator-detail .resubmission-indicator-body-div {
  margin: 16px;
  width: calc(100% - 32px);
}

.resubmission-indicator-body-div .resubmission-indicator-body {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
}

.resubmission-indicator-body-div .resubmission-indicator-body-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.storedProductReturnBtnGroup .cancelRequest {
  width: 100%;
}
.resubmission-indicator-detail .resubmission-indicator-footer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 16px 16px 16px;
}
.resubmission-indicator-footer .buttonClass {
  width: 100%;
}
@media only screen and (max-width: 926px) {
  .resubmission-indicator-detail .resubmission-indicator-footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
@media only screen and (max-width: 360px) {
  .resubmission-indicator-detail .resubmission-spinner {
    align-content: center;
    width: 200px;
  }
}
