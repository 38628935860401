.verifyRequesterCheckBox .verifyRequesterCheckBoxChkBoxRoot {
  margin-right: 10px;
  padding: 0;
}

.verifychkBoxDescriptionLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #18181a;
  max-width: max-content;
  margin-top: 10px;
  margin-left: 8px;
}

.verify-requester-info .verify-requester-info-header {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  width: 100%;
}

.verify-requester-info .verify-requester-info-title-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.verify-requester-info .verify-requester-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.patient-current-address-box-container {
  margin-top: 8px;
  width: 100%;
}
.verify-requester-info-box-container .verify-requester-info-grid-container {
  width: 100%;
}
.verify-requester-component {
  width: 100%;
}
.verify-requester-input {
  width: 100%;
}
.verify-requester-info .verify-requester-facility-component {
  width: 100%;
}
.verify-requester-info .verify-requester-facility-grid-item {
  width: 100%;
}

.verify-requester-facility-component .facilityDetail {
  margin-top: 16px;
  width: 100%;
}

.verify-requester-facility-component .facilityDetail .facilitywrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: fff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 8px 15px 0;
  overflow: hidden;
  width: 100%;
}

.verify-requester-facility-component .facilityDetail .facility-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.verify-requester-facility-component .changefacilitybtn {
  margin-left: 16px;
  margin-top: 0px;
  min-height: 40px;
  min-width: 120px;
  width: 30%;
}

.mainGridVerifyContainer {
  margin-top: 23px;
  width: calc(100% - 16px);
}

.verify-requester-info-box-container .chkBoxMain {
  display: flex;
  margin-top: 12px;
}
@media only screen and (max-width: 600px) {
  .verify-requester-facility-component .changefacilitybtn {
    width: 47%;
  }
}

@media only screen and (max-width: 920px) {
  .verify-requester-facility-component .changefacilitybtn {
    margin-left: 0px;
  }
  .verify-requester-facility-component .facilityDetail .facility-detail {
    display: flex;
    flex-direction: column;
  }
  .verifychkBoxDescriptionLabel {
    margin-top: 9px;
  }
}
