.missingRxFax {
  margin: 16px 16px 24px 16px;
}

.description-missingRxFax {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.missingRxFax .descp-text-link {
  display: inline-flex;
  margin-top: 16px;
  width: 100%;
}

.missingRxFax .descp-text-link .descp-text {
  color: #323234;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  order: 1;
  width: 50%;
}

.missingRxFax .descp-text-link .descp-link {
  color: #0049bd;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  order: 2;
  text-align-last: end;
  text-decoration-line: underline;
  width: 50%;
}

.title-text {
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.missingRxFax .title-msg-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin: 0px;
  width: 100%;
}

.missingRxFax .title-msg {
  color: #323234;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 0px;
  margin-top: 17px;
  margin-bottom: 10px;
  width: 100%;
}

.missingRxFax .button-printrx-rx {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  justify-content: center;
  height: 32px;
  line-height: 16px;
  padding: 0px;
  text-transform: none;
  width: 130px;
}

.missingRxFax .fax-detail {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 68px;
  margin-top: 8px;
  padding: 8px;
  text-align: center;
}

.missingRxFax .fax-detail .fax-detail-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
}

.fax-detail .fax-detail-div .fax-info-icon {
  margin: 0px 4px;
  width: fit-content;
}

.missingRxFax .fax-detail .fax-detail-div .fax-detail-text {
  color: #0049bd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
}

.missingRxFax .fax-detail .fax-detail-div .fax-detail-text .fax-phone-number {
  margin-left: 0px;
  white-space: nowrap;
}

@media only screen and (max-width: 568px) {
  .missingRxFax .descp-text-link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .missingRxFax .descp-text-link .descp-text {
    order: 2;
    width: 100%;
  }

  .missingRxFax .descp-text-link .descp-link {
    order: 1;
    text-align-last: left;
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .missingRxFax .fax-detail .fax-detail-div .fax-detail-text {
    width: 175px;
  }
}
