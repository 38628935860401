.woundinfocom-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.comMaincContainer {
  margin-top: 41px;
  width: 100%;
}

.woundinfo-desp {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

.comorbodities-data {
  margin-top: 18px;
  width: -webkit-max-content;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  -moz-column-gap: 3em;
  column-gap: 3em;
  margin-left: 4px;
  column-width: 255px;
}

.previousTherapy-cause {
  margin-top: 18px;
}

.comorboditiesCheckbox {
  margin-right: 8px !important;
  padding: 0 !important;
}

.comorboditiesCheckbox .comorboditiesCheckboxRoot {
  margin-right: 10px;
  padding: 0;
}

.comorboditieschkBoxDescriptionText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  max-width: max-content;
  margin-top: 5px;
}

.comorboditieschkBoxDescriptionText-active {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  max-width: max-content;
  margin-top: 5px;
}

.labelRoot .comorbodities-other-input {
  width: calc(100% + 4px);
  margin: 0px 0px 13px;
}
.labelRoot .no-error-comorbodities-other-input {
  width: calc(100% + 4px);
  margin: 0px;
}
.previousTherapy-otherCause-input {
  width: 50%;
  margin: -30px;
  margin-left: 9px;
}
.comorbodities-data .chkBoxMain {
  display: flex;
  margin-bottom: 22px;
}
.comorbodities-data-diab {
  margin-top: 10px;
}

.labelRoot .comorbodities-iscomorboditiesPresent {
  margin-top: -15px;
  text-wrap: unset;
}

.comorbodities-box-container {
  margin: 0px;
  margin-top: 15px;
  padding: 0px;
  width: 100%;
}

.comorbodities-data .comorbodities-grid-container {
  width: 100%;
}

.comorbodities-grid-container .comorbodities-grid-item {
  width: 100%;
}

.comorbodities-data .iscomorboditiesPresent {
  margin-bottom: 16px;
  white-space: inherit;
  width: 100%;
  word-wrap: inherit;
}

.comorbodities-data .radioRoot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 8px;
}

.comorbodities-data .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0px;
  width: calc(33.33% - 8px);
}

.comorbodities-data .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(33.33% - 8px);
}
.comorbodities-data .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(33.33% - 8px);
}
.comorbodities-data .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.comorbodities-data .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.comorbodities-chkBoxRequiredStar {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: red;
  margin-left: 3px;
  margin-top: 6px;
}
.warning-error-message-div {
  margin-top: 5px !important;
  margin-bottom: 16px;
}

@media only screen and (max-width: 920px) {
  .comorbodities-data {
    column-count: 1;
    margin-top: 12px;
    width: calc(100% - 8px);
  }
  .warning-error-message-div {
    margin-bottom: 10px;
  }
  .labelRoot .comorbodities-other-input {
    width: calc(100% - 4px);
    margin-bottom: 20px;
    margin-top: 6px;
  }

  .comorbodities-data .radioRoot {
    justify-content: unset;
    margin-top: 0px;
  }

  .comorbodities-data .iscomorboditiesPresent {
    margin-bottom: 10px;
  }

  .comorbodities-box-container {
    margin-top: 15px;
  }
  .comorbodities-data .chkBoxMain {
    margin-bottom: 14px;
  }
  .labelRoot .comorbodities-iscomorboditiesPresent {
    width: 100%;
    padding-top: 4px;
  }
}
@media only screen and (max-width: 430px) {
  .comorbodities-data .radioRoot {
    margin-top: 0px;
  }
}
