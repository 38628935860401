.woundAssessmentSummary-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 104px 121px 16px;
}
.woundAssessmentSummary-container .wound-assessment-page-summary {
  margin-left: 40px;
  height: auto;
  width: 50%;
  padding-bottom: 40px;
}
.woundAssessmentSummary-container .wound-assessment-summary-header {
  margin-left: 40px;
  width: 90%;
  height: auto;
}
.wound-assessment-summary-header .summarypage-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  color: #323234;
}
.wound-assessment-summary-header .saveOrderDiv {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.saveOrderDiv .button-at-end {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.saveOrderDiv .wound-assessment-save-order-btn {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
}
.wound-assessment-page-summary .summarypage-patient-info {
  margin-top: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 5px;
}
.wound-assessment-page-summary .summarypage-patient-info-div {
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.wound-assessment-page-summary .additional-info {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 18px;
}
.wound-assessment-page-summary .additional-doc-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 8px;
}
.wound-assessment-page-summary .additional-notes {
  margin-top: 18px;
}
.wound-assessment-page-summary .additional-doc-title2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 18px;
}
.wound-assessment-page-summary .summary-additional-titlediv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.wound-assessment-page-summary .listDocs {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 4.1px 0 0 0px;
  width: 544px;
}

.wound-assessment-page-summary .reviewpage-ro-no {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 14px;
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  .woundAssessmentSummary-container {
    margin: 16px 16px 121px 16px;
    width: calc(100% - 32px);
  }
  .woundAssessmentSummary-container .wound-assessment-page-summary {
    margin: 40px 40px 40px 0px;
    padding: 0 40px;
    height: auto;
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .wound-assessment-page-summary .summarypage-patient-info-div {
    width: 100%;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .saveOrderDiv .button-at-end {
    align-items: flex-start;
  }
  .wound-assessment-page-summary .listDocs {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .woundAssessmentSummary-container .wound-assessment-summary-header {
    margin-left: 16px;
    width: calc(100% - 32px);
    height: auto;
  }
}
