.woundBed-GridMaincontainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 16px;
}

.woundBedContainer .bedLabel {
  font-size: 14px;
}

.woundBedContainer .toggleButtonRoot {
  color: #76767a;
  font-size: 14px;
  font-family: "3MCircularTT";
  height: 32px;
  width: 52px;
}

.woundBed-GridMaincontainer .Mui-selected {
  color: #323234 !important;
  border-color: #0049bd !important;
  background-color: #fff !important;
}

@media only screen and (max-width: 400px) {
  .woundBedContainer .bedLabel {
    font-size: 13px;
  }
}

@media only screen and (max-width: 350px) {
  .woundBedContainer .bedLabel {
    font-size: 12px;
  }

  .woundBedContainer .toggleButtonRoot {
    width: 48px;
  }
}
