.cart-component {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 280px;
}

.cart-component .cart-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cart-component .cart-header .cart-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 8px 8px 8px 0px;
  text-align: left;
  width: calc(100% - 38px);
}

.cart-component .cart-header .cart-close-button {
  float: right;
  height: 30px;
  width: 30px;
}

.cart-component .cart-item-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}

.cart-component .cart-item-container.add-border {
  border: 1px solid #e4e4e8;
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  width: calc(100% - 32px);
}

.cart-component .cart-item-container .cart-items {
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  width: 100%;
}

.cart-component .cart-item-container .no-cart-item {
  align-items: center;
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 8px;
  width: calc(100% - 18px);
}

.cart-component .cart-item-container .no-cart-item .no-cart-item-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}

.cart-component .cart-price-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cart-component .cart-price-details .cart-price-details-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: left;
  width: 100%;
}

.cart-component .cart-price-details .cart-price-details-description {
  color: #4c4c4f;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin: 4px 0px;
  text-align: left;
  width: 100%;
}

.cart-component
  .cart-price-details
  .cart-price-details-description
  .cart-price-details-description-note {
  color: #4c4c4f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 0 0 4px;
  text-align: left;
}

.cart-component .cart-price-details .cart-pricing-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px 0px;
  width: calc(100% - 16px);
}

.cart-component
  .cart-price-details
  .cart-pricing-details
  .cart-pricing-details-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px;
  text-align: left;
}

.cart-component
  .cart-price-details
  .cart-pricing-details
  .cart-pricing-details-title.bold {
  font-weight: 700;
}

.cart-component
  .cart-price-details
  .cart-pricing-details
  .cart-pricing-details-value {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px;
  text-align: right;
}

.cart-component
  .cart-price-details
  .cart-pricing-details
  .cart-pricing-details-value.bold {
  font-weight: 700;
}

.cart-component .cart-price-details .cart-shipping-pricing-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 8px 0px;
  width: calc(100% - 16px);
}

.cart-component
  .cart-price-details
  .cart-shipping-pricing-details
  .cart-shipping-pricing-details-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px;
  text-align: left;
}

.cart-component
  .cart-price-details
  .cart-shipping-pricing-details
  .cart-shipping-pricing-details-title.bold {
  font-weight: 700;
}

.cart-component
  .cart-price-details
  .cart-shipping-pricing-details
  .cart-shipping-pricing-details-value {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px;
  text-align: right;
}

.cart-component
  .cart-price-details
  .cart-shipping-pricing-details
  .cart-shipping-pricing-details-value.bold {
  font-weight: 700;
}

.cart-component .cart-price-details .cart-total-pricing-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 8px 0px;
  width: calc(100% - 16px);
}

.cart-component
  .cart-price-details
  .cart-total-pricing-details
  .cart-total-pricing-details-title {
  color: #323234;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin: 4px 0px;
  text-align: left;
}

.cart-component
  .cart-price-details
  .cart-total-pricing-details
  .cart-total-pricing-details-title.bold {
  font-weight: 700;
}

.cart-component
  .cart-price-details
  .cart-total-pricing-details
  .cart-total-pricing-details-value {
  color: #323234;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin: 4px 0px;
  text-align: right;
}

.cart-component
  .cart-price-details
  .cart-total-pricing-details
  .cart-total-pricing-details-value.bold {
  font-weight: 700;
}
