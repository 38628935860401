.add-new-patient-update .paperRoot {
  width: 375px;
  padding: 16px 24px 24px 24px;
}

.add-new-patient-update .add-new-patient-update-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  width: 96%;
  margin: 6px 0 6px 0px;
}
.add-new-patient-update .add-new-patient-update-img {
  width: 100%;
  margin: 12px 0;
}
.add-new-patient-update .add-new-patient-update-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

@media screen and (max-width: 400px) {
  .add-new-patient-update .paperRoot {
    padding: 8px 16px 32px 16px;
    margin: 0 10px;
  }
}
