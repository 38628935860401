.selectFacility-search-container {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
}

.selectFacility-or-div {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 16px;
  margin-top: 40px;
}
.searchFacilityMain {
  background: #f8f8fa;
  /* MSD DS/Gray/10 */

  border: 1px solid #e4e4e8;
  min-height: 120px;
}

.searcFacilitySub {
  margin: 16px;
}
.selectFacility-searchByName {
  width: 545px;
}

.selectFacility-Or {
  width: 60px;
}
.selectFacility-serachById {
  width: 254px;
}

.search-facility-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.facilityResult-subMain .returnToFavourite {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration: underline;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  margin-bottom: 35px;
}

.facilityResult-subMain {
  margin-top: 35px;
}

.facilitySearchErrorPopup .paperRoot {
  width: 450px;
}

@media only screen and (max-width: 340px) {
  .searchFacilityMain {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .selectFacility-search-container {
    flex-direction: column;
  }
  .selectFacility-searchByName {
    width: 100%;
    max-width: 100%;
  }
  .selectFacilityContainer .search-by-facility-submit {
    max-width: 200px;
  }
  .selectFacilityContainer .search-by-name-zipcode {
    margin-top: 8px;
    max-width: 227px;
  }
  .selectFacility-or-div {
    line-height: 0px;
    margin-top: 23px;
  }
  .selectFacility-serachById {
    width: 100%;
  }
}
@media only screen and (min-width: 341px) and (max-width: 920px) {
  .searchFacilityMain {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .selectFacility-search-container {
    flex-direction: column;
  }
  .selectFacility-searchByName {
    width: 100%;
    max-width: 100%;
  }
  .selectFacilityContainer .search-by-facility-submit {
    max-width: 281px;
    margin-left: 28px;
  }

  .selectFacility-or-div {
    line-height: 0px;
    margin-top: 23px;
    margin-left: 0;
  }
  .selectFacility-Or {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .selectFacility-serachById {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 926px) {
  .selectFacility-or-div {
    margin-left: 0px;
  }
}
@media only screen and (width: 540px) {
  .search-facility-title {
    margin-left: 94px;
  }
  .selectFacility-or-div {
    margin-left: 100px;
  }
}
@media only screen and (width: 320px) {
  .selectFacility-or-div {
    margin-left: 95px;
  }
}
@media only screen and (width: 768px) {
  .selectFacilityContainer .selectFacilitySearch {
    width: 234px;
    max-width: 247px;
  }
}
