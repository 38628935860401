.manage-user-table_container {
  margin-top: 16px;
  min-height: 500px;
  max-height: 600px;
  margin-left: 24px;
  overflow: auto;
  width: calc(100% - 48px);
  border: 1px solid #e4e4e8;
}
.manageUser_table {
  width: 100%;
  border-spacing: 0;
}
.manageUser_table th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.manageUser_table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.manageUser_table tbody tr:first-child td {
  border-top: none;
}

.manageUser_table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.manageUser_table tbody tr:nth-child(2n) td {
  background: #fff;
}

.manageUser_table thead th:nth-child(1) {
  width: 14%;
  height: 40px;
}

.manageUser_table thead th:nth-child(2) {
  width: 17%;
  height: 40px;
}
.manageUser_table thead th:nth-child(3) {
  width: 16%;
  height: 40px;
}
.manageUser_table thead th:nth-child(4) {
  width: 11%;
}
.manageUser_table thead th:nth-child(5) {
  width: 12%;
}
.manageUser_table thead th:nth-child(6) {
  width: 13%;
}
.manageUser_table thead th:nth-child(7) {
  width: 25%;
}

.manageUser_table th.up {
  background-image: url("../../../../assets/up_arrow.svg");
}

.manageUser_table th.down {
  background-image: url("../../../../assets/down_arrow.svg");
}

.manageUser_table th.default {
  background-image: url("../../../../assets/default.svg");
}
.img {
  background-color: white;
}
.select-username-link {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}
.table-static-data {
  background: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.last-login-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  background: #323234;
}
.reason-disabeled-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  background: #323234;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .manage-user-table_container {
    margin: 0;
  }
}

.status-red {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.status-blue {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}

@media screen and (max-width: 912px) {
  .manage-user-table_container {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }
}

.status-red {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.status-blue {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}
