.manage-acc-flow {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column !important;
  min-height: calc(100vh - 144px);
  align-items: center;
}

.manage-acc-container {
  margin: 16px 104px 72px 104px !important;
  background-color: #fff;
  max-width: 560px;
  justify-content: center;
  align-content: center;
}

.accountDes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323234;
}
.manage-acc-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 8px;
}
