.in-patient-order-salespurchase-information-component{
  padding: 16px;
    width: 100%;
}
.in-patient-order-salespurchase-information-component
  .in-patient-order-salespurchase-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.salespurchaseInfoDiv {
  margin-top: 8px;
  margin-bottom: 16px;
}

.salespurchaseInfoDiv .salespurchaseInfoDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
  width: 100%;
}
.in-patient-order-salespurchase-information-component
  .in-patient-order-salespurchase-information-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-salespurchase-information-component
  .in-patient-order-salespurchase-information-grid-container
  .in-patient-order-salespurchase-information-grid-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.in-patient-order-salespurchase-information-component
  .in-patient-order-salespurchase-information-grid-container
  .in-patient-order-salespurchase-information-grid-item
  .billing-salespurchase-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}
.in-patient-order-salespurchase-information-component
  .in-patient-order-salespurchase-information-grid-container
  .in-patient-order-salespurchase-information-grid-item
  .salespurchase-information-input {
    width: 60%;
}

@media only screen and (max-width: 926px) {
  .in-patient-order-patient-salespurchase-component {
    width: 100%;
  }
  .in-patient-order-salespurchase-information-component
    .in-patient-order-salespurchase-information-grid-container
    .in-patient-order-salespurchase-information-grid-item
    .salespurchase-information-input {
    width: 100%;
  }
}


@media only screen and (max-width: 600px) {
    .in-patient-order-salespurchase-information-component{
    padding: unset;
    width: 100%;
    margin: 10px;
  }
  .in-patient-order-salespurchase-information-component
    .in-patient-order-salespurchase-information-grid-container
    .in-patient-order-salespurchase-information-grid-item
    .salespurchase-information-input {
    width: 90%;
  }
}