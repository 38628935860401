.add-site-status-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
}

.add-site-status-component .add-site-status-container {
  margin: 40px;
  width: calc(100% - 80px);
}

.add-site-status-container .add-site-status-form {
  width: 70%;
}

.add-site-status-container .add-site-status-component-route-section {
  margin-top: 2px;
  margin-bottom: 42px;
}

.add-site-status-container .add-site-status-hearder {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  width: 100%;
}

.add-site-status-container .add-site-status-text {
  margin: 0;
}

.add-site-status-container .add-site-status-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.add-site-status-loader-pop-up .add-site-status-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

@media only screen and (max-width: 920px) {
  .add-site-status-component {
    margin: 0;
  }

  .add-site-status-container .add-site-status-form {
    width: 100%;
  }

  .add-site-status-component .add-site-status-container {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 450px) {
  .add-site-status-loader-pop-up .add-site-status-loader {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}
