.patient-info-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.patient-info .patient-info-header {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  width: 100%;
}

.patient-info .patient-info-title-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
}

.tooltip .tooltip-content {
  width: 100%;
}

.tooltip-content .tooltip-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}

.tooltip-content .tooltip-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 8px;
  margin-bottom: 4px;
}

.patient-info .patient-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.patient-info .patient-info-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: 100%;
}

.patient-info-box-container {
  margin-top: 8px;
  width: 100%;
}

.patient-info-box-container .patient-info-grid-container {
  width: 100%;
}

.patient-info-grid-container .patient-info-input {
  width: 100%;
}

.phone {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #94949a;
  font-size: 14px;
  padding: 11.15px 12px;
}

.phone:focus {
  outline: none;
}

.Mui-error + div .phone {
  border-color: #d32f2f;
}

.popper .tooltip {
  background-color: #fff;
  border: 1px solid #808084;
  border-radius: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  color: #000;
  font-size: 14px;
  margin: 16px;
  padding: 14px 32px 16px 16px;
  width: 400px !important;
}

.checkboxRootSMS + span {
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.adornedRoot {
  position: absolute;
  right: 13px;
}

.adornedRoot1 {
  position: absolute;
  right: 13px;
}

.nextBtn.containedBtn {
  margin-top: 70px;
}

.dob input.input {
  border-right: 0;
}

div.dob {
  padding-right: 0;
}

.dob .outline {
  border-radius: 2px;
}

.dob.showError .outline {
  border: 1px solid #d32f2f;
}

.dob.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.dob1 input.input {
  border-right: 0;
}

div.dob1 {
  padding-right: 0;
}

.dob1 .outline {
  border-radius: 2px;
}

.dob1.showError .outline {
  border: 1px solid #d32f2f;
}

.dob1.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.Mui-error > input.MuiInputBase-input {
  border-color: #d32f2f;
}

div.select {
  text-transform: capitalize;
}

div.selectRoot {
  border-radius: 2px;
}

.selectRoot div.select {
  border-right: unset;
  height: 20px;
}

.selectRoot .selectIcon {
  top: 10px;
}

div.placeHolder {
  border-radius: 2px;
  color: #76767a;
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
}

.placeHolder .selectIcon {
  top: 10px;
}

.patient-info .patient-info-input .patient-info-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.patient-info-state-input {
  height: 42px;
}

.patient-info-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.patient-info-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.patient-info div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.patient-info .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.patient-info-state-input .patient-info-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

svg.tooltipRoot {
  width: 18.5px;
  height: auto;
  margin-left: 8.83px;
  cursor: pointer;
}

.addressValidationPopup .paperRoot {
  min-width: 480px;
  height: 355px;
}

.addressInfo .radioRoot {
  margin-bottom: 20px;
}

.addressInfoRadioRoot {
  margin-left: 16px;
}

.addressValidationPopup .popupCloseIcon {
  display: none;
}

.addressInfo .addressInfoOptiontxt {
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
  font-family: "3MCircularTT";
}

.validateAddressMainContainer {
  padding: 24px;
}
.validateAddressHeader {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.validateAddressSameAsEntered {
  font-family: "3MCircularTT";
  margin-top: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.validateAddressFound {
  font-family: "3MCircularTT";
  margin-top: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  right: 16px;
}

.novalidateAddressFound {
  font-family: "3MCircularTT";
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.addressValidationPopup .returnOrderEntryBtn {
  min-width: 204px;
}

.addressValidationPopup .continueBtn {
  margin-left: 24px;
  min-width: 204px;
}

.buttonGroup {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
}

.patient-info-grid-container .patient-address-line-2 {
  display: flex;
  flex-direction: row;
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px 8px 0 0;
  width: 100%;
}

.patient-info-grid-container .patient-address-line-2 .tooltipAddressIcon {
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: -4px 0 auto 8px;
  width: 14px;
  zoom: 100%;
}

.addressValidationPopup .address-info-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}
 .duplicate-check-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 400px;
  height: 190px;
}

@media only screen and (max-width: 920px) {
  .patient-info-box-container .patient-info-grid-container {
    width: 100%;
  }
  .addressValidationPopup .paperRoot {
    height: 400px;
    min-width: 0;
    margin-left: 10px;
    margin-right: 10px;
  }

  .addressValidationPopup .continueBtn {
    margin-left: 0%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 800px) {
  .patient-info .patient-info-title-div {
    align-items: flex-start;
  }
  .addressValidationPopup .paperRoot {
    min-width: 300px;
    height: 355px;
  }
  .buttonGroup {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  .patient-info .patient-info-title {
    margin-top: 16px;
  }

  svg.tooltipRoot {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    margin-top: 17px;
  }
  .patient-info .patient-info-header {
    margin-top: 0px;
  }
  .addressValidationPopup .returnOrderEntryBtn {
    width: 100%;
  }
  .addressValidationPopup .continueBtn {
    width: 100%;
  }
  .duplicate-check-loader
  {
    width: 300px;
  }
}
@media screen and (min-width: 360px) and (max-width: 600px) {
  .patient-info-grid-container .placeHolder div.placeHolder {
    font-size: 12px;
  }

  .popper .tooltip {
    width: 280px !important;
  }
  
}

@media screen and (max-width: 360px) {
  .popper .tooltip {
    width: 200px !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 920px) {
  .buttonGroup {
    margin-left: 0px;
  }
  .addressValidationPopup .continueBtn {
    margin-top: 0px;
  }
  .address-info-loader {
    width: 350px;
  }
  .patient-info .patient-info-header {
    margin-top: 0px;
  }
  svg.tooltipRoot {
    margin-bottom: 4px;
    align-self: flex-end;
  }
  .validateAddressMainContainer .buttonGroup .return-to-order-div {
    padding: 8px;
  }
}
