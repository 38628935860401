.territory-table_container {
  margin-top: 16px;
  min-height: 500px;
  max-height: 600px;
  margin-left: 24px;
  overflow: auto;
  width: calc(100% - 48px);
  border: 1px solid #e4e4e8;
}
.territory-Users_table {
  width: 100%;
  border-spacing: 0;
}
.territory-Users_table th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.territory-Users_table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.territory-Users_table tbody tr:first-child td {
  border-top: none;
}

.territory-Users_table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.territory-Users_table tbody tr:nth-child(2n) td {
  background: #fff;
}

.territory-Users_table thead th:nth-child(1) {
  width: 10%;
  height: 40px;
}
.territory-Users_table thead th:nth-child(2) {
  width: 10%;
  height: 40px;
}
.territory-Users_table thead th:nth-child(3) {
  width: 10%;
  height: 40px;
}
.territory-Users_table thead th:nth-child(4) {
  width: 10%;
}
.territory-Users_table thead th:nth-child(5) {
  width: 10%;
}

.territory-Users_table th.up {
  background-image: url("../../../../../assets/up_arrow.svg");
  font-size: 14px;
}

.territory-Users_table th.down {
  background-image: url("../../../../../assets/down_arrow.svg");
  font-size: 14px;
}

.territory-Users_table th.default {
  background-image: url("../../../../../assets/default.svg");
  font-size: 14px;
}
.img {
  background-color: white;
}
.territory-select-username-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}

.territory-table-static-data {
  background: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  gap: 1px;
}
.territory-last-login-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  background: #323234;
}
.territory-reason-disabeled-data {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  background: #323234;
}
.territory-table-static-data-Open {
  color: #d60606;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.territory-status-red {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.territory-status-blue {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}
.territory-table_container .no-territory-list-data {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  margin: 40px;
  width: calc(100% - 80px);
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .territory-manage-user-table_container {
    margin: 0;
  }
  .territory-table_container {
    margin-left: 0px;
    width: 100%;
  }
}

@media screen and (max-width: 912px) {
  .territory-manage-user-table_container {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }
}
