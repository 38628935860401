.patient-fin-info {
  margin: 0;
  width: calc(100% - 4px);
}

.patient-fin-info .patient-fin-component-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0px 0 0 0;
  width: 100%;
}

.patient-fin-component-title .patient-fin-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
  margin-left: 40px;
}
.patient-fin-info .fin-info-contents {
  margin: 0px 32px 0px 0px;
}
.patient-fin-info .all-content-div-pfin-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 31px);
}

.all-content-div-pfin-info .content-div-pfin-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;
  width: 100%;
}

.content-div-pfin-info .content-div-pfin-info-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.content-div-pfin-info .sub-content-div-pfin-info {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}

.sub-content-div-pfin-info .patient-fin-info-content-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}

.sub-content-div-pfin-info .patient-fin-info-content-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 0 8px;
  min-height: 18px;
}
.content-div-pfin-info-last .patient-fin-info-content-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  min-height: 16px;
}

.patient-fin-info-content-value .dot-with-space {
  margin-right: 8px;
}
.border-space {
  height: 30px;
}
