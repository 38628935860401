.acute-order-pickUp-request-summary-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  padding-bottom: 24px;
  width: calc(100% - 120px);
}

.acute-order-pickUp-request-summary-page-component .short-form {
  margin: 16px 40px 0px;
  width: calc(100% - 80px);
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-summary-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0px;
  width: 100%;
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-summary-header
  .acute-order-pickUp-request-summary-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0px;
  text-align: left;
  width: fit-content;
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-summary-header
  .acute-order-pickUp-request-summary-header-button {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-summary-header
  .acute-order-pickUp-request-summary-header-button
  .acute-order-pickUp-request-summary-download-button {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
  width: fit-content;
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-work-order-number {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 16px;
  width: 100%;
}

.acute-order-pickUp-request-summary-page-component
  .short-form
  .acute-order-pickUp-request-work-order-number
  .acute-order-pickUp-request-work-order-number-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  width: fit-content;
}

@media only screen and (max-width: 926px) {
  .acute-order-pickUp-request-summary-page-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header {
    margin: 0px;
    width: 100%;
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-title {
    margin: 16px 0px;
    text-align: left;
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-header-button {
    margin: 8px 0px;
  }

  .acute-order-pickUp-request-summary-page-component .short-form {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 475px) {
  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header {
    flex-direction: column;
    margin: 0px 0px 16px;
    text-align: center;
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-title {
    margin: 0px;
    text-align: center;
    width: 100%;
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-header-button {
    margin: 8px 0px;
    justify-content: center;
    width: 100%;
  }

  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-header-button
    .acute-order-pickUp-request-summary-download-button {
    text-align: center;
    width: 100%;
    padding: 0px;
    margin: 16px 0px;
  }
}

@media only screen and (max-width: 430px) {
  .acute-order-pickUp-request-summary-page-component {
    margin: 0px;
    width: 100%;
  }
}

@media print {
  .acute-order-pickUp-request-summary-page-component
    .short-form
    .acute-order-pickUp-request-summary-header
    .acute-order-pickUp-request-summary-header-button {
    display: none;
  }
}
