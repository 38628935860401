.proofOfDelivery-main {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 16px;
  width: calc(100% - 144px);
  min-height: calc(100vh - 144px);
}
.proofOfDelivery-main .proofOfDelivery-container-main {
  width: calc(100% - 30px);
  height: auto;
  margin-top: 40px;
  margin-left: 40px;
  flex-direction: row;
  display: flex;
}

.pod-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  width: 100%;
}
.pod-alert-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  width: 100%;
}
.pod-subTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}
.pod-paragragh {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  padding: 40px 0px 0px 0px;
  width: 60%;
}
.proofOfDelivery-main .backToMyPatients-btn {
  margin: 0px 0px 70px 4px;
}
.patient-details {
  margin-top: 10px;
}
.submit-pod-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}
.paragraph-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 3px;
  margin-right: 3px;
}
.proof-heading {
  display: block;
  margin-left: 3px;
}
.proofOfDelivery-container-main .patient-details {
  width: 61%;
}

.paragraph {
  width: 60%;
}
.pod-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pod-loader-pop-up .pod-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

@media only screen and (max-width: 1200px) {
  .proofOfDelivery-main {
    width: calc(100% - 30px);
  }
  .proof-heading {
    display: contents;
    margin-left: 3px;
  }
}

@media only screen and (max-width: 912px) {
  .pod-paragraph-mobile {
    width: 100%;
  }
  .pod-paragraph-response {
    width: 100%;
  }
  .paragraph {
    width: 70%;
  }
  .proofOfDelivery-container-main .patient-details {
    width: 72%;
  }
  .pod-paragragh {
    width: 70%;
  }
}
@media only screen and (max-width: 320px) {
  .proof-heading {
    display: contents;
    margin-left: 3px;
  }
  .pod-paragraph-response {
    width: 236px;
  }
  .pod-paragraph-mobile {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .proofOfDelivery-main .proofOfDelivery-container-main {
    margin-left: 15px;
  }
  .proofOfDelivery-container-main .patient-details {
    width: 100%;
  }
  .paragraph {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .pod-paragraph-mobile {
    width: 100%;
  }
  .pod-paragraph-response {
    width: 100%;
  }
  .pod-heading {
    width: 85%;
  }
  .pod-paragragh {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .proofOfDelivery-main {
    width: calc(100% - 30px);
  }
}
