.seach-home-care-order {
  margin: 16px;
  width: calc(100% - 32px);
}
.searchHomeCareLoader {
  height: 100%;
  margin-top: 75px;
}
.seach-home-care-order .seach-home-care-order-loader {
  align-content: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
}

.seach-home-care-order .seach-home-care-order-title {
  color: #323234;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
}
.seach-home-care-order .seach-home-care-order-description {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0px 16px;
  width: 100%;
}
.seach-home-care-order .seach-home-care-order-or-title {
  color: #323234;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 0;
  text-align: center;
}

.seach-home-care-order .seach-home-care-order-search-button {
  margin-top: 12px;
  width: 100%;
}
