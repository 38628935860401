.patient-found-component {
  padding: 16px;
  width: calc(100% - 32px);
}

.patient-found-component .patient-found-header {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 16px;
  width: 100%;
}

.patient-found-component .patients {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0px;
  width: 100%;
}

.patient-found-component .patient-found-grid-container {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  margin: 0px;
  padding: 0px;
}

.patient-found-component
  .patient-found-grid-container
  .patient-found-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.patient-found-grid-item .patient-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 8px 0px;
  width: calc(100% - 16px);
}

.patient-found-grid-item .add-patient-button {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 2px 0px;
  text-decoration: underline;
  text-transform: capitalize;
  width: 100%;
}

.patient-found-grid-item .patient-details {
  display: flex;
  flex-direction: column;
  margin: 8px;
  width: calc(100% - 16px);
}

.patient-details .patient-detail {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.patient-details .patient-detail .patient-detail-title {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px 8px 0px 0px;
  width: 36px;
}

.patient-details .patient-detail .patient-detail-title.for-acute {
  width: 66px;
}

.patient-details .patient-detail .patient-detail-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: calc(100% - 36px);
}

.patient-details .patient-detail .patient-detail-value.for-acute {
  width: calc(100% - 66px);
}

.patient-found-component .button-section {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 0px;
  width: 100%;
}

.patient-found-component .button-section .search-again-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}
