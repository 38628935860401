.srt-prescriber-info-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.srt-prescriber-info-component .srt-prescriber-informantion-grid-container {
  width: 100%;
}

.srt-prescriber-info-component .srt-prescriber-info {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.srt-prescriber-info .srt-prescriber-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 8px;
}

.srt-prescriberDiv {
  margin-top: 8px;
  margin-bottom: 16px;
}

.srt-prescriberDiv .srt-presDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  width: calc(100% - 16px);
}

.srt-prescriber-info-component .srt-prescriber-selected-details-grid-container {
  border: 1px solid #94949a;
  padding: 16px;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item
  .srt-prescriber-name {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0px;
  text-align: left;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item
  .srt-change-prescriber-button {
  display: flex;
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  margin: 8px 0px 8px auto;
  padding: 0px;
  text-align: right;
  text-decoration: underline;
  text-transform: none;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item
  .srt-show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item
  .srt-show-title-and-value-div
  .srt-show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.srt-prescriber-info-component
  .srt-prescriber-selected-details-grid-container
  .srt-prescriber-selected-details-grid-item
  .srt-show-title-and-value-div
  .srt-show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px 0px;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.srt-prescriber-informantion-item {
  width: 100%;
}

.srt-prescriber-info-component .srt-prescriber-informantion-button {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  margin-top: -16px;
  text-transform: none;
  width: 100%;
}

.srt-prescriber-pop-up-container .paperRoot {
  display: flex;
  max-width: 574px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .srt-prescriber-pop-up-container .paperRoot {
    margin: 0px 16px;
    padding: 0px;
    width: calc(100% - 32px);
  }
}
