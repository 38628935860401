.footer-btn-group .pickup-request-tool-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 4px 0px 12px;
  padding: 0px;
  width: 100%;
}

.pickup-request-tool-bar .pickup-request-grid-container {
  margin-left: calc(15% + 16px);
  margin-right: 104px;
  padding: 0px;
  width: 100%;
}

.pickup-request-tool-bar
  .pickup-request-grid-container
  .pickup-request-grid-item {
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.pickup-request-grid-item .left-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pickup-request-grid-item .left-div .firstButton {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.pickup-request-grid-item .left-div .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.pickup-request-grid-item .right-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}

.pickup-request-grid-item .right-div .secondButton {
  min-width: 140px;
}

.pickup-request-grid-item .right-div .thirdButton {
  min-width: 192px;
  text-transform: none;
}

.pickup-request-grid-item .right-div .thirdButton-disabled {
  background: #e4e4e8;
  color: #76767a;
  min-width: 192px;
  text-transform: none;
}

@media screen and (max-width: 920px) {
  .pickup-request-tool-bar .pickup-request-grid-container {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }

  .pickup-request-grid-item .right-div {
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 445px) {
  .pickup-footer {
    height: 112px;
  }

  .pickup-request-grid-item .left-div {
    justify-content: center;
  }

  .pickup-request-grid-item .right-div {
    flex-direction: row;
    justify-content: center;
  }

  .pickup-request-grid-item .right-div .secondButton {
    min-width: 140px;
  }
}

@media screen and (max-width: 350px) {
  .pickup-footer {
    height: 153px;
  }

  .pickup-request-grid-item .right-div {
    flex-direction: column;
  }

  .pickup-request-grid-item .right-div .secondButton {
    min-width: 192px;
  }
}
