.product-not-available-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 16px;
}

.product-not-available-main .product-not-available-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.product-not-available-main .product-not-available-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 16px 0 0 0;
}
.product-not-available-main .unavailable-product-name {
  color: #323234;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
