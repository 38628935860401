.wound-measurement {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.wound-measurement .wound-measurement-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
}

.wound-measurement .wound-measurement-container {
  width: 100%;
}

.wound-measurement-container .wound-measurement-item {
  width: 100%;
}

.wound-measurement-item .wound-measurement-input {
  width: 100%;
}

.wound-measurement-item .wound-measurement-value-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
