.footer-btn-group .confirm-tool-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
}
.confirm-main-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  margin-left: calc(15% - 32px);
  margin-right: 104px;
  width: 100%;
}
.confirm-left-container {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}
.confirm-right-container {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  right: 25px;
  width: 50%;
}
.boxOrderStyle-confirm {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}
.boxOrderStyle-confirm .firstButton {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle-confirm .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle-confirm .secondButton {
  min-width: 140px;
}
.confirm-empty-pop-up {
  width: 375px;
  height: 288px;
}
@media only screen and (max-width: 600px) {
  .confirm-right-container {
    width: 90%;
    right: 0px;
  }
  .boxOrderStyle-confirm .secondButton {
    min-width: 155px;
    margin-left: 51px;
  }
  .boxOrderStyle-confirm {
    margin-left: 33px;
  }
}
@media only screen and (max-width: 340px) {
  .confirm-left-container {
    margin-left: 21px;
  }
  .boxOrderStyle-confirm {
    margin-left: 9px;
  }
}
