.inpatientTransition {
  margin: 37px 0 40px 0;
  width: 100%;
}

.inpatientTransition .inpatientTransition-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
}

.inpatientTransition .inpatientTransition-grid-container {
  width: 100%;
}

.inpatientTransition-grid-container .inpatientTransition-grid-item {
  width: 100%;
}

.inpatientTransition-grid-container .inpatientTransition-grid-item-yes {
  margin-top: 16px;
  width: 100%;
}

.inpatientTransition-grid-container .inpatientTransition-grid-item-ro {
  margin-top: 16px;
  width: 100%;
}
.inpatientTransition-grid-container
  .inpatientTransition-grid-item-ro
  .previous-ro {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
}

.inpatientTransition .was-NPWT-initiated {
  white-space: inherit;
  width: calc(100% - 16px);
}

.inpatientTransition .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.inpatientTransition .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0px;
  width: calc(49% - 4px);
}

.inpatientTransition .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.inpatientTransition .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.inpatientTransition .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.inpatientTransition .dateInitiated {
  margin-top: 0px;
}

.inpatientTransition .dateInitiated input.input {
  border-right: 0;
}

div.dateInitiated {
  padding-right: 0;
}

.inpatientTransition .dateInitiated .outline {
  border-radius: 2px;
}

.inpatientTransition .dateInitiated.showError .outline {
  border: 1px solid #d32f2f;
}

.inpatientTransition .dateInitiated.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.inpatientTransition .facilityDetail {
  margin-top: 18px;
  width: 100%;
}

.inpatientTransition .facilityDetail .facilitywrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: fff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 8px 15px 0;
  overflow: hidden;
  width: 100%;
}

.inpatientTransition .facilityDetail .facility-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inpatientTransition-grid-container .button-change-facility {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  margin-left: 16px;
  margin-top: 0px;
  min-height: 40px;
  min-width: 120px;
  text-transform: capitalize;
  width: 23.5%;
}

.inpatientTransitionReason-grid-container {
  width: 100%;
  padding-right: 15px;
}

.inpatientTransitionReason-block {
  margin-top: 10px;
}
.wasNPWTUsedin60Days-block {
  margin-top: 10px;
}

.inpatientTransition-component div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.inpatientTransition-component .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.inpatientTransition-component .placeHolder .selectIcon {
  top: 10px;
}

.inpatientTransition-component
  .inpatientTransition-input
  .inpatientTransition-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.inpatientTransition-component .inpatientTransition-input {
  width: 100%;
}

@media only screen and (min-width: 601px) {
  .inpatientTransition .was-NPWT-initiated {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .facilityDetail {
    margin: 0;
  }

  .inpatientTransition-grid-item .facilityDetail .facilitywrapper {
    margin: 0;
    width: 100%;
    flex-direction: column;
    height: unset;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .leftfaciltydetails,
  .rightfaciltydetails {
    width: 100%;
    margin: 0;
  }

  .facilityinformation {
    padding-left: 8px;
    box-sizing: border-box;
  }

  .facilityDetail .facilitywrapper {
    margin: 0 0 15px 0;
    width: 100%;
  }
  .inpatientTransition-grid-container .button-change-facility {
    width: 46%;
  }
  .inpatientTransition .radioRoot {
    margin-top: 14px;
  }
}
@media only screen and (max-width: 920px) {
  .inpatientTransition .facilityDetail .facility-detail {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 920px) and (max-width: 1100px) {
  .inpatientTransition .radioRoot {
    margin-top: 10px;
  }
}
