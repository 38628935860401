.datePickerMainGrid {
  display: flex;
}

.addTerritoryMainContainer .changeButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
  align-self: center;
}
.addTerritoryMainContainer {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  padding: 16px 16px 16px 0px;
}
.addTerritoryMainContainer .addTerritoryDetails {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.addTerritoryMainContainer .addTerritoryDetailsBlock {
  margin-left: 10px;
}

.addTerritoryMainContainer .addTerritoryDetailsText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.assignDatesContainer {
  margin-top: 24px;
}
.assignDatesContainer .assignDatesTitle {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.assignDatesContainer .assignDatesFields {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-top: 10px;
}
.assignReasonFields {
  margin-top: 8px;
}
.addSaleTerritoryBtnGroup {
  width: 100%;
  margin-top: 32px;
  gap: 20px;
  display: flex;
}
.addSaleTerritoryBtnGroup .addSaleTerritoryCancelBtn {
  width: 50%;
}
.addSaleTerritoryBtnGroup .addSaleTerritoryAddBtn {
  width: 242px;
}
.reasonForAssignmentChange-input .reasonForAssignmentChange-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.reasonForAssignmentChange-input {
  width: 100%;
}
.reasonForAssignmentChange-input .reasonForAssignmentChange-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.startDate input.input {
  border-right: 0;
}

div.startDate {
  padding-right: 0;
}

.startDate .outline {
  border-radius: 2px;
}

.startDate.showError .outline {
  border: 1px solid #d32f2f;
}

.startDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.endDate input.input {
  border-right: 0;
}

div.endDate {
  padding-right: 0;
}

.endDate .outline {
  border-radius: 2px;
}

.endDate.showError .outline {
  border: 1px solid #d32f2f;
}

.endDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}
@media only screen and (max-width: 600px) {
  .assignDatesContainer .assignDatesTitle {
    font-size: 16px;
  }
}
