.tab-style {
  cursor: pointer;
  background: white;
  width: 100%;
  border: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #0049bd;
  height: 53px;
}

.tab-style.manage-user-accounts {
  height: 32px;
  width: 100%;
}

.tab-style.myLists {
  height: 32px;
  width: 100%;
}

.tabview-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: calc(100vh - 144px);
  background-color: #f8f8fa;
  margin-bottom: 60px;
}

.selected-tab-style {
  cursor: pointer;
  background: white;
  width: 100%;
  border: 0;
  border-bottom: 5px solid #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #0049bd;
  height: 53px;
}

.selected-tab-style.manage-user-accounts {
  height: 32px;
}

.selected-tab-style.myLists {
  height: 32px;
}

.tab-style-div {
  background: white;
  width: 100%;
  border-bottom: 1px ridge;
  display: flex;
  flex-direction: row;
  height: 60px;
}

.tab-font-style {
  color: #0049bd;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.tabview-page .tab-grid-conatiner {
  width: calc(100% - 16px);
  margin-left: 0;
}

.tab-grid-conatiner .tab-grid-item {
  width: 100%;
}

.tab-content {
  width: 100%;
  margin-top: 16px;
  background-color: aqua;
}

.notification-count {
  color: #ffffff;
  background: #cb0000;
  border-radius: 24px;
  align-items: flex-start;
  padding: 2px 6px 2px 6px;
  gap: 10px;
}
.notification-count .notification-text-style {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.notification-wounddetail-style {
  background: #1e64d0;
  border-radius: 24px;
  align-items: flex-start;
  padding: 2px 6px 2px 6px;
  gap: 10px;
}

.notification-count .notification-text-style.unlinked-user {
  color: #0049bd;
}

.notification-count.notification-unlinked-user-style {
  background: #e8f4ff;
  color: #0049bd;
  border-radius: 24px;
  align-items: flex-start;
  padding: 2px 6px 2px 6px;
  gap: 10px;
}

.scroll-tab-div .scrollableTabHeader .scroll-tabs {
  background-color: white;
  justify-content: space-between;
  padding-left: 16px;
  max-height: 53px;
  margin-bottom: 6px;
}
.scroll-tab-div {
  background-color: #ffffff;
  border-bottom: #e4e4e8 1px solid;
}
.scrollableTabHeader .scroll-tabs-indicator {
  height: 4px;
  background-color: #0049bd;
  margin-bottom: 3px;
}
.scrollableTabHeader .scroll-tabs-textlabel-order-overview {
  color: #323234 !important;
  font-size: 14px;
  text-transform: none;
  width: 240px;
  font-weight: 700;
}
.scrollableTabHeader .scroll-tabs-textlabel-select-order-overview {
  color: #0049bd !important;
  font-size: 14px;
  text-transform: none;
  width: 240px;
  font-weight: 700;
}
.scrollableTabHeader .scroll-tabs-height {
  min-height: 53px;
}

@media only screen and (max-width: 600px) {
  .selected-tab-style {
    line-height: 15px;
    padding: 0;
  }
  .tabview-page .tab-grid-conatiner .tab-grid-item {
    padding-left: 0px;
  }
  .tab-style {
    width: 90px;
  }
  .scrollableTabHeader .scroll-tabs-textlabel-order-overview {
    width: auto;
    color: #323234 !important;
  }
  .scrollableTabHeader .scroll-tabs {
    justify-content: space-between;
    background-color: white;
    padding-left: 0px;
    margin-bottom: 0px;
    height: 35px;
    margin: 0px 15px 0px 15px;
  }
  .scrollableTabHeader .scroll-tabs-indicator {
    height: 3px;
    background-color: #0049bd;
    top: 34px;
  }

  .scrollableTabHeader .scroll-tabs-height {
    min-height: 30px;
    min-width: auto;
  }
  .scrollableTabHeader .scroll-tabs-textlabel-select {
    width: auto;
    color: #0049bd !important;
  }
  .scroll-tab-div {
    border-bottom: none;
    padding-left: 12px;
    padding-right: 12px;
  }
}
