.update-user-prompt-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.saveUserConfirmPopup {
  display: flex;
  flex-direction: column;
  margin: 4px 16px 8px 16px;
  min-height: 245px;
  width: 375px;
}
.saveUserConfirmPopup .header-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  margin-top: 12px;
}

.saveUserConfirmPopup .header-sub-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
}

.saveUserConfirmPopup .return-to-order-entry {
  margin-top: 16px;
  width: 100%;
}
.return-to-order-entry .return-to-order-entry-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  width: 100%;
}
.saveUserConfirmPopup .exit-without-saving {
  margin-top: 8px;
  width: 100%;
}

.exit-without-saving .exit-without-saving-button {
  color: #cb0000;
  border-color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  width: 100%;
}
@media only screen and (max-width: 920px) {
  .saveUserConfirmPopup {
    width: 330px;
  }
}
