.in-patient-order-delivery-preference-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 32px;
  width: 100%;
}

.in-patient-order-delivery-preference-component
  .in-patient-order-delivery-preference-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0px 0px 12px 0px;
  text-align: left;
}

.in-patient-order-delivery-preference-component
  .in-patient-order-delivery-preference-desc {
  color: #4c4c4f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px 0px 12px 0px;
  text-align: left;
}

.in-patient-order-delivery-preference-grid-container
  .delivery-preference-grid-item
  .delivery-preference-input {
  width: 100%;
}

.in-patient-order-delivery-preference-grid-container
  .delivery-preference-input
  .delivery-preference-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.in-patient-order-delivery-preference-grid-container
  .delivery-preference-grid-item
  .deliveryPreference-deliveryInstructions-input {
  width: 100%;
}
.delivery-preference-grid-item .delivery-preference-eta {
  padding-left: 16px;
}
.deliveryPreferenceNeedByDate input.input {
  border-right: 0;
}

div.deliveryPreferenceNeedByDate {
  padding-right: 0;
}

.deliveryPreferenceNeedByDate .outline {
  border-radius: 2px;
}

.deliveryPreferenceNeedByDate.showError .outline {
  border: 1px solid #d32f2f;
}

.deliveryPreferenceNeedByDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.delivery-preference-grid-item
  .deliveryPreference-deliveryInstructions-input
  .showDeliveryInstructionError {
  border: 1px solid #d32f2f;
}
