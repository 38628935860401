.vac-location-component-title {
  display: flex;
  width: 100%;
  margin-top: 24px;
}
.vac-location-component-title .vac-location-review-order-edit-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  min-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
}
.vac-location-component-title .vac-location-review-order-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
.vac-therapy-location-container .vac-therapy-loc-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  min-height: 18px;
}
.vac-therapy-location-container .vac-location-review-order-content-title {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}
.vac-location-review-content .additional-review-order-content-title {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}
.vac-therapy-location-container .conatiner-vac-location-review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .vac-therapy-location-container .conatiner-vac-location-review {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
