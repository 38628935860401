.woundAssess-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.woundAssess-dates {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
