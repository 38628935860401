.link-to-help {
  text-decoration: none;
  color: #0049bd;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.route-section {
  margin-bottom: 35px;
}

.arrow-right {
  transform: rotate(-90deg);
  height: 16px;
  width: auto;
  position: relative;
  top: 3px;
  margin: 0 12px;
}

.contact-us-txt {
  color: #94949a;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}

.contact-us-sent-section {
  padding: 35px 357px 330px 24px;
}

.contact-us-section {
  padding: 35px 24px 40px 24px;
  margin: 16px;
}

.contact-us-sent-section {
  margin: 16px;
}

.contact-us-section,
.contact-us-sent-section {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
  margin-right: 56px;
}

.anchorfont {
  font-size: 14px;
  font-weight: 700;
  color: #0049bd;
  text-decoration: none;
  border-bottom: 1px solid;
}

.spanFont {
  font-size: 14px;
  font-weight: 300;
  color: #76767a;
}

.subText {
  font-size: 14px;
  color: #323234;
  font-weight: 400;
  margin-top: 8px;
}

.subHeaderText {
  font-size: 14px;
  color: #323234;
  font-weight: 400;
  margin-left: 8px;
  margin-bottom: 12px;
}

.helpAndSupportGridContainer.gridRoot {
  margin-left: 10px;
  width: 100%;
}

.resourcediv {
  margin-top: "24px";
}

.printdiv {
  margin-top: 50px;
  margin-bottom: 50px;
}

.sys-req-section {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
  margin: 16px 56px 16px 16px;
}

.sys-req-no-margin {
  margin-right: 0;
}

.route-section-sys-req {
  padding: 35px 0 35px 24px;
}

.resource-header {
  margin: 32px 0px 12px 8px;
}

.helpAndSupportGridContainer .list-item.list-root {
  padding-left: 14px;
}

.printdiv .froms-header {
  margin: 6px 0px 12px 8px;
}

.help-header {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin: 32px 0px 12px 8px;
}

.faq-section {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 16px 56px 16px 16px;
  padding: 35px 24px 48px 24px;
}

.faq-section-no-margin {
  margin-right: 0;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .helpAndSupportGridContainer.gridRoot {
    margin: 0;
    flex-direction: row;
  }

  .helpAndSupportGridContainer .list-item.list-root {
    max-width: none;
    padding: 8px 9px;
  }

  .resource-header {
    margin-top: 25px;
  }

  .faq-section {
    margin: 0px;
  }
  .sys-req-section {
    margin: 0px;
  }
  .contact-us-section {
    margin: 0px;
  }
}

@media only screen and (max-width: 926px) {
  .contact-us-sent-section {
    padding: 35px;
  }
  .contact-us-section .contact-us-sent-section {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 926px) {
  .contact-us-section {
    margin: 0px;
  }
}
