.life-order-guide-container {
  width: 100%;
  margin: 0px 80px 40px 80px;
}
.life-order-guide-container .life-order-guide-display-sections {
  min-height: calc(70vh - 144px);
  background: #ffffff;
  padding: 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.life-order-guide-component {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  margin: 0 16px;
}

.life-order-guide-component .life-guide-grid-option-container {
  margin: 0px;
  padding: 0px;
  row-gap: 16px;
  width: 100%;
}

.life-order-guide-component
  .life-guide-grid-option-container
  .life-guide-card-option-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.life-order-guide-component
  .life-guide-grid-option-container
  .life-guide-card-option-grid-item
  .life-guide-card-section {
  align-items: center;
  border: 1px solid #0049bd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 16px;
  width: calc(100% - 48px);
}

.life-guide-card-section .life-guide-card-section-title {
  color: #0049bd;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px 0px 8px;
}

.life-order-guide-container .life-order-guide-container-back-button {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 8px;
  text-transform: none;
}

@media screen and (max-width: 768px) {
  .life-order-guide-container {
    margin: 0px;
  }
  .life-order-guide-component {
    width: 100%;
  }
  .life-order-guide-container .life-order-guide-display-sections {
    padding: 20px;
    display: flex;
  }
  .life-order-guide-component
    .life-guide-grid-option-container
    .life-guide-card-option-grid-item
    .life-guide-card-section {
    height: 100px;
    width: auto;
  }
}
