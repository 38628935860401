.short-form .create-service-request-vac-therapy-unit-component {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.short-form
  .create-service-request-vac-therapy-unit-component
  .create-service-request-vac-therapy-unit-div {
  background: #f8f8fa;
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  padding: 8px;
  width: calc(100% - 16px);
}

.create-service-request-vac-therapy-unit-div .vac-therapy-unit-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.create-service-request-vac-therapy-unit-div .vac-therapy-unit-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.create-service-request-vac-therapy-unit-div
  .vac-therapy-unit-grid-container
  .vac-therapy-unit-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.vac-therapy-unit-grid-item .show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 8px 8px 0px;
  width: 100%;
}

.vac-therapy-unit-grid-item
  .show-title-and-value-div
  .show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.vac-therapy-unit-grid-item
  .show-title-and-value-div
  .show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px 0px;
  text-align: left;
  width: 100%;
}
