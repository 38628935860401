.order-option-component {
  align-items: center;
  border: 1px solid #0049bd;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 16px;
  width: calc(100% - 48px);
}

.order-option-component.disabled {
  background-color: #f8f8fa;
  border: 1px solid #e4e4e8;
  cursor: default;
}

.order-option-component .order-option-title-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto auto 0px;
}

.order-option-component .order-option-title-div .order-option-icon {
  margin: 0px;
}

.order-option-component .order-option-title-div .order-option-title {
  color: #0049bd;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px 0px 8px;
}

.order-option-component .order-option-title-div .order-option-title.disabled {
  color: #76767a;
}

.order-option-component .order-option-description {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0px 0px;
  text-align: center;
}

.order-option-component .new-feature {
  color: #0049bd;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px 0px auto;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .order-option-component {
    margin: 8px 0px;
    height: unset;
    width: unset;
  }
}

@media only screen and (max-width: 430px) {
  .order-option-component {
    margin: 8px 0px;
    padding: 16px;
    width: calc(100% - 36px);
  }
}
