.errorTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 24px;
}

.errorDetail {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.errorCode-errorDetail {
  margin-bottom: unset;
}
.errorCode-errorDetail-no-error-code {
  margin-bottom: 16px;
}
.nextOrderPopup .popupCloseIcon {
  display: none;
}

.errorPopup .paperRoot {
  width: 386px;
  height: auto;
}

@media only screen and (max-width: 414px) {
  .errorPopup .paperRoot {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}
