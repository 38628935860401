.reusableOrderPopup {
  min-height: 202px;
  max-width: 420px;
}
.reusableOrderPopupContent {
  padding: 16px 16px 8px;
}
.reusableOrderPopupTitle {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
  color: #323234;
  margin-right: 10px;
}
.reusableOrderDNoBtn {
  width: 100%;
  padding: 8px 0px;
}
.reusableOrderDYesBtn {
  width: 100%;
  background-color: #cb0000;
  color: white;
  text-transform: none;
}
.reusableOrderDOptBtnNo {
  width: 100%;
}
.reusableOrderPopup .reusableOrderDYesBtn {
  width: 100%;
  background-color: #cb0000;
  color: white;
  text-transform: none;
  height: 40px;
}
.reusableOrderPopup .reusableOrderDYesBtn:hover {
  width: 100%;
  background-color: #cb0000;
  color: white;
  text-transform: none;
}
.reusableOrderDYesBtn {
  background-color: #cb0000;
  color: white;
}
.DialogTitle {
  position: absolute;
  right: 2px;
  top: -5px;
  padding: 0px;
}
.popupCloseIcon {
  float: right;
  margin-top: -6px;
}
.buttons-alignment-row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.buttons-alignment-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
