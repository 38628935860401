.linkMain {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
}

.linkButton {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-line: underline;
}

.linkbuttonMargin {
  margin-right: 24px;
  cursor: pointer;
}

.newTabImg {
  margin-left: 4px;
  position: relative;
  bottom: 2px;
}

.dressing-dialog {
  min-width: 775px;
  min-height: 677px;
  overflow-x: hidden;
}

.body-dailog {
  margin-top: 40px;
}

.dressingsize-dialog {
  min-width: 713px;
  min-height: 397px;
}

.size-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.size-text {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.dressingsizebody-dailog {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.size-maincontainer {
  margin-top: 40px;
  padding: 16px 16px 32px 16px;
}

.linkButtonWtg {
  position: relative;
  bottom: 6px;
}

.wtgAnchor {
  text-decoration: none;
}
.dressingImg {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 450px) {
  .linkbuttonMargin {
    margin-right: 7px;
    width: 131px;
  }
  .wtgAnchor {
    margin-right: 0px;
    margin-top: 10px;
    padding: 0px;
    width: 315px;
  }
}
@media screen and (max-width: 600px) {
  .linkMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 920px) {
  .dressing-dialog {
    width: auto;
    height: auto;
    min-width: unset;
    min-height: unset;
  }
  .dressingsize-dialog {
    min-width: unset;
    min-height: unset;
    width: auto;
    height: auto;
  }
}
