.alert-container.alert-container-root {
  margin-left: 16px;
  width: 70%;
}
.addpatientbutton-div {
  float: right;
  position: relative;
  top: -7;
}
.alert-box-container.alert-box-container-root {
  display: flex;
  flex-direction: row;
}

.patient-data-box {
  width: 35%;
}
.order-menu-bar-drop-down {
  width: 100%;
}
.dailogClassPatient .dailogPaperPatient {
  max-width: 662px;
  min-width: 662px;
  min-height: 378px;
  background-color: white;
}

.comfirmationTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  flex: 100%;
}

.closeIcon {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.add-patient-popup .paperRoot {
  width: 662px;
}

.emptyPopup {
  width: 200px;
  height: 56px;
}

.no-patient-err-msg .no-patient-error-code {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  padding-top: 16px;
}

.removePatientToast {
  position: fixed;
  z-index: 1;
  left: 520px;
  top: 0;
  margin-top: 6px;
}

.removePatientToast .modal-content {
  width: 349px;
}

.removePatientToast .codeSentText {
  max-width: 269px;
}

.removePatientToast .codeSentAlertClose {
  left: 89%;
}

.myPatientListSpinner {
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.statusIconMain.yellowCircle {
  color: #eaca00;
}

.statusIconMain.orangeCircle {
  color: #ff9649;
}

.statusIconMain.redCircle {
  color: #cb0000;
}

.statusIconMain.greenCircle {
  color: #00ac31;
}

.statusIconMain.greyCircle {
  color: #94949a;
}

.statusIconMain.blueCircle {
  color: #1e64d0;
}

.statusIconMain.purpleCircle {
  color: purple;
}

.cancelShare-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}

.cancelShare-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  padding: 16px;
}

.my-patient-data {
  padding: 16px;
  height: calc(100% - 60px);
  width: calc(100% - 32px);
}

.my-patient-data .addpatientBox {
  margin-top: 2%;
}

.deleteorder-spinner {
  width: 375px;
  min-height: 202px;
  margin-top: 31px;
}

.warning-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 448px;
}

.addpatientBox .order-status-input {
  color: #76767a;
  margin-left: 10px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 200px;
  padding: 8px 8px 8px 8px;
}

.addpatientBox .order-status-select {
  color: #76767a;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  height: 32px;
  text-align: center;
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 8px 8px 8px;
}

.patients-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 16px 0px;
  width: 100%;
}

.patients-list .patients-card-container {
  background-color: #fff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: row;
  padding: 16px 0 16px 16px;
  width: calc(100% - 18px); /* 16px padding left + 2px border left and right */
}

.patients-card-container .patient-details-main-grid-container {
  margin: 0px;
  padding: 0px;
  width: calc(100% - 46px);
}

.patients-card-container
  .patient-details-main-grid-container
  .patient-details-main-grid-item {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.patient-details-main-grid-item .patient-details-grid-container {
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.patient-details-grid-container .patient-details-header-grid-item {
  margin: 0px 0px 8px;
  padding: 0px;
}

.patients-card-container .patient-menu-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 30px;
  margin: -15px auto 0;
  width: 30px;
}

.patients-card-container .patient-menu-actions .patient-menu-actions-icon {
  border-radius: 0px;
  display: flex;
  justify-content: center;
}

.patient-details-main-grid-item
  .patient-details-grid-container
  .patient-details-grid-item {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.patient-details-main-grid-item
  .patient-details-grid-container
  .patient-details-header-grid-item {
  display: flex;
  flex-direction: row;
  margin: 0 0 8px;
  padding: 0px;
}

.patient-details-header-grid-item .patient-details-name-and-ready-care-div {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}

.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .patient-name {
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  white-space: normal;
  width: 100%;
}

.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .order-type-icon {
  margin: auto 8px;
  position: relative;
  top: 4px;
  white-space: pre;
  width: 30px;
  z-index: 2;
}

.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .patient-is-ready-care {
  border-radius: 15px;
  background: #e8f4ff;
  color: #0049bd;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 14px;
  margin: auto 0px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .is-customer-plus {
  border-radius: 15px;
  background: #e3f7e8;
  color: #007713;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .patient-is-vacPeelAndPlace {
  border-radius: 15px;
  background: #e8f4ff;
  color: #0049bd;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 14px;
  margin: auto 5px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .stored-product-capsule {
  border-radius: 15px;
  background: #fff0d4;
  color: #915800;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-details-name-and-ready-care-div
  .order-source-capsule {
  color: #4c4c4f;
  background: #f8f8fa;
  border-radius: 15px;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  margin-left: 4px;
  white-space: pre;
  width: max-content;
}
.patient-details-grid-container
  .patient-details-header-grid-item
  .statusIconMain {
  border-radius: 10px;
  height: 10px;
  margin: auto 4px auto 0px;
  width: 10px;
}

.patient-details-grid-container
  .patient-details-header-grid-item
  .patient-status {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: auto 4px;
  text-align: left;
}

.patient-details-grid-item .patient-details-div {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  width: 100%;
}

.patient-details-div .title {
  color: #76767a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: 54px;
}

.patient-details-div .title.right-side {
  width: 62px;
}

.patient-details-div .title.for-mobile {
  width: 30px;
}

.patient-details-div .value {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: calc(100% - 54px);
}

.patient-details-div .value.right-side {
  width: calc(100% - 56px);
}

.patient-details-div .value.no-title {
  width: 100%;
}

.patient-details-div .value.right-side.no-title {
  height: auto;
  padding-left: 16px;
  width: calc(100% - 16px);
}

.patient-details-div .value.for-mobile {
  width: calc(100% - 30px);
}

.patient-details-main-grid-container .patient-details-main-grid-item.alerts {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px 0px;
  height: auto;
}

.patientDataPopUp .paperRoot {
  padding: 24px 10px 10px 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  width: 50%;
  margin: 8px;
}
.my-patient-grid-list-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}
.listContainer {
  padding: 6px;
  border: 1px solid #bdbdc1;
  border-radius: 2px 0px 0px 2px;
}
.listContainer-enable {
  border: 1px solid #457acf;
}
.myPatientGridContainer {
  padding: 6px;
  border: 1px solid #bdbdc1;
  border-radius: 0px 2px 2px 0px;
}
.myPatientGridContainer-enable {
  border: 1px solid #457acf;
}
.mobileEnabledToggle {
  padding: 6px;
  border: 1px solid #457acf;
}
.mpd-icon-button-filter {
  display: flex;
}
.mpd-icon-button-download {
  display: flex;
}

.order-type-tooltip-popper {
  background-color: transparent;
  padding: 0px;
}

.order-type-tooltip-popper .order-type-tooltip {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 9px 8px 15px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 24px;
  line-height: 18px;
  margin-bottom: 2px;
}

.order-type-tooltip-content {
  color: #323234;
  cursor: pointer;
  padding: 2px 4px;
  width: fit-content;
}

@media screen and (min-width: 600px) {
  .mpd-icon-button-filter {
    margin-left: 12px;
  }
  .mpd-icon-button-download {
    margin-left: 8px;
    margin-right: 14px;
  }

  .my-patient-data .no-patient-err-msg {
    margin: 32px 16px;
    height: 290px;
  }

  .no-patient-err-msg .no-patient-phone-value {
    color: #0049bd;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 8px;
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .order-type-icon {
    margin: auto 8px;
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .patient-is-ready-care {
    margin: auto 0px;
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .order-type-icon {
    width: 30px;
    margin: unset;
  }
}

@media only screen and (min-width: 761px) and (max-width: 1280px) {
  .patientDataPopUp .paperRoot {
    width: 60%;
  }
}

@media only screen and (max-width: 760px) {
  .patientDataPopUp .paperRoot {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .my-patient-data {
    padding: 0px;
    height: calc(100% - 60px);
    width: calc(100% + 16px);
  }

  .my-patient-data .addpatientBox {
    padding: 8px;
    width: calc(100% - 16px);
  }

  .patients-list {
    width: calc(100% + 2px);
  }

  .my-patient-data .no-patient-err-msg {
    padding: 32px 16px;
    margin-left: 16px;
  }

  .patient-details-grid-container
    .patient-details-header-grid-item
    .statusIconMain {
    border-radius: 10px;
    height: 10px;
    margin: 6px 4px auto 0px;
    width: 10px;
  }

  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-status {
    margin: 4px;
  }

  .patient-details-header-grid-item .patient-details-name-and-ready-care-div {
    padding-right: 16px;
    width: calc(100% - 16px);
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .patient-is-ready-care {
    margin: auto 10px;
    margin-top: 4px;
  }

  .myPatient-icon-label-span {
    display: flex;
    flex-direction: row;
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .patient-is-ready-care {
    margin: unset;
    margin-left: 10px;
    margin-top: 4px;
  }

  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .patient-is-vacPeelAndPlace {
    margin-top: 4px;
    margin-left: 10px;
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .order-type-icon {
    width: 20px;
    margin: unset;
  }
  .patient-details-grid-container
    .patient-details-header-grid-item
    .patient-details-name-and-ready-care-div
    .order-source-capsule.mobile-screen {
    margin-top: 4px;
  }
}

@media screen and (max-width: 415px) {
  .addpatientBox .order-status-input {
    border: 0px groove #b4b4b8;
    color: #76767a;
    font-size: 14px;
    font-weight: 400;
    height: 37px;
    margin-left: 2px;
    text-align: center;
    width: 100%;
  }
  .addpatientBox .order-status-select {
    border: 0px groove #b4b4b8;
    color: #76767a;
    font-size: 14px;
    font-weight: 400;
    height: 37px;
    margin-left: 2px;
    text-align: center;
    width: 100%;
  }

  .addpatientBox .order-placeHolder {
    border: 0px groove #b4b4b8;
    color: #b4b4b8;
    font-size: 14px;
    font-weight: 400;
    height: 37px;
    margin-left: 2px;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width: 361px) and (max-width: 600px) {
  .button-div-name-and-addpatient {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: unset;
    width: 100%;
  }
  .addpatientbutton-div {
    padding: unset;
  }
}

@media screen and (max-width: 360px) {
  .button-div-name-and-addpatient {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: unset;
    width: 100%;
  }
  .addpatientbutton-div {
    padding: unset;
  }
}

@media only screen and (min-width: 341px) and (max-width: 600px) {
  .warning-pop-up {
    width: 288px !important;
    margin: 16px !important;
  }
  .addpatientBox {
    margin-top: 4% !important;
  }
}

@media screen and (max-width: 340px) {
  .warning-pop-up {
    width: 238px !important;
    margin: 20px !important;
  }
  .addpatientBox {
    margin-top: 4% !important;
  }
}
.quick-link-group {
  margin-top: 16px;
}

.quick-links-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}
.quick-links-main-div .quick-links-title {
  align-self: center;
  font-weight: 700;
  margin: 0px 16px;
  padding: 10px 0px;
  width: calc(100% - 32px);
}
.quick-links-main-div .view-supply-order-status {
  align-self: center;
  color: #0049bd;
  margin: 0px 16px;
  width: calc(100% - 32px);
}
.need-help-sales-assistance-button {
  padding: 16px 0 24px 0;
}
.need-help-sales-assistance-button .need-help-sales-assistance {
  align-self: center;
  margin: 0px 16px;
  width: calc(100% - 32px);
}
.quick-links-main-div
  .view-supply-order-status-button-div
  .view-supply-order-status:hover {
  background: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 920px) {
  .quick-links-main-div
    .view-supply-order-status-button-div
    .view-supply-order-status {
    padding: 12px;
  }
}
@media (max-width: 600px) {
  .quick-link-group {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .quick-links-main-div .view-supply-order-status {
    white-space: nowrap;
  }
}
