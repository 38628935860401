.national-registry-search-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
}

.national-registry-search-component .national-registry-search-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0px;
  text-align: left;
}

.national-registry-search-component .national-registry-search-grid-container {
  margin: 24px 0px 8px;
  padding: 0px;
  width: 100%;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item.top-padding {
  padding-top: 16px;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item
  .national-registry-search-header-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  text-align: left;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item
  .search-my-prescriber-list-instead-button {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
  text-align: right;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item
  .national-registry-search-npi-input {
  width: 100%;
}

.national-registry-search-component
  .national-registry-search-grid-container
  .national-registry-search-grid-item
  .search-with-npi-button {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 20px 0px 0px 8px;
  text-align: center;
  width: calc(100% - 8px);
}

.national-registry-search-component .orDiv {
  align-items: center;
  color: #323234;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  height: 48px;
  line-height: 18px;
  width: 100%;
}

@media only screen and (max-width: 485px) {
  .national-registry-search-component
    .national-registry-search-grid-container
    .national-registry-search-grid-item
    .search-my-prescriber-list-instead-button {
    padding: 16px 0px 0px;
    justify-content: flex-start;
    text-align: left;
  }
}
