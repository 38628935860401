.nrprescriber-search {
  padding: 16px;
}

.nrprescriber-search .nrheader {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin: 10px 0;
}

.nrprescriberSearchLabel .nrprescriber-informantion-addOrRemove-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}

.nrprescriberSearchLabel {
  display: flex;
  flex-direction: row;
  margin: 15px 0px;
}
.nrbutton-align-sm {
  text-align: start;
}
.nrbutton-align-lg {
  text-align: end;
}

.nrprescriberSearchInputs {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.nrprescriberSearchInputs .searchButton {
  margin-top: 19px;
  width: 100%;
}

.nrprescriberSearchInputs .searchField {
}

.searchField .searchlabelname {
  width: 100%;
}
.nrprescriberSearchInputs .searchNR {
  width: 100%;
}
.stateField {
  width: 30%;
  align-self: flex-end;
}

.nrprescriber-search .patient-info-input {
  width: 100%;
}

.nrprescriber-search .patient-info-input .patient-info-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.nrprescriber-search div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}
.orDiv {
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 48px;
  align-items: center;
}
.grid-search-item {
  padding: 5px 0;
}
.nationRegistry-state-input {
  height: 42px;
}

.nationRegistry-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
  height: 43px;
}
.nationRegistry-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.nationRegistry-state-input .nationRegistry-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

@media screen and (max-width: 600px) {
  .nrprescriberSearchInputs .searchButton {
    margin-top: 0px;
    width: 100%;
  }
}
.nrPrescriberErrorPopup .paperRoot {
  width: 413px;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .nrPrescriberErrorPopup .paperRoot {
    margin: 0 4px 0 4px;
  }
}
