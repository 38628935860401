.paperpropsClass {
  max-height: 316px !important;
  min-width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
  width: auto !important;
}

.menuItemDrop {
  white-space: break-spaces !important;
}
/* need to add important  since in inputbase core folder its set to mui component*/
.customDropdownDisabled {
  -webkit-text-fill-color: #d0d0d3 !important;
}
