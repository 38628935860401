.alertToolTipMain {
  cursor: pointer;
}
.alertCountButton {
  justify-content: center;
  font-weight: 700;
  color: #cb0000;
  cursor: pointer;
}
.tooltip-content {
  width: fit-content;
  background-color: #ffffff;
  max-height: 250px;
  overflow: overlay;
}
.tooltip-content .alert-tooltip-button {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 8px;
  border-radius: 5px;
  align-items: flex-start;
  margin-bottom: 2px;
  cursor: pointer;
  justify-content: center;
}
.tooltip-arrow .tooltip {
  padding: 0;
  margin: 0;
  background: #fff0f0;
}

.alertToolTipPopper .alertToolTip {
  background-color: #fff;
  border-radius: 0;
  color: #000;
  font-size: 14px;
  padding: 0;
}
