.dressingHeader {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.dressingBody {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.dressingMain {
  margin-top: 40px;
  width: calc(100% - 16px);
}

.unCondensedDressingMain {
  border: 1px solid #e4e4e8;
  margin-top: 10px;
}
.unCondensedDressingMainSub {
  margin: 14px;
}
.unCondensedDressingbtnMain {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: end;
}

.unCondensedDressingbtnMain .dressing-useDefaultDressingBtn {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0049bd;
  text-decoration: underline;
  text-transform: none;
}

.unCondensedDressingbtnMain .dressing-useDefaultDressingBtn:hover {
  text-decoration: underline;
  color: #0049bd;
}

.unCondensedDressingMainSub .dressing-supplies-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
