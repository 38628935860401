.contributing-cause {
  width: 100%;
}

.contributing-cause .contributing-cause-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.contributing-cause .contributing-cause-box-container {
  margin-top: 8px;
  width: 100%;
}

.contributing-cause .contributing-cause-grid-container {
  width: 100%;
}

.contributing-cause-grid-container .contributing-cause-grid-item {
  width: 100%;
}

.contributing-cause .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.contributing-cause .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.contributing-cause .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.contributing-cause .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.contributing-cause .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.contributing-cause .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.dateOfAccident input.input {
  border-right: 0;
}

div.dateOfAccident {
  padding-right: 0;
}

.dateOfAccident .outline {
  border-radius: 2px;
}

.dateOfAccident.showError .outline {
  border: 1px solid #d32f2f;
}

.dateOfAccident.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

div.placeholder {
  border-radius: 2px;
  color: #76767a;
}

.placeholder div.placeholder {
  border-right: unset;
  height: 20px;
}

div.selectRootAccidentType {
  border-radius: 2px;
  text-transform: capitalize;
}

.selectRootAccidentType div.selectAccidentType {
  border-right: unset;
  height: 20px;
}

.selectRootAccidentType .selectIcon {
  top: 10px;
}
.wound-information-input {
  width: 100%;
}

.wound-information .wound-information-input .wound-information-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.wound-information div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.wound-information .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.primary-select,
.primary-type-input {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.primary-type-placeholder {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
}
