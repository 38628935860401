.in-patient-order-container-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.in-patient-order-container-loader-pop-up {
  height: 100%;
  width: 100%;
}
.in-patient-order-container-loader-pop-up
  .in-patient-order-container-loader-pop-up-div {
  display: flex;
  justify-content: center;
  height: 250px;
  margin: 0;
  width: 350px;
}
.inpatient-order-container-popup .paperRoot {
  width: 430px;
  padding: 16px;
}

@media screen and (max-width: 450px) {
  .in-patient-order-container-loader-pop-up
    .in-patient-order-container-loader-pop-up-div {
    margin: 16px;
    max-width: 350px;
    width: calc(100% - 32px);
  }
  .inpatient-order-container-popup .paperRoot {
    width: 100%;
  }
}
