.woundBedHeaderDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.woundBedTitle {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.woundBedMainContainer {
  margin-top: 41px;
}

.woundBedInfo {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.woundBedInfoDiv {
  margin-top: 8px;
  margin-bottom: 24px;
}

.woundBedPercent {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #323234;
}

.woundBedPercentDiv {
  margin-top: 16px;
  margin-bottom: 24px;
}

.woundBedError {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cb0000;
}

.wndBedpopper .wndBedtooltip {
  background-color: #fff;
  color: #000;
  padding: 14px 32px 16px 16px;
  font-size: 14px;
  border: 1px solid #808084;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  width: auto;
  height: auto;
}

.woundBedHeaderDiv .woundBed-astersik {
  color: red;
  font-size: 12px;
  width: auto;
  height: auto;
  margin-left: 3px;
}
.woundBedTitleError {
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
@media only screen and (max-width: 920px) {
  .woundBedTitle {
    margin-top: 20px;
  }
  .woundBed-astersik {
    margin-top: 18px;
  }
  .woundBedTitleError {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .woundBedMainContainer {
    zoom: 90%;
  }

  .wndBedpopper .wndBedtooltip {
    background-color: #fff;
    color: #000;
    padding: 14px 32px 16px 16px;
    font-size: 14px;
    border: 1px solid #808084;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 3px 1px rgba(0, 0, 0, 0.12);
    border-radius: 0;
    width: 180px !important;
    height: auto;
  }
}
