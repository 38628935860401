.srt-wound-info-main-div {
  margin-bottom: 40px;
  width: 100%;
}
.srt-wound-info-main-div .srt-wound-info-card-header {
  font-size: 18px;
  font-weight: 700;
}
.srt-wound-info-main-div
  .srt-wound-info-counter-div
  .srt-wound-info-card-sub-header {
  font-size: 14px;
}
.srt-wound-info-main-div .srt-wound-info-counter-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.srt-wound-info-main-div .srt-wound-info-qty-btn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.srt-wound-info-main-div .srt-wound-info-content-div {
  border: 1px #e4e4e8 solid;
  width: 100%;
  border-radius: 2px;
  margin-left: 2px;
  margin-top: 10px;
  padding: 16px 16px;
}
.srt-wound-info-main-div
  .srt-wound-info-content-div
  .srt-wound-info-content-select {
  width: 100%;
}
.srt-wound-info-main-div
  .srt-wound-info-content-div
  .srt-wound-info-content-input-grid {
  margin-bottom: 10px;
}
.srt-wound-info-main-div
  .srt-wound-info-content-div
  .srt-wound-info-content-header {
  font-size: 14px;
  font-weight: 700;
}
.srt-wound-info-main-div .srt-wound-info-content-div .margin-right {
  margin-right: 8px;
}
.srt-wound-info-main-div
  .srt-wound-info-counter-div
  .srt-wound-info-qty-btn-div
  .in-patient-qty-input {
  width: 40px;
}
