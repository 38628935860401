.facility-data-main-div {
  padding: 2px;
  margin: 0px !important;
}
.facility-data-main-div .facility-container {
  margin-bottom: 14px;
}
.facility-data-main-div .view-order {
  max-height: 16px;
}
.facility-data-main-div .view-order-link {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-decoration: underline;
}
.facility-data-main-div .description-1 {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}
.facility-data-main-div .description-1 {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.view-order .view-order-btn {
  background-color: transparent;
  color: #0049bd;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 8px;
}
.facility-container .facility-name {
  color: #323234;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;
  line-height: 18px;
}
.facility-container .facility-name .facility-type {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  display: contents;
  gap: 4px;
}
.facility-container .home-care-order-facility-address {
  bottom: 16px;
}
.facility-container .facility-adressLine {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
}
.facility-container .facility-phone-label {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  width: 90px;
}
.facility-container .facility-deatils-grid .facility-id-label {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  width: 90px;
}
.facility-container .facility-id {
  align-items: center;
  background-color: transparent;
  display: flex;
  margin-top: 8px;
}
.facility-container .facility-id-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  width: calc(100% - 90px);
}

.facility-container .facility-phone-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  width: calc(100% - 90px);
}

.facility-container .facility-deatils-grid {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.facility-container .facility-not-connected-facilityId {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.facility-container .facility-id-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.facility-container .facility-with-no-access {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 10px;
}
.facility-with-no-access .facility-blue-indicator-icon {
  align-self: flex-start;
}
.facility-with-no-access .facility-indicator-text {
  color: #0049bd;
  font-size: 12px;
  line-height: 14px;
}
@media only screen and (max-width: 920px) {
  .facility-data-main-div .facility-container {
    padding: 8px;
  }
  .facility-container .facility-adressLine {
    width: max-content;
  }
  .facility-container .facility-id-and-phone {
    display: contents;
  }
  .facility-container .facility-id {
    display: flex;
    flex-direction: row;
  }
  .facility-container .facility-id-div {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  .facility-container .facility-not-connected-id {
    margin-left: 52px;
  }
  .facility-container .facility-not-connected-facilityId {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .facility-container .home-care-order-facility-address {
    margin: 8px 0px 8px 0px;
  }
  .facility-data-main-div .view-order {
    max-height: none;
    display: flex;
    justify-content: flex-end;
  }
  .view-order .view-order-btn {
    margin-top: 0px;
  }
}
