.verify-sms-container {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.verify-sms-container .verify-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.verify-sms-container .instruction {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 8px;
}
.verify-sms-container .code-container {
  margin-top: 24px;
}
.verify-sms-container .code-input-container {
  margin-top: 16px;
}
.verify-sms-container .bck-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  width: 100%;
  max-width: 70px;
}
.verify-sms-container .btns-container {
  margin-top: 72px;
  margin-bottom: 16px;
  justify-content: space-between;
}
.verify-sms-container .verify-btn {
  width: 100%;
  text-transform: none;
}
.verify-sms-container .resend-toast {
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 130px;
}
