.facilityBaseUserRole {
  box-sizing: border-box;
  background-color: #f8f8fa;
  min-height: 500px;
  margin-top: 2%;
  border: 1px solid rgb(228, 228, 232);
  width: 100%;
}

.baserole-desc-container .facilityBaseUserRole {
  width: 100%;
}

.facilityimg {
  margin-left: 44%;
  right: 60.17%;
  bottom: 65.64%;
  margin-top: 6%;
}
.baseroletitle {
  font-size: 28px;
  line-height: 4px;
  text-align: center;
  color: #323234;
  margin-bottom: 4%;
  width: 100%;
  margin-top: 1%;
}
.baseroledesc {
  font-size: 16px;
  line-height: 6px;
  text-align: center;
  color: #76767a;

  width: 100%;
}

.sub-sections {
  margin: 12px 0;
  display: inline-block;
  margin-left: 5px;
}
.contact-values {
  color: #0049bd;
  text-decoration-line: underline;
  font-weight: 700;
  cursor: pointer;
}
.base-role-grid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.base-role-grid-container .base-role-desc-text {
  padding: 0px 7px;
}
@media screen and (max-width: 600px) {
  .facilityBaseUserRole {
    margin-left: calc(4% - 4px);
  }
}
