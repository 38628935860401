.update-storage-location-component {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.update-storage-location-component .update-storage-location-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 20px 16px 8px 16px;
  width: calc(100% - 32px);
}

.update-storage-location-component .update-storage-location-grid-container {
  padding: 0px;
  margin: 16px;
  width: calc(100% - 32px);
}

.update-storage-location-component
  .update-storage-location-grid-container
  .grid-item {
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.update-storage-location-component
  .update-storage-location-grid-container
  .grid-item-product {
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.update-storage-location-grid-container .grid-item .product-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-bottom: 12px;
  width: 100%;
}
.update-storage-location-grid-container .grid-item .serialNum-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-bottom: 12px;
  padding-left: 20px;
}

.update-storage-location-grid-container
  .grid-item
  .product-details
  .product-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.update-storage-location-grid-container .grid-item .product-details .unit-id {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  width: 100%;
}

.update-storage-location-grid-container
  .grid-item
  .serialNum-details
  .serialNum-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.update-storage-location-grid-container
  .grid-item
  .product-details
  .product-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
  margin-bottom: 8px;
}
.update-storage-location-grid-container
  .grid-item
  .serialNum-details
  .serialNum-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
  margin-bottom: 8px;
}

.update-storage-location-grid-container .grid-item .storage-location-input {
  width: 100%;
}

.update-storage-location-grid-container
  .grid-item
  .storage-location-input.storage-location
  .storage-location-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.grid-item .footer-button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.grid-item .footer-button .cancel-button {
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.grid-item .footer-button .next-button {
  background-color: #0049bd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}
.update-storage-location-grid-container .update-buttons-container {
  display: flex;
  margin-top: 40px;
}

.update-buttons-container .inventoryUpdate-button-item {
  display: flex;
  width: 100%;
}
.update-buttons-container .inventoryUpdate-button-item .inventoryCancelButton {
  width: 100%;
  margin-right: 10px;
}
.update-buttons-container .inventoryUpdate-button-item .inventoryNextButton {
  width: 100%;
  margin-left: 10px;
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .update-storage-location-grid-container .update-buttons-container {
    gap: 5px;
    margin-top: 27px;
  }
  .update-buttons-container
    .inventoryUpdate-button-item
    .inventoryCancelButton {
    width: 100%;
    margin-right: 0px;
  }
  .update-buttons-container .inventoryUpdate-button-item .inventoryNextButton {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .update-storage-location-component {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .update-storage-location-component {
    margin: 0px 8px;
    width: calc(100% - 16px);
  }
}
