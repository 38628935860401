.edit-prescriber-email-component {
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  width: calc(100% - 32px);
}

.edit-prescriber-email-component .edit-prescriber-email-title {
  color: #323234;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 0 8px;
}

.edit-prescriber-email-component .edit-prescriber-email-sub-title {
  color: #323234;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 16px 0 8px;
}

.edit-prescriber-email-component .prescriber-details-grid-container {
  background: #f8f8fa;
  margin: 0;
  padding: 11px 16px;
}

.edit-prescriber-email-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.prescriber-details-grid-item .prescriber-name {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.prescriber-details-grid-item .prescriber-details-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.prescriber-details-div .prescriber-details-value {
  display: flex;
  flex-direction: row;
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.prescriber-details-value .prescriber-details-sub-header {
  color: #76767a;
  margin-right: 4px;
  min-width: 40px;
}

.prescriber-details-div .prescriber-details-address-value {
  display: flex;
  flex-direction: row;
  color: #76767a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.edit-prescriber-email-component .edit-prescriber-emails-table {
  border: 1px solid #e4e4e8;
  border-spacing: 0;
  width: 100%;
}

.edit-prescriber-email-component .edit-prescriber-emails-table th {
  color: #76767a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 8px;
  text-align: left;
}

.edit-prescriber-emails-table td {
  padding: 8px;
  height: 24px;
}

.edit-prescriber-emails-table tr {
  height: 24px;
}

.edit-prescriber-emails-table tbody tr:first-child td {
  border-top: none;
}

.edit-prescriber-emails-table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.edit-prescriber-emails-table tbody tr:nth-child(2n) td {
  background: #fff;
}

.edit-prescriber-emails-table thead th:nth-child(1) {
  cursor: pointer;
  text-align: center;
  width: 56px;
}

.edit-prescriber-emails-table thead th:nth-child(2) {
  width: calc(100% - 126px);
}

.edit-prescriber-emails-table thead th:nth-child(3) {
  width: 70px;
}

.edit-prescriber-emails-table .edit-prescriber-email-isDefault {
  cursor: pointer;
  padding: 8px;
}

.edit-prescriber-emails-table .edit-prescriber-email-isDefault.disabled {
  cursor: default;
}

.edit-prescriber-emails-table
  .edit-prescriber-email-isDefault
  .edit-prescriber-email-isDefault-div {
  align-items: center;
  display: flex;
  justify-content: center;
}

.edit-prescriber-emails-table .edit-prescriber-email {
  color: #323234;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  margin: auto 0px;
}

.edit-prescriber-emails-table .edit-prescriber-email-delete {
  color: #cb0000;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin: auto 0px;
  text-decoration-line: underline;
}

.edit-prescriber-emails-table .edit-prescriber-email-delete.disabled {
  color: #76767a;
  cursor: default;
}

.edit-prescriber-email-component .edit-prescriber-no-email-message-div {
  align-items: center;
  background-color: #f2f2f5;
  border: 1px solid #e4e4e8;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
  width: 100%;
}

.edit-prescriber-email-component
  .edit-prescriber-no-email-message-div
  .edit-prescriber-no-email-message {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  text-align: center;
}

.edit-prescriber-email-component .add-prescriber-email-grid-container {
  border: 1px solid #e4e4e8;
  margin: 8px 0px;
  padding: 8px 0;
}

.edit-prescriber-email-component
  .add-prescriber-email-grid-container
  .add-prescriber-email-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.add-prescriber-email-grid-item .success-message-div {
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0px 6px;
  width: 100%;
}

.add-prescriber-email-grid-item .success-message-div .success-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 16px;
  width: 16px;
}

.add-prescriber-email-grid-item .success-message-div .success-message {
  color: #007713;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: auto 0px auto 4px;
}

.add-prescriber-email-grid-item .success-message-div .success-message.delete {
  color: #1e64d0;
}

.add-prescriber-email-grid-item .chkBoxMain {
  align-items: center;
  display: flex;
  height: 40px;
  margin: 0px 6px;
  width: 100%;
}

.add-prescriber-email-grid-item .chkBoxMain .eScript-checkbox-description-text {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: auto 0px;
}

.add-prescriber-email-grid-item .add-email-address-button-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  width: 100%;
}

.add-prescriber-email-grid-item .add-email-address-button-div.mobile {
  justify-content: center;
}

.add-prescriber-email-grid-item
  .add-email-address-button-div
  .add-email-address-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
  width: max-content;
}

.add-prescriber-email-grid-item
  .add-email-address-button-div
  .add-email-address-button:disabled {
  color: #76767a;
}

.add-prescriber-email-grid-item
  .add-email-address-button-div
  .add-email-address-button
  .add-email-address-icon {
  margin-left: -4px;
}

.add-prescriber-email-grid-item .new-email-input-div {
  margin: 0px 16px 0px;
  width: calc(100% - 32px);
}

.add-prescriber-email-grid-item .new-email-input-div .new-email {
  margin: 0px;
  width: 100%;
}

.add-prescriber-email-grid-item .new-email-input-div .new-email-input {
  margin: 0px;
  width: 100%;
}

.add-prescriber-email-grid-item .add-prescriber-email-buttons-div {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 60px;
  width: 100%;
}

.add-prescriber-email-grid-item
  .add-prescriber-email-buttons-div
  .add-email-button-div {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 40px;
  width: calc(100% - 50px);
}

.add-prescriber-email-grid-item
  .add-prescriber-email-buttons-div
  .add-email-button-div
  .add-email-button {
  background-color: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  margin: 0;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.add-prescriber-email-grid-item
  .add-prescriber-email-buttons-div
  .add-email-button-div
  .add-email-button:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: #76767a;
}

.add-prescriber-email-grid-item
  .add-prescriber-email-buttons-div
  .close-icon-button-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 40px;
  width: 40px;
}

.add-prescriber-email-grid-item
  .add-prescriber-email-buttons-div
  .close-icon-button-div
  .close-icon-button {
  height: 40px;
  width: 40px;
}

.edit-prescriber-email-component .edit-prescriber-email-buttons-grid-container {
  margin: 16px 0px 0px;
  padding: 0px;
  width: 100%;
}

.edit-prescriber-email-component
  .edit-prescriber-email-buttons-grid-container
  .edit-prescriber-email-buttons-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.edit-prescriber-email-buttons-grid-item .cancel-btn {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  margin: 16px 8px 0px 0px;
  text-align: center;
  text-transform: none;
  width: calc(100% - 8px);
}

.edit-prescriber-email-buttons-grid-item .cancel-btn.mobile {
  margin: 16px 0 0;
  width: 100%;
}

.edit-prescriber-email-buttons-grid-item .update-prescriber-btn {
  background-color: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  margin: 16px 0px 0px 8px;
  text-align: center;
  text-transform: none;
  width: calc(100% - 8px);
}

.edit-prescriber-email-buttons-grid-item .update-prescriber-btn.mobile {
  margin: 16px 0 0;
  width: 100%;
}

.edit-prescriber-email-buttons-grid-item .update-prescriber-btn:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: #76767a;
}

.edit-prescriber-email-address-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 300px;
  margin: auto;
  width: 300px;
}
