.national-registry-result-table-component {
  border: 1px solid #e4e4e8;
  max-height: 320px;
  min-height: 300px;
  overflow-y: auto;
}

.national-registry-result-table-component .select-national-registry-link {
  color: #0049bd;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
}

.national-registry-result-table-component .table-static-data {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
}
.national-registry-result-table-component .selected-button-disabled {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #323234;
}
