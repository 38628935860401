.printCommonDocs {
  margin-top: 40px;
  width: 100%;
}

.printCommonDocs .printCommonDocs-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 8px;
}

.printCommonDocs .printCommonDocs-grid-container {
  width: 100%;
}

.printCommonDocs .printCommonDocs-grid-container .printCommonDocs-grid-item {
  width: 100%;
}
.printCommonDocs
  .printCommonDocs-grid-container
  .printCommonDocs-grid-item.remove-margin-top {
  padding-top: 0px;
}
.printCommonDocs .printCommonDocs-input .printCommonDocs-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.printCommonDocs div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.printCommonDocs .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.printCommonDocs .placeHolder .selectIcon {
  top: 10px;
}

.printCommonDocs-grid-container .printCommonDocs-input {
  width: 100%;
}

.printCommonDocs .button-printCommonDocs {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40.125px;
  line-height: 18px;
  text-transform: capitalize;
  width: 100%;
}

.common-docs-paper-props {
  max-height: 316px;
  width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
}
