.inventory-main-container-div {
  background-color: #ffffff;
  width: 100%;
  margin: 20px 40px 20px 20px;
}

.inventory-main-container-div .inventory-sub-container-div .inventory-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 40px 24px;
}
.scrollableTabHeader .inventory-scroll-tabs-textlabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: none;
  width: 25%;
}
.inventory-main-container-div
  .inventory-sub-container-div
  .inventory-scroll-table-header {
  margin-left: 25px;
}
.inventory-container-spinner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}
.inventory-main-container-div .inventory-container-error-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 406px;
}

@media only screen and (max-width: 920px) {
  .inventory-main-container-div {
    margin: 0px;
  }
  .inventory-main-container-div .inventory-sub-container-div .inventory-title {
    margin: 8px;
    padding: 16px;
  }
  .scrollableTabHeader .inventory-scroll-tabs-textlabel {
    height: 30px;
    width: auto;
  }
  .scrollableTabHeader .scroll-tabs-height {
    min-height: 30px;
    min-width: auto;
  }
  .inventory-main-container-div
    .inventory-sub-container-div
    .inventory-scroll-table-header {
    margin: 0px 25px;
  }
  .inventory-sub-container-div .inventory-section-to-display {
    padding-bottom: 12px;
  }
}
