.condensedBlock {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  padding: 27px 16px;
  margin: 0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.non-condensed-mode-option {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 30px;
}
.usePreviousOrder {
  text-decoration: underline;
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  align-self: center;
  cursor: pointer;
}
.chooseDressingSuppliesEdit {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
@media only screen and (max-width: 920px) {
  .condensedBlock {
    padding: 12px;
    width: calc(100% - 28px);
  }
  .non-condensed-mode-option {
    gap: 24px;
  }
}
