.leaveAssessmentPopup .paperRoot {
  width: 383px;
  height: 240px;
}
.cancelConfirmAssessmentText {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #323234;
  margin-top: 56px;
  text-align: center;
}
.cancelConfirmationTextDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  text-align: center;
  margin-top: 8px;
}
.leaveAssessmentPopup .backAssessmentButton {
  margin: 10px 16px 10px 16px;
}

.leaveAssessmentPopup .cancelAssessmentBtn {
  margin: 0px 16px 10px 16px;
  background-color: #cb0000;
}
.leaveAssessmentPopup .cancelAssessmentBtn:hover {
  margin: 0px 16px 10px 16px;
  background-color: #cb0000;
}
