.srtServiceCovering-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.srtServiceCovering-info .srtServiceCovering-info-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.srtServiceCovering-info .srtServiceCovering-info-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-bottom: 40px;
}

.srtServiceCovering-info
  .srtServiceCovering-info-grid-container
  .srtServiceCovering-info-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.srtServiceCovering-info
  .srtServiceCovering-info-grid-container
  .srtServiceCovering-info-item
  .srtServiceCovering-type-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: calc(100% - 8px);
}

.srtServiceCovering-info
  .srtServiceCovering-info-grid-container
  .srtServiceCovering-info-item
  .srtServiceCovering-info-input {
  width: calc(100% - 8px);
}

.srtServiceCovering-info
  .srtServiceCovering-info-grid-container
  .srtServiceCovering-info-item
  .srtServiceCovering-info-select {
  width: calc(100% - 8px);
  padding: 10px;
  font-size: 14px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #94949a;
}

.srtServiceCovering-info .srtServiceCovering-info-select:focus {
  width: 100%;
  border: 1px solid #323234;
  outline: none;
}

.srtServiceCovering-info .placeHolder {
  width: calc(100% - 8px);
  color: #76767a;
}

@media only screen and (max-width: 926px) {
  .srtServiceCovering-info {
    width: 100%;
  }
}
