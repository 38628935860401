.data-entry-verification {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
}

.data-entry-verification .data-entry-verification-heading {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 24px 0 -8px;
  width: 100%;
}

.data-entry-verification .data-entry-verification-grid-container {
  margin: 8px 0px 8px -8px;
  padding: 0px;
  width: 100%;
}

.data-entry-verification
  .data-entry-verification-grid-container
  .data-entry-verification-grid-item {
  margin: 0px;
  padding: 8px;
  width: 100%;
}

.data-entry-verification
  .data-entry-verification-grid-item
  .data-entry-verification-input {
  width: 100%;
}

.data-entry-verification
  .data-entry-verification-grid-item
  .data-entry-verification-input
  .data-entry-verification-select {
  align-items: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.data-entry-verification-grid-item
  .data-entry-verification-select.place-holder {
  border-radius: 2px;
  background-color: #f2f2f5;
  color: #76767a;
  width: 100%;
}

.data-entry-verification-grid-item .place-holder .selectIcon {
  top: 10px;
}

.data-entry-verification-grid-item .placementDate input.input {
  border-right: 0;
}

.data-entry-verification div.placementDate {
  padding-right: 0;
}

.data-entry-verification-grid-item .placementDate .outline {
  border-radius: 2px;
}

.data-entry-verification-grid-item .placementDate.showError .outline {
  border: 1px solid #d32f2f;
}

.data-entry-verification-grid-item .placementDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.data-entry-verification div.placeholder {
  height: 42px;
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.placeholder div.placeholder {
  height: 42px;
  border-right: unset;
  height: 20px;
}

.data-entry-verification .dressings-and-supplies-heading {
  color: #323234;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 24px 0 0;
  width: 100%;
}

.data-entry-verification-grid-item .dressings-and-supplies-description {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: -8px 0px 8px;
}

.data-entry-verification-grid-item .dressings-and-supplies-header {
  color: #808084;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.data-entry-verification-grid-item .dressings-and-supplies-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.dressings-and-supplies-div .dressings-and-supplies-value {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

@media screen and (max-width: 920px) {
  .data-entry-verification {
    width: calc(100% - 40px);
  }
}
.data-entry-verification-grid-item .Mui-error > input.MuiInputBase-input {
  border-color: #76767a;
}
