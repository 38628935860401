.national-registry-no-result-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 24px;
}

.national-registry-no-result-component .national-registry-no-result-header {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  text-align: left;
}

.national-registry-no-result-component
  .national-registry-no-result-description {
  align-items: flex-start;
  background: #f8f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 24px 0px;
  min-height: 42px;
  width: 100%;
}

.national-registry-no-result-component
  .national-registry-no-result-description
  .national-registry-no-result-description-text {
  color: #323234;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-left: 16px;
}

.national-registry-no-result-component
  .national-registry-no-result-search-again-button {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  width: 100%;
}
