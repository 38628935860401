.wound-dimensions-main-container .dimension {
  width: 560px;
  margin-top: 35px;
}

.dimension-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.dimension .wound-measurement-date {
  width: 266px;
}

.dimension .dimension-box-container {
  margin-top: 12px;
  width: 100%;
}

.woundMeasurementDate input.input {
  border-right: 0;
}

div.woundMeasurementDate {
  padding-right: 0;
}

.woundMeasurementDate .outline {
  border-radius: 2px;
}

.woundMeasurementDate.showError .outline {
  border: 1px solid #d32f2f;
}

.woundMeasurementDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.dimension .dimension-box-container {
  margin-top: 12px;
  width: 100%;
}

.dimension-box-container .dimension-grid-container {
  width: 100%;
}

.dimension-grid-container .dimension-grid-item {
  width: 100%;
}

.dimension .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.dimension .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.dimension .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.dimension .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.dimension .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.dimension .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.dimension-box-container .dimension-grid-container .depth-input {
  width: 100%;
}
@media only screen and (max-width: 920px) {
  .wound-dimensions-main-container .dimension {
    width: 100% !important;
  }

  .wound-dimensions-main-container .dimension .wound-measurement-date {
    width: calc(100% - 16px);
  }
}
