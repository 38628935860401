.home-care-order-not-found-header {
  font-weight: 700;
  font-size: 20px;
  width: 90%;
}
.home-care-order-not-found-card {
  background-color: #f8f8f8;
  padding: 16px;
  border: 1px #e4e4e8 solid;
  width: 100%;
}
.home-care-order-not-found-card .home-care-order-not-found-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
.home-care-order-not-found-card .home-care-order-not-found-sub-title {
  font-size: 14px;
}
.search-agian-button-div {
  margin: 18px 0px 8px 0px;
  width: 100%;
}
.search-again-button {
  width: 100%;
}
