.in-patient-order-prescriber-informantion-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  width: 77.77%;
}

.in-patient-order-prescriber-informantion-component
  .in-patient-order-prescriber-informantion-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .in-patient-order-prescriber-informantion-description {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px 0px;
  text-align: left;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .in-patient-order-prescriber-search-prescriber-button-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .in-patient-order-prescriber-search-prescriber-button-div
  .search-prescriber-informantion-button {
  color: #0049bd;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  height: 48px;
  margin: 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container {
  border: 1px solid #e4e4e8;
  margin: 16px 0px 0px;
  padding: 16px;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item
  .prescriber-name {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0px;
  text-align: left;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item
  .change-prescriber-button {
  display: flex;
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  margin: 8px 0px 8px auto;
  padding: 0px;
  text-align: right;
  text-decoration: underline;
  text-transform: none;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item
  .show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item
  .show-title-and-value-div
  .show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.in-patient-order-prescriber-informantion-component
  .prescriber-selected-details-grid-container
  .prescriber-selected-details-grid-item
  .show-title-and-value-div
  .show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px 0px;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.in-patient-order-prescriber-pop-up-container .paperRoot {
  display: flex;
  max-width: 574px;
  width: 100%;
}

@media only screen and (max-width: 926px) {
  .in-patient-order-prescriber-informantion-component {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .in-patient-order-prescriber-pop-up-container .paperRoot {
    margin: 0px 16px;
    padding: 0px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .in-patient-order-prescriber-pop-up-container .paperRoot {
    margin: 0px 8px;
    padding: 0px;
    width: calc(100% - 16px);
  }
}
