.sales-history-component-container {
  width: 100%;
}

.sales-history-component-container .sales-history-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  min-height: calc(100vh - 244px);
  width: calc(100% - 72px);
}
.sales-history-component .sales-history-component-route-section {
  margin: 40px 40px 24px 40px;
}

.sales-history-component .sales-history-child-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.sales-history-title {
  color: var(--msdds-gray-80, #323234);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  margin-left: 40px;
  margin-bottom: 0px;
}

.sales-history-subDesc-component .sales-history-downloadbtn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #0049bd;
  text-underline-offset: 4px;
  text-decoration: underline;
}

.sales-history-subDesc-component .sales-history-downloadbtn:disabled {
  color: #76767a;
}

.sales-history-subDesc-component {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
  margin-left: 40px;
}

.sales-history-subDesc {
  color: var(--msdds-gray-80, #323234);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  width: 85%;
}
.sales-history-component .sales-history-dropdown-grid-main {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 93%;
  margin-left: 40px;
  margin-top: 24px;
}

.sales-history-dropdown-grid-main .sales-history-year-dropdown-input {
  height: 40px;
  width: 100%;
}

.sales-history-dropdown-grid-main
  .sales-history-year-dropdown-input
  .sales-history-year-dropdown {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.sales-history-dropdown-grid-main .sales-history-requestor-dropdown-input {
  height: 40px;
  width: 100%;
}

.sales-history-dropdown-grid-main
  .sales-history-requestor-dropdown-input
  .sales-history-requestor-dropdown {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.sales-history-dropdown-grid-main .sales-history-salespeople-dropdown-input {
  height: 40px;
  width: 100%;
}

.sales-history-dropdown-grid-main
  .sales-history-people-dropdown-input
  .sales-history-people-dropdown {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.no-data {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  margin: 40px;
  width: calc(100% - 80px);
}

.no-data .no-data-message {
  color: #323234;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

@media only screen and (max-width: 920px) {
  .sales-history-component-container .sales-history-component {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 600px) {
  .sales-history-component .sales-history-component-route-section {
    margin: 24px;
  }
  .sales-history-title {
    margin-left: 24px;
  }
  .sales-history-subDesc-component {
    display: flex;
    flex-direction: column;
    align-items: unset;
    gap: unset;
    align-self: unset;
    margin-left: 24px;
  }
  .sales-history-subDesc {
    width: 100% !important;
  }
  .sales-history-subDesc-component .sales-history-downloadbtn {
    justify-content: flex-start;
  }
  .sales-history-component .sales-history-dropdown-grid-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    margin-left: 24px;
    margin-top: 24px;
  }
  .dropDownGridItem {
    width: 100%;
  }
  .sales-history-component .sales-history-dropdown-grid-main {
    flex-direction: column;
    margin-left: 24px !important;
  }
}

.salesHistoryCsvSaveToast {
  position: fixed;
  z-index: 1;
  left: 520px;
  top: 0;
  margin-top: 6px;
}

@media only screen and (max-width: 1024px) {
  .sales-history-component .sales-history-dropdown-grid-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 93%;
    margin-left: 40px;
    margin-top: 24px;
  }
  .dropDownGridItem {
    width: 100%;
  }
  .sales-history-subDesc {
    width: 75%;
  }
}
