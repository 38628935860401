.confirm-placement-header-title {
  align-items: center;
  background: #fff0f0;
  border-radius: 2px;
  color: #cb0000;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 8px 16px;
  width: fit-content;
  min-width: 109px;
}
