.resubmission-success-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;
}

.resubmission-success-pop-up .resubmission-success-pop-up-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
}

.resubmission-success-pop-up .resubmission-success-pop-up-description {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.resubmission-success-pop-up .resubmission-success-pop-up-description1 {
  color: #007713;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 0px;
}

.resubmission-success-pop-up .submitButton {
  margin: 16px 0 0 0;
  width: 100%;
}
.resubmission-success-pop-up-description .resubmission-check-mark-img {
  margin-top: 5px;
}

@media only screen and (min-width: 601px) and(max-width:740px) {
  .resubmission-success-pop-up-description .resubmission-check-mark-img {
    margin-top: 0px;
  }
}
