.search-list-component .search-results {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 0px;
}
.search-list-component .grid-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 260px;
  margin-top: 20px;
}
.search-list-component .select-facility {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #1e64d0;
  margin-right: 24px;
  margin-bottom: 16px;
  cursor: pointer;
}
.search-list-component .facility-card-even {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 24px 11px 16px;
  gap: 8px;
  height: 94px;
  background: #f8f8fa;
}
.search-list-component .facility-card-odd {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 24px 11px 16px;
  gap: 8px;
  height: 94px;
  background: #ffffff;
}
.search-list-component .facility-name {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.search-list-component .facility-details-view {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.search-list-component .facility-details {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  width: 150px;
}
.search-list-component .facility-details-facility-id-label {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  color: #76767a;
}
.search-list-component .facility-details-facility-Id {
  color: #212122;
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 10px;
}
.search-list-component .back-to-search-btn-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 24px 0px 0px 0px;
}
.back-to-search-btn-container .back-to-search-btn {
  width: 100%;
}
.back-to-search-btn .back-to-search-label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #f7f8fa;
}
.back-to-search-btn .back-to-search-label-contained {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
}
.back-to-search-btn-container .add-manual-facility-btn {
  width: 100%;
}
.back-to-search-btn .add-manual-facility-btn-label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
}
.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 48px;
  width: 100%;
}
@media only screen and (max-width: 920px) {
  .search-list-component .facility-details-facility-id-label {
    margin-left: unset;
  }
  .search-list-component .facility-details-view {
    display: flex;
    flex-direction: column;
    gap: unset;
  }
  .search-list-component .back-to-search-btn-container {
    display: flex;
    flex-direction: column;
  }
  .search-list-component .facility-card-even {
    padding: 16px 8px;
  }
  .search-list-component .facility-card-odd {
    padding: 16px 8px;
  }
  .search-list-component .select-facility {
    margin-right: 12px;
  }
}
