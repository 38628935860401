.footer-btn-group .create-service-request-tool-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
}

.create-service-request-tool-bar .create-service-request-main-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  margin-left: calc(15% - 32px);
  margin-right: 84px;
  width: 100%;
}

.create-service-request-left-container {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}

.create-service-request-right-container {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  width: 50%;
}

.create-service-request-left-container .boxOrderStyle,
.create-service-request-right-container .boxOrderStyle {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
}

.create-service-request-right-container .boxOrderStyle {
  padding: 8px 0px 8px 16px;
}

.create-service-request-left-container .boxOrderStyle .firstButton {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.create-service-request-right-container .boxOrderStyle .secondButton {
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 140px;
  line-height: 18px;
  text-decoration: none;
}

.create-service-request-right-container .boxOrderStyle .secondButton:hover {
  border: 1px solid #0049bd;
  background-color: unset;
  text-decoration: none;
}

.create-service-request-right-container .boxOrderStyle .thirdButton {
  min-width: 192px;
}

.create-service-request-right-container .boxOrderStyle .thirdButton-disabled {
  background: #e4e4e8;
  color: #76767a;
  min-width: 192px;
}

@media only screen and (max-width: 926px) {
  .footer-btn-group .create-service-request-tool-bar {
    flex-direction: column;
    margin: 0px;
  }

  .create-service-request-tool-bar .create-service-request-main-container {
    align-items: baseline;
    margin: auto 16px;
  }

  .create-service-request-main-container
    .create-service-request-left-container {
    margin: 0px auto 0px 0px;
    width: fit-content;
  }

  .create-service-request-main-container
    .create-service-request-right-container {
    margin: 0px;
    right: unset;
    width: fit-content;
  }
}

@media only screen and (max-width: 475px) {
  .create-service-request-left-container .boxOrderStyle .firstButton {
    min-width: fit-content;
  }

  .create-service-request-right-container .boxOrderStyle .secondButton {
    min-width: fit-content;
  }

  .create-service-request-right-container .boxOrderStyle .thirdButton {
    min-width: fit-content;
  }
}

@media only screen and (max-width: 350px) {
  .create-service-request-left-container {
    width: 50px;
  }

  .create-service-request-right-container {
    gap: 8px;
    width: calc(100% - 64px);
  }

  .create-service-request-left-container .boxOrderStyle .firstButton {
    width: 50px;
  }

  .create-service-request-right-container .boxOrderStyle {
    padding: 8px 0px 8px 8px;
  }

  .create-service-request-right-container .boxOrderStyle .secondButton {
    height: 40px;
    width: 50px;
  }
}
