.footer-btn-group .addToolBar {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
}

.addSalesMainContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  margin-left: calc(15% - 32px);
  margin-right: 104px;
  width: 100%;
}

.addSalesRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.boxAddInternalStyle {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: -8px;
}

.boxAddInternalStyle .back-button {
  min-width: 185px;
}

@media only screen and (max-width: 600px) {
  .addSalesMainContainer {
    margin: 0px;
  }

  .addSalesRightContainer {
    justify-content: center;
  }
}
