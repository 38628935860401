.wound-assements-report-filter-component {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 16px 0px;
  width: 100%;
}

.wound-assements-report-filter-component
  .wound-assements-report-filter-grid-container {
  margin: 0px;
  padding: 0px;
  row-gap: 8px;
  width: 100%;
}

.wound-assements-report-filter-component
  .wound-assements-report-filter-grid-container
  .wound-assements-report-filter-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.wound-assements-report-filter-component
  .wound-assements-report-filter-grid-container
  .wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  color: #323234;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  height: 40px;
  line-height: 18px;
  margin: 0px;
  padding: 0;
  text-align: left;
  width: calc(100% - 8px);
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar::placeholder {
  color: #94949a;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar
  .search-icon-div {
  height: 100%;
  margin: 0;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar
  .search-icon-div
  .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 8px;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar
  .wound-assements-report-filter-input {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-input-field-searchbar
  .wound-assements-report-filter-input
  .MuiInputBase-input {
  border: none;
  padding: 0px 8px 0px 0px;
  text-overflow: ellipsis;
}

.wound-assements-report-filter-date-range {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.wound-assements-report-filter-grid-item
  .wound-assements-report-filter-date-range
  .date {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.wound-assements-report-filter-date input.input {
  border-right: 2px;
  height: 22px;
  width: 100%;
}

div.wound-assements-report-filter-date {
  width: 100%;
}

div .wound-assements-report-filter-date .adornedRoot {
  position: absolute;
  right: 18px;
}

.wound-assements-report-filter-date .outline {
  border-radius: 1px;
  border: 1px solid #94949a !important;
  width: calc(100% - 18px);
}

.wound-assements-report-filter-date.show-error .outline {
  border: 1px solid #cb0000 !important;
}

.wound-assements-report-filter-date.no-error.Mui-error .outline {
  border: 1px solid #94949a !important;
}

.wound-assements-report-filter-for-dates .error-with-warning-icon-div {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  min-height: 16px;
  width: 100%;
}

.wound-assements-report-filter-for-dates
  .error-with-warning-icon-div
  .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.wound-assements-report-filter-for-dates
  .error-with-warning-icon-div
  .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.wound-assements-report-filter-for-dates
  .error-with-warning-icon-div
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}

.wound-assements-report-filter-grid-item .apply-filter-button {
  align-items: center;
  display: flex;
  justify-content: center;
  border-color: #0049bd;
  color: #0049bd;
  font-weight: 700;
  font-size: 12px;
  font-style: normal;
  height: 42px;
  line-height: 16px;
  margin: 19px 0px 0px 8px;
  width: calc(100% - 8px);
}

.wound-assements-report-filter-grid-item .apply-filter-button:disabled {
  border: 1px solid #76767a;
  color: #76767a;
  cursor: none;
}

@media only screen and (max-width: 600px) {
  .wound-assements-report-filter-grid-item
    .wound-assements-report-filter-date-range
    .date {
    margin: 8px 0;
  }
}
