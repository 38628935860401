.clinical-information {
  margin: 37px 0 40px 0;
  width: 100%;
}

.clinical-information.secondary {
  margin: 37px 0 0px 0;
}

.clinical-information .clinical-information-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
}

.clinical-information .clinical-information-box-container {
  margin: 8px 0px;
  width: 100%;
}

.clinical-information .clinical-information-grid-container {
  width: 100%;
}

.clinical-information-grid-container .clinical-information-grid-item {
  width: 100%;
}

.clinical-information-grid-item .clinical-information-input {
  width: 100%;
}

.clinical-information .shortNarrativeOfPossibleConsequences {
  white-space: inherit;
  line-height: 20px;
  position: unset;
}

.clinical-information .textView {
  margin-top: 4px;
}

.clinical-information .helperText {
  margin: 10px 0 0;
  text-align: right;
}

.clinical-information .textarea-root {
  padding: 0px;
}

.clinical-information-grid-item .clinical-information-woud-age-div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 2px;
  width: 50%;
}

.clinical-information
  .clinical-information-woud-age-div
  .clinical-information-input-wound-age {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-width: 50px;
  width: 5px;
}
.clinical-information .wound-age-items {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 20px;
  width: 19%;
}

.WoundageType {
  height: 40px;
}
.clinical-information-woundAgeType-select {
  background-color: #0049bd;
}
.WoundageType .clinical-information-woundAgeType-input {
  width: 101px;
}
.clinical-information .clinical-information-input .clinical-information-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.clinical-information-woundAgeType-props {
  width: 100px;
}
.clinical-information div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.clinical-information .placeHolder div.placeHolder {
  border-right: unset;
  height: 22px;
  width: 100%;
}

.clinical-information .placeHolder .selectIcon {
  top: 10px;
}

.clinical-information .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.clinical-information .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0px;
  width: calc(49% - 4px);
}

.clinical-information .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.clinical-information .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.clinical-information .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.clinical-information .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.clinical-information .primary-select,
.primary-type-input {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.clinical-information .primary-type-placeholder {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
}

.clinical-information .wound-category {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
}
.clinical-information .category {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.clinical-information .bordered-div {
  border: 1px solid #e4e4e8;
  padding: 15px 16px;
}

.clinical-information .radio-question {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.clinical-information .radio-question-root {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
  max-width: 147px;
}

.clinical-information .radio-question-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
  max-width: 147px;
}
.clinical-information .radio-question-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
  max-width: 147px;
}
.clinical-information .custom-icon-div-error-warning {
  margin-top: 6px;
  width: max-content;
}
.clinical-information .radio-question-txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
}
.clinical-information .question-container {
  margin-top: 16px;
}
.clinical-information .question-container .text-question {
  margin-top: 8px;
  width: 100%;
}
.clinical-information .question-container .dateQuestionRoot {
  max-width: 165px;
}
.clinical-information .question-container .dateQuestionInput {
  min-width: 139px;
}
.radio-question-label {
  white-space: normal !important;
}

@media only screen and (max-width: 920px) {
  .css-dmmspl-MuiFormGroup-root {
    white-space: pre-wrap !important;
  }
  .clinical-information .radio-question {
    margin-top: 16px;
    width: 100%;
  }

  .clinical-information .radio-question .radio-question-root {
    max-width: unset;
  }

  .clinical-information .radio-question .radio-question-active {
    max-width: unset;
  }
}

@media only screen and (max-width: 450px) {
  .clinical-information .radio-question-root {
    padding: 16px 8px 16px 0px;
    width: 100%;
  }
  .clinical-information .radio-question-active {
    padding: 16px 8px 16px 0px;
    width: 100%;
  }
}
@media only screen and (min-width: 970px) and (max-width: 1070px) {
  .clinical-information .radio-question {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .clinical-information .bordered-div {
    padding: 15px 18px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 430px) {
  .clinical-information .wound-age-items {
    width: 40%;
  }
}
@media only screen and (min-width: 431px) and (max-width: 500px) {
  .clinical-information .wound-age-items {
    width: 30%;
  }
}
@media only screen and (min-width: 921px) and (max-width: 1024px) {
  .clinical-information .wound-age-items {
    width: 30%;
  }
}
@media only screen and (width: 600px) {
  .clinical-information .wound-age-items {
    width: 24%;
  }
}
