.order-upload-doc .docUploadFileDetailCard {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  margin-bottom: 10px;
}

.order-upload-doc .docUploadFileDetailCard .docUploadFilename {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 50%;
  align-self: center;
  overflow-wrap: break-word;
  margin: 0px 0px 0px 11px;
}
.order-upload-doc .docUploadFileDetailCard .abc {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.order-upload-doc .docUploadFileDetailCard .docUploadfileStatus {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 50%;
  align-self: center;
  display: flex;
}

.order-upload-doc .docUploadFileDetailCard .orderDocUpload {
  margin-top: 4px;
  margin-left: 3px;
  width: 100%;
}

.docUploadFileDetailCard .docUploadfileCloseFail {
  width: 78px;
  align-self: center;
  text-align: right;
  cursor: pointer;
  position: relative;
  top: 4px;
}

.upload-document-with-type-or-status {
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
  width: 100%;
}

.upload-document-with-type-or-status .upload-document-grid-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.upload-document-grid-container .upload-document-grid-item {
  margin: 0 0 15px 0;
  min-height: 56px;
  padding: 0 !important;
  width: 100%;
}

.upload-document-grid-item .upload-document-filename-div {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.upload-document-type-div .upload-document-type-input {
  margin: 0;
}

.upload-document-grid-item
  .upload-document-filename-div
  .upload-document-filename-title {
  color: #76767a;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  width: 100%;
}

.upload-document-grid-item
  .upload-document-filename-div
  .upload-document-filename-value {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
  position: relative;
  width: 100%;
}

.upload-document-grid-item .document-type-or-status-div {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

.upload-document-grid-item
  .document-type-or-status-div
  .upload-document-type-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 16px;
  width: fit-content;
}

.document-type-or-status-div .upload-document-type-div .document-type {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.document-type-or-status-div
  .upload-document-type-div
  .documentType-info-input {
  height: 43px;
  width: 280px;
}

.documentType-info-input .documentType-info-select {
  align-items: center;
  display: block;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: calc(100% - 16px);
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: calc(100% - 16px);
}

.placeHolder .selectIcon {
  top: 10px;
}

.upload-document-grid-item
  .document-type-or-status-div
  .docUploadfileStatuserror {
  color: #cb0000;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 18px;
  margin: auto 15px;
  position: relative;
  top: 11px;
  width: fit-content;
}

.upload-document-grid-item
  .document-type-or-status-div
  .docUploadfileCloseSuccess {
  align-items: center;
  cursor: pointer;
  margin: auto 15px;
  position: relative;
  text-align: right;
  top: 11px;
}

.upload-document-grid-item
  .document-type-or-status-div
  .docUploadfileCloseFail {
  cursor: pointer;
  margin: auto 15px;
  position: relative;
  text-align: right;
  top: 11px;
}

.upload-document-grid-item .document-type-or-status-div .upload-error-text {
  font-weight: 400;
}

.order-Upload-button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
}

.order-Upload-button .orderDocUploadBtn {
  margin: 0 8px 0 0;
  width: fit-content;
}

@media only screen and (max-width: 600px) {
  .upload-document-grid-container .upload-document-grid-item {
    min-height: 0;
    margin: 0;
  }
  .upload-document-grid-item
    .document-type-or-status-div
    .upload-document-type-div {
    margin: 8px 0px 20px 0px;
    width: 100%;
  }
  .upload-document-grid-item
    .upload-document-filename-div
    .upload-document-filename-value {
    margin: 0;
  }
  .upload-document-grid-item .document-type-or-status-div {
    justify-content: flex-start;
  }
  .document-type-or-status-div
    .upload-document-type-div
    .documentType-info-input {
    height: 43px;
    width: 100%;
  }
}

@media only screen and (width: 320px) {
  .document-type-or-status-div
    .upload-document-type-div
    .documentType-info-input {
    width: 250px;
  }
}
@media only screen and (min-width: 380px) and (max-width: 420px) {
  .document-type-or-status-div
    .upload-document-type-div
    .documentType-info-input {
    width: 320px;
  }
}
@media only screen and (min-width: 360px) and (max-width: 375px) {
  .document-type-or-status-div
    .upload-document-type-div
    .documentType-info-input {
    width: 280px;
  }
}
