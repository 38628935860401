.reviewOrderHeader {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.newOrder-container .patientForm.summary {
  margin-bottom: 116px;
}

.patientForm.summary .saveOrderDiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.saveOrderDiv .saveOrderBtn {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
}

.printOrderDiv .printOrderDivBtn {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
}
.orderSummaryHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-top: 16px;
}
.newOrderSaveToast {
  left: 40%;
  margin-top: 6px;
  max-width: 100%;
  position: fixed;
  top: 0px;
  width: fit-content;
  z-index: 1;
}
.review-order-loading {
  height: 300px;
  width: 300px;
}

.review-order-loading .review-order-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  width: 300px;
}
.reviewOrderToast {
  position: fixed;
  z-index: 1;
  left: 35%;
  top: 0;
  max-width: 30%;
  margin-top: 6px;
}

@media screen and (max-width: 920px) {
  .newOrder-container .patientForm.summary {
    margin-bottom: 244px;
  }
  .reviewOrderHeader {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 350px) {
  .newOrder-container .patientForm.summary {
    margin-bottom: 300px;
  }
}

@media screen and (max-width: 414px) {
  .patientForm.summary .saveOrderDiv {
    justify-content: left;
  }
}
