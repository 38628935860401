.patient-information-review-order-component {
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 0;
  width: 100%;
}

.patient-information-review-order-component
  .patient-information-review-order-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  width: fit-content;
}

.patient-information-review-order-component
  .patient-information-review-order-header
  .patient-information-review-order-edit-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  margin: auto 0px;
  text-align: left;
  text-decoration: underline;
  width: fit-content;
}
