.assessWoundbed .assess-woundBedMainContainer {
  margin-top: 41px;
}
.assessWoundbed .assess-woundBedHeaderDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.assessWoundbed .assess-wndBedpopper .assess-wndBedtooltip {
  background-color: #fff;
  color: #000;
  padding: 14px 32px 16px 16px;
  font-size: 14px;
  border: 1px solid #808084;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  width: auto;
  height: auto;
}
.assessWoundbed .assess-woundBedInfoDiv {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.assessWoundbed .assess-woundBedPercentDiv {
  margin-top: 24px;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}
.assessWoundbed .assess-woundBedError {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #cb0000;
}
.assessWoundbed .assess-woundBedPercent {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}
.assessWoundbed .assess-woundBedTitle {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.woundBedContainer {
  width: 70%;
}

@media only screen and (max-width: 600px) {
  .assess-woundbed-component {
    zoom: 90%;
  }
  .assess-woundbed-component svg.tooltipRoot {
    margin-top: unset;
  }
}
