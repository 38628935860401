.orderOverviewWoundDetails-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  margin: 16px 56px 16px 16px;
  width: calc(100% - 72px);
}
.Wound-details-navigator-main {
  margin-top: 16px;
}
.wound-details-not-present {
  margin: auto;
  display: flex;
  justify-content: center;
  place-content: center;
  flex-direction: column;
  height: 330px;
  text-align: center;
}
.wound-details-not-present .wound-details-not-present-error-text {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 16px 20px;
  width: calc(100% -32px);
}
.orderOverviewWoundProgress-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  width: calc(100% - 72px);
}
.wound-detail-route-section {
  margin: 0 0 0 18px;
}
@media only screen and (max-width: 920px) {
  .orderOverviewWoundDetails-container {
    width: 100%;
    margin: 0px;
  }
}
