.facility-search-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.facility-search-field-container .search-by-facility-id {
  width: 100%;
  max-width: 382px;
}

.facility-search-field-container .search-by-facility-submit {
  width: 100%;
  max-width: 144px;
}

.facility-search-field-container .search-button {
  width: 100%;
  margin-top: 16px;
}

.select-facility-container {
  margin-top: 10px;
}

.select-facility-container .select-facility-search {
  width: 100%;
  max-width: 160px;
}
.select-facility-container .search-by-facility-submit {
  width: 100%;
  max-width: 74px;
}
@media only screen and (max-width: 1024px) {
  .facility-search-field-container {
    align-items: flex-start;
  }
  .select-facility-container .search-by-facility-submit {
    max-width: 100%;
  }
  .select-facility-container .select-facility-search {
    width: 100%;
    max-width: 100%;
  }
}
