.discharge-loader {
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}
.dischargeSuccessPara {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 8px;
  margin-left: 30px;
  margin-top: 0%;
}

.dischargeSuccessPara2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-left: 30px;
  margin-top: 8px;
}

.dischargeSuccessPara3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 30px;
  margin-top: 16px;
}

.dischargeSummaryBtn .backtoPatientBtn {
  width: 237px;
  margin-bottom: 40px;
  margin-left: 30px;
  margin-top: 24px;
}
.clipboardIcon {
  display: "flex";
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 35%;
}
.gridLine {
  width: 95%;
  margin-left: 35px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}
.summaryDischargeRequestHeader .orderSummaryForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .gridLine {
    width: 100%;
    margin-left: 0px;
  }
  .clipboardIcon {
    margin-left: 0px;
    display: flex;
    background: "red";
    align-self: center;
    margin-left: 2;
  }
  .clipboardIconImg {
    height: 100px;
  }
  .dischargeSummaryBtn .backtoPatientBtn {
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .clipboardIconImg {
    height: 70px;
    margin-left: 13px;
  }
}
