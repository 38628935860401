.order-found-main-div {
  padding: 16px;
}
.order-found-main-div .seach-home-care-order-found-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 8px;
}
.order-found-main-div .seach-home-care-found-description-1 {
  color: #0049bd;
  background: #e8f4ff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 8px 8px 0px;
}
.order-found-main-div .seach-home-care-found-description-2 {
  color: #323234;
  background: #e8f4ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 0px 8px 8px 8px;
}
.order-found-main-div .search-again-button {
  margin-top: 16px;
  width: 100%;
}
.loader-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-found-main-div .facility-not-found {
  background: #fff0d4;
}
.order-found-main-div .home-care-order-facility-found {
  background: #e8f4ff;
}
@media only screen and (max-width: 920px) {
  .order-found-main-div .seach-home-care-order-found-title {
    margin-bottom: 8px;
  }
}
