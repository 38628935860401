@media only screen and (max-width: 767px) {
  .alertPopUp .paperRoot {
    width: 90%;
  }
  .ConfirmPlacementalert .paperRoot {
    width: 90%;
  }
  .DischargePendingalert .paperRoot {
    width: 90%;
  }
  .MissingRxalert .paperRoot {
    width: 90%;
  }
  .ProofofDeliveryNeededalert .paperRoot {
    width: 90%;
  }
  .SharedOrderalert .paperRoot {
    width: 90%;
  }
  .SuppliesDeliveredalert .paperRoot {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .IncompleteOrderalert .paperRoot {
    max-width: 90%;
  }
  .IncompleteWoundDetailsalert .paperRoot {
    max-width: 90%;
  }
}
