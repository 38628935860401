.vac-therapy-unit-review-request-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-review-request-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
  height: 20px;
  margin: 0px;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-review-request-header
  .vac-therapy-unit-review-request-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  width: fit-content;
}

.vac-therapy-unit-review-request-component .vac-therapy-unit-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-grid-container
  .vac-therapy-unit-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-grid-container
  .vac-therapy-unit-grid-item
  .show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-grid-container
  .vac-therapy-unit-grid-item
  .show-title-and-value-div
  .show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.vac-therapy-unit-review-request-component
  .vac-therapy-unit-grid-container
  .vac-therapy-unit-grid-item
  .show-title-and-value-div
  .show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px 0px;
  text-align: left;
  width: 100%;
}
