.education-option-component {
  border: 1px solid #0049bd;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.education-option-component.left {
  margin-left: 12px;
}

@media only screen and (max-width: 920px) {
  .education-option-component.leftMobile {
    width: 100%;
    margin-top: 12px;
  }
  .education-option-component.rightMobile {
    margin-left: 10px;
    width: 100%;
  }
  .education-option-component.centerMobile {
    margin-left: 12px;
    width: 100%;
  }
}
.education-option-component.right {
  margin-right: 12px;
}

.education-option-component.center {
  margin-top: 24px;
  height: calc(100% - 24px);
  margin-right: 12px;
}

.education-option-component .education-option-image-div {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 16px 16px 16px 16px;
}

.education-option-image-div .education-option-image {
  cursor: pointer;
  object-fit: scale-down;
  width: 100%;
}

.education-option-component .education-option-title {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin: 0px 16px 8px;
  line-height: 22px;
  width: fit-content;
}

.education-option-component .education-option-body {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0px 16px 16px 16px;
  line-height: 18px;
}
