.dropZone-Button-Main {
  display: flex;
  gap: 10px;
}
@media only screen and (max-width: 400px) {
  .dropZone-Button-Main {
    flex-direction: column;
  }
}
.scanbotErrorPopup .paperRoot {
  width: 413px;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .scanbotErrorPopup .paperRoot {
    margin: 0 4px 0 4px;
  }
}
