.confirmCodeSent {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #323234;
  margin-top: 24px;
}

.confirmCodeSentIntro {
  width: 379px;
  margin-top: 9px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #323234;
}

.submitCodeContainer {
  margin-top: 20px;
}

.submitCodeContainer .submitCode {
  width: 384px;
  text-transform: none;
}

.backlink {
  margin-top: 36px;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: blue;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: rgb(30, 100, 208);
  text-decoration-color: rgb(30, 100, 208);
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.codeinput {
  width: 390px;
}

.codeSentStatusToast {
  position: fixed;
  z-index: 1;
  left: 50;
  top: 0;
  margin-top: 6px;
}

.error-message-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cb0000;
  margin-top: 8px;
  display: block;
}

@media only screen and (max-width: 926px) {
}

@media only screen and (max-width: 600px) {
  .confirmCodeSentIntro {
    width: 350px;
  }
  .codeinput .MuiPaper-root {
    padding: 0;
  }
  .submitCodeContainer {
    width: 100%;
  }
  .submitCodeContainer .submitCode {
    width: 100%;
  }
  .codeinput {
    width: 100%;
  }
}
@media only screen and (max-width: 360px) {
  .confirmCodeSentIntro {
    width: 95%;
  }
}
