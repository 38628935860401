.manage-user-accounts-component-container {
  width: 100%;
}

.manage-user-accounts-component-container .manage-user-accounts-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  min-height: calc(100% - 32px);
  width: calc(100% - 72px);
}

.manage-user-accounts-component .manage-user-accounts-component-route-section {
  margin: 24px 24px 16px;
}

.manage-user-accounts-component .manage-user-accounts-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 24px 8px;
  width: calc(100% - 48px);
}

.manage-user-accounts-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  width: calc(100% - 48px);
}

.manage-user-accounts-component .short-form .manage-user-accounts-tabview-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.manage-user-accounts-component
  .short-form
  .manage-user-accounts-tabview-page
  .manage-user-accounts-tab-style-div {
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
}

.manage-user-accounts-tabview-page .manage-user-accounts-tab-grid-conatiner {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-user-accounts-tabview-page
  .manage-user-accounts-tab-grid-conatiner
  .manage-user-accounts-tab-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-user-accounts-component-container .manage-user-accounts-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.manage-user-accounts-loader-pop-up .manage-user-accounts-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

@media screen and (max-width: 920px) {
  .manage-user-accounts-component-container .manage-user-accounts-component {
    margin: 16px 16px 101px 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 450px) {
  .manage-user-accounts-component-container .manage-user-accounts-component {
    margin: 0px;
    width: 100%;
  }

  .manage-user-accounts-component
    .short-form
    .manage-user-accounts-tabview-page
    .manage-user-accounts-tab-style-div {
    height: 112px;
  }

  .manage-user-accounts-loader-pop-up .manage-user-accounts-loader {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}
