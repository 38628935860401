.inpatient-therapy-start-date-main-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}
.inpatient-therapy-start-date-main-div .inpatient-therapy-start-date-sub-div {
  width: 100%;
}
.inpatient-therapy-start-date-sub-div .therapy-start-date-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 20px;
  text-align: left;
}
.inpatient-therapy-start-date-sub-div .therapy-start-date-header-desc {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.therapyStartDate input.input {
  border-right: 0;
}

div.therapyStartDate {
  padding-right: 0;
}

.therapyStartDate .outline {
  border-radius: 2px;
}

.therapyStartDate.showError .outline {
  border: 1px solid #d32f2f;
}

.therapyStartDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

@media only screen and (max-width: 920px) {
}
