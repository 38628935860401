.search-user-table {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-user-table .search-user-table-container {
  border: 1px solid #e4e4e8;
  margin: 0px;
  min-height: 300px;
  overflow: auto;
  width: 100%;
}

.search-user-table-container .searchuser-table {
  border-spacing: 0;
  width: 100%;
}
.search-result-tables_users-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.search-result-tables_users-link-data {
  color: #0049bd;
  font-size: 13px;
  font-style: normal;
  font-weight: 200;
  line-height: 16px;
  text-decoration-line: underline;
  cursor: pointer;
}

.searchuser-table {
  width: 100%;
  border-spacing: 0;
}
.searchuser-table th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 2px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.searchuser-table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.searchuser-table tbody tr:first-child td {
  border-top: none;
}

.searchuser-table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.searchuser-table tbody tr:nth-child(2n) td {
  background: #fff;
}

.searchuser-table thead th:nth-child(1) {
  width: 25%;
  padding-left: 10px;
}

.searchuser-table thead th:nth-child(2) {
  width: 25%;
  padding: 0px 14px 0px 10px;
}

.searchuser-table thead th:nth-child(3) {
  width: 25%;
  padding: 0px 18px 0px 0px;
}

.searchuser-table thead th:nth-child(4) {
  width: 25%;
  padding: 0px 17px 0px 9px;
}

.searchuser-table th.up {
  background-image: url("../../../../../assets/up_arrow.svg");
}

.searchuser-table th.down {
  background-image: url("../../../../../assets/down_arrow.svg");
}

.searchuser-table th.default {
  background-image: url("../../../../../assets/default.svg");
}
.img {
  background-color: white;
}

.search-user-table .pagination-grid-container {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.search-user-table .pagination-grid-container .pagination-grid-item {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-title {
  margin: 10px 8px 10px 0px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  line-height: 16px;
  width: 70px;
}

.pagination-grid-item
  .items-per-page-dropdown
  .items-per-page-input
  .items-per-page-select {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: pre;
}

.pagination-grid-item .pagination-number-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 26px;
  margin: 4px 0px;
  width: 100%;
}

.pagination-grid-item .pagination-number-list button.disabled {
  color: grey;
}

.pagination-grid-item .pagination-number-list button {
  color: #0049bd;
}

.pagination-grid-item .pagination-total-counts {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination-grid-item .pagination-total-counts .last-section-right {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
}

@media only screen and (max-width: 600px) {
  .search-user-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
