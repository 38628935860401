.scanBotMainContainer {
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 100%;
}
.scanBot-Document-Scanner {
  height: 100%;
  width: 100%;
  margin: 0px;
}
.scanBotMainDiv {
  align-items: center;
  background: #e8f4ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 0px;
}

.imageContainer {
  display: flex;
  height: calc(100% - 88px);
  margin: 0px;
}

.scanBotMainContainer .scanBotFooterDiv {
  align-items: center;
  background: #e8f4ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin: 0px;
  padding: 4px 8px;
  width: calc(100% - 16px);
}

.scanBotTitle {
  color: var(--msdds-blue-70, #0049bd);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.scanBotPageDetail {
  color: var(--msdds-blue-70, #0049bd);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  cursor: pointer;
}
.scanBotFooterDiv .scanBot-Done-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.scanBot-ImageResult-Main {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}
.scanBot-ImageDetail {
  width: 100%;
  transform: none;
  object-fit: scale-down;
}

.scanBot-ImageDetail-Main {
  border: 8px solid var(--msdds-gray-00, #fff);
  background: var(--msdds-gray-10, #e4e4e8);
  /* Elevation 1 */
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.scanBot-ImageResult-Container .scanBot-Image-FooterDiv {
  background: var(--msdds-blue-05, #e8f4ff);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  padding: 4px 8px;
  height: 40px;
}

.scanBot-ImageResult-Delete-Container .scanBot-Image-delete-FooterDiv {
  background: var(--msdds-blue-05, #e8f4ff);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  gap: 8px;
  padding: 4px 8px;
  height: 46px;
  width: calc(100% - 16px);
}
.scanBot-Image-delete-FooterDiv .scanBot-Delete-Button {
  color: var(--msdds-red-70, #9c0000);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  border: 1px solid var(--msdds-red-70, #9c0000);
}

.scanBot-ImageResult-Delete-Container {
  overflow: scroll;
  width: 100%;
}

.scanBot-ImageResult-Container {
  overflow: scroll;
  width: 100%;
}

.scanBot-error-Message-popup .paperRoot {
  display: flex;
  max-width: 574px;
  width: 100%;
  padding: 16px 16px 40px 16px;
  height: 250px;
}
