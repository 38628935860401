div.admin-msg-container{
    margin-top: 22px;
}

.admin-msg-container .admin-msg-title{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #323234;
    margin-bottom: 8px;
}
.admin-msg-container .msg-input-label{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #323234;
}
.admin-msg-container .msg-txt-box{
    width: 100%;
}
.admin-msg-container .msg-limit{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #76767a;
    margin-top: 8px;
}
.admin-msg-container .captcha-container{
    margin-top: 50px;
    margin-bottom: 8px;
}