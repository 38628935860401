.salemanageuser-edit-container {
  width: 100%;
  margin-right: 35px;
}
.salemanageuser-edit-container .salemanageuser-edit-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
  padding-bottom: 60px;
}
.salemanageuser-edit-component .salemanageuser-edit-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}
.salemanageuser-edit-component .salemanageuser-edit-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 34px 0px;
}
.salemanageuser-edit-main-section .salemanageuser-edit-contactinfo {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #323234;
  margin: 12px 17px 10px;
}
.salemanageuser-edit-component .sale-manage-edit-component-route-section {
  margin: 26px 40px;
}
.error-popup {
  width: 450px;
  height: 188px;
}
.error-popup-msg {
  margin-top: 24px;
  padding: 0px 32px 0px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.error-popup-Closebtn {
  padding: 0px 16px;
  margin-top: 30px;
}
@media only screen and (max-width: 920px) {
  .salemanageuser-edit-container {
    overflow-x: hidden;
  }
  .salemanageuser-edit-container .salemanageuser-edit-component {
    margin: 0;
  }
  .salemanageuser-edit-component .sale-manage-edit-component-route-section {
    display: flex;
  }
}
