.footer-btn-group .footerMainDiv {
  position: relative;
}
.footer-btn-group .salesManageToolBar {
  width: 100%;
  padding: 0;
  min-height: 40px;
}

.salesManageUserFooterMaincontainer {
  width: 96%;
  flex-wrap: wrap;
  height: 40px;
}

.boxmanageSupply {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -8px;
}

.boxmanageSupply .cancelclass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration: underline;
  display: inline-block;
}
.manageSalesRightContainer {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  width: 40%;
}
.manageSalesLeftContainer {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  left: 15%;
  position: relative;
  width: 60%;
}
@media only screen and (max-width: 600px) {
  .footer-btn-group .salesManageToolBar {
    width: 100%;
  }
}
