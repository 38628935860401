.preferece {
  margin: 8px 0px 0px 0px;
  overflow: hidden;
  padding: 0px;
  width: 100%;
  margin-right: 16px;
  height: max-content;
}

.preferece-component {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  width: calc(100% - 8px);
}

.preferece-component .title {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 18px;
  margin: 0px;
  min-width: max-content;
  width: calc(100% - 130x);
}

.preferece-component .preference-root {
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 130px;
}

.preferece-component .preference-root-label-yes {
  box-sizing: border-box;
  height: 18px;
  margin: 0px;
  padding: 0px;
  width: 48px;
}

.preferece-component .preference-root-label-no {
  box-sizing: border-box;
  height: 18px;
  margin: 0px;
  padding: 0px;
  width: 40px;
}

.preferece-component .optiontxt {
  color: #18181a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
