.homecareprovider-search {
  padding: 16px;
}
.homecareprovider-search .homeCareProviderHeader {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.homecareprovider-search .nr-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 22px;
  margin-bottom: 10px;
}
.homecareprovider-payer-inputs {
  display: flex;
  margin-top: 24px;
}
.homecareprovider-search-input {
  width: 527px;
}
.homecareprovider-informantion-item {
  width: 100%;
  display: flex;
}
.home-care-provider-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;
}
.home-care-provider-search .home-care-provider-search-label {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
}
.search-homecareprovider-inputs .home-outlined-input-root {
  width: 100%;
  max-height: 40px;
  margin-right: 8px;
  max-width: 536px;
  margin-bottom: 12px;
}
.facilities-database-button-mobile-view {
  margin-top: 24px;
  text-align: end;
}
.facilities-database-button-mobile-view .facilities-database-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration-line: underline;
  text-transform: none;
}
.facilities-database-button-mobile-view .facilities-database-button:hover {
  text-decoration: underline;
}
.search-homecareprovider-inputs {
  margin: 0;
}
.search-homecareprovider-inputs .hcp-search-icon-div {
  height: 100%;
  border: 1px solid #e4e4e8;
  margin-bottom: 10px;
}
.hcp-search-icon-div .hcp-user-search-input {
  width: calc(100% - 57px);
}
.hcp-search-icon-div .hcp-search-icon {
  color: #76767a;
  height: 100%;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: -10px;
}
.filter-hcp-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  min-height: 40px;
  width: 100%;
  margin-right: 5px;
}
.search-homecareprovider-inputs .search-results-container {
  max-height: 220px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #ccccd0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  position: fixed;
  max-width: 506px;
  width: 100%;
  z-index: 55;
  margin: 0px 16px;
}
.txtAlign {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
}
.search-results-container {
  max-height: 220px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #ccccd0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  position: fixed;
  max-width: 506px;
  width: 100%;
  z-index: 55;
  margin: 0px 16px;
}
.search-results-container .no-results {
  max-width: 568px;
  min-height: 18px;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  cursor: pointer;
}
.search-results-container .no-results .no-results-title {
  color: #cb0000;
  cursor: default;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 8px 6px 8px;
}
.search-results-container .no-results .no-results-would-you-like-to {
  cursor: default;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.search-results-container
  .no-results
  .no-results-would-you-like-to
  .home-care-provider-add-new-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-height: 18px;
  line-height: 18px;
  padding: 4px 8px 6px 8px;
  text-decoration: underline;
  text-transform: none;
}
.search-results-container .search-results {
  align-items: center;
  cursor: pointer;
  display: flex;
  max-width: 568px;
  min-height: 18px;
  padding: 10px 16px;
}

.home-care-provider-name-list {
  background-color: white;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}
.dropDown-home-care-provider-name-list {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.dropDown-home-care-provider-city-state-facility-type-list {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.home-care-provider-name-div {
  margin-right: 8px;
}

.home-care-provider-second-list {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
}
.homecareErrorPopup .paperRoot {
  width: 444px;
  height: auto;
}
@media screen and (max-width: 920px) {
  .homecareprovider-search {
    padding: 16px;
  }
  .home-care-provider-search {
    margin-top: 12px;
    flex-direction: row;
    gap: 30%;
  }
  .home-care-provider-search .home-care-provider-search-label {
    margin: 0px 0px 3px 0px;
  }
  .search-results-container .no-results .no-results-would-you-like-to {
    margin-bottom: 2px;
    display: contents;
  }
  .search-results-container
    .no-results
    .no-results-would-you-like-to
    .home-care-provider-add-new-button {
    padding: 7px 8px 6px 0px;
  }
  .search-results-container .no-results {
    flex-direction: column;
    padding: 0px;
  }
}

@media only screen and (max-width: 720px) {
  .homecareErrorPopup .paperRoot {
    margin: 0 4px 0 4px;
  }
}
@media only screen and (max-width: 550px) {
  .search-homecareprovider-inputs .search-results-container {
    width: calc(100% - 32px);
    margin-left: 0px;
  }
}
@media only screen and (max-width: 450px) {
  .home-care-provider-search {
    width: 150px;
  }
  .facilities-database-button-mobile-view-grid {
    align-items: flex-end;
  }
  .homecareprovider-informantion-item .mobile-view-alignment {
    text-align: -webkit-right;
  }
  .facilities-database-button-mobile-view {
    width: 150px;
  }
}
@media only screen and (min-width: 450px) and (max-width: 920px) {
  .facilities-database-button-mobile-view {
    margin-top: 12px;
  }
}
