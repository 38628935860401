.edit-facility-component {
  display: flex;
  flex-direction: column;
  margin: 16px 16px 8px;
  width: calc(100% - 32px);
}

.edit-facility-component .edit-facility-header {
  color: #323234;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  text-align: left;
}

.edit-facility-component .facility-details-grid-container {
  background-color: #f8f8fa;
  border: 1px solid #f2f2f5;
  margin: 24px 0;
  padding: 16px;
  width: 100%;
}

.facility-details-grid-container .facility-details-grid-item {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-details-grid-item .facility-name {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 0px;
}

.facility-details-grid-item .facility-address-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.facility-details-grid-item .facility-address-div .facility-address-value {
  color: #76767a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.facility-details-grid-item .facility-siteUseId-div {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 0px;
  width: 100%;
}

.facility-details-grid-item .facility-siteUseId-div .facility-siteUseId-title {
  color: #76767a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.facility-details-grid-item .facility-siteUseId-div .facility-siteUseId-value {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.edit-facility-component .edit-facility-sub-header {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
}

.edit-facility-component .permissions-count {
  color: #76767a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.edit-facility-component .site-access-status-role-grid-container {
  margin: 0px 0px 24px;
  padding: 0px;
  width: 100%;
}

.edit-facility-component
  .site-access-status-role-grid-container
  .site-access-status-role-grid-item {
  margin: 8px 0;
  padding: 0px;
  width: 100%;
}

.site-access-status-role-grid-item .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.site-access-status-role-grid-item .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.site-access-status-role-grid-item .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.site-access-status-role-grid-item .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.site-access-status-role-grid-item .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.site-access-status-role-grid-item .site-access-status-role-title {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.site-access-status-role-grid-item .site-access-status-role-input {
  height: 42px;
  width: 100%;
}

.site-access-status-role-grid-item
  .site-access-status-role-input
  .site-access-status-role-select {
  align-items: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.site-access-status-role-grid-item
  .site-access-status-role-select.place-holder {
  border-radius: 2px;
  background-color: #f2f2f5;
  color: #76767a;
  width: 100%;
}

.site-access-status-role-grid-item
  .site-access-status-role-select.place-holder.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #76767a;
}

.site-access-status-role-grid-item .place-holder .selectIcon {
  top: 10px;
}

.edit-facility-component .site-access-status-title {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0;
  white-space: break-spaces;
}

.edit-facility-component .site-access-permissions {
  width: 100%;
}

.edit-facility-component
  .site-access-permissions
  .site-access-permissions-categories {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  width: 100%;
}

.edit-facility-component
  .site-access-permissions
  .site-access-permissions-categories
  .site-access-permission-category {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.edit-facility-component
  .site-access-permissions
  .site-access-permissions-categories
  .site-access-permission-category
  .site-access-permission-category-name {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0px 0px;
  text-align: left;
}

.site-access-permissions-categories
  .site-access-permission-category
  .facility-permissions-grid-container {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.edit-facility-component
  .site-access-permissions
  .facility-permissions-grid-container
  .facility-permissions-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-permissions-grid-item
  .facility-permissions-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: 10px;
  width: max-content;
}

.facility-permissions-grid-item
  .facility-permissions-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  margin-top: 10px;
  width: max-content;
}

.edit-facility-component .edit-facility-buttons-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.edit-facility-component
  .edit-facility-buttons-grid-container
  .edit-facility-buttons-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.edit-facility-buttons-grid-item .cancelBtn {
  border-radius: 0px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 16px 8px 16px 0px;
  text-align: center;
  text-transform: none;
  width: calc(100% - 8px);
}

.edit-facility-buttons-grid-item .updateBtn {
  border-radius: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 16px 0px 16px 8px;
  text-align: center;
  text-transform: none;
  width: calc(100% - 8px);
}

.edit-facility-buttons-grid-item .updateBtn.enabled {
  background-color: #0049bd;
  color: #ffffff;
}

.edit-facility-buttons-grid-item .updateBtn.disbaled {
  background-color: #e4e4e8;
  color: #76767a;
}

@media screen and (max-width: 430px) {
  .edit-facility-component {
    overflow: scroll;
  }

  .edit-facility-component
    .site-access-permissions
    .site-access-permissions-categories {
    margin: 16px 0px 8px;
  }

  .edit-facility-component
    .site-access-permissions
    .facility-permissions-grid-container {
    margin: 8px 0px;
  }
}
