.inpatient-order-error-popup .inpatient-order-error-popup-header {
  font-size: 20px;
  line-height: 28px;
  color: #323234;
  font-weight: 700;
  width: 85%;
}

.inpatient-order-error-popup .inpatient-order-error-popup-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 12px 0px;
}

.inpatient-order-error-popup .inpatient-order-error-popup-description2 {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  padding: 8px 0px 12px 0px;
  width: 100%;
}

.inpatient-order-error-popup .inpatient-order-error-popup-btn1 {
  width: 100%;
  margin: 4px 0px;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
}

.inpatient-order-error-popup .inpatient-order-error-popup-btn2 {
  width: 100%;
  margin: 4px 0px;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
  color: #0049bd;
}

@media screen and (max-width: 920px) {
  .inpatient-order-error-popup .paperRoot {
    max-width: 450px;
  }
}
