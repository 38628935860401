.search-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.search-field-container .search-by-name-name {
  width: 100%;
}

.search-by-name-name .search-facility-title {
  margin: 0px;
}
.search-field-container .search-by-name-zipcode {
  width: 100%;
}

.search-field-container .search-by-name-submit {
  width: 100%;
}

.search-field-container .search-button {
  width: 100%;
  margin-top: 16px;
}

.facility-search-manually-input {
  width: 100%;
  height: 40px;
}

.facility-search-manually-input .facility-state-select {
  height: 20px !important;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.selectFacilityContainer {
  margin-top: 10px;
}

.selectFacilityContainer .search-by-name-name {
  width: 100%;
  max-width: 279px;
}

.selectFacilityContainer .search-by-name-zipcode {
  width: 100%;
  max-width: 160px;
}

.selectFacilityContainer .search-by-name-submit {
  width: 100%;
  max-width: 74px;
}

.facility-state-input {
  height: 42px;
}

.facility-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.facility-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.facility-state-input .facility-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
@media only screen and (max-width: 600px) {
  .search-field-container .search-by-name-zipcode {
    margin-top: 10px;
  }
}
