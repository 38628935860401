.sales-rounding-list-page {
  width: 100%;
  min-height: 600px;
}
.sales-rounding-list-page .sales-rounding-facility-name-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sales-rounding-list-page
  .sales-rounding-facility-name-div
  .sales-rounding-facility-name-header {
  font-size: 20px;
  font-weight: 400px;
}
.sales-rounding-list-page
  .sales-rounding-facility-name-div
  .sales-rounding-facility-name-header
  .sales-rounding-facility-name-header-bold {
  font-weight: bold;
}
.sales-rounding-list-page .sales-rounding-change-teritory-button {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 8px;
  text-transform: none;
  text-decoration: underline;
  margin-left: 30px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
}

.sales-rounding-list-page .sales-rounding-tool-back-button {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  color: #0049bd;
  text-transform: none;
  font-weight: 400;
  margin-right: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 12px 0;
}
.sales-rounding-list-page .sales-rounding-full-screen-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: calc(20% - 20px);
}
.sales-rounding-search-input {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin-top: 24px;
  width: 100%;
}
.sales-rounding-search-input .search-icon-div {
  height: 100%;
  margin: 0;
}

.sales-rounding-search-input .search-icon-div .territory-search-icon {
  color: #76767a;
  margin: 8px;
}
.sales-rounding-search-input .user-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.sales-rounding-facility-list-div
  .sales-rounding-table-section
  .sales-rounding-card-view {
  width: 100%;
}
.sales-rounding-card-view .sales-rounding-grid-container {
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #e4e4e8;
}
.sales-rounding-grid-container
  .sales-conatiner-grid-item-facility-name-favourite {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sales-conatiner-grid-item-facility-name-favourite
  .sales-rounding-facility-name {
  color: #0049bd;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}
.sales-conatiner-grid-item-facility-name-favourite .sales-rounding-favorite {
  cursor: pointer;
}
.sales-rounding-list-page
  .sales-rounding-change-teritory-button
  .change-sales-territory-icon {
  margin-bottom: 10px;
}
.sales-rounding-facility-list-div .sales-rounding-mobile-view {
  position: sticky;
  background: white;
  padding-bottom: 20px;
  top: 0;
}
.sales-rounding-card-view .sales-rounding-no-facilities {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin: 40px;
  width: calc(100% - 80px);
}
.sales-rounding-list-page .change-sales-territory-popup-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: calc(20% - 20px);
}
@media screen and (max-width: 768px) {
  .sales-rounding-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .sales-rounding-list-page .sales-rounding-facility-name-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .sales-rounding-list-page .sales-rounding-change-teritory-button {
    margin: 10px 0px 0px 0px;
    display: flex;
    align-items: center;
  }
  .sales-rounding-list-page
    .sales-rounding-facility-name-div
    .sales-rounding-facility-name-header {
    font-size: 28px;
  }
  .sales-rounding-facility-list-div .sales-rounding-mobile-view {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 8px 16px 8px;
  }
}
