.woundInfoDR {
  margin-top: 40px;
}
.woundInfoDR .woundInfoDRTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.woundInfoDR .woundInfoDRDesp {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.woundInfoDR .woundInfoDRDate {
  margin-top: 15px;
}
.woundInfoDR .woundInfo1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 10px;
}
.woundInfoDR .woundInfo2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 20px;
}

.woundInfoDR .woundInfo-rowDiv {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
}
.woundInfoDR .woundInfo-finalD {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.woundInfoDR .dimension-box-container {
  margin-top: 12px;
  width: 100%;
}

.woundInfoDR .dimension-grid-container {
  width: 100%;
}

.woundInfoDR .woundMeasuredata-box-container {
  width: 97%;
}

.woundInfoDR .woundMeasure-grid-container {
  width: 106%;
}

.woundMeasure-grid-container .woundMeasure-grid-item {
  width: 100%;
}
.woundMeasure-grid-item .stopBillDate {
  color: #94949a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.woundMeasure-grid-item .stopBillDate input.input {
  background: #f2f2f5;
  border-right: 0;
}

.woundMeasure-grid-item div.stopBillDate {
  padding-right: 0;
}

.woundMeasure-grid-item .stopBillDate .outline {
  border-radius: 2px;
}

.woundMeasure-grid-item .stopBillDate.showError .outline {
  border: 1px solid #d32f2f;
}

.woundMeasure-grid-item .stopBillDate.noError.Mui-error .outline {
  border: 1px solid #b4b4b8;
}

.woundInfoDR .depth-input {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .woundInfoDR .woundInfo-rowDiv {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    gap: 9px;
  }
  .woundInfoDR .woundInfo-finalD {
    margin-top: 9px;
  }
  .woundInfoDR .woundMeasuredata-box-container {
    margin-top: 9px;
  }
}
