.national-registry-result-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
}

.national-registry-result-component .national-registry-result-header {
  color: #323234;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
  width: 100%;
}

.national-registry-result-component .filter-prescriber-div {
  border: 1px solid #94949a;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: 16px 0px;
  width: 100%;
}

.national-registry-result-component .filter-prescriber-div .search-icon {
  height: 24px;
  margin: 8px 10px;
  width: 24px;
}

.national-registry-result-component
  .filter-prescriber-div
  .filter-prescriber-input {
  width: 100%;
}
