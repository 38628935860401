.role-permissions-component {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 40px;
  padding: 0px;
  width: calc(100% - 40px);
}

.role-permissions-component .role-permissions-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.role-permissions-component
  .role-permissions-grid-container
  .role-permissions-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.role-permissions-component .role-permissions-category-div {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.role-permissions-component
  .role-permissions-category-div
  .role-permission-category-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: left;
}

.role-permissions-component
  .role-permissions-category-div
  .role-permission-checkbox-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.role-permission-checkbox-div .facility-permissions-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: 8px;
  min-width: 200px;
  width: max-content;
}

.role-permission-checkbox-div .facility-permissions-checkbox-root {
  color: #0049bd;
}

.role-permission-checkbox-div .facility-permissions-checkbox {
  color: #0049bd;
}

.role-permission-checkbox-div
  .facility-permissions-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  margin-top: 8px;
  min-width: 200px;
  width: max-content;
}

.role-permission-checkbox-div .roles-permission-sub-div-desc {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 40px;
}

@media only screen and (max-width: 926px) {
  .role-permissions-component {
    flex-direction: column;
  }

  .role-permissions-component .role-permissions-category-div {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .role-permissions-component
    .role-permissions-category-div
    .role-permission-category-title {
    font-size: 16px;
    line-height: 20px;
  }
}
