.question-container .outline {
  border-radius: 2px;
}

.question-container .dateQuestionRoot.showError .outline {
  border: 1px solid #d32f2f;
}

.question-container .dateQuestionRoot.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.radio-question-label {
  margin-top: 0px;
  white-space: normal;
}

@media only screen and (max-width: 330px) {
  .radio-question-label {
    margin-top: -8px;
  }
}
