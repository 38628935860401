.missing-rx-fax-in-later-sent {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.description-missingRxFaxSent {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
}

.description-missingRxFaxSent .bold {
  font-weight: 700;
}

.missing-rx-fax-in-later-sent .changePres-title-msg-div {
  display: flex;
  flex-direction: row;
  margin: 0 0 8px;
  width: 100%;
}

.changePres-title-msg-div .changePres-title-msg {
  color: #323234;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: auto 8px auto 0px;
  width: 100%;
}

.changePres-title-msg-div .button-printrx-change-prescription-rx {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  justify-content: center;
  height: 32px;
  line-height: 16px;
  margin: auto 0px;
  padding: 0px;
  text-transform: none;
  width: 130px;
}

.missing-rx-fax-in-later-sent .fax-detail {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 68px;
  margin: 8px 0px 16px;
  padding: 8px;
  text-align: center;
}

.missing-rx-fax-in-later-sent .fax-detail .fax-detail-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
}

.missing-rx-fax-in-later-sent .fax-detail .fax-detail-div .fax-info-icon {
  margin: 0px 4px;
  width: fit-content;
}

.missing-rx-fax-in-later-sent .fax-detail .fax-detail-div .fax-detail-text {
  color: #0049bd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
}

.missing-rx-fax-in-later-sent
  .fax-detail
  .fax-detail-div
  .fax-detail-text
  .fax-phone-number {
  margin-left: 0px;
  white-space: nowrap;
}

.missing-rx-fax-in-later-sent .done-button {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  height: 40px;
  line-height: 18px;
  margin: auto;
  text-transform: none;
  width: 100%;
}

@media only screen and (max-width: 430px) {
  .missing-rx-fax-in-later-sent .fax-detail .fax-detail-div .fax-detail-text {
    width: 175px;
  }
}
