.location-of-vac-therapy {
  margin-top: 40px;
}
.location-of-vac-therapy .location-of-vac-therapy-heading {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  width: 100%;
}
.location-of-vac-therapy .location-of-vac-therapy-heading2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.location-of-vac-therapy .drop {
  min-width: 100%;
  margin-top: 16px;
}
.location-of-vac-therapy .vac-therapy-container-grid {
  margin-top: 6px;
}
.location-of-vac-therapy .test-vac-therapy-location {
  padding-top: 4px;
}
.location-of-vac-therapy .vac-loc-other-detail-container {
  width: 100%;
  margin-top: 16px;
}
.location-of-vac-therapy .vac-therapy-loc-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}
.vac-therapy-loc-input {
  width: 100%;
}
.drop-down-values {
  max-height: 316px;
  width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  overflow: auto;
}
.vac-therapy-loc-other-input {
  width: 100%;
}
