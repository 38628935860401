.return-stored-product-popup {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.return-stored-product-popup .paperRoot {
  width: 486px;
}

.return-product-details-component {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  min-height: 100px;
  margin-top: 12px;
}

.return-product-details-component .return-product-details-grid-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin-left: 0px;
  margin-top: 3px;
}

.return-product-details-component
  .return-product-details-grid-container
  .return-product-details-grid-item {
  display: flex;
  padding-top: 9px;
  width: 100%;
}

.return-product-details-component .return-product-name {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.return-product-details-component .return-product-serial {
  margin-top: 0px;
}

.return-product-details-component .current-location {
  width: 100%;
  margin-top: 0px;
}

.return-product-details-component .product-name {
  margin-bottom: 16px;
  margin-left: 8px;
}

.return-product-details-component .therapy-start-date {
  margin-bottom: 16px;
}

.return-product-details-component .return-product-details-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.return-product-details-component .return-product-details-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.return-product-details-component .return-product-current-loc-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 500px) {
  .return-product-details-component {
    width: 100%;
  }
  .return-product-details-component
    .return-product-details-grid-container
    .return-product-details-grid-item {
    padding-top: 4px;
  }
  .return-product-details-component .return-product-details-grid-container {
    margin-top: 9px;
  }
  .return-product-details-component .return-product-current-loc-value {
    width: 95%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 319px) and (max-width: 418px) {
  .return-product-details-component {
    width: 100%;
  }
}
