.peel-and-place-dressing-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.peel-and-place-dressing-component .peel-and-place-dressing {
  border: none;
  display: flex;
  flex-direction: row;
  height: fit-content;
  margin: 0px;
  padding: 16px 0px;
  width: 100%;
}

.peel-and-place-dressing-component .peel-and-place-dressing.warning {
  border: 1px solid #cb0000;
  padding: 16px 8px;
  width: calc(100% - 16px);
}

.peel-and-place-dressing-component .peel-and-place-dressing .warning-icon {
  color: #1e64d0;
  height: 16px;
  margin: 0px 4px auto 0px;
  width: 16px;
}

.peel-and-place-dressing-component .peel-and-place-dressing .warning-icon.red {
  color: #cb0000;
}

.peel-and-place-dressing-component
  .peel-and-place-dressing
  .peel-and-place-message {
  color: #1e64d0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 0px;
}

.peel-and-place-dressing-component
  .peel-and-place-dressing
  .peel-and-place-message.red {
  color: #cb0000;
}

.peel-and-place-dressing-component
  .peel-and-place-dressing
  .peel-and-place-message
  .peel-and-place-message-link-text {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.peel-and-place-dressing-component .peel-and-place-instruction-div {
  align-items: center;
  background-color: #e8f4ff;
  border: 1px solid #abd1fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.peel-and-place-dressing-component
  .peel-and-place-instruction-div
  .peel-and-place-instruction-image {
  min-height: 150px;
  max-height: 100%;
  object-fit: scale-down;
  max-width: 100%;
}

.peel-and-place-dressing-component .peel-and-place-skeleton-loader-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.peel-and-place-dressing-component
  .peel-and-place-skeleton-loader-div
  .peel-and-place-skeleton-loader {
  margin: 4px 0px;
  height: 24px;
  width: 100%;
}
