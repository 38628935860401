.sso-side-nav .sso-side-nav-paper-props {
  background-color: #fff;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  max-height: 316px;
  overflow: auto !important;
  width: 150px;
  z-index: 0;
}

.sso-side-nav .sso-side-name-menu-input {
  background-color: #fff;
  border-radius: 2px;
  margin: 4px 16px 8px;
  height: 42px;
  width: calc(100% - 32px);
}

.sso-side-nav .sso-side-name-menu-input .sso-side-name-menu-select {
  align-items: center;
  background-color: #fff;
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  width: calc(100% - 32px);
}

.sso-side-nav .arrow-dropdown-icon {
  top: calc(50% - 10px);
}

.sso-side-nav-paper-props .sso-side-name-menu-option {
  white-space: break-spaces;
}

.sso-side-nav-paper-props .sso-side-name-menu-option:hover {
  background-color: #e8f4ff;
}

.sso-side-nav-paper-props .sso-side-name-menu-option:focus {
  background-color: #e8f4ff;
}

.selectFacility-container .sso-side-nav .sso-side-nav-menu-input-label {
  justify-content: center;
  height: 40px;
  margin: 0px !important;
  padding: 0px;
}
.ssoSideNav-main-pop-up .paperRoot {
  margin: 8px;
  width: 50%;
}
.searchHomeCareOrderFound .paperRoot {
  margin: 8px;
  width: 50%;
}
.searchHomeCareOrderNotFound .paperRoot {
  margin: 8px;
  width: 45%;
  padding: 0px 12px 12px 12px;
}
.searchHomeCareOrderForm .paperRoot {
  margin: 8px;
  width: 50%;
}
.searchHomeCareOrderLoader .paperRoot {
  width: 25%;
  height: 300px;
}
@media only screen and (max-width: 920px) {
  .ssoSideNav-main-pop-up .paperRoot {
    margin: 8px;
    width: 80%;
  }
}
@media only screen and (max-width: 620px) {
  .searchHomeCareOrderFound .paperRoot {
    width: 100%;
  }
  .searchHomeCareOrderNotFound .paperRoot {
    width: 100%;
  }
  .searchHomeCareOrderForm .paperRoot {
    width: 100%;
  }
  .searchHomeCareOrderLoader .paperRoot {
    width: 80%;
  }
}
