.pickup-location-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  width: 100%;
}

.pickup-location-component .pickup-location-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.pickup-location-div {
  margin-top: 20px;
}

.pickup-location-component .pickup-location-div .other-location-input {
  width: 70%;
}

.pickup-location-component .pickup-location-div .pickup-location-select {
  align-items: center;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 12px;
  line-height: 22px;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}
.pickup-location-component .pickup-location-input-div {
  width: 70%;
}

@media screen and (max-width: 926px) {
  .pickup-location-component .pickup-location-input-div {
    width: 100%;
  }
  .pickup-location-component .pickup-location-div .other-location-input {
    width: 100%;
  }
}
