.supply-order-summary-container {
  margin: 16px 120px 16px 16px;
  align-items: flex-start;
  padding: 5px 40px 40px 40px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.supply-order-summary-header {
  margin-bottom: 27px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.supply-order-review-title {
  align-self: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.supplyOrderBtnDiv .supplyOrderBtn {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
}
@media screen and (max-width: 920px) {
  .supply-order-summary-container {
    margin: 0;
  }

  .progressGridMain {
    width: 100%;
  }
  .supply-order-summary-container {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 600px) {
  .supply-order-summary-container {
    overflow-x: hidden;
  }
}
@media print {
  .supply-order-summary-header .supplyOrderBtnDiv {
    display: none;
  }
  .orderSummaryForm {
    gap: 40px;
    margin-top: 10px;
    width: 100%;
  }
}
