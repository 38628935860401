.SSobuttonCss {
  width: 200px;
  margin-left: 600px !important;
}

.ssoNew-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.selectfacility-main {
  min-height: calc(100vh - 144px);
  overflow-x: hidden;
}
.selectFacility-container {
  padding: 4px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.selectfacility-main .selectFacility-container {
  padding: 4px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.selectFacility-container .selectFacility-subMain {
  align-items: flex-start;
  background-color: #fff;
  padding: 40px 40px 64px;
}

.selectFacility-subMain .selectfacility-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.selectFacility-container .sso-side-nav {
  align-items: flex-start;
  background-color: unset;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.selectFacility-subContainer {
  width: 100%;
}
.multigroupsClass {
  width: 100%;
}
.multigroupsMainClass {
  margin: 20px;
  height: 200px;
}
.multigroupsMainClass .submitGroupBtn {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}
.alertInPageContainerInSSORedirect {
  margin-left: 2px;
}
.alertInPageContainerInSSORedirect .site-status-banners-component {
  margin-top: 2px;
  margin-bottom: 0px;
}
.ssoNew-spinner-popup .paperRoot {
  height: 260px;
  width: 400px;
}
@media only screen and (max-width: 540px) {
  .alertInPageContainerInSSORedirect {
    margin: 0px 2px 2px 2px;
  }
  .ssoNew-spinner-popup .paperRoot {
    width: 96%;
    height: 30%;
  }
}

.errorPopupSSOSiteStatus .paperRoot {
  width: 386px;
  height: auto;
}

@media only screen and (max-width: 414px) {
  .errorPopupSSOSiteStatus .paperRoot {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 920px) {
  .selectfacility-main .selectFacility-container {
    padding: unset;
    display: flex;
    flex-direction: column-reverse;
    gap: unset;
  }

  .selectFacility-container .selectFacility-subMain {
    padding: 16px;
  }

  .selectFacility-container .sso-side-nav {
    background-color: #f2f2f5;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 59px;
    margin: 4px 0;
  }
}

@media only screen and (min-width: 920px) and (max-width: 1250px) {
  .selectFacility-subContainer {
    width: 80%;
  }
}
@media only screen and (max-width: 920px) {
  .selectFacility-container {
    padding: unset;
    display: unset;
    flex-direction: unset;
    gap: unset;
  }
}
.main-popup .paperRoot {
  margin: 8px;
}
