.acute-pick-up-request-container-componenet {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.acute-pick-up-request-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.acute-pick-up-request-loader-pop-up .acute-pick-up-request-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 16px auto;
  width: 400px;
}

@media only screen and (max-width: 920px) {
  .acute-pick-up-request-container-componenet {
    margin: 0;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .acute-pick-up-request-loader-pop-up .acute-pick-up-request-loader {
    margin: 16px;
    max-width: 400px;
    min-width: 260px;
    width: calc(100% - 32px);
  }
}
