.requested-sites-component {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.requested-sites-component .requested-sites-component-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.requested-sites-component
  .requested-sites-component-header
  .requested-sites-component-header-title {
  color: #323234;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0px;
}

.requested-sites-component
  .requested-sites-component-header
  .requested-sites-component-header-description {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px;
}

.requested-sites-component
  .requested-sites-component-header
  .requested-sites-component-header-description
  .bold {
  font-weight: 700;
}
.requested-sites-component .unlinked-facilities-table-div {
  border-spacing: 0;
  border: 1px solid #e4e4e8;
  margin: 0px 0px 40px;
  width: 100%;
}

.requested-sites-component
  .unlinked-facilities-table-div
  .unlinked-facilities-table {
  border-spacing: 0;
  width: 100%;
}

.unlinked-facilities-table-users-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.requested-sites-component
  .unlinked-facilities-table-div
  .unlinked-facilities-table
  .unlinked-facilities-tables-link-to-facility {
  color: #cb0000;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: none;
}

.requested-sites-component
  .unlinked-facilities-table-div
  .unlinked-facilities-table
  .unlinked-facilities-tables-link-to-facility.disabled {
  color: #949ab6;
}

.requested-sites-component
  .unlinked-facilities-table-div
  .unlinked-facilities-table
  .unlinked-facilities-tables-data {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.unlinked-facilities-table-div .unlinked-facilities-table th {
  background: #fff;
  border-right: 15px solid white;
  color: #76767a;
  font-weight: bold;
  font-weight: 400;
  font-size: 14px;
  height: 16px;
  line-height: 18px;
  padding: 12px 8px;
  position: sticky;
  text-align: left;
  top: 0;
}

.unlinked-facilities-table-div .unlinked-facilities-table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  tbody
  tr:first-child
  td {
  border-top: none;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  tbody
  tr:nth-child(n)
  td {
  background: #f8f8fa;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  tbody
  tr:nth-child(2n)
  td {
  background: #fff;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  thead
  th:nth-child(1) {
  min-width: 150px;
  width: 60%;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  thead
  th:nth-child(2) {
  min-width: 100px;
  width: 25%;
}

.unlinked-facilities-table-div
  .unlinked-facilities-table
  thead
  th:nth-child(3) {
  min-width: 100px;
  width: 15%;
}

.unlinked-facilities-table-div
  .requested-sites-component
  .facilityDetails
  .facilityName {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 11px;
}

.requested-sites-component .facilityDetails .facilityAddress {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@media only screen and (max-width: 600px) {
  .requested-sites-component {
    width: 100%;
  }
}
