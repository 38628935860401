.missingRxEPrescription {
  padding: 2px 16px;
}

.description-missing-rx {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.patientNamespan {
  text-transform: capitalize;
}

.missingRxEPrescription .descp-text-link {
  display: inline-flex;
  margin-top: 16px;
  width: 100%;
}

.missingRxEPrescription .descp-text-link .descp-text {
  color: #323234;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  order: 1;
  width: 50%;
}

.missingRxEPrescription .descp-text-link .descp-link {
  color: #0049bd;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  order: 2;
  text-align-last: end;
  text-decoration-line: underline;
  width: 50%;
}

.title-text {
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.title-msg {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

.change-e-prescription {
  display: flex;
  margin-top: 20px;
  margin-left: -30px;
  width: calc(100% + 30px);
}

@media only screen and (max-width: 568px) {
  .missingRxEPrescription .descp-text-link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .missingRxEPrescription .descp-text-link .descp-text {
    order: 2;
    width: 100%;
  }

  .missingRxEPrescription .descp-text-link .descp-link {
    order: 1;
    text-align-last: left;
    width: 100%;
  }
}
