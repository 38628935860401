.exchange-vac-unit {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 16px 115px 16px;
  width: calc(100% - 120px);
  min-height: 363px;
}
.submitapi-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}
.exchange-vac-unit .exchange-vac-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.exchange-vac-unit .exchange-vac-route-section {
  margin: 40px auto 26px 40px;
}

.exchange-vac-unit .exchange-vac-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px 16px;
  width: 100%;
}

.exchange-vac-unit .exchange-short-form {
  display: flex;
  flex-direction: column;
  width: 62%;
}

.exchange-short-form .exchange-vacheadernote {
  margin-bottom: 30px;
  margin-left: 40px;
  width: 100%;
}
.exchange-vacheadernote .sn-sec-div {
  margin-top: 35px;
}
.sn-sec-div .confirm-the-placement-sn-sec {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.sn-sec-div .current-sn-div {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.current-sn-div .current-sn-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.current-sn-div .current-sn-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}
.exchange-vacheadernote .title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 0px;
}

.exchange-vacheadernote .description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
  margin-top: 0px;
}

.exchange-short-form .exchange-vac-contact-reason-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

.exchange-short-form
  .exchange-vac-contact-reason-div
  .exchange-vac-contact-reason {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.exchange-short-form .exchange-vac-input {
  width: 100%;
}

.exchange-short-form .exchange-vac-input .exchange-vac-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.exchange-short-form div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.exchange-short-form .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.exchange-vac-unit .change-address-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.exchange-vac-unit .transfer-patient-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.exchange-vac-unit .ask-question-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.exchange-vac-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.exchange-vac-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.exchange-vac-loader-pop-up .exchange-vac-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.exchange-vac-failure-pop-up .paperRoot {
  width: 450px;
}
.exchange-vacheadernote .patient-details-component {
  min-height: 150px;
}
@media only screen and (max-width: 920px) {
  .exchange-vac-unit {
    margin: 0px;
    width: 100%;
  }
  .exchange-vac-unit .exchange-short-form {
    width: calc(100% - 25px);
  }
  .exchange-short-form .exchange-vacheadernote {
    margin-left: 10px;
  }
  .exchange-vac-unit .exchange-vac-route-section {
    margin: 0px 0px 17px;
    padding: 40px 0px 10px 16px;
  }
  .exchange-vacheadernote .title {
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .exchange-vac-unit .exchange-vac-loader {
    margin: auto;
  }
  .exchange-vac-failure-pop-up .paperRoot {
    margin: 12px;
  }
}
