.pod-fax-main {
  width: 100%;
}
.pod-fax-container {
  width: 100%;
  height: auto;
  margin-top: 12px;
  margin-left: 37px;
}
.pod-fax-msg {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
  color: #323234;
}
.fax-msg {
  margin-left: 5px;
}

.pod-fax-main .borderButton {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  text-transform: none;
  margin-left: 16px;
}
.pod-fax-main .borderButton-static-text {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  justify-content: center;
  height: 57px;
  margin: auto;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 93%;
  margin-top: 18px;
  margin-left: 5px;
}
.static-text {
  margin-top: 3px;
}
.pop-up {
  width: 200px;
  height: 56px;
}

.pod-fax-main .print-pod-pdf-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.print-pod-pdf-failure-pop-up .print-pod-pdf-failure-pop-up-div {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 435px;
}

@media only screen and (max-width: 600px) {
  .pod-fax-container {
    margin-left: 0px;
  }
  .pod-fax-main .borderButton-static-text {
    height: 65px;
    width: 100%;
  }
  .fax-msg {
    margin: 0;
  }
  .pod-fax-main .borderButton {
    margin-top: 15px;
    font-size: 8px;
    font-weight: 700;
    margin-left: 0px;
    width: 100%;
    padding: 10px;
  }
  .pod-fax-main .print-pod-pdf-failure-pop-up {
    margin: auto 16px;
    width: calc(100% - 32px);
  }

  .print-pod-pdf-failure-pop-up .print-pod-pdf-failure-pop-up-div {
    margin: auto 16px;
    max-width: 405px;
    width: calc(100% - 32px);
  }
}
@media only screen and (max-width: 320px) {
  .pod-fax-main .borderButton-static-text {
    height: 85px;
  }
}
