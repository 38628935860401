.inpatient-supply-order-delivery-info-main-section {
  width: 76%;
}

.inpatient-supply-order-delivery-info-main-div {
  padding: 16px;
}
.inpatient-supply-order-delivery-info-main-div
  .inpatient-supply-order-delivery-info-card-header {
  font-size: 18px;
  font-weight: 700;
}
.inpatient-supply-order-delivery-info-main-div
  .inpatient-supply-order-delivery-info-card-sub-header {
  font-size: 14px;
  margin: 10px 0;
}

.inpatient-supply-order-delivery-info-NeedByDate input.input {
  border-right: 0;
}

.inpatient-supply-order-delivery-info-methods-drop-down .status-input {
  max-height: 42px;
  color: #323234;
  width: 100%;
}

.inpatient-supply-order-delivery-info-methods-drop-down
  .status-input
  .status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
  max-height: 42px;
}

.inpatient-supply-order-delivery-info-methods-drop-down div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.inpatient-supply-order-delivery-info-methods-drop-down
  .placeHolder
  div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .inpatient-supply-order-delivery-info-main-section {
    width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 928px) {
  .inpatient-supply-order-delivery-info-main-section {
    width: 80%;
  }
}
.inpatient-supply-order-delivery-info-main-div
  .inpatient-supply-order-input-divs {
  margin: 8px 0px;
}
