.myLists-component .myList-prescriber-main-container {
  width: 100%;
}
.myList-prescriber-main-container .myList-user-searchbar {
  margin-left: 40px;
}
.myList-user-searchbar .myList-prescriber-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
}
.myList-prescriber-searchbar .myList-prescriber-icon-div {
  height: 100%;
  margin: 0;
}
.myList-prescriber-searchbar
  .myList-prescriber-icon-div
  .prescriber-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}
.myList-prescriber-searchbar .myList-prescriber-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.myList-user-searchbar .myList-prescriber-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
}
.myList-prescriber-searchbar .myList-prescriber-icon-div {
  height: 100%;
  margin: 0;
}
.myList-prescriber-searchbar
  .myList-prescriber-icon-div
  .prescriber-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}
.myList-prescriber-searchbar .myList-prescriber-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.myList-prescriber-main-container .myList-prescribers-table-grid {
  margin-top: 16px;
  margin-bottom: 16px;
  min-height: 500px;
  max-height: 600px;
  margin-inline: 40px;
  overflow: auto;
  border: 1px solid #e4e4e8;
}
.myList-prescribers-table-grid .myList-prescribers-table-static-data {
  background: #323234;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: 100px;
}
.myList-prescribers-table {
  width: 100%;
  border-spacing: 0;
}
.myList-prescribers-table th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 8px 10px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}
.myList-prescribers-table td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}
.myList-prescribers-table tbody tr:first-child td {
  border-top: none;
}

.myList-prescribers-table tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.myList-prescribers-table tbody tr:nth-child(2n) td {
  background: #fff;
}
.myList-prescribers-table thead th:nth-child(1) {
  height: 40px;
  z-index: 1;
}

.myList-prescribers-table thead th:nth-child(2) {
  width: 32%;
  height: 40px;
}
.myList-prescribers-table thead th:nth-child(3) {
  width: 16%;
  height: 40px;
}
.myList-prescribers-table thead th:nth-child(4) {
  width: 12%;
}
.myList-prescribers-table thead th:nth-child(5) {
  width: 16%;
}
.myList-prescribers-table thead th:nth-child(6) {
  width: 14%;
}
.myList-prescribers-table thead th:nth-child(7) {
  width: 10%;
  z-index: 1;
}
.myList-prescribers-table th.up {
  background-image: url("../../../../assets/up_arrow.svg");
}

.myList-prescribers-table th.down {
  background-image: url("../../../../assets/down_arrow.svg");
}

.myList-prescribers-table th.default {
  background-image: url("../../../../assets/default.svg");
}
.myList-prescribers-table tbody tr {
  min-height: 300px;
}
.myList-prescribers-table-grid .myList-prescribers-name {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}
.myList-prescribers-table-grid .myList-remove-link {
  background-color: transparent;
  color: #cb0000;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  text-decoration: underline;
}
.myList-prescriber-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 300px;
  margin: auto;
  width: 300px;
}
.myList-prescriber-empty-pop-up .paperRoot {
  width: 200px;
  height: 56px;
}

.edit-prescriber-email-address-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit-prescriber-email-address-pop-up .paperRoot {
  width: 586px;
}

.myList-prescriber-main-container .myList-user-add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 620px) {
  .myList-prescriber-main-container .myList-user-searchbar {
    margin-top: 16px;
  }
  .myList-prescriber-main-container .myList-user-add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }
  .myList-prescriber-main-container .myList-user-searchbar {
    margin-left: 20px;
  }
  .myList-prescriber-searchbar .myList-prescriber-search-input {
    font-size: 10px;
  }
}
.myList-prescriber-main-container .myList-add-new-hcp-btn {
  align-items: center;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  min-width: 132px;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.remove-popup-content {
  margin-block: 16px;
}
.remove-popup-header {
  font-size: 14px;
  line-height: 18px;
}
.remove-popup-header-prescriber {
  font-weight: bold;
}
.margin-bottom {
  margin-bottom: 8px;
}
