.confirm-main-component {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 104px 16px 16px;
  width: calc(100% - 120px);
}

.confirm-main-component .confirm-request-component {
  margin: 40px 40px 24px 40px;
  width: 100%;
}

.confirm-request-component .confirm-request-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.confirm-request-component .confirm-request-route-section {
  margin-bottom: 26px;
  width: 80%;
}

.confirm-request-component .confirm-request-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px auto;
  text-align: center;
  width: 100%;
}

.confirm-request-component .confirm-short-form {
  display: flex;
  flex-direction: column;
  width: 62.78%;
}

.confirm-short-form .confirm-request-component-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  width: calc(100% - 16px);
}

.confirm-short-form .confirm-request-component-detail {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 16px;
  line-height: 18px;
  margin-top: 0px;
  width: 100%;
}

.confirm-main-component .exit-confirm-pop-up-container {
  align-items: flex-start;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0px;
  max-width: 491px;
}

.confirm-main-component .confirm-placement-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.confirm-placement-loader-pop-up .confirm-placement-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.confirm-main-component .confirm-placement-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.confirm-placement-failure-pop-up .confirm-placement-failure-pop-up-div {
  height: 165px;
  max-width: 450px;
}

.confirm-placement-failure-pop-up
  .confirm-placement-failure-pop-up-div.no-serial-number {
  height: 220px;
}

.confirm-main-component .confirm-placement-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.no-serial-number-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 16px;
  width: 400px;
}
.error-popup-design .paperRoot {
  width: 450px;
}
@media only screen and (max-width: 920px) {
  .confirm-main-component {
    margin: 0px;
    width: 100%;
    min-height: calc(100vh - 233px);
    overflow-x: hidden;
  }

  .confirm-request-component .confirm-short-form {
    margin: 0;
    width: 100%;
  }

  .confirm-main-component-container .confirm-request-component {
    margin: 0px;
    padding: 10px;
    height: unset;
  }

  .confirm-short-form .confirm-request-component-detail {
    width: 80%;
  }

  .confirm-short-form .confirm-request-component-header {
    width: calc(100% - 45px);
    height: unset;
  }

  .patient-details-component-confrmplac-height-adjustment {
    width: 80%;
    padding: 0px;
  }

  .confirm-main-component .confirm-request-component {
    margin: 40px 40px 24px 20px;
  }
  
  .error-popup-design .paperRoot {
    width: 350px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 414px) {
  .no-serial-number-pop-up {
    max-height: fit-content;
    max-width: 380px;
    width: calc(100% - 32px);
  }
}

@media only screen and (width: 320px) {
  .patient-details-component-confrmplac-height-adjustment {
    margin-left: -5px;
  }
}
