div.shareorder {
  max-width: 600px;
  margin: 0 auto;
}
.shareorder .paperRoot {
  width: 100%;
}

.shareorderinivite {
  max-width: 600px;
  margin: 0 auto;
}

.shareorderinvitesuccess {
  max-width: 600px;
  margin: 0 auto;
}

.shareorderinvitesuccess .paperRoot {
  width: 100%;
}

.shareorderinivite .paperRoot {
  width: 100%;
}

.shareOrderPopup .so-popup-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 19px;
  padding: 16px;
}

.shareOrderPopup .so-item-1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.shareOrderPopup .so-item-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
  margin-top: 4px;
}
.shareOrderPopup .so-item-3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
}
/* .shareOrderPopup .so-item-4{
    margin-top: 150px;
} */
.shareOrderPopup .so-item-5 {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.shareOrderPopup .so-outlined-input {
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808084;
}
.shareOrderPopup .so-outlined-input-root {
  width: 100%;
  max-height: 40px;
  margin-right: 8px;
  max-width: 413px;
}
.shareOrderPopup .share-search-btn {
  width: 100%;
  max-width: 147px;
}
.shareOrderPopup .so-textarea-root {
  padding: 0;
}
.shareOrderPopup .so-note-limit {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #76767a;
  margin-top: 4px;
}

.shareOrderPopup .share-cancel-btn {
  margin-right: 4px;
  width: 100%;
}
.shareOrderPopup .share-order-btn {
  margin-left: 4px;
  width: 100%;
}

/*---------------- success popup css -----------------------*/
div.share-success-container {
  display: flex;
  flex-direction: column;
  padding: 48px 16px 16px 16px;
}
.share-success-container .share-success-header {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.share-success-container .share-success-message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
  margin-top: 4px;
  margin-bottom: 16px;
}
.share-success-container .success-done-btn {
  width: 100%;
}

.emailLink {
  color: #0049bd;
  text-decoration: underline;
}

.successMessageShareOrder {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.saveshare-spinner {
  height: 200px;
  margin-top: 31px;
}

.saveshare-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #323234;
  padding: 16px;
  align-content: center;
}
@media only screen and (max-width: 600px) {
  .shareorderinvitesuccess .paperRoot {
    width: 90%;
  }
}
