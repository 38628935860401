.order-menu-bar-main-div {
  width: 100%;
}
.order-menu-bar-main-div .order-menu-start-new-order-div {
  background: linear-gradient(
    221deg,
    rgb(42, 42, 131) -3.14%,
    rgb(0, 73, 189) 29.44%,
    rgb(35, 179, 223) 76.3%,
    rgb(36, 215, 255) 95.35%
  );
  border-radius: 4px;
  padding: 16px;
}
.order-menu-bar-main-div .order-menu-start-new-order {
  border: 1px solid #0049bd;
  color: #0049bd;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  justify-content: space-between;
  line-height: 18px;
  padding: 16px;
  width: 100%;
}
.order-menu-bar-main-div .order-menu-start-new-order:hover {
  background: #ffffff;
}
.menu-bar-paperstyle .content-menuBar {
  overflow-x: auto;
  margin-right: 14px;
}
.content-menuBar .menu-bar-new-order-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 8px 12px 0px 12px;
}
.menu-bar-paperstyle .order-menu-bar-OrderLink {
  color: #0049bd;
  font-weight: 700;
  gap: 8px;
  padding: 10px 16px 10px 12px;
  text-decoration: underline;
  width: 100%;
}
.menu-bar-paperstyle .order-menu-bar-OrderLink:hover {
  background: transparent;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) and (max-width: 920px) {
  .order-menu-bar-main-div .order-menu-start-new-order {
    padding: 12px;
  }
  .content-menuBar .menu-bar-new-order-title {
    padding: 8px 0px 0px 0px;
  }
  .menu-bar-paperstyle .content-menuBar {
    margin: 0px 10px;
  }
}
