.product-properties-component {
  display: flex;
  flex-direction: column;
  margin: 0 24px 42px;
}

.product-properties-component .section-title-div {
  margin-top: 7px;
  width: auto;
}

.product-properties-component .section-title-div-display-title {
  color: rgb(50, 50, 52);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  padding-top: 56px;
}

.product-properties-component .section-button-div {
  padding-bottom: 24px;
  padding-top: 50px;
  text-align: center;
}

.product-properties-component .section-button-div .ancher-tag {
  text-decoration: none;
}

.product-properties-component .section-button-div .section-button {
  align-items: center;
  background-color: #0049bd;
  border-radius: 2px;
  color: #fff;
  flex-direction: row;
  height: 48px;
  padding: 14px 20px;
  min-width: 60%;
}

.product-properties-component .section-button-div .section-button-body {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
}

.product-properties-component .section-title-div .section-title {
  color: rgb(50, 50, 52);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  padding-top: 40px;
}

.product-properties-component .section-title-div .tagline {
  color: rgb(50, 50, 52);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  padding-top: 40px;
  position: static;
}

.product-properties-component .section-title-div .header {
  color: rgb(50, 50, 52);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 56px;
  padding: 0;
  margin: 0;
  max-height: 112px;
}

@media only screen and (min-width: 1312px) {
  .product-properties-component .section-title-div .header {
    max-height: 56px;
  }
}

.product-properties-component .product-properties-column {
  display: flex;
  margin-top: 48px;
}

.product-properties-component .product-properties-column .left-column {
  margin-right: 32px;
}

.product-properties-component .product-properties-column .right-column {
  display: flex;
  flex-direction: column;
}

.right-column .button-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 24px 0;
  width: calc(100% - 25px);
}

.right-column .button-div .allow-button {
  background-color: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  text-transform: none;
}

.product-properties-component .related-product-main-container {
  width: 250px;
  height: auto;
  border: 1px solid #0049bd;
  padding: 15px;
  border-radius: 5px;
}

.product-properties-component
  .related-product-main-container
  .related-parameters-container {
  padding: 15px 10px 10px 10px;
}

.related-parameters-container .related-parameters-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.related-parameters-container
  .related-parameters-item-container
  .related-parameters-item-check-icon {
  color: #0049bd;
  margin-right: 20px;
}

.related-product-main-container
  .related-parameters-container
  .related-parameters-title {
  color: rgb(50, 50, 52);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.related-product-main-container
  .related-parameters-container
  .related-parameters-title-item {
  color: rgb(50, 50, 52);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.product-properties-component .related-exudate-level-container {
  padding: 15px 10px 10px 10px;
}

.product-properties-component
  .related-exudate-level-container
  .related-exudate-level-title {
  color: rgb(50, 50, 52);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.related-exudate-level-container .related-exudate-level-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.related-exudate-level-container
  .related-exudate-level-item-container
  .related-exudate-level-item-check-icon {
  color: #0049bd;
  margin-right: 20px;
}

.related-exudate-level-container
  .related-exudate-level-item-container
  .related-exudate-level-title-item {
  color: rgb(50, 50, 52);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.product-properties-component .place-order-pop-up {
  align-items: center;
  height: 70%;
  justify-content: center;
  width: 70%;
}

.product-properties-component h1 {
  font-size: 48px;
  margin: 0;
  display: inline;
}

.product-properties-component h2 {
  margin: 0;
  font-size: 1em;
}

.product-properties-component h3 {
  margin: 0;
  font-size: 1em;
}

.product-properties-component p {
  color: rgb(50, 50, 52);
  margin: 0;
}

.product-properties-component a {
  background-color: transparent;
}

@media (max-width: 600px) {
  .product-properties-component .product-properties-column {
    flex-direction: column-reverse;
  }

  .product-properties-component .right-column {
    width: 100%;
    margin-bottom: 16px;
  }

  .product-properties-component .section-button-div {
    padding: 19px 4px 0px 0px;
  }
  .product-properties-component .section-title-div .header {
    max-height: 177px;
    line-height: 34px;
    font-size: 22px;
  }
  .product-properties-component .section-title-div-display-title {
    padding-top: 34px;
  }
  .product-properties-component .section-button-div .section-button {
    height: 59px;
  }
}
@media only screen and (max-width: 820px) {
  .product-properties-component {
    display: flex;
    flex-direction: column;
    margin: 0 24px 42px;
  }

  .product-properties-component .product-properties-column {
    flex-direction: column-reverse;
  }

  .product-properties-component .section-button-div {
    order: -1;
    padding: 19px 4px 0px 0px;
  }
  .product-properties-component .section-title-div {
    margin-top: 21px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .product-properties-component .related-product-main-container {
    width: 228px;
  }
  .product-properties-component .section-title-div-display-title {
    font-size: 25px;
  }
  .product-properties-component .section-title-div {
    margin-top: 35px;
  }
}
@media (max-width: 820px) {
  .product-properties-component .related-product-main-container {
    padding: 24px 0 32px;
    width: 100%;
  }
  .product-properties-component .related-parameters-title,
  .product-properties-component .related-exudate-level-title {
    text-align: left;
  }
  .product-properties-component
    .related-product-main-container
    .related-parameters-container {
    text-align: left;
  }
}
