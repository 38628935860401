.device-information-exchange-vac {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;
}

.device-information-exchange-vac .device-information-exchange-vac-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
}
.device-information-exchange-vac .device-information-exchange-vac-header-desc {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0px;
}
.device-information-exchange-vac .device-informationdata-entry-grid-container {
  margin-top: 0px;
  padding: 0px;
  width: 100%;
}
.device-information-exchange-vac .device-information-exchange-vac-part-one {
  margin-top: 40px;
  margin-bottom: 16px;
}

.device-information-exchange-vac .device-information-exchange-vac-part-two {
  margin-top: 0px;
}

.device-information-exchange-vac
  .device-information-exchange-vac-grid-container {
  width: 100%;
}

.device-informationdata-entry-grid-container
  .device-information-exchange-vac-grid-item {
  padding-left: 5px;
  width: 100%;
}
.device-information-exchange-vac-grid-container .device-grid-text-area {
  width: 100%;
}
.device-information-exchange-vac-grid-item .device-info-header-title {
  margin: 0px;
  white-space: pre-wrap;
  text-wrap: wrap;
  width: 100%;
}
.device-information-exchange-vac-grid-item .device-info-problem-injury-desc {
  margin: 0px;
  white-space: pre-wrap;
  width: 100%;
}
.serviceDate .outline {
  border-radius: 2px;
}

.serviceDate.showError .outline {
  border: 1px solid #d32f2f;
}

.serviceDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.device-information-exchange-vac .radioRoot {
  flex-direction: row;
  justify-content: space-between;
  gap: unset;
}

.device-information-exchange-vac .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.device-information-exchange-vac .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.device-information-exchange-vac .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.device-information-exchange-vac .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.device-information-exchange-vac-grid-item input.input {
  background: #f2f2f5;
  border-right: 0;
  color: #323234;
}

.device-information-exchange-vac .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.device-information-exchange-vac .textarea-root {
  padding: 0;
}
.device-information-exchange-vac
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
}
.device-informationdata-entry-grid-container
  .device-informationdata-entry-grid-item {
  margin: 0px;
  width: 100%;
}

.device-informationdata-entry-grid-item .device-informationdata-entry-input {
  width: 100%;
}

.device-informationdata-entry-grid-item .device-informationdata-entry-select {
  align-items: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.device-informationdata-entry-grid-item
  .device-informationdata-entry-select
  .place-holder {
  border-radius: 2px;
  background-color: #f2f2f5;
  color: #76767a;
}

.device-informationdata-entry-grid-item .place-holder .selectIcon {
  top: 10px;
}

.device-informationdata-entry-grid-item .serviceDate input.input {
  border-right: 0;
}

div.serviceDate {
  padding-right: 0;
}

.device-informationdata-entry div.placeholder {
  height: 42px;
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.placeholder div.placeholder {
  height: 42px;
  border-right: unset;
  height: 20px;
}
.device-information-exchange-vac .textarea-problem-root {
  padding: 0;
}

@media screen and (max-width: 920px) {
  .device-informationdata-entry {
    width: calc(100% - 40px);
  }
  .device-information-exchange-vac .device-information-exchange-vac-part-two {
    margin-top: 0px;
  }
  .device-information-exchange-vac .radioRoot {
    margin-top: 0px;
    flex-direction: row;
    gap: 5px;
  }

  .device-information-exchange-vac .optionRoot {
    width: 30%;
  }

  .device-information-exchange-vac .optionRoot-active {
    width: 30%;
  }
}
@media screen and (max-width: 377px) {
  .device-information-exchange-vac .optiontxt {
    font-size: 10px;
    line-height: 14px;
  }
  .device-information-exchange-vac .optiontxtSelect {
    font-size: 10px;
    line-height: 14px;
  }
  .device-information-exchange-vac .textarea-problem-root {
    margin-top: 15px;
  }
  .device-information-exchange-vac .radioRoot {
    margin-top: 15px;
  }
}
