.add-facility-manually {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-facility-manually .add-facility-manually-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0 56px 24px 0;
}

.add-facility-manually .facility-manually-inputs {
  margin-right: -8px;
}

.add-facility-manually .facility-manually-component {
  width: 100%;
}

.facility-manually-input {
  width: 100%;
  height: 40px;
}

.gird-div-for-extra-margin {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.facility-manually-input .facility-type-select {
  height: 20px !important;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.facility-manually-input .facility-state-select {
  height: 20px !important;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.add-facility-manually div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.add-facility-manually .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.footer-buttons {
  margin-top: 24px;
}

.facility-manually-inputs .saveBtn {
  width: 100%;
}

.facility-manually-inputs .cancelBtn {
  width: 100%;
}

.facility-manual-state-input {
  height: 42px;
}

.facility-manual-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.facility-manual-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.facility-manual-state-input .facility-manual-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
