.facility-supply-order-report-filter-component {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.facility-supply-order-report-filter-component
  .facility-supply-order-report-filter-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-supply-order-report-filter-component
  .facility-supply-order-report-filter-grid-container
  .facility-supply-order-report-filter-grid-item {
  margin: 0px;
  padding: 4px;
  width: 100%;
}

.facility-supply-order-report-filter-component
  .facility-supply-order-report-filter-grid-container
  .facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  color: #323234;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  height: 40px;
  line-height: 18px;
  margin: 0px;
  padding: 0;
  text-align: left;
  width: 100%;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar::placeholder {
  color: #94949a;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar
  .search-icon-div {
  height: 100%;
  margin: 0;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar
  .search-icon-div
  .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 8px;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar
  .facility-supply-order-report-filter-input {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-input-field-searchbar
  .facility-supply-order-report-filter-input
  .MuiInputBase-input {
  border: none;
  padding: 0px 8px 0px 0px;
}

.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-date-range {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}
.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-types {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}
.facility-supply-order-report-filter-grid-item
  .facility-supply-order-report-filter-apply-filter {
  margin-left: 0px;
}
.facility-supply-order-report-filter-date input.input {
  border-right: 2px;
  height: 22px;
  width: 100%;
}

div.facility-supply-order-report-filter-date {
  width: 100%;
}

div .facility-supply-order-report-filter-date .adornedRoot {
  position: absolute;
  right: 18px;
}

.facility-supply-order-report-filter-date .outline {
  border-radius: 1px;
  border: 1px solid #94949a !important;
  width: calc(100% - 18px);
}

.facility-supply-order-report-filter-date.show-error .outline {
  border: 1px solid #cb0000 !important;
}

.facility-supply-order-report-filter-date.no-error.Mui-error .outline {
  border: 1px solid #94949a !important;
}

.facility-supply-order-report-filter-grid-item .apply-filter-button {
  align-items: center;
  display: flex;
  justify-content: center;
  border-color: #0049bd;
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  height: 42px;
  line-height: 18px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  text-transform: none;
}
.facility-supply-order-report-filter-types .facility-supply-order-select {
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.facility-supply-order-report-filter-types div.placeHolder {
  color: #76767a;
}
@media screen and (max-width: 600px) {
  .facility-supply-order-report-filter-grid-item .apply-filter-button {
    margin-left: 0px;
    width: 100%;
  }
  .facility-supply-order-report-filter-grid-item
    .facility-supply-order-report-filter-types {
    margin-left: 0px;
  }
  .facility-supply-order-report-filter-grid-item
    .facility-supply-order-report-filter-date-range {
    margin-left: 0px;
  }
}
