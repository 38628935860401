.in-patient-order-supply-selection-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0px 32px;
  width: 100%;
}

.in-patient-order-supply-selection-component
  .in-patient-order-supply-selection-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 0px 0px 12px 0px;
  text-align: left;
}

.in-patient-order-supply-selection-component
  .in-patient-order-supply-selection-header-title {
  margin: 0px;
  white-space: pre-wrap;
  width: 100%;
}

.in-patient-order-supply-selection-component .radioRoot {
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.in-patient-order-supply-selection-component .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.in-patient-order-supply-selection-component .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.in-patient-order-supply-selection-component .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(25% - 8px);
}

.in-patient-order-supply-selection-component .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.in-patient-order-supply-selection-component .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

@media screen and (max-width: 600px) {
  .in-patient-order-supply-selection-component .optionRoot {
    width: calc(50% - 8px);
  }

  .in-patient-order-supply-selection-component .optionRoot-active {
    width: calc(50% - 8px);
  }

  .in-patient-order-supply-selection-component .optionRoot-error {
    width: calc(50% - 8px);
  }
}
@media screen and (max-width: 340px) {
  .in-patient-order-supply-selection-component .radioRoot {
    margin: 16px 0;
  }
}
