.provideAdditionalInfo {
  margin-top: 30px;
  margin-bottom: 80px;
}

.provideAdditionalInfo .provideAdditionalInfoTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-bottom: 5px;
  margin-top: 42px;
}

.additionalInfoTextarea {
  width: 60%;
  height: 91px;
  line-height: 16px;
  margin-top: -1px;
  border: none;
}
.provideAdditionalInfoError {
  font-weight: 700px;
  font-size: 12px;
  font-style: italic;
  color: #d32f2f !important;
  width: 564px;
}
.noProvideAdditionalError {
  display: none;
}
@media screen and (max-width: 920px) {
  .additionalInfoTextarea {
    width: 100%;
  }
}
