.product-request-overview-value {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  margin-top: 0px;
  margin-bottom: 15px;
  line-height: 16px;
  text-decoration-line: underline;
}
.review-product-info {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 19px;
  margin-bottom: 5px;
}
.review-product-info-div {
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  width: 100%;
}
.productinformation-grid-item {
  height: 40px;
  justify-content: center;
  flex-direction: column;
  padding-top: 0%;
  margin-top: 1%;
}
.review-product-request .product-request-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.review-product-request .product-request-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}
.reviewRequest-main-component .review-product-request {
  min-height: 120px;
  margin-bottom: 16px;
  width: calc(100% - 16px);
  margin-left: 16px;
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .review-product-info-div {
    display: grid;
    gap: 9px;
  }
}
