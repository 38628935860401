.crossIcon {
  margin-left: 32px;
  margin-top: 26px;
  cursor: pointer;
}
.prescriber-info-Selected-UpdateBtn .button-update-email {
  border: 1px solid #cb0000;
  border-radius: 2px;
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #0049bd;
  text-transform: none;
  margin-left: 20px;
  margin-top: 26px;
  &:hover {
    border-color: #cb0000;
  }
}
.update-prescriberemail-input {
  width: 100%;
}

.prescriberUpdateInfoDiv {
  margin-top: 20px;
}
.prescriberErrorDesc2 {
  width: 85%;
  display: flex;
  margin-left: -10px;
}

.prescriberErrorDesc2Label {
  font-size: 14px;
  color: #cb0000;
  margin-left: 7px;
  margin-top: 10px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
}
.imgalert {
  margin-top: 14px;
  margin-left: 30px;
}
.submit-prescription-div .prescriberEmailError {
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  margin: 0 0 0 32px;
}

.submit-prescription-div .prescriberEmailError .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px 4px;
}

.submit-prescription-div .prescriberEmailError .warning-icon {
  margin: 0 4px 0 6px;
  width: 16px;
}

.submit-prescription-div
  .prescriberEmailError
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}
.prescriber-info-email-input .prescriber-info-email-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

@media only screen and (max-width: 920px) {
  .crossIcon {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .prescriber-info-Selected-UpdateBtn .button-update-email {
    font-size: 12px;
    margin-left: 4px;
    padding: 6px 0px 6px 0px;
    width: 100%;
  }
}
