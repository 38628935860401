.resupplyJustificationTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 15px;
  margin-bottom: 0px;
}
.resupplyJustificationDropdown {
  margin-top: 4px;
  margin-bottom: 40px;
}
.resupplyJustificationDropdown .resupplyJustification-input {
  width: 100%;
}
.resupplyJustificationDropdown
  .resupplyJustification-input
  .resupplyJustification-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}
.resupplyJustificationDropdown div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}
.resupplyJustificationDropdown .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}
.resupplyJustificationDropdown .placeHolder .selectIcon {
  top: 10px;
}
.resupplyJustificationDropdown {
  width: 100%;
}
.resupplyJustificationDropdown .resupplyJustificatio-grid-container {
  width: 100%;
}
.resupplyJustificationDropdown
  .resupplyJustificatio-grid-container
  .resupplyJustificatio-grid-item {
  width: 100%;
}
