.parent-div {
  margin-bottom: 0px;
}
.parent-div .unlinkedUsers-requestedFacility-component {
  background: #e8f4ff;
  border: 1px solid #e4e4e8;
  margin-bottom: 16px;
  margin-top: 10px;
  min-height: 80px;
  width: 100%;
}

.unlinkedUsers-requestedFacility-component
  .unlinkedUsers-requestedFacility-grid-container {
  width: 100%;
  margin-left: 0px;
  margin-top: 8px;
  gap: 2px;
}

.unlinkedUsers-requestedFacility-component
  .unlinkedUsers-requestedFacility-grid-container
  .unlinkedUsers-requestedFacility-grid-item {
  line-height: 18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 0px;
}

.unlinkedUsers-requestedFacility-component .requested-facility {
  margin-left: 8px;
  margin-top: 3px;
}

.unlinkedUsers-requestedFacility-component
  .unlinkedUsers-requestedFacility-title {
  color: #76767a;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
}
.unlinkedUsers-requestedFacility-component .requested-facility-name {
  margin-left: 8px;
}
.requested-facility-name .requested-facility-name-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-bottom: 0px;
  margin-left: 8px;
}
.unlinkedUsers-requestedFacility-component .requested-facility-address {
  margin-left: 8px;
}
.unlinkedUsers-requestedFacility-component .requested-facility-address-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-bottom: 0px;
  margin-left: 8px;
}
.emptyPopup {
  max-width: 100%;
  min-height: 56px;
}
.last-div {
  width: 100%;
}
.last-div-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .parent-div .unlinkedUsers-requestedFacility-component {
    min-height: 77px;
    height: auto;
  }
  .unlinkedUsers-requestedFacility-component
    .unlinkedUsers-requestedFacility-grid-container
    .unlinkedUsers-requestedFacility-grid-item {
    justify-content: flex-start;
    height: unset;
  }
  .unlinkedUsers-requestedFacility-component .requested-facility {
    margin-top: unset;
  }
  .unlinkedUsers-requestedFacility-component .requested-facility-address {
    margin-left: 16px;
    margin-top: 2px;
  }
  .unlinkedUsers-requestedFacility-component .requested-facility-address-value {
    margin-left: 0px;
  }
  .unlinkedUsers-requestedFacility-component .requested-facility-address {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .unlinkedUsers-requestedFacility-component .requested-facility-name {
    margin-left: 8px;
    margin-bottom: unset;
    margin-top: 0px;
  }
}
