.delivery-information {
  margin-top: 40px;
  width: 100%;
}
.delivery-info-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.deliveryInfopopper .deliveryInfotooltip {
  background-color: #fff;
  color: #000;
  padding: 14px 32px 16px 16px;
  font-size: 14px;
  border: 1px solid #808084;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  width: auto;
  height: auto;
}
.delivery-information .delivery-information-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.delivery-information .delivery-information-box-container {
  margin-top: 8px;
  width: 100%;
}

.delivery-information .delivery-information-grid-container {
  width: 100%;
}

.delivery-information-grid-container .delivery-information-grid-item {
  width: 100%;
}

.delivery-information-grid-item .delivery-information-input {
  width: 100%;
}

.deliveryProductNeedByDate input.input {
  border-right: 0;
}

div.deliveryProductNeedByDate {
  padding-right: 0;
}

.deliveryProductNeedByDate .outline {
  border-radius: 2px;
}

.deliveryProductNeedByDate.showError .outline {
  border: 1px solid #d32f2f;
}

.deliveryProductNeedByDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

div.placeholder {
  border-radius: 2px;
  color: #76767a;
}

.placeholder div.placeholder {
  border-right: unset;
  height: 20px;
}

.delivery-information .delivery-information-input .delivery-information-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.delivery-information div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.delivery-information .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.delivery-information .placeHolder .selectIcon {
  top: 10px;
}

.delivery-information .facilityDetail .facilitywrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: fff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  margin: 0 8px 15px 0;
  overflow: hidden;
  width: 100%;
}

.delivery-information .facilityDetail .facility-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.delivery-information .facilitytitle {
  color: #808084;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 11px;
  text-transform: capitalize;
}

.delivery-information .facilityvalue {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.delivery-information .facilityDetail {
  margin-top: 8px;
  width: 100%;
}
.deliveryAddress-state-input {
  height: 42px;
}

.deliveryAddress-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.deliveryAddress-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.deliveryAddress-state-input .deliveryAddress-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.delivery-information .delivery-address-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.delivery-information .delivery-address-div .delivery-address-title {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 20px 0 0;
}

.delivery-information
  .delivery-address-div
  .delivery-address-title.bottom-margin {
  margin: 20px 0 8px;
}

.delivery-information .delivery-address-div .delivery-address-note {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px;
}

.delivery-information .delivery-address-div .delivery-address-note.bold {
  font-weight: 700;
}

.delivery-information .delivery-address-div .delivery-address-default-mode {
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  min-height: 68px;
  padding: 10px 8px 10px 16px;
  width: calc(100% - 40px);
}

.delivery-information
  .delivery-address-div
  .delivery-address-default-mode.error {
  border: 1px solid #d32f2f;
}

.delivery-address-default-mode .delivery-address-default-mode-div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}

.delivery-address-default-mode .delivery-address-default-mode-div.edit-button {
  align-items: flex-end;
}

.delivery-address-default-mode
  .delivery-address-default-mode-div
  .delivery-address-default-mode-title {
  color: #808084;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.delivery-address-default-mode
  .delivery-address-default-mode-div
  .delivery-address-default-mode-value {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.delivery-address-default-mode
  .delivery-address-default-mode-div
  .delivery-address-default-mode-edit-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: right;
  text-decoration-line: underline;
  margin: 16px 0px 0px;
  cursor: pointer;
}

.delivery-information .delivery-address-edit-mode {
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 8px 10px 16px;
  width: calc(100% - 40px);
}

.delivery-information .delivery-address-edit-update-mode {
  border: 1px solid #cb0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 8px 10px 16px;
  width: calc(100% - 40px);
}

.delivery-information
  .delivery-address-edit-mode
  .delivery-address-edit-mode-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  height: 42px;
  margin: 16px;
  width: auto;
}

.delivery-information
  .delivery-address-edit-update-mode
  .delivery-address-edit-mode-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
  height: 42px;
  margin: 16px;
  width: auto;
}

.delivery-address-edit-mode
  .delivery-address-edit-mode-buttons
  .delivery-address-edit-mode-cancel-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
  width: 74px;
}

.delivery-address-edit-update-mode
  .delivery-address-edit-mode-buttons
  .delivery-address-edit-mode-cancel-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
  width: 74px;
}

.delivery-address-edit-update-mode
  .delivery-address-edit-mode-buttons
  .delivery-address-edit-mode-update-button {
  border: 1px solid #cb0000;
  &:hover {
    border-color: #cb0000;
  }
}

.delivery-information .delivery-address-default-mode-warning-div {
  display: flex;
  flex-direction: row;
  margin: 4px 0 0 0px;
  width: 100%;
}

.delivery-information
  .delivery-address-default-mode-warning-div
  .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.delivery-information .delivery-address-default-mode-warning-div .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.delivery-information
  .delivery-address-default-mode-warning-div
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}

@media only screen and (max-width: 800px) {
  .delivery-information .delivery-information-title {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .deliveryInfopopper .deliveryInfotooltip {
    background-color: #fff;
    color: #000;
    padding: 14px 32px 16px 16px;
    font-size: 14px;
    border: 1px solid #808084;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 3px 1px rgba(0, 0, 0, 0.12);
    border-radius: 0;
    width: 150px !important;
    height: auto;
  }
}
@media only screen and (min-width: 810px) and (max-width: 920px) {
  .delivery-info-header .informationToolTipIcon {
    margin-top: 21px;
  }
  .delivery-information .delivery-information-title {
    margin-top: 19px;
  }
}
