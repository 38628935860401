.move-unit-to-stored-product-success-component {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.move-unit-to-stored-product-success-component .title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 16px 8px 16px;
  width: calc(100% - 32px);
}

.move-unit-to-stored-product-success-component .grid-container {
  margin: 8px 16px 16px;
  padding: 0px;
  width: calc(100% - 32px);
}

.move-unit-to-stored-product-success-component .grid-container .grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item.top-space {
  margin: 8px 0px 0px;
  padding: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .success {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
  width: 100%;
}
.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .success
  .success-icon {
  color: #007713;
  height: 100%;
  margin: 0px;
}
.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .success
  .success-text {
  color: #007713;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .product-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .product-details
  .product-detail-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .product-details
  .product-detail-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.move-unit-to-stored-product-success-component
  .grid-container
  .grid-item
  .return-to-inventory-button {
  background-color: #0049bd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .move-unit-to-stored-product-success-component {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .move-unit-to-stored-product-success-component {
    margin: 0px 8px;
    width: calc(100% - 16px);
  }
}
