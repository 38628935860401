.prescriptionDoc {
  margin-left: 32px;
  margin-top: -8px;
}

.prescriptionDoc .rxUploadDesp {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

.prescriptionDoc .dropZoneStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 138px;
  background: #f8f8fa;
  cursor: pointer;
  border: 1px dashed #1e64d0;
}

.prescriptionDoc .dragAndDrop {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration-line: underline;
  color: #76767a;
}

.prescriptionDoc .uploadWidgetCard {
  margin-top: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid #e4e4e8;
  margin-bottom: 15px;
}

.prescriptionDoc .uploadWidgetCard.error {
    border: 1px solid #cb0000;
}

.prescriptionDoc .uploadIconStyle {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.prescriptionDoc .fileDetailCard {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  margin-bottom: 10px;
  margin-top: 10px;
}

.prescriptionDoc .fileDetailCard .filename {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 50%;
  align-self: center;
  overflow-wrap: break-word;
  margin-left: 11px;
}

.prescriptionDoc .fileDetailCard .fileStatus {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 40%;
  color: #007713;
  align-self: center;
  text-align-last: right;
}

.fileDetailCard .fileClose {
  width: 50px;
  align-self: center;
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.prescriptionDoc .fileDetailCard .fileStatuserror {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 40%;
  color: #cb0000;
  align-self: center;
  text-align-last: right;
}
.successmsg {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #007713;
  align-self: center;
}
.successmsgerror {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  align-self: center;
}
@media only screen and (max-width: 920px) {
  .prescriptionDoc .fileDetailCard .fileStatuserror {
    width: auto;
    margin-left: 5px;
  }
  .prescriptionDoc .fileDetailCard .fileClose {
    text-align: end;
  }
  .successmsg {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #323234;
    width: 40%;
    color: #007713;
    align-self: center;
    text-align: start;
  }
}
