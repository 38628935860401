.dressingKitInfo .dressingKitBtnGrid {
  height: 48px;
  margin-top: -8px;
}

.supplyOrderDressingKitInfo .mainGridDressing {
  margin-bottom: 8px;
}

.dressingKitInfo .dressingKit-add-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 16px 0px;
  min-height: 16px;
  text-decoration: underline;
  text-transform: none;
}

.dressingKitInfo .dressingadd-icon {
  transform: scale(1.5) !important;
}

.dressingKitInfo {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  min-height: 114px;
  padding: 16px;
}

.dressing-kitdropdown-input {
  width: 100%;
  text-overflow: clip;
  height: 40px;
}

.dressing-kitdropdown-input .dressingKit-type-select {
  height: 20px !important;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  color: black;
}

.dressingKitCloseBtn {
  cursor: pointer;
  position: absolute;
  margin-left: 20px;
  margin-top: 27px;
}
.dressingKitCloseBtnHide {
  display: none;
}
.dressingKitInfo .dressingSizeKit,
.supplyOrderDressingKitInfo .dressingSizeKit {
  margin-top: 8px;
  width: 100%;
}

.dressingKitLabel {
  position: relative;
  top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.currentMainGrid .dressKitSubLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 18px;
}

.currentMainGrid .dressingLabel {
  margin-left: 12px;
}

.currentMainGrid .dressingLabel.message {
  margin-left: 0px;
}

.dressingKitInfo .dressingKitHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.dressingKitInfo .dressingKitBody {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px;
}

div.dressingplaceHolder {
  width: 100%;
  border-radius: 2px;
  color: #76767a;
}

.dressingplaceHolder div.dressingplaceHolder {
  width: 100%;
  border: none;
}

.dressingplaceHolder .selectIcon {
  top: 10px;
}

.currentMainGrid .peel-and-place-quantity-error {
  color: #cb0000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0px 16px;
}

.currentMainGrid .peel-and-place-quantity-error .phone-value {
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  white-space: pre;
}

.dressingKitPaperProps {
  max-height: 316px;
  width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
}

@media screen and (max-width: 600px) {
  .dressingKitCloseBtn {
    margin-left: 8px;
    margin-top: 28px;
  }
}

@media screen and (max-width: 920px) {
  .dressingKitInfo .dressingSizeKit,
  .supplyOrderDressingKitInfo .dressingSizeKit {
    margin-top: 8px;
  }
  .currentMainGrid .dressingLabel {
    margin-left: 0px;
  }
}
