.facility-not-connected-container .facility-not-connected-description1 {
  color: #915800;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  padding: 8px;
}
.facility-not-connected-container .facility-not-connected-description2 {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 0px 8px 8px 8px;
}
.facility-not-connected-container .facility-not-associated {
  background: #fff0d4;
}
