.duplicate-order-check-dialog {
  display: flex;
  flex-direction: column;
  margin: 4px 16px 16px 16px;
  width: 375px;
}

.duplicate-order-check-dialog .dialog-title {
  color: #323234;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  margin-top: 12px;
  min-width: 303px;
}

.duplicate-order-check-dialog .dialog-subtitle {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  min-height: 36px;
  min-width: 343px;
}
.duplicate-order-check-dialog .dialog-subtitle .dialog-subtitle-value
{
  color: #323234;
  font-weight: 700;
}
.duplicate-order-check-dialog .dialog-instructions {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  min-width: 343px;
}

.duplicate-order-check-dialog .continue-order {
  margin-top: 16px;
  width: 100%;
}

.continue-order .continue-order-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.duplicate-order-check-dialog .delete-order {
  margin-top: 8px;
  width: 100%;
  margin-bottom: 8px;
}

.delete-order .delete-order-button {
  background-color: #cb0000;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.delete-order .delete-order-button:hover {
  background-color: #cb0000;
}

.duplicate-order-check-dialog .save-and-exit-order {
  margin-top: 8px;
  width: 100%;
}

.save-and-exit-order .save-and-exit-button {
  color:  #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

@media only screen and (min-width: 331px) and (max-width: 920px) {
  .duplicate-order-check-dialog {
    width: calc(100% - 32px);
    max-width: 345px;
    overflow: hidden;
  }
  .duplicate-order-check-dialog .dialog-title {
    min-width: unset;
    width: inherit;
  }
}

@media only screen and (max-width: 330px) {
  .duplicate-order-check-dialog {
    width: calc(100% - 32px);
    max-width: 270px;
  }

  .duplicate-order-check-dialog .dialog-title {
    min-width: unset;
    width: auto;
  }

  .duplicate-order-check-dialog .dialog-subtitle {
    min-width: unset;
    width: auto;
  }
}
