.reviewRequest-main-component {
  background-color: #f8f8fa;
  margin-bottom: 85px;
  min-height: 100%;
  width: 100%;
}
.reviewRequest-main-component .review-Request-component {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 16px 104px 112px 16px;
  min-height: calc(100% - 144px);
  padding: 40px;
}
.review-Request-component .reviewRequest-route-section {
  margin-bottom: 26px;
}
.review-Request-component .review-Request-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.review-request-component .reviewRequest-desp {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  flex: none;
  align-self: stretch;
}

.review-discharge-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 12px;
  color: #323234;
}
.reviewRequest-desp {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 10px;
  margin-top: 5px;
}

.review-discharge-request {
  width: 80px;
}
.reviewRequest-main-component .review-discharge-request {
  min-height: 120px;
  margin-bottom: 16px;
  width: 600px;
}

.review-discharge-request .discharge-request-grid-container {
  width: 100%;
  margin-top: 14px;
}

.review-discharge-request
  .discharge-request-grid-container
  .discharge-request-grid-item {
  height: 40px;
  justify-content: center;
  flex-direction: column;
  padding-top: 0%;
  margin-top: 1%;
}

.review-discharge-request .patient-name {
  color: #323234;
  font-style: normal;
  font-size: 16px;
  height: 18px;
  line-height: 18px;
  margin-bottom: 0px;

  margin-top: 3px;
}

.review-discharge-request .dob-date {
  margin-bottom: 16px;
}

.review-discharge-request .discharge-request-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: -6px;
  margin-bottom: 0px;
}

.review-discharge-request .discharge-request-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}
.review-discharge-request .review-summary {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
}
.attestationStyling {
  margin-left: 20px;
}

.discharge-request-loader-pop-up {
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

.discharge-request-loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  margin: 0;
  padding: 0;
  width: 300px;
}

.reviewRequest-main-component .discharge-request-error {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.discharge-request-error .discharge-request-error-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 410px;
}

.review-discharge-request .wound-accessor-review {
  padding-left: 20px;
  width: 100%;
}

@media only screen and (max-width: 350) {
  .discharge-request-error-pop-up {
    height: 200px;
    width: 250px;
  }
}

@media only screen and (min-width: 351) and (max-width: 600) {
  .discharge-request-error-pop-up {
    height: 165px;
    width: 310px;
  }
}
@media only screen and (max-width: 920px) {
  .reviewRequest-main-component .review-Request-component {
    margin: 0px;
    padding: 20px;
  }
  .reviewRequest-main-component .review-discharge-request {
    width: 100%;
    margin-bottom: 32px;
  }

  .review-discharge-request
    .discharge-request-grid-container
    .discharge-request-grid-item {
    margin-top: 3%;
  }
}

@media only screen and (max-width: 615px) {
  .reviewRequest-main-component .review-discharge-request {
    margin-bottom: 80px;
  }
  .discharge-request-error .discharge-request-error-pop-up {
    width: 270px;
  }
}

@media only screen and (max-width: 350px) {
  .reviewRequest-main-component .review-discharge-request {
    margin-bottom: 275px;
  }
  .review-discharge-request
    .discharge-request-grid-container
    .discharge-request-grid-item {
    margin-top: 3%;
  }
}

@media only screen and (max-width: 615px) {
  .reviewRequest-main-component .review-discharge-request {
    margin-bottom: 150px;
  }
}

@media only screen and (max-width: 445px) {
  .reviewRequest-main-component .review-discharge-request {
    margin-bottom: 175px;
  }
}

@media only screen and (max-width: 350px) {
  .reviewRequest-main-component .review-discharge-request {
    margin-bottom: 275px;
  }
}
