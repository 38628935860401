.selectedSupplies-main .selectedSupplies-price-grid-main {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.selectedSupplies-main {
  padding-right: 16px;
}
.inpatient-supplyorder-selectedsupplies-main-div {
  padding: 16px;
  width: 90%;
}

.inpatient-supplyorder-selectedsupplies-main-review-div {
  margin-top: 20px;
  width: 90%;
}
.inpatient-order-selectedsupplies-main-review-div {
  margin-top: 20px;
}

@media screen and (min-width: 320px) and (max-width: 414px) {
  .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 100%;
  }
  .inpatient-order-selectedsupplies-main-review-div {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .inpatient-order-selectedsupplies-main-review-div {
    width: 90%;
  }
}
@media screen and (min-width: 1600px) {
  .inpatient-supplyorder-selectedsupplies-main-div {
    width: 70%;
  }
  .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 70%;
  }
  .inpatient-order-selectedsupplies-main-review-div {
    width: 65%;
  }
}
.inpatient-supplyorder-selectedsupplies-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-bottom: 10px;
}

.selectedSupplies-main {
  margin-top: 20px;
}

.selectedSupplies-price-grid-main
  .selectedSupplies-price-grid-sub-totalpricelabel {
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
}
.selectedSupplies-price-grid-main .selectedSupplies-price-grid-sub-totalprice {
  display: flex;
  justify-content: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
}

.selectedSupplies-price-grid-main
  .selectedSupplies-price-grid-final-totalprice {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #323234;
}

.selectedSupplies-price-grid-sub-note {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.selectedSupplies-price-div {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}
@media only screen and (max-width: 920px) {
  .selectedSupplies-main .selectedSupplies-price-grid-main {
    flex-direction: column;
    margin-top: 10px;
  }
  .selectedSupplies-price-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  .selectedSupplies-price-grid-main
    .selectedSupplies-price-grid-sub-totalpricelabel {
    justify-content: unset;
  }
  .selectedSupplies-price-grid-main
    .selectedSupplies-price-grid-sub-totalprice {
    justify-content: unset;
  }
  .selectedSupplies-price-grid-main
    .selectedSupplies-price-grid-final-totalprice {
    display: flex;
    justify-content: unset;
  }
  .selectedSupplies-price-grid-main .selectedSupplies-price-grid-sub-note {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 920px) {
  .inpatient-supplyorder-selectedsupplies-main-div {
    padding: unset;
    width: calc(100% - 20px);
    margin: 10px;
  }
}

.inpatient-selected-supplies-column {
  justify-content: right;
}
