.failRxUpload {
  padding: 16px;
}
.failText {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin: 0 0 16px;
}
.failTextLight {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.failRxUploadDoneBtn {
  margin-top: 32px;
}
.failRxUploadBtn {
  width: 100%;
}
