.provideAdditionalWoundInfo {
  margin-top: 30px;
  margin-bottom: 80px;
}

.additionalWoundInfoTextarea {
  width: 100%;
  height: 91px;
  line-height: 16px;
  margin-top: -1px;
  border: none;
}
.provideAdditionalWoundInfoError {
  font-weight: 700px;
  font-size: 12px;
  font-style: italic;
  color: #d32f2f !important;
  width: 564px;
}
.noProvideAdditionalWoundError {
  display: none;
}
.additionalNotestMain {
  width: 560px;
}
@media only screen and (max-width: 600px) {
  .additionalNotestMain {
    width: 100%;
  }
  .additionalWoundInfoTextarea {
    width: 100%;
  }
}
