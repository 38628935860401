.radio-question-root {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin-left: 0;
  margin-right: 0;
}
.optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.radio-question-txt {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
}
