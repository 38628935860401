.sales-assistance-additional-info-component {
  margin: 40px 0px;
  width: 100%;
}

.sales-assistance-additional-info-component .additional-info-input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0px;
}
.sales-assistance-additional-info-component .additional-info-grid-container {
  width: 100%;
}
.sales-assistance-additional-info-component .additional-info-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}
.sales-assistance-additional-info-component
  .additional-info-grid-container
  .additional-info-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.sales-assistance-additional-info-component
  .additional-info-grid-container
  .additional-info-grid-item
  .additional-info-input {
  width: 100%;
}

.additional-info-input .showCommentError {
  border: 1px solid #d32f2f !important;
}

.additional-info-grid-item .noCommentError {
  border: none;
}
