.sales-assistance-upload-doc-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.salesAssistance {
  margin: 0 16px 0 0;
  width: calc(68% - 16px);
}

.salesAssistance .salesAssistance-upload-document-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 40px;
}

.salesAssistance .salesAssistance-upload-document-description {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  align-self: stretch;
  flex-grow: 0;
}

.salesAssistance .uploadDocDesp .npwtLinkFont {
  color: #0049bd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-decoration-line: underline;
}

.salesAssistance .dropZoneStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 138px;
  background: #f8f8fa;
  cursor: pointer;
  border: 1px dashed #1e64d0;
}

.salesAssistance .dragAndDrop {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration-line: underline;
  color: #76767a;
}

.salesAssistance .uploadWidgetCard {
  margin-top: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border: 1px solid #e4e4e8;
  margin-bottom: 15px;
}

.salesAssistance .uploadIconStyle {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}

.salesAssistance .fileDetailCard {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  margin-bottom: 10px;
}

.salesAssistance .fileDetailCard .filename {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 50%;
  align-self: center;
  overflow-wrap: break-word;
  margin-left: 11px;
}

.salesAssistance .fileDetailCard .fileStatus {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 40%;
  color: #007713;
  align-self: center;
  text-align-last: right;
}

.fileDetailCard .fileClose {
  width: 50px;
  align-self: center;
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.salesAssistance .fileDetailCard .fileStatuserror {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: 40%;
  color: #cb0000;
  align-self: center;
  text-align-last: right;
}

.uploadDocDesp {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  align-self: stretch;
  flex-grow: 0;
}
@media only screen and (min-width: 601px) and (max-width: 920px) {
  .salesAssistance {
    margin: 0 16px 0 0;
    width: calc(96% - 16px);
  }
  .salesAssistance .fileDetailCard .fileStatus {
    width: 240px;
  }
  .salesAssistance .fileDetailCard .fileStatuserror {
    width: 113px;
  }
}
@media only screen and (max-width: 600px) {
  .salesAssistance {
    margin: 0 16px 0 0;
    width: calc(100% - 16px);
  }
  .salesAssistance .fileDetailCard .fileStatuserror {
    width: 168px;
  }
}
@media only screen and (max-width: 321px) {
  .salesAssistance .fileDetailCard .fileStatuserror {
    width: 91px;
  }
  .salesAssistance .fileDetailCard .fileStatus {
    width: 85px;
  }
}
