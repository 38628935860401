h2.o-form-head {
  display: none;
}

#okta-sign-in .auth-header {
  padding: 122px 0 0 0 !important;
  display: none;
}

#okta-sign-in {
  border-style: none !important;
}

#okta-sign-in.auth-container.main-container {
  box-shadow: none !important;
  padding: 16px;

  max-width: 264px;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  min-width: auto;
  margin: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#okta-sign-in .auth-content {
  max-width: 264px;
  padding: 0;
}

#okta-sign-in.no-beacon .auth-content {
  padding-top: 3px;
}

#okta-sign-in .auth-footer {
  display: none;
}

#okta-sign-in .focused-input {
  box-shadow: none;
}

#okta-sign-in.auth-container.main-container .margin-btm-5 {
  margin-bottom: 38px !important;
}

#okta-sign-in .o-form .o-form-fieldset.margin-btm-0 {
  margin-bottom: 20px;
}

#okta-sign-in.auth-container .button-primary {
  background: #0049bd;
}

#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:hover {
  background: #0049bd;
}

#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:hover {
  background: #0049bd;
}

#okta-sign-in .o-form-input-error .icon-16 {
  left: 0;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border-bottom: none;
}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus,
#okta-sign-in input[type="radio"]:focus + label,
#okta-sign-in input[type="text"]:focus {
  box-shadow: none;
}

#okta-sign-in.auth-container .okta-form-label {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

#okta-sign-in .o-form .custom-checkbox label {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  color: #4c4c4f;
}

#okta-sign-in .o-form-button-bar {
  padding-bottom: 16px;
}

#okta-sign-in .visibility-16:before {
  content: url("../assets/passwordeye.svg");
  color: #76767a;
}

#okta-sign-in .visibility-off-16:before {
  content: url("../assets/reveal.svg");
  color: #76767a;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
.okta-form-label:after {
  content: "*";
  color: red;
}
/* mfa screen icon */

#okta-sign-in.auth-container .mfa-okta-verify {
  display: none;
}

#okta-sign-in.auth-container .mfa-okta-email {
  display: none;
}

#okta-sign-in.auth-container .mfa-okta-phone {
  display: none;
}

#okta-sign-in.auth-container .okta-sign-in-beacon-border {
  border-color: transparent;
}

#okta-sign-in .mfa-verify .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .enroll-totp .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .enroll-choices .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .enroll-email .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .enroll-activate-email .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .enroll-manual-push .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .activate-push .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .recovery-question .o-form-button-bar .button {
  width: auto;
  margin: auto;
}

#okta-sign-in .password-reset .o-form-button-bar .button {
  width: auto;
  margin: auto;
}
#okta-sign-in
  > div.auth-content
  > div
  > div
  > div.siw-main-footer
  > div
  > a.link.js-unlock {
  display: none;
}

/* help link*/
#okta-sign-in
  > div.auth-content
  > div
  > div
  > div.siw-main-footer
  > div
  > a.link.js-help {
  display: none;
}

#okta-sign-in
  > div.auth-content
  > div
  > div
  > div.siw-main-footer
  > div
  > a.link.js-forgot-password {
  display: none;
}

.authenticator-icon-container {
  display: none !important;
}
#okta-sign-in .siw-main-header .beacon-container {
  display: none;
}
