.prescription-option {
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  margin-top: 16px;
  flex-direction: row;
}

.prescription-option .radio-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  height: 20px;
}

.prescription-option .prescription-option-text {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.prescription-option .radio-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 1px;
  margin-left: 4px;
  margin-top: 4px;
}

.prescription-option .radio-description {
  color: #656569;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 1px;
}

.changePrescriptionTypesTitle {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  margin-top: 3px;
}

.phone-number {
  color: #656569;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
}
