.patient-not-found-component {
  padding: 16px;
  width: calc(100% - 32px);
}

.patient-not-found-component .error-message-div {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  margin: 24px 0px 24px;
  padding: 9px 16px;
  width: calc(100% - 32px);
}

.patient-not-found-component .error-message-div .error-message-text {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.error-message-div .error-message .phone-number-with-extension {
  color: #070708;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  white-space: nowrap;
}

.patient-not-found-component .error-message-div .error-code-info {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: left;
  width: 100%;
}

.patient-not-found-component .search-again {
  width: 100%;
}
