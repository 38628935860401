.send-note-component {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 16px 101px 16px;
  width: calc(100% - 120px);
  min-height: calc(100vh - 144px);
}

.send-note-component .send-note-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.send-note-component .send-note-route-section {
  margin: 40px auto 26px 40px;
}

.send-note-component .send-note-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px 16px;
  width: 100%;
}

.send-note-component .short-form {
  display: flex;
  flex-direction: column;
  width: 62%;
}

.short-form .headernote {
  margin-bottom: 30px;
  margin-left: 40px;
  width: 100%;
}

.headernote .title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.headernote .description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
  margin-top: 0px;
}

.short-form .send-note-contact-reason-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
}

.short-form .send-note-contact-reason-div .send-note-contact-reason {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.short-form .send-note-input {
  width: 100%;
}

.short-form .send-note-input .send-note-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.short-form div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.short-form .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.send-note-component .change-address-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.send-note-component .transfer-patient-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.send-note-component .ask-question-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}
.updateHomeCareProvider-div {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 62%;
}
.updateHomeCareProvider-selectedtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.send-note-component .current-selected-prescriber-div {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
}

.send-note-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.send-note-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.send-note-loader-pop-up .send-note-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.send-note-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.patient-details-component {
  min-height: 150px !important;
}
.sendnote-subtext-caregiver {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
@media screen and (max-width: 320px) {
  .headernote .description {
    width: 250px;
  }
}
@media screen and (min-width: 330px) and (max-width: 450px) {
  .headernote .description {
    width: 300px;
  }
}
@media screen and (max-width: 600px) {
  .short-form .send-note-contact-reason-div {
    width: 100%;
  }
  .send-note-loader-pop-up .send-note-loader {
    width: 250px;
  }
  .send-note-component {
    width: calc(100% - 32px);
    min-height: 700px;
    margin: 16px;
  }
  .send-note-component .short-form {
    width: calc(100% - 32px);
  }
  .short-form .headernote {
    margin-left: 15px;
  }
  .send-note-component .send-note-route-section {
    margin: 40px 26px 14px;
  }
  .send-note-component .send-note-loader {
    margin-top: 260px;
  }
  .updateHomeCareProvider-div {
    margin-left: 10px;
    width: calc(100% - 30px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .send-note-component {
    padding-bottom: 123px;
    margin: 11px 117px 11px 11px;
  }
  .send-note-component .short-form {
    width: 82%;
  }
  .updateHomeCareProvider-div {
    width: 82%;
  }
}
@media screen and (max-width: 280px) {
  body {
    margin: 0;
  }
}
