.placeAnOrder-main-component {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 16px;
  width: 691px;
}
.placeAnOrder-text-section .placeAnOrder-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 24px 10px 0px;
}
.placeAnOrder-select-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 9px;
  margin-bottom: 10px;
}
.placeAnOrder-facility-details-section {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.placeAnOrder-addPatientbutton {
  margin-top: 11px;
}

@media screen and (min-width: 300px) and (max-width: 600px) {
  .placeAnOrder-addPatientbutton {
    width: 100px;
    margin-left: 30px;
    padding-top: 5px;
  }
}
.placeAnOrder-main-component
  .placeAnOrder-text-section
  .placeAnOrder-container {
  align-items: center;
  padding: 15px 24px;
  width: 100%;
}
.placeAnOrder-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.placeAnOrder-grid-item-add-patient {
  align-items: flex-start;
  width: 100%;
}
.placeOrderContainer {
  padding: 16px 16px 24px 16px;
  width: 574px;
  height: auto;
}
.placeOrderContainer .placeOrderContainer-heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding: 0px 10px 10px 0px;
}
.placeOrderContainer .placeOrderContainer-content1 {
  font-size: 14px;
  line-height: 18px;
}
.placeOrderContainer .placeOrderContainer-content2 {
  font-size: 14px;
  line-height: 18px;
}
.placeOrderContainer .placeOrderContainer-content2 .createAcc {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}
.placeOrderContainer .placeOrderButtons {
  display: flex;
  gap: 15px;
}
.placeOrderContainer .placeOrderButtons .placeOrderButton1 .login-button {
  width: 280px;
}
.placeOrderContainer .placeOrderButtons .placeOrderButton2 .createAcc-button {
  width: 280px;
}
@media only screen and (max-width: 600px) {
  .placeOrderContainer {
    width: 285px;
    height: auto;
  }
  .placeOrderContainer .placeOrderButtons {
    display: flex;
    flex-direction: column;
  }
  .placeAnOrder-main-component {
    width: 285px;
  }
  .placeAnOrder-select-patient .placeAnOrder-container {
    display: block;
  }
  .placeAnOrder-container .placeAnOrder-grid-item-add-patient {
    max-width: 100%;
  }
  .placeAnOrder-container .placeAnOrder-grid-item {
    max-width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 920px) {
  .placeAnOrder-main-component {
    width: 550px;
  }
}
