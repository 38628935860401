.facility-supply-order-list-page-main-div {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.facility-supply-order-list-page-main-div .facility-supply-order-breadCrumbs {
  margin: 32px 40px;
}

.facilitySupplyOrderMaindiv-aggrid {
  padding: 20px 40px 40px 40px;
}

.facility-supply-orders-header-and-buttons .facility-supply-button-group {
  display: flex;
}

.facility-supply-order-list-page-main-div
  .facility-supply-orders-header-and-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 40px;
}

.facility-supply-order-list-page-main-div
  .facility-supply-orders-header-and-buttons
  .facility-supply-header-buttons {
  cursor: pointer;
  margin-right: 16px;
}
.facility-supply-order-list-page-main-div
  .facility-supply-orders-header-and-buttons
  .facility-supply-header-buttons.no-margin-right {
  margin-right: 0;
}
.facility-supply-order-list-page-main-div
  .facility-supply-orders-header-and-buttons
  .facility-supply-header-download-button {
  margin-right: 16px;
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}

.facility-supply-order-list-page-main-div
  .facility-supply-header-download-button:disabled {
  color: #76767a;
}

.facility-supply-order-list-page-main-div
  .facility-supply-orders-header-and-buttons
  .facility-supply-header-buttons
  :hover {
  background: #ffffff;
}
.facility-supply-order-list-page-main-div .facility-supply-order-searchbar-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
}
.facility-supply-order-searchbar-div .facility-supply-order-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  height: 40px;
  width: 100%;
}

.in-patient-facility-supply-order-container-popup
  .in-patient-facility-supply-order-container-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
}
.in-patient-facility-supply-order-container-popup .paperRoot {
  width: 400px;
  padding: 16px;
}

.facilitySupplyOrderStatus .facility-supply-order-greenCircle {
  color: #00ac31;
  border-radius: 10px;
  height: 10px;
  margin: 6px 4px auto 0px;
  width: 10px;
}

.facilitySupplyOrderStatus .facility-supply-order-blueCircle {
  color: #1e64d0;
  border-radius: 10px;
  height: 10px;
  margin: 6px 4px auto 0px;
  width: 10px;
}

.in-patient-facility-supply-order-header-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.inpatient-facility-supplyorder-ro {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}

.facility-supply-order-list .MuiInputBase-input {
  text-overflow: ellipsis;
}
.facility-supply-order-searchbar .MuiOutlinedInput-root {
  max-height: 42px;
}
.facility-supply-order-searchbar .search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  max-height: 42px;
}

.facility-supply-order-searchbar .status-input {
  max-height: 42px;
  color: #76767a;
  width: 100%;
}

.facility-supply-order-searchbar .status-input .status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
  max-height: 42px;
}
@media screen and (max-width: 600px) {
  .facility-supply-order-list-page-main-div {
    width: 100%;
  }
  .facility-supply-order-list-page-main-div .facility-supply-order-breadCrumbs {
    margin: 32px 16px;
  }
  .facility-supply-order-list-page-main-div
    .facility-supply-orders-header-and-buttons {
    margin: 0px 16px;
  }
  .facilitySupplyOrderMaindiv-aggrid {
    padding: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 330px) {
  .facility-supply-order-list-page-main-div
    .facility-supply-order-breadCrumbs
    .link-to-navigator {
    font-size: 13px;
  }
  .facility-supply-order-list-page-main-div
    .facility-supply-order-breadCrumbs
    .title-txt {
    font-size: 13px;
  }
}
.pagination-component-grid-container {
  margin: 16px 0px;
  padding: 0px;
  width: 100%;
}

.pagination-component-grid-item {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.pagination-component-grid-item .items-per-page-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pagination-component-grid-item .items-per-page-dropdown .items-per-page-title {
  margin: 10px 8px 10px 0px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.pagination-component-grid-item .items-per-page-dropdown .items-per-page-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  line-height: 16px;
  width: 70px;
}

.pagination-component-grid-item
  .items-per-page-dropdown
  .items-per-page-input
  .items-per-page-select {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: pre;
}

.pagination-component-grid-item .pagination-component-number-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 26px;
  margin: 4px 0px;
  width: 100%;
}

.pagination-component-grid-item
  .pagination-component-number-list
  button.disabled {
  color: grey;
}

.pagination-component-grid-item .pagination-component-number-list button {
  color: #0049bd;
}

.pagination-component-grid-item .pagination-component-total-counts {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination-component-grid-item
  .pagination-component-total-counts
  .last-section-right {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
}
@media screen and (max-width: 600px) {
  .facility-supply-order-list-page-main-div
    .facility-supply-order-searchbar-div {
    padding: 16px;
  }
}
