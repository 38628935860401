.proofOfDeliveryOptions {
  width: 60%;
}

.proofOfDeliveryOptions .pod-container {
  width: 100%;
}

.pod-container .pod-option-title {
  width: 100%;
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 5px;
}

.pod-container .pod-option-icon {
  cursor: pointer;
}

.pod-container .pod-option-title-grid {
  margin-top: 8px;
}

.pod-container .pod-option-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 42px;
}

@media only screen and (max-width: 600px) {
  .pod-container .pod-option-desc {
    margin-left: 0px;
    width: 100%;
  }
  .proofOfDeliveryOptions {
    width: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .pod-container .pod-option-desc {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 912px) {
  .proofOfDeliveryOptions {
    width: 70%;
  }
}
