.product-images {
  display: flex;
  flex-direction: column;
}

.product-images .product-main-image {
  display: flex;
  flex-direction: column;
}

.product-images .product-main-image .product-image {
  height: 308px;
  object-fit: contain;
  width: 308px;
}

.product-images .product-thumbnail-image {
  cursor: pointer;
  display: flex;
  text-align: center;
  width: 100%;
}

.product-images .product-thumbnail-image .thumbnail-image {
  border: 10px solid #f8f8fa;
  height: 56px;
  margin: 8px;
  width: 56px;
}
@media only screen and (max-width: 820px) {
  .product-images .product-main-image .product-image {
    width: unset;
  }
}
