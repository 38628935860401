.administration-main {
  width: 100%;
}
.administration-main .administration {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 16px;
  width: 100%;
  min-height: calc(100vh - 144px);
}
.administration-main .return-to-dashboard {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 112px;
}
.administration-main .return-to-dashboard-button {
  color: #0049bd;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 18px;
  padding: 0;
  text-align: left;
  text-transform: none;
  width: auto;
}
.administration-main .return-to-dashboard-button:hover {
  background-color: transparent;
}
.administration-main .internalAdministration {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0px 112px 16px;
  min-height: calc(100vh - 144px);
}
.administration-main .administrationTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 24px;
  margin-left: 24px;
}
.administration-main .administration .administration-container-main {
  width: auto;
  margin-left: 40px;
  gap: 80px;
}
.administration-main .internalAdministration .administration-container-main {
  width: auto;
  margin-left: 40px;
  gap: 80px;
}
.administration-main .administrationMainTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.administration-main .admin-subTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 3px;
}
.administration-main .admin-subTitleWidth {
  width: 241px;
}

.administration-main .adminBtnGrid .adminBtnMain {
  width: 241.33px;
  height: 40px;
  border-radius: 2px;
}

.administration-main .administration-container-main .adminBtnGrid {
  margin-top: 16px;
}

.administration-main .madminBtnStyle {
  display: block;
  width: 20px;
  height: 16px;
  background: #e8f4ff;
  border-radius: 24px;
  font-size: 12px;
}

.administration-main .madminBtnMain {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 1200px) {
  .administration-main .administration {
    margin: 0px;
  }
  .administration-main .internalAdministration {
    margin: 0px;
    padding-bottom: 32px;
  }
  .administration-main .administration .administration-container-main {
    margin-bottom: 40px;
  }
  .administration-main .return-to-dashboard {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .administration-main .internalAdministration {
    width: auto;
  }
  .administration-main .return-to-dashboard {
    margin: 16px;
  }
  .administration-main .internalAdministration .administration-container-main {
    margin: 0px 32px;
  }
}
