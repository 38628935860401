.site-status-banners-component {
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  max-height: 220px;
  overflow-y: auto;
  width: 100%;
}

.site-status-banners-component::-webkit-scrollbar {
  -webkit-appearance: none;
}

.site-status-banners-component::-webkit-scrollbar:vertical {
  width: 11px;
}

.site-status-banners-component::-webkit-scrollbar:horizontal {
  height: 11px;
}

.site-status-banners-component::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
