.patient-vac-reviewdetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 24px 16px 16px 0px;
  width: 67%;
}

.patient-vac-reviewdetails .patient-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  min-height: 20px;
  line-height: 20px;
}

.patient-vac-reviewdetails .patient-dob {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  min-height: 22px;
  line-height: 22px;
}

.patient-vac-reviewdetails .device-details {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 0px;
}

.patient-vac-reviewdetails .device-image {
  height: 56px;
  width: 56px;
}

.patient-vac-reviewdetails .device-descriptiopn {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 17px 8px;
  line-height: 22px;
}
@media screen and (max-width: 600px) {
  .patient-vac-reviewdetails {
    margin: 0;
    width: 100%;
  }
}
@media screen and (max-width: 515px) {
  .patient-vac-reviewdetails {
    margin: 0;
    width: 100%;
  }
}
