.pick-up-main-component {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 16px 186px;
  width: calc(100% - 120px);
}

.pick-up-main-component .pick-up-main-component-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  height: 100%;
  width: 100%;
}

.pick-up-main-component .pickup-request-component {
  margin: 40px 40px 26px;
}

.pickup-request-component .pickup-request-route-section {
  margin-bottom: 26px;
}

.pickup-request-component .pickup-request-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.pickup-request-component .pickup-request-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px 16px;
  width: 100%;
}

.pickup-request-component .short-form {
  display: flex;
  flex-direction: column;
  width: 62.78%;
}

.pickup-request-component .pickup-request-component-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  min-height: 24px;
  line-height: 24px;
  width: calc(100% - 16px);
}
.pickup-request-component .note-confirmation-popup {
  width: 100%;
}

.confirmation-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  margin: 0px 16px;
  min-height: 208px;
  max-width: 516px;
  width: calc(100% - 32px);
}

.confirmation-pop-up .title-main {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
}

.confirmation-pop-up .title-desc {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 32px 0;
}

.confirmation-pop-up .back-btn {
  margin: 0px;
  text-transform: none;
  width: 100%;
}

@media screen and (max-width: 920px) {
  .pick-up-main-component {
    margin: 16px 16px 225px;
    width: calc(100% - 32px);
  }

  .pickup-request-component .short-form {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .pick-up-main-component .pickup-request-component {
    margin: 16px;
  }
}

@media screen and (min-width: 446px) and (max-width: 600px) {
  .pick-up-main-component {
    margin: 16px 16px 225px;
    width: calc(100% - 32px);
  }

  .pickup-request-component .short-form {
    width: 100%;
  }
}

@media screen and (width: 320px) {
  .pick-up-main-component {
    margin: 16px 0px 380px;
    width: 100%;
  }
  .pick-up-main-component .pickup-request-component {
    margin: 16px;
  }
}
@media screen and (min-width: 360px) and (max-width: 445px) {
  .pick-up-main-component {
    margin: 16px 0px 210px;
    width: 100%;
  }

  .pick-up-main-component .pickup-request-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .pickup-request-component .short-form {
    width: 100%;
  }
}
