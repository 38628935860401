.in-patient-order-placement-date-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  width: 100%;
}

.in-patient-order-placement-date-component
  .in-patient-order-placement-date-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 16px 0px;
  text-align: left;
}

.in-patient-order-placement-date-component
  .in-patient-order-placement-date-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-placement-date-component
  .in-patient-order-placement-date-grid-container
  .in-patient-order-placement-date-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.in-patient-order-placement-date-component
  .in-patient-order-placement-date-grid-container
  .in-patient-order-placement-date-grid-item
  .in-patient-order-placement-date-title {
  color: #323234;
  margin: 0px;
}

.in-patient-order-placement-date-component
  .in-patient-order-placement-date-grid-container
  .in-patient-order-placement-date-grid-item
  .in-patient-order-placement-date-title.error {
  color: #cb0000;
}

.in-patient-order-placement-date input.input {
  border-right: 2px;
  width: 100%;
}

div.in-patient-order-placement-date {
  width: 100%;
}

div.in-patient-order-placement-date .adornedRoot {
  position: absolute;
  right: 18px;
}

.in-patient-order-placement-date .outline {
  border-radius: 1px;
  border: 1px solid #94949a !important;
  width: calc(100% - 18px);
}

.in-patient-order-placement-date.show-error .outline {
  border: 1px solid #cb0000 !important;
}

.in-patient-order-placement-date.no-error.Mui-error .outline {
  border: 1px solid #94949a !important;
}

@media only screen and (max-width: 926px) {
  .in-patient-order-placement-date-component {
    width: 100%;
  }
}
