.titleOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.selectedDressingTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #323234;
}
.submittedDate {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #76767a;
  align-self: center;
}
.editSelection {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  color: #0049bd;
  cursor: pointer;
}
.vacDressingkitHeader {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
  margin-top: 15px;
}

.dressing-kit-content-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 3px 0 0 8px;
  min-height: 18px;
}
.dressing-kit-size-content-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #76767a;
}
.dressing-kit-size-content-value .dot-with-space-kit {
  margin-right: 8px;
}
@media only screen and (max-width: 920px) {
  .supply-order-title-and-date {
    display: flex;
    flex-direction: column;
  }
  .submittedDate {
    margin-left: 0px;
    align-self: flex-start;
  }
  .editSelection {
    display: flex;
    align-items: center;
  }
}
