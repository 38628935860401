.wound-assessment-report-table-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
  width: 100%;
}

.wound-assessment-report-table-component
  .wound-assessment-report-table-container {
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px;
  overflow: auto;
  width: 100%;
}

.wound-assessment-report-table-container .wound-assessment-report-table {
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  .no-data {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  width: 100%;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  .no-data
  .no-data-message {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.wound-assessment-report-table-container .wound-assessment-report-table th {
  color: #76767a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 4px 8px;
  text-align: left;
  width: calc(100% - 16px);
}

.wound-assessment-report-table-container .wound-assessment-report-table th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.wound-assessment-report-table-container .wound-assessment-report-table td {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 56px;
  padding: 4px 8px;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tbody
  tr:first-child
  td {
  border-top: none;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tbody
  tr:nth-child(n)
  td {
  background: #f8f8fa;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tbody
  tr:nth-child(2n)
  td {
  background: #fff;
}

.wound-assessment-report-table-container .wound-assessment-report-table thead {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  tr {
  margin: 0px;
  width: 100%;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th {
  height: 48px;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(1) {
  min-width: 80px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(2) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(3) {
  min-width: 112px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(4) {
  min-width: 120px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(5) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(6) {
  min-width: 160px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(7) {
  min-width: 108px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(8) {
  min-width: 120px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(9) {
  min-width: 90px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(10) {
  min-width: 128px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(11) {
  min-width: 136px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(12) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(13) {
  min-width: 90px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  thead
  th:nth-child(14) {
  min-width: 108px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container .wound-assessment-report-table th.up {
  background-image: url("../../../../../assets/up_arrow.svg");
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  th.down {
  background-image: url("../../../../../assets/down_arrow.svg");
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  th.default {
  background-image: url("../../../../../assets/default.svg");
}

.wound-assessment-report-table .wound-assessment-report-row {
  min-height: 56px;
}

.wound-assessment-report-table-container .wound-assessment-report-table tbody {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.wound-assessment-report-table-container .wound-assessment-report-table tr {
  margin: 0px;
  width: 100%;
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(1) {
  min-width: 80px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(2) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(3) {
  min-width: 112px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(4) {
  min-width: 120px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(5) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(6) {
  min-width: 160px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(7) {
  min-width: 108px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(8) {
  min-width: 120px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(9) {
  min-width: 90px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(10) {
  min-width: 128px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(11) {
  min-width: 136px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(12) {
  min-width: 88px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(13) {
  min-width: 90px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table-container
  .wound-assessment-report-table
  tr
  td:nth-child(14) {
  min-width: 108px;
  background-position: calc(100% - 8px);
}

.wound-assessment-report-table .wound-assessment-report-basic-detail {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 56px;
}

.wound-assessment-report-table .wound-assessment-report-basic-detail.failed {
  color: #cb0000;
}

.wound-assessment-report-table .wound-assessment-report-ro-number {
  color: #0049bd;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  min-height: 56px;
  text-decoration: underline;
}

.wound-assessment-report-table .wound-assessment-report-therapy-status {
  display: flex;
  flex-direction: row;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain {
  border-radius: 10px;
  height: 10px;
  margin: auto 4px auto 0px;
  width: 10px;
}
.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.yellowCircle {
  color: #eaca00;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.orangeCircle {
  color: #ff9649;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.redCircle {
  color: #cb0000;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.greenCircle {
  color: #00ac31;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.greyCircle {
  color: #94949a;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.blueCircle {
  color: #1e64d0;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .statusIconMain.purpleCircle {
  color: purple;
}

.wound-assessment-report-table
  .wound-assessment-report-therapy-status
  .therapy-status-detail
  .order-status {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: auto 0px;
  text-align: left;
}

.wound-assessment-report-table .wound-assessment-report-assessment {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-height: 56px;
  text-align: center;
}

.wound-assessment-report-table
  .wound-assessment-report-assessment
  .attachment-detail {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.wound-assessment-report-table-component .pagination-grid-container {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.wound-assessment-report-table-component
  .pagination-grid-container
  .pagination-grid-item {
  margin: 8px 0px;
  padding: 0px;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-title {
  margin: 10px 8px 10px 0px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.pagination-grid-item .items-per-page-dropdown .items-per-page-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  line-height: 16px;
  width: 70px;
}

.pagination-grid-item
  .items-per-page-dropdown
  .items-per-page-input
  .items-per-page-select {
  align-items: center;
  display: flex;
  overflow: hidden;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: pre;
}

.pagination-grid-item .pagination-number-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 26px;
  margin: 4px 0px;
  width: 100%;
}

.pagination-grid-item .pagination-number-list button.disabled {
  color: grey;
}

.pagination-grid-item .pagination-number-list button {
  color: #0049bd;
}

.pagination-grid-item .pagination-total-counts {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination-grid-item .pagination-total-counts .last-section-right {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: fit-content;
}
