.manage-user-accounts-users-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -8px 0px 0px;
  width: 100%;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-search-container {
  background-color: #f8f8fa;
  border: 1px solid #e4e4e8;
  margin: 0px;
  padding: 0px 16px 16px 0;
  width: 100%;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-input-item {
  margin: 0px;
  padding: 16px 0 0 16px;
  width: 100%;
}

.manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-item
  .manage-user-accounts-subtitle {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 16px 0px;
}

.manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-item
  .manage-user-accounts-subtitle-description {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 8px 16px 16px;
}

.manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-input-item
  .manage-user-accounts-input {
  width: 100%;
}

.manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-input-item
  .search-button {
  align-items: center;
  background: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  margin: 0px;
  text-transform: none;
  width: 100%;
}

.manage-user-accounts-grid-search-container
  .manage-user-accounts-grid-input-item
  .search-button:disabled {
  background: #e4e4e8;
  color: #76767a;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-result-container {
  margin: 0px;
  padding: 16px 0px;
  width: 100%;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.manage-user-accounts-users-component
  .manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-input-item {
  margin: 0px;
  padding: 16px 0 0 16px;
  width: 100%;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .search-result {
  align-items: center;
  color: #323234;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 4px 0px;
  justify-content: flex-start;
  height: 40px;
  line-height: 20px;
}
.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .button-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .button-div
  .add-new-user-button {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0;
  text-transform: capitalize;
  text-decoration-line: underline;
  width: max-content;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .button-div
  .add-new-user-button:disabled {
  color: #76767a;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .button-div
  .add-new-user-button
  .add-new-user-icon {
  margin-left: -4px;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .search-result-description {
  align-items: center;
  background-color: #e8f4ff;
  color: #323234;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: flex-start;
  line-height: 18px;
  padding: 24px 16px;
}
.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .search-noresult-description
  .search-noresult-description-span {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  align-self: flex-start;
  margin-bottom: 0px;
}

.manage-user-accounts-grid-result-container
  .manage-user-accounts-grid-item
  .search-noresult-description {
  background-color: #f8f8fa;
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

@media only screen and (max-width: 768px) {
  .manage-user-accounts-grid-search-container
    .manage-user-accounts-grid-input-item
    .search-button {
    margin: 16px 0;
  }
}

@media only screen and (max-width: 450px) {
  .manage-user-accounts-grid-result-container
    .manage-user-accounts-grid-item
    .add-new-user-button {
    justify-content: flex-start;
  }
}
