.email-notification-prefereces {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email-notification-prefereces .email-notification-preferences-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 56px 0px 8px 0px;
}

.email-notification-prefereces .email-notification-preferences-body {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  padding: 0px;
}

.email-notification-prefereces .email-notification-preferences-description {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 40px 0;
  word-break: break-word;
  padding: 0px;
  width: 100%;
}

.email-notification-preferences-description .bolder {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .email-notification-prefereces {
    padding-left: 8px;
  }
}
