.shared-order-header-title {
  background-color: #f1f1fc;
  color: #5d5dbd;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0;
  margin-right: 56px;
  padding: 8px 16px;
  width: max-content;
}
.shared-alert-body {
  margin: 6px 16px 24px 16px;
}
.sharedFromName {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}

.your-note {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 10px;
}

.your-note-desp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.stop-sharing {
  width: 50%;
}

.edit-order {
  width: 50%;
}

.stop-sharing-spinner {
  min-width: 574px;
  min-height: 188px;
}

.order-sharing-stopped .paperRoot {
  max-width: 574px;
  min-height: 188px;
}

.spinner-space {
  margin-top: 25px;
}

.stopper-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.order-sharing-stop .paperRoot {
  min-width: 574px;
  max-width: 574px;
  min-height: 188px;
  padding: 16px;
}

.stopper-desp {
  margin-top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 24px;
}
.stopper-desp-errorCode {
  margin-bottom: unset;
}

.button-row-sharing {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  gap: 16px;
  width: 100%;
}
