.secondary-wound {
  margin-top: 0px;
  width: 100%;
}

.secondary-wound .add-secondary-wound {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  line-height: 20px;
  margin-left: 0px;
  margin-top: 40px;
  min-height: 48px;
  min-width: 217px;
  text-transform: capitalize;
}

.secondary-wound .remove-secondary-wound {
  align-items: center;
  border: 1px solid;
  border-color: #cb0000;
  border-radius: 2px;
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  line-height: 20px;
  margin-left: 0px;
  margin-top: 40px;
  min-height: 48px;
  min-width: 217px;
  text-transform: capitalize;
}

.secondary-wound .remove-secondary-wound:hover {
  border-color: #cb0000;
}

.secondary-wound .secondary-wound-detail {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}
