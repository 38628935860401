.review-woundAssessor .review-woundAssessor-title {
  margin-top: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 7px;
}
.review-woundAssessor .review-woundAssessor-titlediv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.review-woundAssessor
  .review-woundAssessor-edit
  .review-woundAssessor-edit-button {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  top: 20px;
  position: relative;
  margin-left: 4px;
  text-decoration-line: underline;
  text-transform: none;
  cursor: pointer;
}
.review-woundAssessor-row {
  display: flex;
  flex-direction: row;
}
