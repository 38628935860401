.edit-email-section {
  width: 100%;
  display: flex;
}

.edit-email-section .edit-prescriber-email-button {
  display: flex;
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0px 8px 0;
  padding: 0px;
  text-align: right;
  text-decoration: underline;
  text-transform: none;
}
.edit-email-section .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.edit-email-section .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.edit-email-input {
  width: 100%;
}
.edit-email-section .edit-email-input-multiple {
  width: calc(90% - 50px);
}

.edit-email-section .extra-input-field {
  width: 70%;
  display: flex;
  margin: 0;
}

.edit-email-section .extra-input-field .extra-input-base-field {
  width: 100%;
}

.email-edit-section-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.edit-email-section .email-radio-group {
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .email-edit-section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .edit-email-section .email-radio-group {
    margin-left: 0px;
  }
  .edit-email-section .extra-input-field {
    width: 100%;
    display: flex;
    margin: 0;
  }
  .edit-email-section .edit-email-input-multiple {
    width: calc(80% - 50px);
  }
  .edit-email-section .edit-email-input-multiple-input-field {
    margin-left: -14px;
  }
}
