.facility-search-field-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.facility-search-field-container .search-by-name {
  width: 100%;
  max-width: 245px;
}

.search-by-name .search-facility-title {
  margin: 0px;
}

.facility-search-field-container .search-by-name-submit {
  width: 100%;
  max-width: 144px;
}

.facility-search-field-container .search-button {
  width: 100%;
  margin-top: 16px;
}

.search-facility-manual-input {
  width: 100%;
  height: 40px;
}

.facility-search-field-container
  .search-facility-manual-input
  .facility-state-select {
  height: 20px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.select-facility-container {
  margin-top: 10px;
}

.select-facility-container .search-by-name {
  margin-right: 16px;
  width: 100%;
  max-width: 279px;
}

.select-facility-container .facility-name-label {
  width: 100%;
}
.select-facility-container .facility-name {
  width: 100%;
}

.select-facility-container .search-by-name-zipcode {
  width: 100%;
  max-width: 160px;
}

.select-facility-container .search-by-name-submit {
  width: 100%;
  max-width: 74px;
}

.facility-state-input {
  height: 42px;
  background: #fff;
}

.facility-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.facility-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.facility-state-input .facility-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

@media only screen and (max-width: 1024px) {
  .select-facility-container .search-by-name-submit {
    max-width: 100%;
  }
  .select-facility-container {
    margin-top: 4px;
  }
  .select-facility-container .search-by-name-zipcode {
    margin-top: 10px;
    max-width: 100%;
  }
  .select-facility-container .search-by-name {
    margin-left: 28px;
    max-width: 100%;
  }
  .facility-search-field-container {
    justify-content: center;
  }
  .facility-search-field-container .search-by-name {
    margin: 0;
  }
}
