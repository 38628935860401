.supplyOrder-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 104px 121px 16px;
  width: calc(100% - 120px);
}

.supplyOrder-container .supply-order-page {
  margin: 0 40px;
  width: calc(100% - 80px);
}

.supplyOrder-container .supplyOrder-container-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.supplyOrder-container .supplyOrder-container-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px 16px;
  width: 100%;
}

.choose-supply {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 35px;
}

.choose-supplyMain {
  max-width: 580px;
  width: 67%;
}

.currentSupply-main {
  max-width: 560px;
  width: 67%;
  margin-top: 15px;
}

.reSupply-main {
  max-width: 560px;
  width: 67%;
}

@media screen and (max-width: 920px) {
  .supplyOrder-container {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .choose-supplyMain {
    max-width: unset;
    width: 100%;
  }

  .currentSupply-main {
    max-width: unset;
    width: 100%;
  }

  .reSupply-main {
    max-width: unset;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .supplyOrder-container .supply-order-page {
    margin-left: 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (min-width: 320px) and (max-width: 515px) {
  .supplyOrder-container {
    margin: 0px;
    width: 100%;
  }

  .supplyOrder-container .supply-order-page {
    margin-left: 16px;
    width: calc(100% - 32px);
  }

  .currentSupply-main {
    width: 100%;
    margin-top: 15px;
  }
  .reSupply-main {
    width: 100%;
  }
}
