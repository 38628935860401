.inpatient-supplyorder-shippingaddress-main-div {
    padding: 16px;
    width: 60%;
  }
  .inpatient-supplyorder-shippingaddress-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #323234;
    margin-bottom: 10px;
  }
  .inpatient-supplyorder-shippingaddress-Inputs {
    margin-top: 17px;
  }
  .inpatient-supplyorder-shippingaddress-Input {
    width: 100%;
  }
  .inpatient-supplyorder-shippingaddress-state-input {
    height: 42px;
  }
  
  .inpatient-supplyorder-shippingaddress-state-input .MuiOutlinedInput-notchedOutline {
    border-color: #94949a;
  }
  .inpatient-supplyorder-shippingaddress-state-error .MuiOutlinedInput-notchedOutline {
    border-color: #d32f2f;
  }
  
  .inpatient-supplyorder-shippingaddress-state-input .inpatient-supplyorder-shippingaddress-state-root {
    height: 12px;
    border: none !important;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
  .inpatient-supplyorder-shippingaddress-box-container{
    margin-top: 12px;
    width: 100%;
    margin-bottom: 10px;
  }
 
  .inpatient-supplyorder-shippingaddress-checkbox .inpatient-supplyorder-shippingaddress-chkboxroot {
    margin-right: 10px;
    padding: 0;
  }
  
  .inpatient-supplyorder-shippingaddress-descriptionlabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #18181a;
    max-width: max-content;
    margin-top: 10px;
    margin-left: 8px;
  }

  .inpatient-supplyorder-shippingaddress-condensed .sub-content-div {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
  }
    
  .sub-content-div .inpatient-supplyorder-shippingaddress-condensed-content-value {
    color: #323234;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0px;
    min-height: 18px;
  }

  @media only screen and (max-width: 920px) {
    .inpatient-supplyorder-shippingaddress-main-div{
        width: 80%;
    }
  }