.billing-address-review-component {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}

.billing-address-review-component .billing-address-review-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 32px;
  height: 20px;
  margin: 0px;
  width: 100%;
}

.billing-address-review-component
  .billing-address-review-header
  .billing-address-review-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  width: fit-content;
}

.billing-address-review-component
  .billing-address-review-header
  .billing-address-review-edit-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  margin: auto 0px;
  text-align: left;
  text-decoration: underline;
  width: fit-content;
}

.billing-address-review-component .show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.billing-address-review-component
  .show-title-and-value-div
  .show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.billing-address-review-component
  .show-title-and-value-div
  .show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px 0px;
  text-align: left;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
}
