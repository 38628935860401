.facility-settings-header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.facility-settings-header .facility-details {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.facility-details .faciltiy-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.facility-details .faciltiy-id {
  color: #76767a;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.facility-settings-header .facility-address-city-state-zip {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.facility-settings-header-grid-container {
  margin-top: 0px;
  width: 100%;
}

.facility-settings-header-grid-container .facility-settings-header-grid-item {
  width: 100%;
  padding-left: 16px;
}

.facility-settings-header
  .facility-settings-header-grid-item
  .facility-setting-sub-header {
  display: flex;
  flex-direction: column;
  margin: 20px 0 24px 0;
}

.facility-setting-sub-header .facility-setting-sub-header-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}
.facility-setting-sub-header .facility-setting-sub-header-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.facility-settings-header .favourite-facility {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin: 0;
  width: fit-content;
}

.favourite-facility .favourite-facility-button {
  color: #0049bd;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}

.favourite-facility .favourite-facility-button-disabled {
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}

.facility-settings-header .update-facility-type-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.update-facility-type-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.update-facility-type-loader-pop-up .update-facility-type-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}
@media screen and (max-width: 450px) {
  .update-facility-type-loader-pop-up .update-facility-type-loader {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 200px;
  }
}
