.footer-btn-group .send-note-tool-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
}

.send-note-main-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  margin-left: calc(15% - 32px);
  margin-right: 104px;
  width: 100%;
}

.send-note-left-container {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}

.send-note-right-container {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  right: 25px;
  width: 50%;
}

.boxOrderStyle {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: -8px;
}

.boxOrderStyle .firstButton {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle .secondButton {
  min-width: 140px;
}

.boxOrderStyle .thirdButton {
  min-width: 192px;
}

.boxOrderStyle .thirdButton-disabled {
  background: #e4e4e8;
  color: #76767a;
  min-width: 192px;
}
@media only screen and (max-width: 920px) {
  .send-note-main-container {
    margin: auto 0px;
  }
}
@media screen and (min-width: 566px) and (max-width: 600px) {
  .send-note-right-container {
    right: unset;
  }
}
@media only screen and (max-width: 565px) {
  .footer-btn-group .send-note-tool-bar {
    flex-direction: column;
    margin: 0px;
  }
  .send-note-main-container {
    align-items: baseline;
    height: 30px;
    margin-right: 104px;
  }
  .send-note-left-container {
    width: 100%;
  }
  .send-note-right-container {
    width: 100%;
    right: unset;
  }
}
@media only screen and (max-width: 565px) {
  .footer-btn-group .send-note-tool-bar {
    flex-direction: column;
    margin: 0px;
  }
  .send-note-main-container {
    align-items: baseline;
    height: 30px;
    margin-right: unset;
    margin-left: unset;
  }
  .send-note-left-container {
    width: 100%;
  }
  .send-note-right-container {
    width: 100%;
    right: unset;
  }
}
