.patient-data-container {
  background-color: #f8f8fa;
  margin: 0px 0px 12px;
  padding: 0px 0px 8px 8px;
}
.patient-data-container .order-found-patient-name {
  margin-bottom: 0px;
}
.patient-data-container .patient-name {
  font-size: 14px;
  font-weight: 400;
}
.patient-data-container .patient-address {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
}
.patient-data-container .patient-data-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  width: 85%;
}
.patient-data-container .patient-dob-and-ro {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}
@media only screen and (max-width: 420px) {
  .patient-data-container .patient-dob-and-ro {
    display: flex;
    flex-direction: row;
  }
  .patient-data-container .patient-dob-ro-grid {
    margin-top: 8px;
  }
}
