.cancelPatientOrder {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 491px;
}
.cancelPatientOrder .cancelPatientOrder-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 16px;
}
.cancelPatientOrder .cancelPatientOrder-header .cancelPatientOrder-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  text-align: left;
}

.cancelPatientOrder .resason-for-cancel-input-fields {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 0 16px 16px;
  width: calc(100% - 16px);
}

.cancelPatientOrder .cancellationReason-grid-container {
  width: 100%;
}

.cancellationReason-grid-container .cancellationReason-grid-item {
  width: 100%;
}
.cancellationReason-grid-item .cancellationReason {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.cancelPatientOrder .cancellationReason-grid-item .cancellationReason-input {
  width: calc(100% - 16px);
}

.cancelPatientOrder .cancellationReason-grid-item .cancellationReason-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: calc(100% - 16px);
}

div.placeholder {
  border-radius: 2px;
  color: #76767a;
}

.placeholder div.placeholder {
  border-right: unset;
  height: 20px;
}

.cancelPatientOrder div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: calc(100% - 16px);
}

.cancelPatientOrder .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: calc(100% - 16px);
}

.cancelPatientOrder .placeHolder .selectIcon {
  top: 10px;
}
.cancelPatientOrder .cancellationReason-footer {
  display: flex;
  flex-direction: row;
  margin: 0px 0 16px 16px;
  width: calc(100% - 32px);
}

.cancelPatientOrder .cancelBtn {
  width: 100%;
}

.cancelPatientOrder .submitBtn {
  width: 100%;
}
.error-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 448px;
}
