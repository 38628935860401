.alert-box-tool-tip {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 8px;
  align-items: flex-start;
  margin-bottom: 2px;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.alert-box-tool-tip .label-arrow-box-tool-tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.alert-box-tool-tip .name-tool-tip {
  margin-right: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  justify-content: flex-start;
  text-transform: none;
  padding: 6px 8px 6px 0px;
  width: 100%;
}

.alert-box-tool-tip .alert-label-and-date-tool-tip {
  color: #4c4c4f;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.alert-arrow-icon path {
  stroke: green;
}

.alert-box-container .alert-dialog {
  min-width: 300px;
  min-height: 10px;
  background-color: #fff;
}

/* mobile css */
@media only screen and (max-width: 820px) {
  .alert-box-tool-tip {
    pointer-events: auto !important;
  }
  .alert-box .label-arrow-box {
    width: 100%;
  }
}
