.favorite-facility {
  font-size: 16px;
  margin-top: -90px;
  line-height: 32px;
  margin-left: 0px;
  font-weight: 600;
}
.favoriteFacility-container .MuiOutlinedInput-root {
  max-height: 42px;
}

.select-facility {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding-left: 15px;
}
.imgclick {
  cursor: pointer;
}
.facility-spinner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
.tables_container {
  margin-top: 6px;
  max-height: 500px;
  min-height: 300px;
  overflow: auto;
  border: 1px solid #e4e4e8;
  margin-left: 0px;
}
.tables-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.tables-static-data-mobileScreen {
  position: sticky;
  left: 0;
  box-shadow: 5px 0px 5px 0px #f2f2f5;
}
.facility-data-mobileScreen {
  width: 170px;
}
.fav-facility-name-mobile-screen {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
}
.tables {
  width: 100%;
  border-spacing: 0;
}
.tables th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 2px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.tables td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.tables tbody tr:first-child td {
  border-top: none;
}

.tables tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.tables tbody tr:nth-child(2n) td {
  background: #fff;
}

.tables thead th:nth-child(1) {
  width: 100%;
  position: sticky;
  left: 0;
  z-index: 1;
}

.tables thead th:nth-child(2) {
  width: 40%;
  padding: 0px 0px 0px 12px;
}

.tables thead th:nth-child(3) {
  width: 12%;
}

.tables thead th:nth-child(4) {
  width: 11%;
}
.tables thead th:nth-child(5) {
  width: 19%;
  padding: 0px 0px 0px 8px;
}
.tables thead th:nth-child(6) {
  width: 20%;
  padding: 0px 0px 0px 9px;
}

.tables th.up {
  background-image: url("../../assets/up_arrow.svg");
}

.tables th.down {
  background-image: url("../../assets/down_arrow.svg");
}

.tables th.default {
  background-image: url("../../assets/default.svg");
}
.img {
  background-color: white;
}

.favoriteFacility-subMain {
  margin-top: 100px;
}
@media screen and (max-width: 920px) {
  .tables thead th:nth-child(1) {
    box-shadow: 5px 0px 5px 0px #f2f2f5;
  }
  .imgclick {
    margin-left: 16px;
  }
}
