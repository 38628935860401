.product-name-section {
  width: 100%;
}
.product-name-section .product-sub-section {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.product-name-section .product-sub-section .product-part-code {
  color: #76767a;
}
.product-sub-section .product-package-quantity {
  margin-left: 8px;
}
@media only screen and (max-width: 600px) {
  .product-name-section .product-sub-section {
    flex-direction: column;
    align-items: unset;
  }
  .product-sub-section .product-package-quantity {
    margin-left: 0px;
  }
}
