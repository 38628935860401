.npi-noresult {
  padding: 16px;
  margin-bottom: 24px;
}

.npi-noresult .header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.no-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 42px;
  background: #f8f8fa;
  margin-top: 24px;
  justify-content: center;
  margin-bottom: 24px;
}

.no-result-text {
  margin-left: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}

.no-result-btn {
  width: 100%;
}
