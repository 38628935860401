.myList-hcp-main-component {
  margin: 0px 40px 80px 20px;
}

.myList-hcp-main-component .myList-hcp-main-sub-component {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 23px;
}

.myList-hcp-main-sub-component .myList-hcp-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
  width: calc(100% - 386px);
}

.myList-hcp-user-searchbar .myList-hcp-search-icon-div {
  height: 100%;
  margin: 0;
}

.myList-hcp-user-searchbar .myList-hcp-search-icon-div .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.myList-hcp-user-searchbar .myList-hcp-user-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.myList-hcp-main-sub-component .hcp-facilityCalssification-input {
  height: 40px;
  width: 174px;
}

.myList-hcp-main-sub-component
  .hcp-facilityCalssification-input
  .hcp-facilityCalssification-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 140px;
  font-size: 14px;
}

.myList-hcp-main-sub-component div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 140px;
}

.myList-hcp-main-sub-component .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 140px;
}

.myList-hcp-main-sub-component .placeHolder .selectIcon {
  top: 10px;
}

.myList-hcp-main-sub-component .myList-add-new-hcp-btn {
  align-items: center;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  min-width: 132px;
  text-decoration-line: underline;
  text-transform: capitalize;
}
.myListHcpClearButton {
  margin-left: 23px;
  margin-top: 10px;
}

.myList-hcpFacility-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

.paperpropsClass {
  max-height: 316px !important;
  min-width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
  width: 174px !important;
}

@media only screen and (max-width: 600px) {
  .myList-hcp-main-component {
    margin: unset;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 920px) {
  .myList-hcp-main-component .myList-hcp-main-sub-component {
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-left: unset;
  }
  .myList-hcp-main-sub-component .myList-hcp-user-searchbar {
    width: calc(100% - 30px);
  }
  .myList-hcp-user-searchbar .myList-hcp-user-search-input {
    font-size: 12px;
  }
  .myList-hcp-main-sub-component .hcp-facilityCalssification-input {
    width: 94%;
  }
  .myList-hcp-main-sub-component
    .hcp-facilityCalssification-input
    .hcp-facilityCalssification-select {
    width: 94%;
  }
  .myList-hcp-main-sub-component .myList-add-new-hcp-btn {
    margin-bottom: 8px;
  }
}
