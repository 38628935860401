.site-status-banner-component {
  margin: 4px 0px;
  width: 100%;
}

.site-status-banner-component .site-status-banner-alert {
  border-left: 8px solid;
  height: fit-content;
}

.site-status-banner-alert .site-status-alert-message {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.site-status-banner-component .site-status-information {
  color: #0049bd;
}

.site-status-banner-component .site-status-warning {
  color: #915800;
}

.site-status-banner-component .site-status-alert {
  color: #cb0000;
}

.site-status-banner-component .site-status-title-and-description {
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
}

.site-status-title-and-description .site-status-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.site-status-title-and-description .site-status-detail {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.site-status-detail .site-status-url-link {
  cursor: pointer;
  padding: 0px;
  text-decoration: underline;
  width: 100%;
}

.site-status-banner-component .site-status-close-button-div {
  display: flex;
  align-items: center;
  margin: auto 0px;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.site-status-close-button-div .site-status-close-button {
  padding: 0px;
}
