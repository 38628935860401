.missing-rx-header-title {
  background-color: #fff0f0;
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0;
  margin-right: 56px;
  padding: 8px 16px;
  width: max-content;
}
.eprescriber {
  width: 574px;
  min-height: 306px;
}
.eprescribersent {
  width: 574px;
  height: 280px;
}
.missingrxloading {
  width: 574px;
  height: 306px;
}

.rxuploadprescriber {
  width: 574px;
  min-height: 394px;
}

.rxuploadprescribersent {
  width: 574px;
  height: 178px;
}

.rxuploadprescriberfailed {
  width: 574px;
  height: 210px;
}

.fax-in-later {
  width: 574px;
  min-height: 322px;
}

.fax-in-later-sent {
  width: 574px;
  height: 326px;
}

.missing-rx-spinner {
  min-height: 188px;
  width: auto;
}

.change-prescriber {
  width: 574px;
  min-height: 436px;
}

.spinner-missing-rx-space {
  margin-top: 25px;
}

.missing-rx-alerts {
  height: auto;
  width: 574px;
}

@media only screen and (max-width: 600px) {
  .rxuploadprescriber {
    width: 100%;
  }
  .eprescriber {
    width: 300px;
  }
  .eprescribersent {
    width: 300px;
    height: 330px;
  }
  .missingrxloading {
    width: 300px;
    height: 100%;
  }
  .change-prescriber {
    width: 300px;
  }
  .fax-in-later-sent {
    width: 300px;
    height: 100%;
  }
  .rxuploadprescribersent {
    width: 300px;
    height: 200px;
  }

  .missing-rx-alerts {
    height: 100%;
    width: 100%;
  }
}
