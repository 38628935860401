.shareOrderInvite-component {
  width: 100%;
}
.shareOrderInvite-input {
  width: 100%;
}
.shareOrderInviteMain {
  margin-top: 4px;
  margin-left: 16px;
  margin-right: 16px;
  min-height: 405px;
}
.shareOrderInviteHeader {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.shareOrderInviteNote {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #76767a;
  margin-top: 4px;
}

.shareOrderInviteMain .btnGridMain {
  margin-top: 16px;
}

.btnGridMain .sharedOrderButton {
  width: 100%;
  text-transform: none;
}
