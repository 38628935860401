.new-order-option-component {
  align-items: flex-start;
  align-self: stretch;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 16px 104px 16px 16px;
  min-height: calc(100vh - 144px);
  padding: 0px 40px 40px 40px;
  width: calc(100% - 120px);
}

.new-order-option-component .new-order-option-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.new-order-option-component
  .new-order-option-section
  .new-order-option-hearder-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.new-order-option-section
  .new-order-option-hearder-div
  .view-status-supply-order-link {
  color: #0049bd;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-right: 20px;
  text-decoration: underline;
}

.new-order-option-component .new-order-option-section .new-order-option-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 40px 0px 32px;
  text-align: left;
  width: calc(100% - 360px);
}

.new-order-option-component
  .new-order-option-section
  .order-option-grid-container {
  margin: 0px;
  padding: 0px;
  row-gap: 16px;
  width: 100%;
}

.new-order-option-component
  .new-order-option-section
  .order-option-grid-container
  .order-option-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

@media only screen and (max-width: 926px) {
  .new-order-option-component {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 600px) {
  .new-order-option-component {
    margin: 16px;
    padding: 16px;
    width: calc(100% - 64px);
  }
  .new-order-option-component
    .new-order-option-section
    .new-order-option-hearder-div {
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding-bottom: 16px;
  }
  .new-order-option-section
    .new-order-option-hearder-div
    .view-status-supply-order-link {
    align-self: flex-end;
    padding-right: 0px;
  }
  .new-order-option-component
    .new-order-option-section
    .new-order-option-title {
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .new-order-option-component {
    margin: 0px;
    padding: 16px;
    width: calc(100% - 32px);
  }
  .new-order-option-section
    .new-order-option-hearder-div
    .view-status-supply-order-link {
    font-size: 14px;
  }
}
