.patient-details-component {
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  min-height: 130px;
  margin: 16px 0px;
  width: 100%;
}

.patient-details-component-confrmplac-height-adjustment {
  min-height: 100px;
  width: 100%;
}

.patient-details-component .patient-details-grid-container {
  width: 100%;
  margin-left: 0px;
  margin-top: 8px;
}

.patient-details-component
  .patient-details-grid-container
  .patient-details-grid-item {
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.patient-details-component .patient-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-top: -8px;
}

.patient-details-component .date-of-birth {
  margin-top: -10px;
  margin-left: 8px;
}

.patient-details-component .rental-order {
  margin-top: -10px;
}

.patient-details-component .product-name {
  margin-bottom: 16px;
  margin-left: 8px;
}

.patient-details-component .patient-location {
  margin-bottom: 16px;
  margin-left: 8px;
}

.patient-details-component .therapy-start-date {
  margin-bottom: 16px;
}

.patient-details-component .patient-details-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.patient-details-component .patient-details-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}

.patient-details-component .patient-ro-value {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 16px;
  text-decoration-line: underline;
}

.emptyPopup {
  max-width: 100%;
  min-height: 56px;
}
@media only screen and (max-width: 600px) {
  .patient-details-component {
    height: 180px;
    width: 100%;
  }
  .patient-details-component-confrmplac-height-adjustment {
    height: 100px;
    min-width: 280px;
    width: 100%;
  }
  .patient-details-grid-container {
    row-gap: 8px;
  }
}
@media only screen and (max-width: 320px) {
  .patient-details-component {
    width: 90%;
    padding: 0px 12px 0px 0px;
  }
  .patient-details-component-confrmplac-height-adjustment {
    height: 100px;
    width: 224px;
    padding: 0px 12px 0px 0px;
  }
}

@media only screen and (max-width: 350px) {
  .patient-details-component {
    width: 100%;
    padding: 0;
  }
}
