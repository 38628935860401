.cancelShare-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}
.cancelShare-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  padding: 16px;
}

.successStopShare {
  width: 600px;
  height: 188px;
}
.successStopShareTitle {
  padding: 16px 0px 0px 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.successStopShareDesp {
  margin-top: 24px;
  padding: 0px 16px 0px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.successStopShareClosebtn {
  padding: 0px 16px;
  margin-top: 24px;
}
.successStopShareButton {
  padding-left: 16px;
  width: 100%;
}
