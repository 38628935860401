.update-storage-space {
  margin-top: 25px;
}

.inventory-pop-up-spinner {
  align-items: center;
  display: flex;
  height: 272px;
  width: 100%;
  justify-content: center;
}
