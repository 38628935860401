.service-options-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  width: 100%;
}

.service-options-component .service-options-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.service-options-component .service-options-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.service-options-component .service-option-div {
  display: flex;
  flex-direction: row;
  margin: 16px 0 0;
  width: 100%;
}

.service-option-div .service-option-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
  width: 100%;
}

.service-option-div .service-option-input-div {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
}

.service-option-div .service-option-input-div .service-option-input {
  height: 42px;
  width: 100%;
}

.service-option-div .service-option-input-div .placeHolder {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
}

.service-option-div
  .service-option-input-div
  .service-option-input
  .service-option-select {
  align-items: center;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 12px;
  line-height: 22px;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.service-option-div .close-icon-button-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40px;
}

.service-option-div .close-icon-button-div .close-icon-button {
  margin: 24px 0px auto;
}

.service-options-component .add-service-option-button-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 16px 0 0;
  width: 100%;
}

.service-options-component
  .add-service-option-button-div
  .add-service-option-button {
  align-items: center;
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  min-width: 163px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.service-options-component
  .add-service-option-button-div
  .add-service-option-button:hover {
  text-decoration-line: underline;
}

.service-options-component
  .add-service-option-button-div
  .add-service-option-button:disabled {
  color: #76767a;
  cursor: default;
}

.service-options-component .create-service-request-special-instructions {
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  width: 100%;
}

.service-options-component
  .create-service-request-special-instructions
  .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.service-options-component
  .create-service-request-special-instructions
  .textarea-root {
  padding: 0;
}
