.customer-plus-inventory-main-div {
  width: 100%;
  padding: 24px;
}

.customer-plus-inventory-main-div .customer-plus-inventory-table-header {
  font-size: 14px;
  font-weight: 700;
  color: #323234;
  margin-bottom: 8px;
}
.customer-plus-inventory-main-div .customer-plus-inventory-btn-grp {
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.ag-theme-quartz .inventory-customer-plus-location {
  color: #0049bd;
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.text-uppercase {
  text-transform: uppercase;
}
.ag-theme-quartz .inventory-customer-plus-servicePending {
  color: #0049bd;
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.ag-theme-quartz .inventory-customer-plus-storage-days {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.ag-theme-quartz .inventory-customer-plus-missing-asset {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customer-plus-inventory-main-div .inventory-customer-plus-ag-table {
  margin: 16px 0;
}
.menu-column-menu-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 26px;
}
.ag-theme-quartz .ag-overlay-no-rows-center {
  margin-top: 50px;
}
.h-700 {
  height: 700px;
}
.h-800 {
  height: 800px;
}
.h-900 {
  height: 900px;
}

@media screen and (max-width: 926px) {
  .menu-column-menu-btn {
    padding-right: 6px;
  }
}

@media screen and (max-width: 600px) {
  .customer-plus-inventory-main-div .customer-plus-inventory-btn-grp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  .customer-plus-inventory-main-div .customer-plus-manage-sl-btn {
    height: 42px;
    width: 60%;
  }
  .customer-plus-inventory-main-div .customer-plus-download-btn {
    width: 40%;
  }
}
.customer-plus-ag-table .ag-root-wrapper {
  height: 100%;
  overflow: hidden;
}
