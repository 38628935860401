.requesting-facility {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.requesting-facility-div {
  margin-top: 10px;
  width: 100%;
}

.requestingfacilityDiv {
  margin-top: 8px;
  margin-bottom: 16px;
}

.requestingfacilityDiv .presDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  width: calc(100% - 16px);
}

.requesting-facility .requesting-facility-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 8px;
}

.requesting-facility .requesting-facility-grid-container {
  width: 100%;
}

.insurance-informantion-grid-container .insurance-informantion-input {
  width: 100%;
}

.requesting-facility .requesting-facility-button {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  margin-top: -16px;
  text-transform: none;
  width: 100%;
}
.requesting-facility .requesting-facility-button-error {
  border: 1px solid #cb0000;
  border-radius: 2px;
  color: #0049bd;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  line-height: 20px;
  margin-top: -16px;
  text-transform: none;
  width: 100%;
}
.requesting-facility-item {
  width: 100%;
}
.requestingfacility-grid-item .requestingfacilityDetail {
  width: 100%;
}

.requestingfacilityDetail .facilitywrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
}

.requesting-facility .requestingfacilityDetail .facility-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.requesting-facility
  .requestingfacilityDetail
  .facility-detail
  .leftfaciltydetails {
  width: calc(65% - -1px);
}
.requesting-facility .requestingfacility-grid-container {
  border: 1px solid #e4e4e8;
  margin-top: 5px;
  margin-left: 0px;
  width: calc(100% - 16px);
}

.requestingfacility-grid-container .requestingfacility-grid-item {
  width: 100%;
  padding: 0px !important;
}

.requestingfacility-grid-container .button-change-requesting-facility {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #0049bd;
  line-height: 18px;
  margin-top: 20px;
  text-transform: capitalize;
  text-decoration-line: underline;
  padding: unset;
  text-align: end;
  margin-right: 10px;
}
@media only screen and (max-width: 920px) {
  .requesting-facility .requestingfacilityDetail .facility-detail {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 620px) {
  .requestingfacility-grid-container .button-change-requesting-facility {
    margin-right: unset;
    margin-left: 8px;
    text-align: unset;
    margin-top: 8px;
  }
}
@media only screen and (width: 600px) {
  .requestingfacility-grid-container .button-change-requesting-facility {
    margin-right: 8px;
    text-align: end;
  }
}
@media only screen and (width: 540px) {
  .requestingfacility-grid-container .button-change-requesting-facility {
    margin: 8px 8px 0px 0px;
    text-align: end;
  }
}
