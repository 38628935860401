.facility-nf-container .result-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.facility-nf-container .facility-nf {
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0px 16px;
  max-width: 542px;
  background: #f8f8fa;
  margin-top: 24px;
}
.facility-nf .facility-nf-msg {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 8px;
}
.facility-nf .facility-nf-msg-for-new-order {
  text-align: center;
  font-weight: 700;
}
.facility-nf .facility-manual-entry-msg {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 16px;
}
.facility-nf .unlinked-requested-facility-not-found {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 16px;
  margin-left: 20px;
}
.facility-nf .facility-nf-btns {
  width: 100%;
}
.facility-nf .facility-nf-btns-for-new-order {
  width: 50%;
  justify-content: center;
}
.facility-nf .btn1-container {
  padding-right: 4px;
  width: 100%;
}
.facility-nf .btn2-container {
  padding-left: 4px;
  width: 100%;
}
.new-order-button {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  width: 100%;
}

.new-order-button .facility-nf-btns {
  margin: 8px auto 0px auto;
  width: 40%;
}
.new-order-button .facility-user-profile-btns {
  margin: 8px auto 0px auto;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .facility-nf .btn1-container {
    padding-right: 0px;
    padding-top: 4px;
  }
  .facility-nf .btn2-container {
    padding-left: 0px;
    padding-top: 4px;
  }
  .new-order-button .facility-nf-btns {
    width: 52%;
  }
}
