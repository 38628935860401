.therapyoutcome-type {
  width: 100%;
  margin-top: 3%;
}
.therapyoutcome-type .therapyoutcome-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}
.therapyoutcome-type .radioRoot {
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3%;
}
.therapyoutcome-type .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  width: calc(49% - 4px);
}
.therapyoutcome-type .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  width: calc(49% - 4px);
}
.therapyoutcome-type .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.therapyoutcome-type .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.therapygoalachieved {
  color: #323234;
  font-size: 16px;
  line-height: 20px;
}
.therapyoutcome-data {
  margin-top: 10px;
  width: 59%;
  column-count: 2;
  column-gap: 3em;
}
.chkBoxDescriptiononeText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  max-width: 207px;
  margin-top: 10px;
}
.chkBoxDescriptiononeText-active {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  max-width: 207px;
  margin-top: 10px;
}
.patientDied {
  margin-top: 20px;
}
.chkBoxDescriptionText-active {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  max-width: max-content;
  margin-top: 10px;
}
.chkBoxDescriptionText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  max-width: max-content;
  margin-top: 10px;
}
.therapygoalnotachieved {
  color: #323234;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 600px) {
  .therapyoutcome-data {
    column-count: 1;
  }
}
