.sales-rounding-dashboard-main-div {
  width: 100%;
}

.sales-rounding-dashboard-main-div .sales-rounding-dashboard-facility-name {
  padding-bottom: 40px;
}

.sales-rounding-dashboard-facility-name .sales-rounding-account-name {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.sales-rounding-dashboard-facility-name
  .sales-rounding-dashboard-tool-back-button {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  color: #0049bd;
  text-transform: none;
  font-weight: 400;
  margin-right: 8px;
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 12px 0;
}

.sales-rounding-dashboard-search-download-div {
  margin-bottom: 16px;
  padding: 6px 0px;
}

.sales-rounding-dashboard-download-div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sales-rounding-dashboard-download-div.mobile-view {
  cursor: pointer;
  padding: 10px;
}

.sales-rounding-dashboard-main-div .srt-accordionMain {
  position: unset;
  box-shadow: none;
  border-bottom: 1px solid var(--MSD-DS-Gray-10, #e4e4e8);
  background: var(--MSD-DS-Gray-00, #fff);
}
.srt-accordionMain .Mui-expanded .expandSrtIcon {
  color: #4949bd;
  height: 15px;
  width: 25px;
  margin-top: 25px;
  margin-bottom: unset;
}
.srt-accordionMain .MuiAccordionSummary-expandIconWrapper {
  transition: unset;
  -webkit-transition: unset;
}
.srt-accordionMain .expandSrtIcon {
  color: #4949bd;
  height: 15px;
  width: 25px;
  margin-bottom: 70px;
  margin-top: unset;
}

.srt-patients-card-container {
  width: 100%;
}

.srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
}
.srt-patient-details-main-grid-item
  .srt-patient-details-grid-container
  .srt-patient-details-header-grid-item {
  display: flex;
  flex-direction: row;
  margin: 0 0 8px;
  padding: 0px;
}
.srt-patient-details-main-grid-item
  .srt-patient-details-grid-container
  .srt-patient-details-status-header {
  justify-content: center;
}
.srt-patient-details-main-grid-item
  .srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-name {
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: auto 0px;
  text-align: left;
  white-space: normal;
  width: 100%;
}
.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div
  .srt-order-type-icon {
  margin: auto 8px;
  position: relative;
  top: 4px;
  white-space: pre;
  width: 30px;
  z-index: 2;
}

.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div
  .srt-patient-is-ready-care {
  border-radius: 15px;
  background: #e8f4ff;
  color: #0049bd;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 14px;
  margin: auto 0px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div
  .srt-is-customer-plus {
  border-radius: 15px;
  background: #e3f7e8;
  color: #007713;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div
  .srt-patient-is-vacPeelAndPlace {
  border-radius: 15px;
  background: #e8f4ff;
  color: #0049bd;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 14px;
  margin: auto 5px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-details-name-and-ready-care-div
  .srt-stored-product-capsule {
  border-radius: 15px;
  background: #fff0d4;
  color: #915800;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}

.srt-order-type-tooltip-popper .srt-order-type-tooltip {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 9px 8px 15px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 24px;
  line-height: 18px;
  margin-bottom: 2px;
}

.srt-order-type-tooltip-content {
  color: #323234;
  cursor: pointer;
  padding: 2px 4px;
  width: fit-content;
}

.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-statusIconMain {
  border-radius: 10px;
  height: 10px;
  margin: 9px 4px auto 0px;
  width: 10px;
}
.srt-statusIconMain.yellow {
  color: #eaca00;
}

.srt-statusIconMain.orange {
  color: #ff9649;
}

.srt-statusIconMain.red {
  color: #cb0000;
}

.srt-statusIconMain.green {
  color: #00ac31;
}

.srt-statusIconMain.grey {
  color: #94949a;
}

.srt-statusIconMain.blue {
  color: #1e64d0;
}

.srt-statusIconMain.purple {
  color: purple;
}
.srt-patient-details-grid-container
  .srt-patient-details-header-grid-item
  .srt-patient-status {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: auto 4px;
  text-align: left;
}

.srt-patient-details-header-grid-item .srt-patient-details-div {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  width: 100%;
}
.srt-patient-details-header-grid-item .srt-patient-details-product-div {
  margin-left: 12px;
}
.srt-patient-details-div .title {
  color: #76767a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: 54px;
}
.srt-patient-details-div .updatedtitle {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  height: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: 54px;
}

.srt-patient-details-div .title.right-side {
  width: 62px;
}

.srt-patient-details-div .title.for-mobile {
  width: 30px;
}

.srt-patient-details-div .value {
  color: #323234;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  width: calc(100% - 54px);
}

.srt-patient-details-div .updateValue {
  color: #323234;
  font-weight: 700;
}

.srt-patient-details-div .value.right-side {
  width: calc(100% - 56px);
}

.srt-patient-details-div .value.no-title {
  width: 100%;
}

.srt-patient-details-div .value.right-side.no-title {
  height: auto;
  padding-left: 16px;
  width: calc(100% - 16px);
}

.srt-patient-details-div .value.for-mobile {
  width: calc(100% - 30px);
}
.srt-myPatient-icon-label-span {
  display: flex;
  flex-direction: row;
}
.srt-patient-details-main-grid-item
  .srt-patient-details-grid-container
  .srt-patient-details-grid-item {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.sales-rounding-dashboard-main-div .srt-sales-rounding-full-screen-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: calc(20% - 20px);
}
.srt-sales-rounding-no-patients {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin: 40px;
  width: calc(100% - 80px);
}
.srt-dashboard-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.srt-dashboard-loader-pop-up .srt-dashboard-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 16px auto;
  width: 400px;
}

.sales-rounding-download-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sales-rounding-download-success-pop-up .download-success {
  margin: 12px 16px 16px;
  min-height: 138px;
  width: 432px;
}
.download-success .download-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
}

.download-success .download-pop-up-content {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 24px 0 32px 0;
}
.download-success-buttons-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.download-success .download-success-cancel-btn {
  box-shadow: none;
  color: #cb0000;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-transform: none;
}
.download-success .download-success-cancel-btn:hover {
  background-color: #ffffff;
  box-shadow: none;
  text-decoration: underline;
}

.download-success .download-success-download-btn {
  background-color: #0049bd;
  box-shadow: none;
  color: #ffffff;
  padding: 10px;
  border-radius: 2px 0px 0px 0px;
  text-transform: none;
}
.download-success .download-success-download-btn:hover {
  background-color: #0049bd;
  box-shadow: none;
}

.sales-rounding-dashboard-search-input {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  width: 100%;
}
.dashboard-user-search-input .MuiInputBase-input {
  text-overflow: ellipsis;
}

.sales-rounding-dashboard-search-input .dashboard-search-icon-div {
  height: 100%;
  margin: 0;
}

.sales-rounding-dashboard-search-input
  .dashboard-search-icon-div
  .dashboard-search-icon {
  color: #76767a;
  margin: 8px;
}
.sales-rounding-dashboard-search-input .dashboard-user-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.srt-accordion-main-div .srt-accordion-main-content.Mui-expanded {
  margin: unset;
  margin-top: 20px;
}
.srt-accrodin-details {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .sales-rounding-download-success-pop-up {
    margin: 16px;
    max-height: 100%;
    max-width: calc(100% - 32px);
  }
  .sales-rounding-download-success-pop-up .download-success {
    height: fit-content;
    margin: 12px 16px 16px;
    max-height: fit-content;
    max-width: calc(100% - 32px);
    width: calc(100% - 32px);
  }
}
@media only screen and (max-width: 728px) {
  .srt-patient-details-header-grid-item .srt-patient-details-product-div {
    margin-left: unset;
  }
  .sales-rounding-dashboard-main-div .sales-rounding-dashboard-facility-name {
    padding: 12px 12px 12px 2px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .srt-patient-details-grid-container
    .srt-patient-details-header-grid-item
    .srt-statusIconMain {
    border-radius: 10px;
    height: 10px;
    margin: 6px 4px auto 0px;
    width: 10px;
  }
  .srt-patient-details-grid-container
    .srt-patient-details-header-grid-item
    .srt-patient-status {
    margin: 4px;
  }
  .srt-accordionMain .Mui-expanded .expandSrtIcon {
    margin-top: 75px;
    margin-bottom: unset;
  }

  .srt-accordionMain .expandSrtIcon {
    margin-bottom: 120px;
    margin-top: unset;
  }
  .srt-patient-details-grid-container
    .srt-patient-details-header-grid-item
    .srt-patient-details-name-and-ready-care-div
    .srt-order-type-icon {
    margin: unset;
  }
}
@media screen and (max-width: 450px) {
  .srt-dashboard-loader-pop-up .srt-dashboard-loader {
    margin: 16px;
    max-width: 400px;
    min-width: 260px;
    width: calc(100% - 32px);
  }
}
