.orderstepper-container {
  margin: 16px 0px 16px 16px;
}
.orderstepperForm {
  background-color: #fff;
  margin-right: 57px;
  margin-bottom: 0px;
}
.orderstepperForm .short-form {
  width: 65.56%;
}

@media only screen and (max-width: 926px) {
  .orderstepper-container {
    margin: 0px;
  }
  .orderstepperForm {
    margin-right: 0px;
  }
}
