.submitted-document-grid-view-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.submitted-document-grid-view-container .submitted-grid-view-item {
  padding: 0;
  margin: 0;
}
.submitted-grid-view-item .doucument-details-container {
  display: flex;
  flex-direction: row;
  margin: 0 16px 16px 0px;
  border: 1px solid #e4e4e8;
  min-height: 128px;
}
.doucument-details-container .icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 22px;
  pointer-events: none;
}
.icon-div .file-icon {
  display: flex;
  width: 50px;
  height: 54px;
}
.icon-div .file-icon-text {
  font-size: 15px;
  color: #76767a;
  margin-top: 46px;
  position: relative;
  right: 40px;
}
.icon-div .file-icon-text-tiff {
  font-size: 15px;
  color: #76767a;
  position: relative;
  right: 30px;
  margin-top: 46px;
}
.icon-div .file-icon-text-for-three {
  font-size: 15px;
  color: #76767a;
  margin-top: 46px;
  position: relative;
  right: 30px;
}
.doucument-details-container .details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 114px);
  margin-right: 10px;
}
.details .file-details {
  margin-top: 15px;
}
.file-details .document-name-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin: 0;
}
.file-details .document-name-title-value {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  overflow-wrap: break-word;
  word-break: break-all;
  text-decoration-skip-ink: none;
  cursor: pointer;
}
.details .document-details {
  margin: 0;
}
.document-details .document-type-div {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  width: 100%;
}
.document-type-div .doc-type-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #76767a;
  margin: 0;
  width: 70px;
}
.document-type-div .doc-type-title-value {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #323234;
  margin-left: 6px;
  margin-bottom: 0;
  margin-top: 0;
  width: calc(100% - 76px);
}

.document-details .document-submitted-date-div {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 5px;
  width: 100%;
}

.document-submitted-date-div .doc-submitted-date-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #76767a;
  margin: 0;
  width: 70px;
}

.document-submitted-date-div .doc-submitted-date-value {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #323234;
  margin-left: 6px;
  margin-top: 0;
  width: calc(100% - 76px);
  overflow-wrap: break-word;
}

@media only screen and (max-width: 1300px) {
  .document-name-title-value {
    overflow-wrap: break-word;
  }
  .submitted-grid-view-item .doucument-details-container {
    min-height: 158px;
  }
}
@media only screen and (max-width: 1089px) {
  .submitted-grid-view-item .doucument-details-container {
    min-height: 172px;
  }
  .submitted-document-grid-view-container .submitted-grid-view-item {
    width: 100%;
  }
}
