.additional-permission-div {
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: calc(100% - 32px);
}
.additional-permission-div .additional-permission-desc {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
}
.additional-permission-div .additional-permission-desc .technicalContact {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-decoration-line: underline;
  white-space: nowrap;
}
.additional-permission-div .doneBtn {
  width: 100%;
}
@media only screen and (min-width: 360px) and (max-width: 400px) {
  .patientDataPopUp .technicalContact {
    display: grid;
  }
}
