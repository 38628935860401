.newOrder-container {
  margin: 16px 0px 31px 16px;
  flex-direction: column !important;
}
.newOrder-container-landingPage {
  margin: unset;
}
.newOrder-LandingPageContainer {
  margin: unset;
}
.newOrder-container .patientForm {
  background-color: #fff;
  margin: 0px 104px 110px 0px;
  padding: 8px 40px 40px 40px;
}
.newOrder-container .patientForm-landingPage {
  margin: 0px 0px 110px 0px;
}
.newOrder-container .patientForm-LandingPage {
  margin: unset;
  margin-bottom: 110px;
}
.patientForm.summary {
  margin-bottom: 20px;
}

.newOrder-flow {
  background-color: #f8f8fa;
  display: flex;
  margin: 0px;
  min-height: calc(100vh - 144px);
  width: 100%;
}
.newOrder-flow .select-a-facility-back {
  border: none;
  background-color: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #0049bd;
  margin-bottom: 12px;
}
.backIcon-SelectaFacility {
  margin-bottom: 3px;
}
.newOrderLandingPage-flow {
  margin: 16px 112px;
}

.patientForm .short-form {
  width: 70%;
}

.orderSummaryForm {
  margin-top: 64px;
  margin-bottom: 48px;
  border-bottom: 1px solid #f2f2f5;
}

.orderSummaryForm .success-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orderSummaryBtn .backtoPatientBtn {
  width: 237px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.orderSuccessPara3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 0px;
  text-align: left;
}

.orderSuccessPara2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 0px;
  text-align: left;
}

.orderSuccessPara {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 8px;
  margin-top: 0px;
  text-align: left;
}

.saveAndExitPopup .paperRoot {
  width: 383px;
  height: 240px;
}

.exitConfirmationText {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-top: 56px;
  text-align: center;
}

.exitConfirmationDescText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 8px;
  text-align: center;
}

.saveAndExitPopup .exitCancelBtn {
  margin: 16px 16px 16px 16px;
}

.saveAndExitPopup .exitBtn {
  margin: 0px 16px 10px 16px;
  text-transform: none;
}

.newOrderSaveToast {
  left: 40%;
  margin-top: 6px;
  max-width: 100%;
  position: fixed;
  top: 0px;
  width: fit-content;
  z-index: 1;
}

.newOrderErrorPopup .paperRoot {
  width: 403px;
  height: 230px;
}

.saveapi-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}

.therapy-loader {
  height: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.saveapi-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  padding: 16px;
}
.sharedOrderStyle {
  padding: 10px 16px;
  gap: 8px;
  height: auto;
  background: #f1f1fc;
  border-radius: 5px;
}
.shareOrderDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.shareOrderAlertTitle {
  font-weight: 700;
  color: #5d5dbd;
}
.stopSharingbtnLink {
  float: right;
  text-decoration: underline;
  cursor: pointer;
  color: #0049bd;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 700;
}
.shareOrderNote {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.sharedOrderalert {
  margin-top: 0px;
}

.showMissingRequiredFieldPrompt {
  display: flex;
  flex-direction: column;
  height: 206px;
  width: 500px;
  justify-content: center;
}

.showMissingRequiredFieldPrompt .cancelBtn {
  margin: 0px 16px 16px 16px;
}

.showMissingRequiredFieldPrompt .showMissingRequiredFieldTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #0049bd;
  flex: none;
  margin: 24px 16px 0px 16px;
}

.showMissingRequiredFieldPrompt .showMissingRequiredField-desp {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  margin: 8px 16px 26px 16px;
}

.successStopShare {
  width: 600px;
  height: 188px;
}
.successStopShareTitle {
  padding: 16px 0px 0px 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.successStopShareDesp {
  margin-top: 24px;
  padding: 0px 16px 0px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.successStopShareClose {
  padding: 0px 16px;
  margin-top: 24px;
}
.successStopShareButton {
  padding-left: 16px;
  margin-top: 24px;
  width: 100%;
}

/* mobile css */
@media only screen and (max-width: 932px) {
  .newOrder-container .patientForm {
    box-sizing: border-box;
    margin: 0px 104px 140px 0px;
    width: calc(100% - 104px);
  }
  .patientForm .short-form {
    width: 75%;
  }
}
@media only screen and (max-width: 920px) {
  .newOrder-flow {
    background-color: #fff;
  }
  .newOrderLandingPage-flow {
    margin: unset;
  }
  .homeorder-mobileresponse {
    width: 100%;
  }
  .homeorder-mobileresponse-sso-vacOrder {
    width: 100%;
    background-color: #f8f8fa;
    padding-bottom: 12px;
  }
  .newOrder-container {
    margin: 0px;
  }
  .newOrder-container .patientForm {
    box-sizing: border-box;
    margin: 0 0 188px;
    width: 100%;
  }
  .container-mobileresponsive {
    width: 100%;
    overflow-x: hidden;
  }
  .orderSummaryForm {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .patientForm .short-form {
    width: calc(100% + 16px);
  }
  .newOrderSaveToast {
    left: 37%;
    margin-top: 6px;
    max-width: 100%;
    position: fixed;
    top: 0px;
    width: fit-content;
    z-index: 1;
  }
  .select-facility-return-btn {
    width: 100%;
    background-color: #f8f8fa;
  }
  .orderSuccessPara3 {
    margin-top: 12px;
    margin-left: 6px;
  }
  .orderSummaryBtn .backtoPatientBtn {
    margin-left: 6px;
  }
}

@media screen and (max-width: 601px) and (max-width: 912px) {
  .newOrderSaveToast {
    left: auto;
    margin-top: 6px;
    max-width: 100%;
    position: fixed;
    top: 0px;
    width: fit-content;
    z-index: 1;
  }
}

@media only screen and (max-width: 915px) {
  .newOrder-container .patientForm {
    margin: 0 0 176px;
    padding: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .newOrder-container .patientForm {
    box-sizing: border-box;
    margin: 0 0 174px;
    width: 100%;
  }

  .saveapi-spinner {
    width: 300px;
  }

  .successStopShare {
    width: 100% !important;
    height: 220px !important;
  }

  .successStopShareTitle {
    font-size: 18px !important;
  }

  .showMissingRequiredFieldPrompt {
    width: 340px;
  }

  .newOrderSaveToast {
    left: auto;
    margin-top: 6px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
  }
  .newOrder-flow .select-a-facility-back {
    margin-bottom: 0;
  }
  .not-saved-pop-up .paperRoot {
    width: 90%;
  }
  .orderSummaryBtn .backtoPatientBtn {
    width: calc(100% - 10px);
  }
}
.uploadDocument-error {
  color: #76767a;
  font-weight: 400;
  font-size: 14px;
}
@media only screen and (max-width: 568px) {
  .newOrder-container .patientForm {
    box-sizing: border-box;
    margin: 0 0 174px;
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .newOrder-container .patientForm {
    box-sizing: border-box;
    margin: 0 0 194px;
    width: 100%;
  }
}

@media only screen and (max-width: 359px) {
  .showMissingRequiredFieldPrompt {
    width: auto;
  }
  .missing-field-pop-up .paperRoot {
    width: 90%;
  }
}

@media only screen and (max-width: 353px) {
  .newOrder-container .patientForm {
    margin: 0 0 198px;
    padding: 16px;
  }
}

@media only screen and (max-width: 330px) {
  .newOrder-container .patientForm {
    margin: 0 0 224px;
    padding: 16px;
  }
}
@media print {
  .orderSummaryBtn .backtoPatientBtn {
    display: none;
  }
}
