.landline-warn-modal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0px 16px;
  width: calc(100% - 32px);
}

.landline-warn-modal .title {
  color: #323234;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.landline-warn-modal .description {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 9px;
}

.landline-warn-modal .landline-warn-grid-container {
  width: 100%;
  margin-top: -26px;
}

.confirm-leave-modal .confirm-leave-modal-input {
  width: 100%;
}

.confirm-leave-modal .confirm-leave-modal-grid-container {
  width: 100%;
}

.confirm-leave-modal .confirm-leave-modal-input {
  width: 100%;
}

.landline-warn-modal .changeToMobile {
  background: #cb0000;
  margin-top: 32px;
  text-transform: none;
  width: 100%;
}

.landline-warn-modal .changeToMobile:hover {
  border: 0;
  background: #cb0000;
}

.landline-warn-modal .back-manage-btn {
  margin-bottom: 24px;
  margin-top: 8px;
  width: 100%;
  text-transform: none;
}
