.in-patient-order-replacement-information-component {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
  }
  
  .in-patient-order-replacement-information-component
    .in-patient-order-replacement-information-title {
    color: #323234;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin: 0px 0px 12px 0px;
    text-align: left;
  }

  .in-patient-order-replacement-information-component
    .in-patient-order-replacement-information-desc {
    color: #4C4C4F;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0px 0px 12px 0px;
    text-align: left;
  }

  

.in-patient-order-replacement-information-grid-container
  .replacementinfo-delivery-location-grid-item
  .replaceinfo-delivery-location-input {
  width: 100%;
}

.in-patient-order-replacement-information-grid-container
  .replacementinfo-delivery-location-grid-item
  .replaceinfo-delivery-location-input
  .replaceinfo-delivery-location-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.replaceinfo-deliveryInstructions-input{
    width: 100%;
}
