.patient-not-found {
  padding: 16px;
}
.patient-not-found .header {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.patient-not-found b, .patient-not-found .sub-text {
  font-size: 14px;
  line-height: 18px;
}

.patient-not-found b {
  font-weight: 700;
}

.patient-not-found .sub-text {
  margin: 0;
  font-weight: 400;
}

.patient-not-found .not-found-msg {
  padding: 9px 16px;
  margin-bottom: 24px;
  background: #F8F8FA;
  border: 1px solid #E4E4E8;
}

.patient-not-found .search-again {
  width: 100%;
}