.wound-exudate {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.wound-exudate .wound-exudate-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 8px 0;
}

.wound-exudate .wound-exudate-grid-container {
  margin-top: 0px;
  width: 100%;
  margin-left: 1px;
}

.wound-exudate-grid-container .wound-exudate-grid-item {
  padding-top: 0px !important;
  width: 100%;
}

.wound-exudate-grid-container .wound-exudate-input {
  width: 272px;
}

.wound-exudate-grid-container div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 272px;
}

.wound-exudate-grid-container .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 272px;
}

.wound-exudate-grid-container .placeHolder .selectIcon {
  top: 10px;
}
