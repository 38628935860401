.success-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;
  width: 584px;
  height: auto;
}

.success-pop-up .success-pop-up-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.success-pop-up .success-pop-up-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.success-pop-up .success-pop-up-description1 {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.success-pop-up .success-pop-up-description2 {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.success-pop-up .submitButton {
  margin: 16px 0 0 0;
  width: 100%;
}
