.site-status-location-component {
  margin-top: 38px;
  width: 100%;
}

.site-status-location-component .site-status-location-header {
  color: #323234;
  font-style: normal;
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}

.site-status-location-component .site-status-location-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin: 8px 0px;
  width: 100%;
  white-space: pre-wrap;
}

.site-status-location-component .site-status-location-check-block {
  width: 100%;
  margin-top: 10px;
  column-count: 2;
}
.site-status-location-component .site-status-location-checkbox-item {
  display: inline-block;
  width: 100%;
  margin-left: 21px;
  margin-bottom: 12px;
}
.site-status-location-check-block .site-status-location-chkbox-desp {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  max-width: max-content;
  margin-top: 4px;
}

.site-status-location-component .site-status-location-checkbox {
  margin-right: 10px;
  padding: 0;
}
.site-status-location-component
  .site-status-location-checkbox
  .site-status-location-checkbox-root {
  margin-right: 10px;
  padding: 0;
  width: 100%;
}

@media only screen and (max-width: 920px) {
  .site-status-location-grid-container .site-status-location-checkbox-item {
    margin-left: 9px;
  }
  .site-status-location-component .site-status-location-label {
    text-wrap: wrap;
  }
  .site-status-location-component .site-status-location-check-block {
    margin-top: 25px;
  }
}
