.sale-manage-users-component-container {
  width: 100%;
  margin-right: 35px;
}

.sale-manage-users-component-container .sale-manage-users-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
  padding-bottom: 60px;
}

.sale-manage-users-component .sale-manage-users-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}

.sale-manage-users-component .sale-manage-users-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 40px 0px;
}

.sale-manage-users-component .sale-manage-users-component-route-section {
  margin: 26px 40px;
}

.sale-manage-users-component-container .sale-fetch-users-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sale-fetch-users-loader-pop-up .sale-fetch-users-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.sale-fetch-users-loader-pop-up .sale-fetch-users-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sale-fetch-users-failure-pop-up .sale-fetch-users-failure-pop-up-div {
  height: 165px;
  width: 432px;
}

.sale-manage-users-component .sale-filter-mng-usr {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin: 0 0 0 40px;
  width: calc(100% - 72px);
}

.sale-filter-mng-usr .sale-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
  width: 55%;
}

.sale-user-searchbar .sale-search-icon-div {
  height: 100%;
  margin: 0;
}

.sale-user-searchbar .sale-search-icon-div .sale-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.sale-user-searchbar .sale-user-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.sale-filter-mng-usr .sale-user-status-input {
  height: 40px;
  width: 25%;
}

.sale-filter-mng-usr .sale-user-status-input .sale-user-status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.sale-filter-mng-usr div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 25%;
}

.sale-filter-mng-usr .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.sale-filter-mng-usr .placeHolder .sale-selectIcon {
  top: 10px;
}

.sale-filter-mng-usr .sale-add-new-user-btn {
  align-items: center;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  min-width: 132px;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.sale-manage-users-component .sale-users-table {
  display: flex;
  flex-direction: column;
  margin: 16px 0 16px 16px;
  width: calc(100% - 32px);
}
.select-area-div {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .sale-manage-users-component-container .sale-manage-users-component {
    margin: 0 16px;
  }

  .sale-manage-users-component .sale-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .sale-manage-users-component .sale-filter-mng-usr {
    flex-direction: column;
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .sale-filter-mng-usr div.placeHolder {
    width: calc(100% - 32px);
  }
  .sale-manage-users-component .sale-user-searchbar {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .sale-manage-users-component .sale-search-icon-div {
    height: 100%;
    margin: 0;
  }
  .sale-manage-users-component .sale-search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 13px;
  }

  .sale-manage-users-component .sale-user-search-input {
    width: calc(100% - 32px);
  }

  .sale-manage-users-component .sale-user-status-input {
    height: 40px;
    margin: 0 16px;
    width: calc(100% - 32px);
  }
  .sale-manage-users-component .sale-add-new-user-btn {
    justify-content: flex-start;
    margin: 0 16px;
    width: calc(100% - 32px);
  }
}
@media screen and (max-width: 912px) {
  .sale-manage-users-component-container .sale-manage-users-component {
    margin: 0;
    overflow-x: hidden;
  }
  .sale-manage-users-component .sale-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .select-area-div {
    margin-left: 14px;
    width: calc(100% - 32px);
  }
}
