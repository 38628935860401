.inpatient-device-placement-main-div {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}
.inpatient-device-placement-main-div .inpatient-device-placement-sub-div {
  width: 100%;
}
.inpatient-device-placement-sub-div .device-placement-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  text-align: left;
}
.inpatient-device-placement-sub-div .device-placement-header-desc {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .device-placement-radio-group {
  margin-top: 10px;
}
.ag-theme-quartz .inpatient-device-placement-days {
  display: flex;
  margin-left: 15px;
}

.ag-theme-quartz .inpatient-device-placement-location-name {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollableTabHeader .inpatient-scroll-tabs-textlabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: none;
  width: 25%;
  color: #0049bd;
}
.scrollableTabHeader .scroll-tabs-height-ip {
  min-height: 30px;
  min-width: max-content;
}
.scrollableTabHeader .scroll-tabs-indicator {
  height: 4px;
  background-color: #0049bd;
  margin-bottom: 3px;
}
.margin-left {
  margin-left: 6px;
}
.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .scroll-tab-component {
  margin: 12px 0px;
}
.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .device-placement-radio-group
  .placementTypeRadioRoot {
  flex-direction: row;
  gap: 12px;
  margin-bottom: 10px;
}
.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .device-placement-radio-group
  .placementTypeRootActive {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 38%;
  text-wrap: balance;
}

.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .device-placement-radio-group
  .placementTypeOptionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 38%;
  text-wrap: balance;
}
.inpatient-device-placement-main-div
  .inpatient-device-placement-sub-div
  .device-placement-radio-group
  .placementTypeOptiontxt {
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
}
.disabled {
  opacity: 0.5;
}
.sideNavmadminBtnStyle {
  margin-top: 0px;
}

@media only screen and (max-width: 920px) {
  .inpatient-device-placement-main-div
    .inpatient-device-placement-sub-div
    .device-placement-radio-group
    .placementTypeRootActive {
    width: 100%;
  }
  .inpatient-device-placement-main-div
    .inpatient-device-placement-sub-div
    .device-placement-radio-group
    .placementTypeOptionRoot {
    width: 100%;
  }
}
