.facility-supply-order-main-div {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 144px);
}

@media screen and (max-width: 600px) {
  .facility-supply-order-main-div {
    margin: 0px;
  }
}
