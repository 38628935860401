.contact-information-mp {
  margin: 8px 8px 40px 8px;
  width: calc(100% - 16px);
}

.contact-information-mp .contact-information-header-mp {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0;
}

.emailTitle-mp {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.emailValue-mp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.phoneTitle-mp {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.contactData-mp {
  margin-top: 22px;
  display: flex;
  flex-direction: row;
}

.contactData-mp .phoneType-mp {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.contactData-mp .phoneValue-mp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.contactData-mp .phoneDetailType-mp {
  margin-right: 64px;
  margin-left: 64px;
}

.contactData-mp .editPhone-mp {
  align-self: self-end;
  display: flex;
  flex-direction: column;
  width: auto;
}

.contactData-mp .editPhone-mp .editPhone-mp-btn {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  text-transform: none;
}

.editForm-mp {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 512px;
  height: 116px;
  background: #f8f8fa;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.contact-information-mp .contact-information-component-mp {
  width: 100%;
}

.contact-information-mp .phone-type-container-mp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0;
  padding: 0px;
  width: 100%;
}

.contact-information-mp .phone-type-container-mp .radio-group-mp {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.contact-information-mp .phone-type-container-mp .extension-mp {
  max-width: 80px;
  margin-right: 10px;
  margin-left: 5px;
}

.contact-information-mp .contact-information-input-mp {
  width: 100%;
}

.contact-information-mp .radioRoot-mp {
  display: inline-block;
  flex-direction: row;
  justify-content: flex-start;
}

.contact-information-mp .optionRoot-mobile-mp {
  width: 87px;
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
}

.contact-information-mp .optionRoot-landline-mp {
  width: 97px;
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
}

.contact-information-mp .optiontxt-mp {
  color: #18181a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.contact-information-mp .MuiRadio-root-mp {
  padding-left: 0px;
}

.contact-information-mp .radio-title-label-mp {
  margin-top: 0px;
  margin-left: unset;
}

.contactInfo-save-mp {
  display: flex;
  align-self: self-end;
  gap: 16px;
}

.contactInfo-button-mp {
  width: 120px;
}

.loader .paperRoot {
  width: 375px;
  height: 250px;
}

.authenticateviaemail .paperRoot {
  min-height: 390px;
  height: auto;
  width: 375px;
}

.authenticateviasms .paperRoot {
  max-height: 390px;
  min-height: 374px;
  width: 375px;
}

.confirmleave .paperRoot {
  height: 250px;
  width: 480px;
}

.success .paperRoot {
  height: auto;
  width: 375px;
}

.failure .paperRoot {
  height: auto;
  width: 375px;
}

.landlinewarn .paperRoot {
  height: auto;
  width: 480px;
}

.pop-up-div {
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .contact-information-mp .contact-information-component-mp {
    width: 100%;
    margin: 0;
  }
  .contact-information-mp .phone-type-container-mp .radio-group-mp {
    flex-direction: column;
  }

  .contact-information-mp .radio-title-label-mp {
    margin-left: 8px;
  }

  .editForm-mp {
    padding: 16px;
    gap: 16px;
    width: auto;
    height: auto;
  }

  .contactData-mp {
    flex-direction: column;
  }

  .contactData-mp .phoneDetailType-mp {
    margin: 16px 0px;
  }

  .contactData-mp .editPhone-mp {
    align-self: center;
    border: 1px solid #0049bd;
    color: #0049bd;
    width: 100%;
  }

  .contactData-mp .editPhone-mp .editPhone-mp-btn {
    text-decoration-line: none;
    height: 40px;
  }
  .loader .paperRoot {
    width: 293px;
  }
}
