.pre-release-content-container {
  display: flex;
  flex-direction: column;
}

.pre-release-content-container .pre-release-content-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.pre-release-content-container .pre-release-scroll-table-header {
  margin-top: 16px;
}
.scrollableTabHeader .pre-release-scroll-tabs-textlabel {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: none;
  width: 25%;
}

@media screen and (max-width: 920px) {
  .scrollableTabHeader .pre-release-scroll-tabs-textlabel {
    height: 30px;
    width: auto;
  }
  .scrollableTabHeader .scroll-tabs-height {
    min-height: 30px;
    min-width: auto;
  }
}
