.inventory-success-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 16px;
  height: 258px;
  width: 491px;
}

.inventory-success-pop-up .title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 40px 0 0 0;
}

.inventory-success-pop-up .description1 {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
}

.inventory-success-pop-up .description2 {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 32px 0;
}

.inventory-success-pop-up .description2 .phone-value {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-decoration-line: none;
  white-space: nowrap;
}

.inventory-success-pop-up .back-button {
  margin: 0 0 40px 0;
  text-transform: none;
  width: 100%;
}
@media only screen and (max-width: 620px) {
  .inventory-success-pop-up {
    height: 330px;
    width: 245px;
    margin-top: 5px;
  }
  .inventory-success-pop-up .title {
    text-align: center;
    margin: 5px 0 0 0;
  }
  .inventory-success-pop-up .description1 {
    margin: 8px 0;
  }
  .inventory-success-pop-up .description2 {
    margin: 1px 0 15px 0;
  }
  .inventory-success-pop-up .back-button {
    margin: 0px;
  }
}
