.changeSalesTerritory {
  padding: 12px;
}

.changeSalesTerritoryTitle {
  font-weight: 700;
  font-size: 20px;
  padding-top: 16px;
  margin-top: 0px;
}

.changeSalesTerritory
  .changeSalesTerritory-search
  .changeSalesTerritoryRolesCheckBox {
  display: flex;
  justify-content: center;
}

.sale-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
}

.sale-user-searchbar .sale-search-icon-div {
  height: 100%;
  margin: 0;
}

.sale-user-searchbar .sale-search-icon-div .sale-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 4px;
}

.sale-user-searchbar .sale-user-search-input {
  color: #76767a;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.sale-user-status-input {
  height: 42px;
  width: 100%;
}

.sale-user-status-input .sale-user-status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.paperpropsClass {
  max-height: 316px !important;
  min-width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
  width: auto !important;
}

.sale-user-status-input .sale-user-status-select.MuiInputBase-input {
  height: 12px;
  font-size: 14px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
  font-size: 14px;
}

.placeHolder .sale-selectIcon {
  top: 10px;
}

.checkboxLabel {
  width: 100%;
  font-size: 14px;
  align-self: center;
}

.changeSalesTerritory-list {
  margin-top: 12px;
  overflow-x: scroll;
  max-height: 80%;
}

.changeSalesTerritoryList {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 5px;
}

.changeSalesTerritoryList.gray {
  background-color: #f8f8fa;
}

.changeSalesTerritoryList .selectButton {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}

.changeSalesTerritoryRegion {
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.changeSalesTerritoryRegionBlock {
  width: 60%;
  flex-direction: column;
  font-size: 14px !important;
}

.salesPersonTitle {
  color: #76767a;
}

.salesPersonValue {
  font-size: 14px;
}

.changeSalesTerritoryDataGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.changeSalesTerritoryLoadingSpinner {
  margin-top: 75px;
}

.changeSalesTerritoryNoRecords {
  margin: 25%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .changeSalesTerritory {
    height: 470px;
    padding: 16px;
  }

  .changeSalesTerritoryRegionBlock {
    width: 100%;
  }

  .changeSalesTerritoryDataGrid {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .changeSalesTerritory-list {
    margin-top: 12px;
    overflow-x: scroll;
    max-height: 65%;
  }
}
