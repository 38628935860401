.facility-permission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 43px;
  width: 100%;
}

.facility-permission .facility-permission-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
}

.facility-permission .facility-permission-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0 0 0;
}

.facility-permission .facility-permissions {
  display: flex;
  flex-direction: row;
  margin: 0px;
  width: 100%;
}

.facility-permission .facility-permission-description-detail-div {
  display: inline;
  margin: 16px 0px;
  width: 100%;
}

.facility-permission
  .facility-permission-description-detail-div
  .facility-permission-description-detail {
  color: #323234;
  display: contents;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.facility-permission .facility-permission-description-detail .administration {
  color: #000;
  display: contents;
  font-weight: 700;
}

.facility-permission .facility-permission-description-detail .role-privileges {
  color: #0049bd;
  cursor: pointer;
  display: inline;
  font-weight: 700;
  text-decoration-line: underline;
}

.facility-permission-empty-pop-up .paperRoot {
  width: 200px;
  height: 56px;
}

@media only screen and (max-width: 926px) {
  .facility-permission .facility-permissions {
    flex-direction: column;
    margin: 8px 0px 0px;
  }
}
