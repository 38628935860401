.requested-facility-select-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 16px;
}
.requested-facility-select-success .facility-card-gray-success {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 64px;
  background: #f8f8fa;
  margin-bottom: 15px;
  margin-top: 15px;
}
.facility-card-gray-success .facility-details-all-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 10px;
  margin-top: 5px;
}
.facility-card-gray-success .facility-details-all {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  margin-left: 10px;
}
.requested-facility-select-success .title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px 0;
}

.requested-facility-select-success .description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
  margin-top: 2px;
}

.requested-facility-select-success .description .success-text {
  font-weight: 700;
}

.requested-facility-select-success .back-button {
  margin: 0px;
  text-transform: none;
  width: 100%;
}
