.permission-checkbox-component {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.permission-checkbox-component .facility-permissions-checkbox-description-text {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: 8px;
  min-width: 200px;
  width: max-content;
}

.permission-checkbox-component .facility-permissions-checkbox-root {
  color: #0049bd;
}

.permission-checkbox-component .facility-permissions-checkbox {
  color: #0049bd;
}

.permission-checkbox-component
  .facility-permissions-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  margin-top: 8px;
  min-width: 200px;
  width: max-content;
}
