.in-patient-supply-order-detail-page-component {
  background-color: #fff;
  height: 100%;
  margin: 16px;
  padding-bottom: 16px;
  width: calc(100% - 64px);
}
.inPatient-order-detail-sections .inPatient-supply-order-detail-each-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e8;
  padding: 0px 16px 16px;
  border-top: none;
  margin: 0px 16px;
}
.inPatient-supply-order-detail-each-section
  .order-information-review-component {
  margin-top: 16px;
}
.inPatient-supply-order-detail-each-section .shipping-address-review-component {
  margin-top: 16px;
}
.inPatient-supply-order-detail-each-section
  .delivery-information-review-component {
  margin-top: 16px;
}
.inPatient-order-detail-sections
  .inPatient-supply-order-detail-selectedsupp-section
  .inpatient-supplyorder-selectedsupplies-main-div {
  padding: 16px 16px 0px 0px;
}
.inPatient-supply-order-detail-selectedsupp-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e8;
  padding: 0px 16px 16px;
  border-top: none;
  margin: 0px 16px;
}
.inPatient-supply-order-detail-selectedsupp-section
  .inpatient-supplyorder-selectedsupplies-main-div {
  padding: 32px 16px 0px 0px;
  width: 70%;
}
@media only screen and (max-width: 430px) {
  .in-patient-supply-order-detail-page-component {
    margin: 0px;
    padding: 16px;
  }
}
@media only screen and (max-width: 926px) {
  .in-patient-supply-order-detail-page-component {
    width: calc(100% - 36px);
  }
  .inPatient-supply-order-detail-selectedsupp-section
    .inpatient-supplyorder-selectedsupplies-main-div {
    width: 100%;
    margin: 0px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1366px) {
  .inPatient-supply-order-detail-selectedsupp-section
    .inpatient-supplyorder-selectedsupplies-main-div {
    width: 90%;
    margin: 0px;
  }
}
.in-patient-supply-order-detail-page-component
  .inpatient-supply-order-breadCrumbs {
  margin: 16px;
}
.inPatient-supply-order-detail-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.inPatient-supply-order-detail-loader-pop-up
  .inPatient-supply-order-detail-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 16px auto;
  width: 400px;
}
@media print {
  .inPatient-order-detail-sections .inPatient-supply-order-detail-each-section {
    border: none;
  }
  .inPatient-order-detail-sections
    .inPatient-supply-order-detail-orderDetail-section
    .supplyOrderContainer {
    border: none;
  }
  .in-patient-supply-order-detail-page-component
    .inpatient-supply-order-breadCrumbs {
    display: none;
  }
  .inPatient-order-detail-sections
    .inPatient-supply-order-detail-selectedsupp-section {
    border: none;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper {
    width: 980px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper-mobile {
    width: 770px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper-tablet {
    width: 930px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper-iPadMini {
    width: 1030px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper-iPadAir {
    width: 1020px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper-desktop {
    width: 1330px;
  }
}
@media print {
  @page {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .inPatient-order-detail-sections .selected-supplies-print-wrapper {
    page-break-before: auto;
    page-break-after: always;
    padding-top: 20px;
  }
}
@media print {
  .inPatient-order-detail-sections .ag-pinned-left-header,
  .ag-pinned-left-cols-container,
  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 450px) {
  .inPatient-supply-order-detail-loader-pop-up
    .inPatient-supply-order-detail-loader {
    margin: 16px;
    max-width: 400px;
    min-width: 260px;
    width: calc(100% - 32px);
  }
}
@media print {
  .ag-root-wrapper-body {
    zoom: 85% !important;
  }
  .inpatient-supplyorder-selectedsupplies-main-div {
    width: 100% !important;
  }
  .in-patient-supply-order-detail-page-component {
    margin: unset !important;
    width: 100% !important;
  }
}
