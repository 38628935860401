.srtAdditionalNotes-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.srtAdditionalNotes-info .srtAdditionalNotes-info-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 20px 0px 8px;
  text-align: left;
  width: 100%;
}

.srtAdditionalNotes-info .srtAdditionalNotes-info-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.srtAdditionalNotes-info
  .srtAdditionalNotes-info-grid-container
  .srtAdditionalNotes-info-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.srtAdditionalNotes-info
  .srtAdditionalNotes-info-grid-container
  .srtAdditionalNotes-info-item
  .patient-information-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: calc(100% - 8px);
}

.srtAdditionalNotes-info
  .srtAdditionalNotes-info-grid-container
  .srtAdditionalNotes-info-item
  .srt-additional-notes-info-input {
  width: calc(100% - 8px);
}

@media only screen and (max-width: 926px) {
  .srtAdditionalNotes-info {
    width: 100%;
  }
}
