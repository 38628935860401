.change-e-prescription-component {
  margin-top: 8px;
  width: 100%;
}

.error-message-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.error-message-div .error-message {
  color: #cb0000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-right: 20px;
}

.error-message-div .error-message .error-message-phone {
  color: #cb0000;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
}

.ct-prescriberBox {
  margin-top: -5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  margin-left: 30px;
  margin-bottom: 8px;
}

.ct-prescriberBox .ct-prescriberBox-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.ct-prescriber-buttons .ct-editPrescriber {
  color: #0049bd;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-right: 10px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
}

.ct-prescriberEditBox {
  margin-top: -5px;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  margin-left: 30px;
  margin-bottom: 8px;
}

.ct-prescriberEditBox .ct-prescriberEditBox-detail {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.ct-prescriberBox .edit-email-close-btn {
  margin-bottom: 8px;
}
.ct-prescriberEditBox .ct-information-indicator-div {
  display: flex;
  flex-direction: row;
}
.ct-prescriberEditBox .ct-information-indicator {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.ct-prescriberEditBox .ct-information-indicator .ct-information-text {
  font-size: 14px;
  color: #1e64d0;
}
.ct-prescriberEditBox .ct-same-email-warning {
  width: 50%;
}
.ct-prescriberEditBox .ct-same-email-warning-text {
  font-weight: 40;
  font-size: 12px;
  color: #cf8100;
  display: flex;
  gap: 10px;
  text-align: left;
}

.ct-headerdetail {
  color: #76767a;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.ct-prescriberValues {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.ct-prescriberValues.email {
  overflow-wrap: break-word;
}

.ct-prescriber-edit-buttons .ct-updateButton {
  text-transform: none;
  position: relative;
  top: 9px;
  height: 40px;
}

.ct-prescriber-name {
  width: 35%;
}

.ct-prescriber-email {
  width: 40%;
}

.ct-prescriber-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 25%;
}

.ct-prescriber-buttons .resendButton {
  text-transform: none;
  width: 47px;
  height: 40px;
}

.ct-pescriber-email-input {
  width: 100%;
}

.ct-prescriber-name-edit {
  max-width: 35%;
}

.ct-prescriber-email-edit {
  width: 50%;
}

.ct-prescriber-edit-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.ct-prescriberEditBox .ct-prev-email-prompt-mobile {
  margin-top: 16px;
}

@media only screen and (max-width: 600px) {
  .ct-prescriberBox .ct-prescriberBox-detail {
    margin-right: 20px;
    display: block;
  }
  .ct-prescriberEditBox .ct-prescriberEditBox-detail {
    display: block;
  }
  .ct-prescriberValues {
    width: max-content;
  }
  .ct-prescriber-email-edit {
    width: 100%;
    margin-top: 5px;
  }
  .ct-prescriber-email {
    width: 60%;
    margin-top: 8px;
  }
  .ct-prescriber-buttons {
    flex-direction: column;
    margin-left: 7px;
  }
  .ct-prescriber-name {
    width: 100%;
  }
  .ct-closeIconBtn {
    display: none;
  }
  .close-icon {
    display: flex;
    width: 100%;
    margin-top: 29px;
    margin-left: 3px;
  }
  .ct-prescriberEditBox {
    padding-bottom: 15px;
  }
  .ct-headerdetail {
    width: 100%;
  }
  .ct-prescriber-name-edit {
    max-width: 100%;
  }

  .ct-prescriber-email {
    width: 100%;
    margin-top: 8px;
  }

  .ct-prescriber-buttons {
    width: 100%;
  }

  .ct-prescriber-buttons .resendButton {
    width: 100%;
  }

  .ct-prescriber-edit-buttons {
    width: 100%;
  }

  .ct-prescriber-edit-buttons .ct-updateButton {
    width: 100%;
  }
  .ct-prescriberEditBox .ct-same-email-warning {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
