.manage-acc-detail-card {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}
.manage-detail-card {
  padding: 40px;
}
.userNameMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  margin-top: 24px;
}
.userNamePassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
  margin-top: 16px;
}
.titleStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0049bd;
  width: 100%;
  margin-left: 12px;
  cursor: pointer;
}
.titleStyle .titleIcon {
  float: right;
  margin-right: 12px;
}
.grid-item-width {
  width: 100%;
}
.manage-acc-input {
  width: 100%;
}
.userNameDiv {
  width: 95%;
  margin-left: 12px;
  margin-right: 12px;
}
.clearStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  float: right;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}
.manage-acc-password-input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 0px;
}
.manage-acc-new-password {
  margin: 8px 12px;
  width: 95%;
}
.manageAccBtn {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: space-between;
}
.manageAccBtn .manage-acc-verifysms {
  width: 170px;
  margin-right: 16px;
}
.manage-acc-verifyemail {
  width: 170px;
}
.manageAccCancelDiv .manage-acc-cancel {
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
}
.passwordTextStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin: 16px 12px;
}
