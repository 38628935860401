.contact-us .MuiPaper-root {
  box-shadow: none;
  border-radius: 0;
}

.contact-us h2 {
  margin: 0 0 16px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 28px;
}

.contact-us .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.contact-us .selectIcon {
  top: 10px;
}

.contact-us .contactUsForm {
  width: 70%;
}

.contact-us {
  background-color: #fff;
}

.contact-us-input {
  width: 100%;
}

.info-text {
  color: #323234;
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px 24px 24px 0px;
  line-height: 18px;
}

.contact-us .textarea-root {
  padding: 0;
}

.contact-us .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.contact-us .optionRoot {
  width: 49%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #b4b4b8;
}

.contact-us .optiontxt {
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
  font-family: "3MCircularTT";
}

.btn-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 40px 0px 40px 40px;
}

.btn-container .submitBtn,
.btn-container .cancelBtn {
  padding: 11px 14px;
  line-height: 18px;
  font-family: "3MCircularTT";
  text-transform: none;
}

.btn-container .submitBtn {
  border-radius: 2px;
  background-color: #0049bd;
}

.btn-container .cancelBtn {
  border-radius: 2px;
  margin-right: 8px;
  color: #0049bd;
  border-color: #0049bd;
}
.contact-us .contact-us-toast {
  position: fixed;
  top: 18%;
  left: 28%;
  width: 45%;
}
#recaptcha-cs-container {
  display: flex;
  align-self: flex-start;
  margin-top: 40px;
  margin-left: 16px;
}
@media only screen and (max-width: 700px) {
  .contact-us .contactUsForm {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-us .contact-us-toast .contact-us-toast-height {
    height: 100px;
  }
  .contact-us .contact-us-toast {
    position: fixed;
    top: 14%;
    left: 1%;
    width: 98%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 920px) {
  .contact-us .contact-us-toast {
    position: fixed;
    top: 14%;
    left: 9%;
    width: 84%;
  }
}
