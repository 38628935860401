.documentstepper-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: calc(100% - 32px);
  margin: 16px 56px 16px 16px;
  width: calc(100% - 72px);
}

@media only screen and (max-width: 600px) {
  .documentstepper-container {
    margin: 0;
    width: 100%;
  }
}
