.product-availibility-supply-order-Popup {
  max-width: 420px;
}

.product-availibility-supply-order-Popup
  .product-availibility-supply-order-PopupContent {
  padding: 20px;
}

.product-availibility-supply-order-Popup
  .product-availibility-supply-order-PopupTitle {
  font-weight: 700;
  line-height: 24px;
  color: #323234;
  margin-right: 45px;
  text-align: left;
  margin-bottom: 16px;
}

.product-availibility-supply-order-Popup
  .product-availibility-supply-order-PopupDialogParent {
  padding: 8px 0px;
  margin-bottom: 16px;
}

.product-availibility-supply-order-Popup .buttons-alignment-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.product-availibility-supply-order-Popup
  .product-availibility-supply-order-BackToNewBtn {
  width: 100%;
  padding: 8px 0px;
  text-transform: none;
}

.product-availibility-supply-order-Popup
  .product-availibility-supply-order-SelectSuppliesBtn {
  width: 100%;
  background-color: #0049bd;
  color: white;
  text-transform: none;
  height: 40px;
}

@media screen and (max-width: 400px) {
  .product-availibility-supply-order-Popup {
    width: 100%;
  }
  .product-availibility-supply-order-Popup
    .product-availibility-supply-order-PopupTitle {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 24px;
    color: #323234;
    margin-right: 45px;
  }
}
