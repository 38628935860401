.user-profile-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
}

.user-profile-component .user-profile-container {
  margin: 40px;
  width: calc(100% - 80px);
}

.user-profile-container .user-profile-route-section {
  margin-top: 2px;
  margin-bottom: 42px;
}

.user-profile-container .user-profile-hearder {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-bottom: 38px;
  width: 100%;
}

.user-profile-container .reset-password {
  border: 1px solid #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 45px 0;
  text-align: center;
  text-transform: none;
  width: 132px;
}

.user-profile-container .reset-password.update-margin {
  margin: 40px 0;
}

.user-profile-container .reset-password:disabled {
  border: 1px solid #76767a;
  color: #76767a;
}

.user-profile-container .add-facility {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  margin: 0px 0;
  text-align: center;
  text-transform: none;
}

.user-profile-container .add-facility-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.add-facility-loader-pop-up .add-facility-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.user-profile-container .add-facility-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.add-facility-pop-up .add-facility {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 488px;
  width: 574px;
}

.reset-password-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reset-password-success-pop-up .reset-password-success {
  margin: 12px 16px 16px;
  min-height: 138px;
  width: 432px;
}

.reset-password-success .title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
}

.reset-password-success .description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 24px 0 32px;
}

.reset-password-success .description .temporary-data {
  background-color: #f5f5f5;
  color: #00897b;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  margin: 2px;
  padding: 6px 4px;
}

.reset-password-success .description .email {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 4px;
  text-decoration: underline;
}

.reset-password-success .close-btn {
  background-color: #0049bd;
  border-radius: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  margin: 0px;
  width: 100%;
}

.reset-password-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reset-password-failure-pop-up .reset-password-failure-pop-up-div {
  height: 165px;
  width: 432px;
}

.update-user-success-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.update-user-success-pop-up .update-user-success {
  height: 155px;
  margin: 12px 16px 16px;
  width: 432px;
}

.update-user-success .description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 24px 0 32px;
  text-align: center;
}

.update-user-success .ok-btn {
  background-color: #0049bd;
  border-radius: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  margin: 0px;
  width: 100%;
}

.user-profile-container .edit-facility-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit-facility-pop-up .edit-facility {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 550px;
}

.link-to-facility-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.link-to-facility-failure-pop-up .link-to-facility-failure-pop-up-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 400px;
}

.link-to-facility-failure-pop-up
  .link-to-facility-failure-pop-up-div
  .failure-pop-up-message {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 4px 20px;
  width: 100%;
}

.link-to-facility-failure-pop-up
  .link-to-facility-failure-pop-up-div
  .failure-pop-up-message
  .phone-number {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  margin: 16px 2px 20px;
}

.link-to-facility-failure-pop-up
  .link-to-facility-failure-pop-up-div
  .failure-pop-up-back-button {
  justify-content: center;
  text-transform: none;
  width: 100%;
}

@media only screen and (max-width: 920px) {
  .user-profile-component {
    margin: 0px;
  }

  .user-profile-component .user-profile-container {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 599px) {
  .user-profile-container .edit-facility-pop-up {
    margin: auto 16px;
    width: calc(100% - 32px);
  }

  .edit-facility-pop-up .edit-facility {
    margin: auto 16px;
    max-width: 550px;
    width: calc(100% - 32px);
  }
  .add-facility-pop-up .add-facility {
    width: 100%;
    zoom: 80%;
  }
  .reset-password-failure-pop-up .reset-password-failure-pop-up-div {
    width: 100%;
  }
  .reset-password-failure-pop-up .paperRoot {
    margin: 10px;
  }
  .add-facility-loader-pop-up .add-facility-loader {
    width: 300px;
  }
}

@media only screen and (max-width: 414px) {
  .reset-password-success-pop-up {
    margin: 16px;
    max-height: 100%;
    max-width: calc(100% - 32px);
  }

  .update-user-success-pop-up {
    margin: 16px;
    max-width: calc(100% - 32px);
  }

  .update-user-success-pop-up .update-user-success {
    margin: 16px;
    height: fit-content;
    max-width: calc(100% - 32px);
  }

  .reset-password-success-pop-up .reset-password-success {
    height: fit-content;
    margin: 12px 16px 16px;
    max-height: fit-content;
    max-width: calc(100% - 32px);
    width: calc(100% - 32px);
  }

  .link-to-facility-failure-pop-up {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }

  .link-to-facility-failure-pop-up .link-to-facility-failure-pop-up-div {
    max-height: fit-content;
    max-width: 380px;
    margin: 20px 16px;
    width: calc(100% - 32px);
  }
}
