.return-stored-product-popup .return-stored-product-popup-popup-div {
  margin: 20px;
  max-width: 486px;
  max-height: 662px;
}
.return-stored-product-popup-popup-div .return-stored-product-div-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  margin-top: 0px;
}
.return-stored-product-popup-popup-div .return-stored-product-div-desc {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.return-stored-product-popup-popup-div .return-info-div {
  height: 340px;
  overflow-y: auto;
}
