.choose-patient-type {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
}

.choose-patient-type .choose-patient-type-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 16px;
  min-height: 24px;
}

.choose-patient-type .choose-patient-type-what-care-setting {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.choose-patient-type .radioRoot {
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.choose-patient-type .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  line-height: 18px;
  margin: 8px 0 0;
  min-width: 136px;
  width: 25%;
}

.choose-patient-type .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  line-height: 18px;
  margin: 8px 0 0;
  min-width: 136px;
  width: 25%;
}

.choose-patient-type .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.choose-patient-type .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.choose-patient-type .search-button {
  align-items: center;
  background: #0049bd;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  margin: 24px 0px 0px;
  text-transform: none;
  width: 100%;
}

.choose-patient-type .search-button:disabled {
  background: #e4e4e8;
  color: #76767a;
}

.choose-patient-type .serach-view {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.choose-patient-type .serach-view .serach-view-header {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 24px 0 16px;
}

.choose-patient-type .serach-view .serach-view-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item.left {
  padding-right: 8px;
  width: calc(100% - 8px);
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item.right {
  padding-left: 8px;
  width: calc(100% - 8px);
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item.top {
  padding-top: 8px;
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item
  .serach-view-input {
  width: 100%;
}

.choose-patient-type
  .serach-view
  .serach-view-grid-container
  .serach-view-grid-item
  .serach-view-or {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 0px 8px;
  text-align: center;
  width: 100%;
}

.choose-patient-type .serach-view .home-care-order-search {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.choose-patient-type .serach-view .serach-view-or {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 0px 8px;
  text-align: center;
  width: 100%;
}

.search-view-loader-pop-up .paperRoot {
  height: 250px;
  width: 350px;
}

.search-view-loader-pop-up-div {
  display: flex;
  justify-content: center;
  height: 250px;
  margin: 0px;
  width: 350px;
}

@media only screen and (max-width: 600px) {
  .choose-patient-type .choose-patient-type-header {
    margin: 0px 0px 8px;
  }

  .choose-patient-type
    .serach-view
    .serach-view-grid-container
    .serach-view-grid-item
    .serach-view-or {
    margin: 12px 0px 4px;
  }

  .search-view-loader-pop-up .paperRoot {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .search-view-loader-pop-up-div {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
