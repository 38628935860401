.labelRoot .error-with-warning-icon-div {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  min-height: 16px;
  width: 100%;
}

.labelRoot .error-with-warning-icon-div .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.labelRoot .error-with-warning-icon-div .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.labelRoot .error-with-warning-icon-div .warning-icon .warning-icon-image {
  height: 16px;
  width: 16px;
}
