.salesPersonListContainer {
  display: block;
  flex-flow: column;
  padding: 16px 16px 16px 16px;
  overflow: auto;
}
.salesPersonListContainerList {
  overflow-y: scroll;
  height: 280px;
  width: auto;
  margin-top: 10px;
  margin-bottom: 5px;
}
.salesPersonListContainer .title {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  margin-top: 0px;
}
.salesPersonListContainer .territory-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 35px;
  padding: 0;
  margin: 0;
  width: 99%;
}

.territory-user-searchbar .territory-search-icon-div {
  margin: 0;
}

.territory-user-searchbar .territory-search-icon-div .territory-search-icon {
  color: #76767a;

  margin: auto 13px;
}

.territory-user-searchbar .territory-user-search-input {
  color: #0c0c0c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.salesPersonList .selectButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}
.salesPersonList {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  gap: 10%;
  padding: 10px;
}
.salesPersonList.gray {
  background-color: #f8f8fa;
}
.body {
  width: 94%;
}
.territoryRegion {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.territory-detail-container-main {
  display: flex;
}
.territory-detail-container-main .territoriesDetails-main {
  display: flex;
  flex-direction: column;
}
.territoriesDetails-main .territoriesDetails {
  font-size: 14px;
  margin-bottom: 4px;
}
.territoriesDetails-main .territoryRegionmain {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.salesPersonListContainer .noRecords {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 140px;
}
.salesPersonListBtnGroup {
  width: 100%;
  display: flex;
}
.salesPersonListBtnGroup .salesPersonListCancelBtn {
  width: 100%;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .territory-user-searchbar {
    width: 85%;
  }
  .territory-user-searchbar .territory-user-search-input {
    color: #0c0c0c;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
}
