.missingRxUpload {
  padding: 3px 17px;
}

.description-missing-rx {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.missingRxUpload .desp-text-link {
  display: inline-flex;
  width: 100%;
  margin-top: 16px;
}

.missingRxUpload .desp-text-link .desp-text {
  width: 50%;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
}

.missingRxUpload .desp-text-link .desp-link {
  cursor: pointer;
  width: 50%;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #0049bd;
  text-align-last: end;
}

.title-text {
  margin-top: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.title-msg {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

.missing-rx-spinner {
  min-height: 188px;
  width: auto;
}

.prescriberBox {
  width: 100%;
  margin-top: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: right;
  padding: 10px 16px;
  gap: 24px;
  width: 542px;
  height: 60%;
  background: #ffffff;
  border: 1px solid #e4e4e8;
}

.my-modal {
  width: 90vw;
}

.missingRxUpload .prescriptionDoc {
  margin-left: 0px;
  margin-top: -8px;
}

.title-msg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-msg button {
  white-space: nowrap;
}

.missingRxUpload .button-printrx-rx {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-weight: 800;
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  min-height: 32px;
  min-width: 120px;
  width: 20%;
  text-transform: none;
}

.uploadCustomDiv {
  margin-top: 40px;
}

.selectedFileDiv {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  height: 136px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 542px;
}

.selectedFileDiv .fileStatussuccess {
  align-self: center;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 14px;
  justify-content: flex-end;
  line-height: 18px;
  text-align-last: right;
  width: 40%;
}

.selectedFileDiv .fileStatussuccess .rx-upload-check-mark-img {
  margin-right: 5px;
}

.selectedFileDiv .rx-upload-status {
  line-height: 25px;
}

.fileNameTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  padding: 12px 0px 0px 12px;
}

.selected-file-div {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  background-color: antiquewhite;
}

.uploadDocBtn {
  margin-right: 12px;
  margin-bottom: 12px;
  text-align: right;
  text-transform: none;
}

.selected-file-div .fileDetailCard {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: start;
  min-height: 56px;
  width: 100%;
}

.selected-file-div .fileDetailCard .filename {
  align-self: center;
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 11px;
  overflow-wrap: break-word;
  width: 50%;
}

.selected-file-div .fileDetailCard .fileStatuserror {
  align-self: center;
  color: #cb0000;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align-last: right;
  width: 40%;
}

.fileDetailCard .fileClose {
  align-self: center;
  cursor: pointer;
  margin-right: 15px;
  position: relative;
  text-align: right;
  top: 3px;
  width: 50px;
}
.change-pres-button-drop-select {
  text-transform: none !important;
}

@media only screen and (max-width: 600px) {
  .selectedFileDiv {
    width: 100%;
  }
  .selectedFileDiv .fileStatussuccess {
    width: 60%;
  }
}

@media only screen and (max-width: 568px) {
  .missingRxUpload .desp-text-link {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .missingRxUpload .desp-text-link .desp-text {
    order: 2;
    width: 100%;
  }

  .missingRxUpload .desp-text-link .desp-link {
    order: 1;
    text-align-last: left;
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .selectedFileDiv .fileStatussuccess {
    width: 85%;
  }
}
