.video-js .vjs-dock-text {
  display: none;
}

.video-js.vjs-mouse .vjs-big-play-button {
  background-color: white;
  color: #0049bd;
  height: 50px;
  width: 50px;
}

.video-js .vjs-big-play-button {
  line-height: 55px;
  font-size: 41px;
}

.video-js .vjs-play-progress {
  background-color: #0049bd;
}

.video-js .vjs-volume-level {
  background-color: #0049bd;
}

.dialogcontent {
  color: white;
}

.dialogClassRoot .dialogPaper {
  min-width: 800px;
  min-height: 519px;
  background-color: #323234;
}

.dialogVideoTitleRoot {
  color: white;
  font-weight: 700 !important;
  font-size: 20px !important;
  height: 32px !important;
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 0px 0px;
  gap: 8px;
  width: 100%;
  height: 54px;
}

.faq .title {
  width: 100%;
  height: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #323234;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.faq .desc {
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.faqList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 24px 0px;
  width: 100%;
}

.contactus {
  color: #0049bd;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.question {
  vertical-align: text-bottom;
  margin-left: 10px;
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0049bd;
  cursor: pointer;
}

.answer {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 7px 32px 0px 31px;
}

.answer-active {
  display: block;
}

.answer-inactive {
  display: none;
}

.collapse {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  text-align-last: right;
  text-align: end;
  color: #0049bd;
}

.question-answer {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  width: 100%;
}

.questionblock {
  margin-left: 5px;
}

.mainbanner {
  position: relative;
  width: 100%;
}

.mainbanner .mainbanner-watch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 60%;
  left: 36.11px;
  top: 99px;
}

.mainbanner .mainbanner-watch .mainbanner-watch-text {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mainbanner .mainbanner-watch .mainbanner-watch-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px 8px 10px;
  gap: 8px;
  width: 240px;
  height: 40px;
  background: #0049bd;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mainbanner
  .mainbanner-watch
  .mainbanner-watch-button
  .mainbanner-watch-button-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.mainbanner
  .mainbanner-watch
  .mainbanner-watch-button
  .mainbanner-watch-button-text {
  cursor: pointer;
  width: 116px;
  height: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.mainbanner .mainbanner-image {
  width: 100%;
  float: right;
}

.mainbanner .mainbanner-image .mainbanner-poster {
  height: 100%;
  object-fit: fill;
  border-radius: 5px;
  width: 100%;
}

.video-tutorials-header {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #323234;
  width: 100%;
  height: 28px;
  padding: 24px 0px;
}

.mainFaq .faq-video-container {
  padding-left: 15px;
}

.mainFaq .faq-video-container .faq-video-grid-item {
  padding: 0;
  width: 100%;
}

.mainFaq .faq-video-container .faq-video-grid-item .faq-video-box {
  align-items: center;
  background: #f2f2f5;
  border: 1px solid #ccccd0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: calc(100% - 26px);
}

.mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.left {
  margin: 13px 13px 13px 0px;
  width: calc(100% - 13px);
}

.mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.center {
  margin: 13px 14px 13px 13px;
  width: calc(100% - 27px);
}

.mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.right {
  margin: 13px 0px 13px 13px;
  width: calc(100% - 13px);
}

.mainFaq
  .faq-video-container
  .faq-video-grid-item
  .faq-video-box
  .faq-video-poster {
  aspect-ratio: 249.33/139.73;
  border: 1px solid #ccccd0;
  border-radius: 4px;
  height: inherit;
  margin-top: 16px;
  object-fit: fill;
  width: inherit;
}

.mainFaq .faq-video-container .faq-video-grid-item .faq-video-text {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  width: calc(100% - 32px);
}

.mainFaq
  .faq-video-container
  .faq-video-grid-item
  .faq-video-text
  .faq-video-name {
  align-items: flex-start;
  color: #000000;
  cursor: pointer;
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .mainbanner .mainbanner-watch {
    width: 80%;
  }
  .mainbanner .mainbanner-watch .mainbanner-watch-text {
    font-size: 22px;
    line-height: 24px;
  }
  .mainbanner .mainbanner-watch .mainbanner-watch-button {
    width: 160px !important;
    height: 30px !important;
  }
  .faq .title {
    font-size: 22px;
    line-height: 24px;
  }
  .dialogClassRoot .dialogPaper {
    min-width: 300px !important;
    min-height: 280px !important;
  }
  .dialogVideoTitleRoot {
    height: 65px !important;
  }
  .mainbanner
    .mainbanner-watch
    .mainbanner-watch-button
    .mainbanner-watch-button-text {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 800px) {
  .dialogClassRoot .dialogPaper {
    min-width: 628px;
    min-height: 376px;
  }
}

@media only screen and (max-width: 926px) {
  .mainbanner .mainbanner-watch .mainbanner-watch-text {
    font-size: 22px;
    line-height: 24px;
  }
  .mainbanner .mainbanner-watch .mainbanner-watch-button {
    width: 200px;
    height: 30px;
  }
  .mainbanner .mainbanner-image .mainbanner-poster {
    width: 100%;
  }
  .faq {
    margin-top: 16px;
  }
  .faqList {
    padding: 34px 0px 24px 0px;
  }
  .dialogVideoTitleRoot {
    font-size: 18px !important;
  }
  .videoContent-div {
    width: 100%;
    background: #323234;
  }
  .dialogcontent {
    padding: 8px 8px !important;
    width: 95%;
  }
  .video-js .vjs-big-play-button {
    line-height: 50px !important;
    font-size: 25px !important;
  }
  .closeBtnMobile {
    margin: 2px 8px 0px 0px;
  }
  .container-mobile {
    flex-direction: column;
    gap: 10px;
  }
  .mainFaq .faq-video-container .faq-video-grid-item {
    padding-top: 16px;
    padding-left: 16px;
  }
  .mainFaq .faq-video-container .faq-video-grid-item .faqVideoBoxMobile {
    flex-direction: row;
    column-gap: 10px;
    width: 100%;
    height: 100%;
  }
  .mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.left {
    margin: 13px 13px 13px 0px;
    width: 100%;
  }
  .mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.right {
    margin: 13px 13px 13px 0px;
    width: 100%;
  }
  .mainFaq .faq-video-container .faq-video-grid-item .faq-video-box.center {
    margin: 13px 13px 13px 0px;
    width: 100%;
  }
  .mainFaq
    .faq-video-container
    .faq-video-grid-item
    .faqVideoBoxMobile
    .faq-video-poster {
    height: inherit;
    margin-top: 0px;
    object-fit: fill;
    width: 100px;
    margin-left: 11px;
  }
}

@media only screen and (max-width: 500px) {
  .faqList {
    padding: 80px 0px 24px 0px;
  }
  .faq .desc {
    margin-top: 16px;
  }
}
