.move-unit-to-stored-product-component {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.move-unit-to-stored-product-component .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 16px 8px 16px;
  width: calc(100% - 32px);
}

.move-unit-to-stored-product-component .move-unit-grid-container {
  padding: 0px;
  margin: 16px;
  width: calc(100% - 32px);
}

.move-unit-to-stored-product-component
  .move-unit-grid-container
  .move-unit-grid-item {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.move-unit-grid-container .move-unit-grid-item .product-details {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.move-unit-grid-container
  .move-unit-grid-item
  .product-details
  .product-detail-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: 100%;
}

.move-unit-grid-container
  .move-unit-grid-item
  .product-details
  .product-detail-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  width: 100%;
}

.move-unit-grid-container .move-unit-grid-item .update-store-location-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 0px 8px;
  width: 100%;
}

.move-unit-grid-container .move-unit-grid-item .storage-location-input {
  width: 100%;
}

.move-unit-grid-container
  .move-unit-grid-item
  .storage-location-input.storage-location
  .storage-location-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.move-unit-grid-item .footer-button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.move-unit-grid-item .footer-button .cancel-button {
  border: 1px solid #0049bd;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}

.move-unit-grid-item .footer-button .next-button {
  background-color: #0049bd;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  height: 42px;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
}
.move-unit-grid-container .move-buttons-container {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}
.move-buttons-container.button-container {
  width: 100%;
}
.move-buttons-container .move-unit-cancel-button {
  width: 100%;
}
.move-buttons-container .move-unit-confirmMove-button {
  width: 100%;
}
@media screen and (max-width: 480px) {
  .move-unit-to-stored-product-component {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .move-unit-to-stored-product-component {
    margin: 0px 8px;
    width: calc(100% - 16px);
  }
}

@media screen and (max-width: 320px) {
  .move-unit-to-stored-product-component .title {
    width: calc(100% - 40px);
  }
}
