.in-patient-supply-order-second-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}

.in-patient-supply-order-second-page-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 40px 40px 16px 16px;
  width: calc(100% - 80px);
}
.in-patient-supply-order-second-page-popup
  .in-patient-supply-order-second-page-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
}
.in-patient-supply-order-second-page-popup .paperRoot {
  width: 430px;
  padding: 16px;
}

@media screen and (max-width: 450px) {
  .in-patient-supply-order-second-page-popup .paperRoot {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .in-patient-supply-order-second-page-popup
    .in-patient-supply-order-second-page-popup-div {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
}
@media only screen and (max-width: 920px) {
  .in-patient-supply-order-second-page-component {
    width: calc(100% - 40px);
  }
}

@media screen and (max-width: 600px) {
  .in-patient-supply-order-second-page-component {
    margin: 0;
    width: 100%;
  }
  .in-patient-supply-order-second-page-component .short-form {
    margin: 0 0 40px 0;
    width: 100%;
  }
}
