.myLists-custom-header-div {
  display: flex;
  flex-direction: row;
}
.myLists-custom-header-div .myLists-customHeaderLabel {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.myLists-customHeader-popper .myLists-customHeader-tooltip {
  background-color: #fff;
  color: #000;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  width: auto;
  height: auto;
}
.myLists-customHeader-tooltip-div .myLists-customHeader-tooltip-subTitle {
  font-weight: 700;
  font-size: 16px;
}
svg.myLists-infoIcon-tooltipRoot {
  color: #1976d2;
  width: 19.5px;
  height: auto;
  cursor: pointer;
  margin: 0px 0px 4px 6px;
}
