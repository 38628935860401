.device-complications-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;
}

.device-complications-component .device-complications-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin: 8px 0px;
}

.device-complications-component .device-complications-part-one {
  margin-top: 16px;
  margin-bottom: 16px;
}

.device-complications-component .device-complications-part-two {
  margin-top: 16px;
}

.device-complications-component .device-complications-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.device-complications-component
  .device-complications-grid-container
  .device-complications-grid-item {
  margin: 0px;
  padding: 8px 0 0;
  width: 100%;
}

.device-complications-component .device-complications-header-title {
  margin: 0px;
  white-space: pre-wrap;
  width: 100%;
}

.device-complications-component .radioRoot {
  flex-direction: row;
  justify-content: space-between;
  gap: unset;
}

.device-complications-component .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.device-complications-component .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.device-complications-component .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  min-height: 48px;
  margin: 0;
  width: calc(33% - 4px);
}

.device-complications-component .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.device-complications-component .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.device-complications-grid-item .describe-injury-with-3m {
  white-space: pre-wrap;
  width: 100%;
}
.device-complications-grid-item input.input {
  background: #f2f2f5;
  border-right: 0;
  color: #323234;
}

.device-complications-grid-item .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.device-complications-grid-item .textarea-root {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 0;
}

@media screen and (max-width: 380px) {
  .device-complications-component .radioRoot {
    margin: 16px 0;
  }

  .device-complications-component .optionRoot {
    width: calc(30% - 4px);
  }

  .device-complications-component .optionRoot-active {
    width: calc(30% - 4px);
  }

  .device-complications-component .optionRoot.unknown {
    width: calc(40% - 4px);
  }

  .device-complications-component .optionRoot-active.unknown {
    width: calc(40% - 4px);
  }
  .device-complications-component .optionRoot-error {
    width: calc(30% - 4px);
  }

  .device-complications-component .optionRoot-error.unknown {
    width: calc(40% - 4px);
  }

  .device-complications-grid-item .textarea-root {
    margin-top: 14px;
  }
}
