.inprocess-order-ro {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.tableData {
  justify-content: center;
}
.tableAlertData {
  justify-content: center;
  font-weight: 700;
  color: #cb0000;
  cursor: pointer;
}
.tableAlertDataNoCursor {
  justify-content: center;
  font-weight: 700;
  color: #cb0000;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
  position: relative;
  bottom: 1px;
  display: inline-table;
}
.circle.redCircle {
  background-color: #cb0000;
}
.circle.yellowCircle {
  background-color: #ff9649;
}
.circle.greenCircle {
  background-color: #00ac31;
}
.orderDetailAndExportBtn {
  display: flex;
  width: 100%;
  place-content: space-between;
  margin-bottom: 20px;
}
.orderDetailAndExportBtn .orderRecordCount {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  align-self: flex-end;
  position: relative;
  bottom: 5px;
  width: calc(100% - 167px);
}
.orderDetailAndExportBtn .export-coversheet-div {
  width: 180px;
  display: flex;
  justify-content: flex-end;
}
.export-coversheet-div .export-title {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
  margin-right: 15px;
}

@media only screen and (max-width: 600px) {
  .orderRecordCount {
    bottom: 10px;
  }
  .export-coversheet-div .export-title {
    margin-right: 0px;
  }
  .orderDetailAndExportBtn .orderRecordCount {
    bottom: 11px;
    width: (100% - 160px);
  }
  .orderDetailAndExportBtn .export-coversheet-div {
    width: 160px;
  }
}
