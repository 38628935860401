.territoryDetail-filter-mng-usr {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  place-content: space-between;
}

.territoryDetail-filter-mng-usr .territoryDetail-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
  width: 55%;
}

.territoryDetail-user-searchbar .territoryDetail-search-icon-div {
  height: 100%;
  margin: 0;
}

.territoryDetail-user-searchbar
  .territoryDetail-search-icon-div
  .territoryDetail-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.territoryDetail-user-searchbar .territoryDetail-user-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.territoryDetail-filter-mng-usr
  .sale-user-status-input
  .sale-user-status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

.territoryDetail-filter-mng-usr div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 20%;
}

.territoryDetail-filter-mng-usr .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.territoryDetail-filter-mng-usr .placeHolder .sale-selectIcon {
  top: 10px;
}

.territoryDetail_tables_container {
  margin-top: 16px;
  max-height: 500px;
  min-height: 300px;
  overflow: auto;
  border: 1px solid #e4e4e8;
  margin-left: 0px;
  width: 100%;
}

.territory-select-result-facility {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding-left: 15px;
}

.territory-search-result-tables-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.territory-tables {
  width: 100%;
  border-spacing: 0;
}
.territory-tables th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 2px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.territory-tables td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.territory-tables tbody tr:first-child td {
  border-top: none;
}

.territory-tables tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.territory-tables tbody tr:nth-child(2n) td {
  background: #fff;
}

.territory-tables thead th:nth-child(1) {
  width: 10%;
}

.territory-tables thead th:nth-child(2) {
  width: 40%;
  padding: 0px 14px 0px 10px;
}

.territory-tables thead th:nth-child(3) {
  width: 12%;
  padding: 0px 18px 0px 0px;
}

.territory-tables thead th:nth-child(4) {
  width: 20%;
  padding: 0px 17px 0px 9px;
}

.territory-tables thead th:nth-child(5) {
  width: 10%;
  padding: 0px 15px 0px 7px;
}

.territory-tables thead th:nth-child(6) {
  width: 20%;
  padding: 0px 13px 0px 7px;
}

.territory-tables th.up {
  background-image: url("../../../../../../assets/up_arrow.svg");
}

.territory-tables th.down {
  background-image: url("../../../../../../assets/down_arrow.svg");
}

.territory-tables th.default {
  background-image: url("../../../../../../assets/default.svg");
}
.img {
  background-color: white;
}

.searchFacilityResult-subMain {
  margin-top: 100px;
}

.territory-parentDiv {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 16px;
}

.paginationTable {
  display: inline-flex;
  margin-top: 4px;
  height: 26px;
}

.itemsPerPage {
  display: inline-flex;
  float: left;
}

.totalCounts {
  display: inline-flex;
  float: right;
}

.list {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  justify-content: center;
  list-style-type: none;
  color: #0049bd;
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
}

.list.active {
  border-bottom-color: #0049bd;
  border-bottom: 3px solid;
}

.numberSpace {
  padding: 2px;
}

.pageNumbers {
  justify-content: center;
}

.handleButton {
  background-color: white;
  border: none;
  color: #0049bd;
}

button {
  border: none;
  background-color: transparent;
}
.numberInside {
  padding: 50px;
  cursor: pointer;
}

select {
  width: 50px;
  height: 26px;
  font-size: 12px;
  text-align: center;
}
.ptag {
  margin-top: 9px;
  margin-right: 10px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.territory-parentDiv .paginationTable button.disabled {
  color: grey;
}

.territory-parentDiv .paginationTable button {
  color: #0049bd;
}

.prevbutton {
  border: none;
}

.dropdown {
  width: 70px;
  height: 35px;
}

.dropdown .dropDown {
  margin-top: 2px;
  font-size: 12px;
  margin-right: 1px;
  padding-left: 1px;
}

.itemsPerPage {
  display: inline-flex;
  float: left;
}

.totalCounts {
  display: inline-flex;
  float: right;
}

.pageNumbers {
  justify-content: center;
}
.last-section-right {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
@media only screen and (max-width: 600px) {
  .territory-tables thead th:nth-child(3) {
    padding: 0px 11px 0px 0px;
  }
  .territory-tables thead th:nth-child(4) {
    padding: 0px 12px 0px 0px;
  }
  .territory-tables thead th:nth-child(5) {
    padding: 0px 12px 0px 8px;
  }
  .territory-tables thead th:nth-child(6) {
    padding: 0px 10px 0px 0px;
  }
  .paginationTable {
    margin-top: 20px;
    height: 37px;
  }
  .itemsPerPage {
    margin-top: -77px;
  }
  .totalCounts {
    margin-top: -77px;
  }
  .territory-parentDiv {
    margin-top: 85px;
  }
}

@media only screen and (min-width: 601px) and(max-width:926px) {
  .territory-tables thead th:nth-child(2) {
    padding: 0px 14px 0px 0px;
  }
  .territory-tables thead th:nth-child(3) {
    padding: 0px 18px 0px 0px;
  }
  .territory-tables thead th:nth-child(4) {
    padding: 0px 17px 0px 0px;
  }
  .territory-tables thead th:nth-child(5) {
    padding: 0px 15px 0px 0px;
  }
  .territory-tables thead th:nth-child(6) {
    padding: 0px 13px 0px 0px;
  }
}
@media only screen and (max-width: 340px) {
  .itemsPerPage {
    margin-top: 0px;
    width: 30px;
  }
  .totalCounts {
    margin-top: 0px;
    margin-left: -28px;
  }
  .territory-parentDiv {
    margin-top: 40px;
  }
  .dropdown {
    margin-top: 7px;
  }
}
@media only screen and (width: 360px) {
  .paginationTable {
    margin-top: 13px;
    height: 28px;
  }
  .itemsPerPage {
    margin-top: -70px;
  }
  .totalCounts {
    margin-top: -70px;
  }
}
@media only screen and (width: 540px) {
  .itemsPerPage {
    margin-top: -35px;
  }
  .totalCounts {
    margin-top: -35px;
  }
}
@media only screen and (max-width: 320px) {
  .itemsPerPage,
  .totalCounts,
  .paginationTable {
    margin-top: 10px;
    display: flex;
    margin-left: 33px;
  }
}

@media screen and (max-width: 600px) {
  .territoryDetail-filter-mng-usr {
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }
  .territoryDetail-user-searchbar {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
  .territoryDetail-search-icon-div {
    height: 100%;
    margin: 0;
  }
  .territoryDetail-search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 13px;
  }
  .territoryDetail-user-search-input {
    width: calc(100% - 32px);
  }
  .territoryDetail-filter-mng-usr {
    flex-direction: column;
    width: 100%;
  }

  .territoryDetail-search-icon-div {
    height: 100%;
    margin: 0;
  }
  .territoryDetail-search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 13px;
  }

  .territoryDetail-user-search-input {
    width: calc(100% - 32px);
  }
  .territoryDetail-user-status-input {
    height: 40px;
    margin: 0 16px;
    width: calc(100% - 32px);
  }
  .territoryDetail-filter-mng-usr .territoryDetail-user-searchbar {
    width: 100%;
  }
  .territoryDetail-filter-mng-usr div.placeHolder {
    width: 100%;
  }
  .territoryDetail_tables_container {
    margin-top: 16px;
    max-height: 500px;
    min-height: 300px;
    overflow: auto;
    border: 1px solid #e4e4e8;
    margin-left: 0px;
    width: 99%;
  }
}
