.woundDebridement {
  margin-top: 38px;
  width: 560px;
}
.woundDebridement-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}
.woundDebridement .woundDebridement-box-container {
  margin-top: 8px;
  width: 97%;
}

.woundDebridement .woundDebridement-sub-box-container {
  margin-top: 20px;
  width: 97%;
}

.woundDebridement .woundDebridement-grid-container {
  width: 106%;
}
.woundDebridement .woundDebridement-grid-item {
  width: 100%;
}
.woundDebridement .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}
.woundDebridement .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.woundDebridement .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.woundDebridement .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.woundDebridement .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.woundDebridement .woundDebridementType-info-input {
  width: 110%;
}
.woundDebridement
  .woundDebridementType-info-input
  .woundDebridementType-info-select {
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  height: 12px;
}

.woundDebridement .debirdement-date-picker {
  width: 110%;
  margin-top: 50px;
}
.woundDebridement .woundDebridementDate {
  height: auto;
}
div.placeholder {
  border-radius: 2px;
  color: #76767a;
}
.placeholder div.placeholder {
  border-right: unset;
  height: 20px;
}

@media only screen and (max-width: 600px) {
  .woundDebridement-component {
    width: 100%;
  }
  .woundDebridement {
    width: 100%;
  }
  .woundDebridement .radioRoot {
    flex-direction: column;
    gap: 10px;
  }
  .woundDebridement .optionRoot {
    width: auto;
  }
  .woundDebridement .optionRoot-active {
    width: auto;
  }
  .woundDebridement .woundDebridementType-info-input {
    width: 100%;
  }
  .woundDebridement .debirdement-date-picker {
    width: 100%;
  }
  .woundDebridement .woundDebridement-grid-container {
    width: 100%;
  }
}
