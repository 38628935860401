.nutrition-container {
  display: flex;
  flex-direction: column;
  width: 560px;
}

.nutrition-container .nutri-label {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
  margin-bottom: 5px;
}

.nutrition-container .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.nutrition-container .radioRoot .optionRoot {
  align-items: center;
  border: 1px solid #b4b4b8;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 48px;
  width: calc(49% - 4px);
}

.nutrition-container .radioRoot .optionRoot-active {
  align-items: center;
  border: 1px solid #0049bd;
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 48px;
  width: calc(49% - 4px);
}
.nutrition-container .radioRoot .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.nutrition-container .optiontxt {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
}

.nutrition-container .optiontxtSelect {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #18181a;
}

.nutrition-container .nutri-status-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
}

.nutrition-container .nutrition-actions-container {
  margin: 24px 0 0;
}

.nutrition-container .nutrition-action-options-container {
  width: 100%;
}

.nutrition-container
  .nutrition-action-options-container
  .nutrition-actions-item {
  width: 100%;
}

.nutrition-container .chkBoxMain {
  display: flex;
}

.nutrition-container .nutri-action-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  padding: 9px 0;
}

@media only screen and (max-width: 920px) {
  .nutrition-container {
    margin-bottom: 16px;
    width: 100%;
  }

  .nutrition-container .radioRoot {
    width: 100%;
  }
}
