.sales-region-main .sale-user-region-input .sale-user-region-select {
  height: 10px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.sales-region-main .sale-user-region-input {
  height: 42px;
  width: 25%;
}
.sales-region-main {
  margin: 0px 0px 24px 40px;
  width: calc(100% - 32px);
}

.sales-region-main div.placeHolder {
  height: 42px;
  width: 25%;
}

@media screen and (max-width: 600px) {
  .sales-region-main div.placeHolder {
    width: calc(100% - 32px);
  }
  .sales-region-main {
    margin: 0px 16px 16px;
    width: calc(100% - 0px);
  }
  .sales-region-main .sale-user-region-input {
    width: calc(100% - 32px);
  }
}
