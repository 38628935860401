.successCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: calc(100vh - 178px);
}

.successCardContainer .successtext {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #323234;
  margin-top: 18.86px;
}

.successCardContainer .successmsg {
  margin-top: 8px;
  max-width: 368px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.successCardContainer .returnbutton {
  margin-top: 16px;
  width: 200px;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
}

.successCardContainer .accnt-submitted {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
}

.successCardContainer .accnt-submitted-msg {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323234;
  max-width: 368px;
  margin-top: 8px;
}
