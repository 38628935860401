.site-status-content-component {
  width: 100%;
}
.site-status-content-component .site-status-content-heading {
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 0px;
  width: 100%;
}
.site-status-content-component .site-status-content-description {
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;
  width: 100%;
}
.site-status-content-component .site-status-content-grid-container {
  padding: 0;
  width: 100%;
}
.site-status-content-grid-container .site-status-content-grid-item {
  padding: 0;
  width: 100%;
}
.site-status-content-component .site-status-content-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.site-status-content-grid-container .site-status-content-input {
  width: 100%;
}
.site-status-content-component .site-status-content-link-details {
  padding-top: 16px;
  width: 100%;
}
.site-status-content-link-details .site-status-content-link-item {
  padding: 0;
  margin: 0;
  width: 100%;
}
.site-status-content-link-details .content-url-link {
  padding: 0;
  margin: 0;
  width: 100%;
}
.site-status-content-component .site-status-content-msg-format {
  margin-top: 16px;
  width: calc(100% - 16px);
}
.site-status-content-msg-format .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}
.site-status-content-msg-format .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 32%;
}
.site-status-content-msg-format .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 32%;
}
.site-status-content-msg-format .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: 32%;
}
.site-status-content-component .custom-site-status-info {
  background-color: #f8f8fa;
  margin-top: 32px;
  padding: 8px 12px 12px 16px;
  width: calc(100% - 16px);
}
.site-status-content-msg-format .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  width: 100%;
}
.site-status-content-msg-format .optiontxtSelect {
  color: #18181a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.custom-site-status-info .custom-site-status-preview {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  padding: 8px;
}

@media only screen and (max-width: 920px) {
  .site-status-content-component .site-status-content-link-details {
    display: flex;
    flex-direction: column;
  }
  .site-status-content-msg-format .optionRoot {
    margin-top: 12px;
    width: 100%;
  }
  .site-status-content-msg-format .optionRoot-active {
    margin-top: 12px;
    width: 100%;
  }
  .site-status-content-msg-format .optionRoot-error {
    margin-top: 12px;
    width: 100%;
  }
}
