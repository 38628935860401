.selected-prescriber-component {
  width: 100%;
}

.selected-prescriber-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 40px 40px;
  width: 62%;
}

.current-selected-prescriber-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.selected-precriber-details-grid {
  border: 1px solid #e4e4e8;
  padding: 16px 16px;
}

.selected-precriber-details-grid .selected-prescriber-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: left;
}

.selected-prescriber-div {
  margin-bottom: 15px;
  width: 100%;
}

.selected-prescriber-div-left {
  margin-bottom: 15px;
  width: 100%;
}

.selected-prescriber-div .selected-prescriber-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
}

.selected-prescriber-div .selected-prescriber-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.selected-prescriber-div-left .selected-prescriber-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #808084;
}

.selected-prescriber-div-left .selected-prescriber-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.selected-precriber-details-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 80px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .selected-prescriber-component .short-form {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .selected-prescriber-div-left {
    padding-right: 15px;
  }
  .selected-prescriber-div .selected-prescriber-value {
    word-wrap: break-word;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .selected-prescriber-component .short-form {
    margin: 0px 0px 40px 40px;
    width: 82%;
  }
}
