.facility-desktop-banner-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facility-desktop-banner-grid .facility-desktop-banner-grid-id {
  padding-left: 8px;
}

.facility-desktop-banner-grid .facility-desktop-banner-grid-dropdown {
  position: relative;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.facility-desktop-banner-grid
  .facility-desktop-banner-grid-dropdown
  .facility-banner-desktop-dropdown-div {
  width: calc(84% - 50px);
  margin-left: 36px;
}

.facility-desktop-banner-grid .facility-desktop-banner-grid-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: calc(5% - 20px);
}

.facility-mobile-banner-grid .facility-mobile-banner-grid-dropdown {
  position: relative;
  bottom: 6px;
}

.facility-mobile-banner-grid .facility-mobile-banner-grid-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 6px;
}
.zoom-for-multi-buttons {
  zoom: 84%;
}
