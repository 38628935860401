.salesManage-territory-main {
  width: 90%;
}
.addTerritoryBtn {
  margin-top: 20px;
  margin-left: 35px;
}
.salesterritoryboxcontainer {
  box-shadow: none;
  border-radius: 0;
  margin-top: 10px;
}
.salesManageuser-profile-territory {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #323234;
  margin-left: 40px;
}
.salesTerritory-main-container {
  display: flex;
  margin-left: 36px;
  box-sizing: border-box;
  border: 1px solid #e4e4e8;
  padding: 11px 9px 8px 12px;
  margin-top: 10px;
  width: 60%;
}
.salesTerritoryRoleNameLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
  width: 10%;
}

.salesTerritoryRegionRoleNameLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
  width: 35%;
}
.assignmentDatelabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
  width: 100%;
  margin-top: 14px;
}
.assignmentDatelabelValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  flex-direction: row;
  width: 100%;
}
.salesTerritoryCodeRoleNameLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
  width: 40%;
}
.salesTerritoryPrimaryRemoveLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  flex-direction: row;
  width: 30%;
}
.salesTerritoryRoleNameValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.salesTerritoryRolecodenameValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration: underline;
}
.isprimarysalesterritory {
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  align-items: right;
  padding-right: 12px;
  text-align: right;
}
.salesTerritoryRoleRemoveLable {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: right;
  color: #cb0000;
  text-decoration: underline;
  cursor: pointer;
  width: 15%;
  padding-right: 12px;
}
.salesTerritoryRoleRemoveLable-disable {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
  color: #949ab6;
  cursor: pointer;
  text-align: right;
  pointer-events: none;
  width: 15%;
  padding-right: 12px;
}
.remove-popup-main-container {
  width: 400px;
  height: 200px;
  left: 27638px;
  padding: 0px 0px 24px 0px;
  border-radius: 4px;
  gap: 16px;
}
.empty-pop-up-remove .paperRoot {
  width: 403px;
  height: 250px;
}
.norecord-pop-up .paperRoot {
  width: 560px;
  height: auto;
  margin: 10px;
}
.remove-popup-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #323234;
}
.remove-popup-sub-heading {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #323234;
}
.remove-back-button {
  width: 100%;
}
.remove-back-button .remove-territory-backbutton {
  width: 93%;
  margin-top: 10px;
  margin-left: 14px;
  text-transform: none;
}
.remove-access-button {
  width: 93%;
  margin-top: 10px;
  margin-left: 14px;
}

.remove-access-button .removeAccess-territory-button {
  background-color: #cb0000;
  width: 100%;
  text-transform: none;
}

.remove-access-button .removeAccess-territory-button:hover {
  background-color: #cb0000;
  color: white;
}
.pop-up-sub-main {
  margin-left: 10px;
}
.assignmentDatelable {
  margin-top: 10px;
}
.AddTerritory-loader {
  display: flex;
  justify-content: center;
  height: 250px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .salesTerritory-main-container {
    display: block;
    gap: 50px;
  }
  .salesTerritoryBoxContainer {
    width: 340px;
  }
  .empty-pop-up-remove .paperRoot {
    zoom: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .empty-pop-up .paperRoot {
    width: 300px !important;
  }
}
