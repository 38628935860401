.sales-assisstance-div {
  width: 100%;
  margin-top: 40px;
}
.sales-assisstance-div .sales-assisstance-header {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 8px;
}
.sales-assisstance-div .sales-assisstance-header.requestor {
  font-size: 18px;
}
.sales-assisstance-div .sales-assisstance-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  width: 70%;
}
.sales-assisstance-div .requestor-data-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.sales-assisstance-div .requestor-data-title-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  overflow-wrap: break-word;
}
.sales-assisstance-div .requestor-data-title-value.email-phNumber {
  color: #0049bd;
  text-decoration: underline;
}
@media only screen and (max-width: 920px) {
  .sales-assisstance-div .sales-assisstance-description {
    width: 100%;
  }
}
