.myList-storage-location-main-div {
  margin: 0px 40px 80px 40px;
}
.myList-storage-location-main-div .myList-storage-loc-sub-div {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 12px;
}
.myList-storage-loc-sub-div .myList-storage-loc-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  width: 55%;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
}
.myList-storage-loc-searchbar .myList-storage-loc-icon-div .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}
.myList-storage-loc-searchbar .myList-storage-loc-search-inputBase {
  width: 100%;
}
.myList-storage-location-main-div
  .myList-storage-loc-sub-div
  .storage-loc-allStatus-input {
  height: 40px;
  width: 40%;
}
.myList-storage-location-main-div
  .myList-storage-loc-sub-div
  .storage-location-allStatus-select {
  font-size: 14px;
}
.myList-storage-loc-sub-div .myList-storage-loc-add-btn {
  align-items: center;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: capitalize;
  width: 60%;
}
.ag-theme-quartz .myList-location-name {
  color: #323234;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.ag-theme-quartz .custom-added-location {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  background: transparent;
}
.storage-location-loader .myList-storage-loc-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}

.myList-storage-loc-sub-div .storage-loc-status-button {
  display: flex;
  flex-direction: row;
  width: 45%;
}
.ag-theme-quartz .custom-location-link {
  padding: 0px;
}
@media only screen and (min-width: 1024px) {
  .myList-storage-location-main-div .ag-theme-quartz .ag-center-cols-container {
    /*Adding important since initally value is set in style in ag-grid to override */
    width: 100% !important;
  }
  .ag-header-container {
    width: 100% !important;
  }
  .ag-header-row-column {
    width: 100% !important;
  }
}
.myList-storage-location-pop-up .paperRoot {
  width: 450px;
}

@media screen and (max-width: 600px) {
  .myList-storage-location-pop-up .paperRoot {
    width: 100%;
    margin: 0px 6px;
  }
}
@media only screen and (max-width: 920px) {
  .myList-storage-location-main-div {
    margin: 0px 20px 80px 20px;
  }
  .myList-storage-location-main-div .myList-storage-loc-sub-div {
    display: flex;
    flex-direction: column;
  }
  .myList-storage-location-main-div
    .myList-storage-loc-sub-div
    .storage-loc-allStatus-input {
    width: 60%;
  }
  .myList-storage-loc-sub-div .myList-storage-loc-add-btn {
    display: flex;
    justify-content: flex-end;
    width: 40%;
  }
  .storage-location-loader .myList-storage-loc-spinner {
    width: 300px;
  }
  .myList-storage-loc-sub-div .myList-storage-loc-searchbar {
    width: 100%;
    margin-top: 12px;
  }
  .myList-storage-loc-sub-div .storage-loc-status-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}
