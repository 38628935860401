.successRxUpload {
  margin-top: 10px;
  padding: 16px;
}
.successText {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.successTextLight {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.successRxUploadDoneBtn {
  margin-top: 32px;
}
.successRxUploadBtn {
  width: 100%;
}
