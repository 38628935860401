.education-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  width: 100%;
  min-height: calc(100vh - 144px);
}

.education-component .education-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.education-component .education-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 24px 24px 0px 24px;
}
@media only screen and (max-width: 920px) {
  .education-component {
    min-height: 750px;
    margin: 0%;
    height: 100%;
  }
  .education-component .education-grid-container {
    width: calc(100% - 10px) !important;
  }
}
.education-component .education-grid-container {
  margin: 24px;
  padding: 0px;
  width: calc(100% - 48px);
}

.education-component .education-grid-container .education-grid-item {
  width: 100%;
}
