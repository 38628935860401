.order-overview {
  margin: 0px;
}
.order-overview .all-content-div-orderoverview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -6px;
  width: 100%;
}
.order-overview .sub-content-div-orderoverview {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-left: 16px;
  margin-bottom: 16px;
  width: calc(100% - 34px);
  border: 1px solid #e4e4e8;
  background: #ffffff;
}

.sub-content-component-border-div {
  border: -0.5px solid #e4e4e8;
}
.sub-content-component-border-div .sub-content-component {
  margin-left: 16px;
  margin-bottom: 15px;
  margin-top: -26px;
}
.grid-container-order-summary {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.grid-container-order-summary .grid-item {
  width: 100%;
  height: fit-content;
}
.border-div {
  border-top: 1px solid #e4e4e8;
  border-bottom: 1px solid #e4e4e8;
}
.border-div .sub-content-component {
  margin-left: 16px;
  margin-bottom: 15px;
  margin-top: -30px;
}
.msApiErrorline {
  font-size: 14px;
  font-weight: 400;
  color: #e2bb2d;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.orderDetailsPatientInfoError {
  border-bottom: 1px solid #e4e4e8;
}
.order-summary-main-div .edit-acute-data-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit-acute-data-loader-pop-up .edit-acute-data-pop-up {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
  width: 400px;
}
@media only screen and (max-width: 926px) {
  .edit-acute-data-loader-pop-up .edit-acute-data-pop-up {
    width: 300px;
  }
}
