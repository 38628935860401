.readycare_tag {
  color: #0049bd;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.readycare_tag_bg {
  border: 1px solid #fff;
  height: 15px;
  border-radius: 24px;
  padding: 2px 6px 2px 6px;
  background: #e8f4ff;
  position: relative;
  top: -2px;
  cursor: pointer;
}

.readycareMedium_tag {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.readycareMedium_tag_bg {
  border: 1px solid #fff;
  height: 15px;
  border-radius: 24px;
  padding: 2px 6px 2px 6px;
  background: #e8f4ff;
  position: relative;
  top: -2px;
}

@media only screen and (min-width: 320px) and (max-width: 620px) {
  .readycareMedium_tag {
    font-size: 13px;
  }
}

.orderSource_tag {
  color: #4c4c4f;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}
.orderSource_tag_bg {
  background: #f8f8fa;
  border: 1px solid #fff;
  border-radius: 24px;
  height: 15px;
  padding: 4px 6px 2px 6px;
  position: relative;
  width: max-content;
}
