.woundInfectionType {
  margin-top: 20px;
}

.woundInfectionType .woundInfectionType-data {
  margin-top: 7px;
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  margin-left: 16px;
}

.woundInfectionType .optionRoot {
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.woundInfectionType .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.woundInfectionType .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.woundInfectionType .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.woundInfectionType .radioLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  color: #323234;
  width: 268px;
  height: 40px;
}
.woundInfectionType .radioTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  margin-top: 16px;
}
.woundInfectionType .radioOptionText {
  width: 268px;
  height: 40px;
}
.woundInfectionType .selectedInfectionTypeOther-other-input {
  width: 80%;
  margin-top: -11px;
  margin-left: 25px;
}
.starAsterisk {
  color: red;
}

@media only screen and (max-width: 600px) {
  .woundInfectionType .woundInfectionType-data {
    column-count: unset;
  }
}
