.search-facility-result {
  font-size: 16px;
  margin-top: -90px;
  line-height: 32px;
  margin-left: 0px;
  font-weight: 600;
}
.favoriteFacility-container .MuiOutlinedInput-root {
  max-height: 42px;
}

.select-result-facility {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding-left: 15px;
}
.search-result-imgclick {
  cursor: pointer;
}
.facility-spinner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
.tables_container {
  margin-top: 6px;
  max-height: 500px;
  min-height: 300px;
  overflow: auto;
  border: 1px solid #e4e4e8;
  margin-left: 0px;
}
.search-result-tables-static-data {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.facility-name-mobileScreen {
  position: sticky;
  left: 0;
  box-shadow: 5px 0px 5px 0px #f2f2f5;
}
.facilityName-mobileScreen {
  width: 170px;
}
.search-facility-name-mobile-screen {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
}
.tables {
  width: 100%;
  border-spacing: 0;
}
.tables th {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 10px 15px;
  font-weight: bold;
  text-align: left;
  height: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  border-right: 15px solid white;
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.tables td {
  padding: 8px 8px 8px 10px;
  height: 24px;
}

.tables tbody tr:first-child td {
  border-top: none;
}

.tables tbody tr:nth-child(n) td {
  background: #f8f8fa;
}

.tables tbody tr:nth-child(2n) td {
  background: #fff;
}

.tables thead th:nth-child(1) {
  width: 10%;
}

.tables thead th:nth-child(2) {
  width: 31%;
  padding: 0px 14px 0px 10px;
}

.tables thead th:nth-child(3) {
  width: 10%;
  padding: 0px 18px 0px 0px;
}

.tables thead th:nth-child(4) {
  width: 12%;
  padding: 0px 17px 0px 9px;
}
.tables thead th:nth-child(5) {
  width: 11%;
  padding: 0px 15px 0px 7px;
}
.tables thead th:nth-child(6) {
  width: 15%;
  padding: 0px 13px 0px 7px;
}
.tables thead th:nth-child(7) {
  width: 15%;
  padding: 0px 13px 0px 7px;
}
.tables th.up {
  background-image: url("../../../assets/up_arrow.svg");
}

.tables th.down {
  background-image: url("../../../assets/down_arrow.svg");
}

.tables th.default {
  background-image: url("../../../assets/default.svg");
}
.img {
  background-color: white;
}

.searchFacilityResult-subMain {
  margin-top: 100px;
}

.parentDiv {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.paginationTable {
  display: inline-flex;
  margin-top: 4px;
  height: 26px;
}

.itemsPerPage {
  display: inline-flex;
  float: left;
}

.totalCounts {
  display: inline-flex;
  float: right;
}

.list {
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  justify-content: center;
  list-style-type: none;
  color: #0049bd;
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
}

.list.active {
  border-bottom-color: #0049bd;
  border-bottom: 3px solid;
}

.numberSpace {
  padding: 2px;
}

.pageNumbers {
  justify-content: center;
}

.handleButton {
  background-color: white;
  border: none;
  color: #0049bd;
}

button {
  border: none;
  background-color: transparent;
}
.numberInside {
  padding: 50px;
  cursor: pointer;
}

select {
  width: 50px;
  height: 26px;
  font-size: 12px;
  text-align: center;
}
.ptag {
  margin-top: 9px;
  margin-right: 10px;
  font-size: 12px;
  color: #76767a;
  font-weight: 400;
  font-style: normal;
}

.parentDiv .paginationTable button.disabled {
  color: grey;
}

.parentDiv .paginationTable button {
  color: #0049bd;
}

.prevbutton {
  border: none;
}

.dropdown {
  width: 70px;
  height: 35px;
}

.dropdown .dropDown {
  margin-top: 2px;
  font-size: 12px;
  margin-right: 1px;
  padding-left: 1px;
}

.pageNumbers {
  justify-content: center;
}
.last-section-right {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.facilitySearchResultErrorPopup .paperRoot {
  width: 500px;
}
@media only screen and (max-width: 600px) {
  .tables thead th:nth-child(3) {
    padding: 0px 11px 0px 0px;
  }
  .tables thead th:nth-child(4) {
    padding: 0px 12px 0px 8px;
  }
  .tables thead th:nth-child(5) {
    padding: 0px 12px 0px 8px;
  }
  .tables thead th:nth-child(6) {
    padding: 0px 10px 0px 8px;
  }
}

@media only screen and (min-width: 601px) and(max-width:926px) {
  .tables thead th:nth-child(2) {
    padding: 0px 14px 0px 0px;
  }
  .tables thead th:nth-child(3) {
    padding: 0px 18px 0px 0px;
  }
  .tables thead th:nth-child(4) {
    padding: 0px 17px 0px 0px;
  }
  .tables thead th:nth-child(5) {
    padding: 0px 15px 0px 0px;
  }
  .tables thead th:nth-child(6) {
    padding: 0px 13px 0px 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 543px) {
  .itemsPerPage {
    margin-top: 30px;
  }
  .totalCounts {
    margin-top: 30px;
  }
  .paginationTable {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 920px) {
  .search-result-imgclick {
    margin-left: 16px;
  }
}
.tables_container .no-data {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  margin: 40px;
  width: calc(100% - 80px);
}
