.help-needed-main-div {
  margin-top: 40px;
  width: calc(100% - 32px);
}
.help-needed-main-div .help-needed-header {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 8px;
}
.help-needed-main-div .helpNeededradioRoot {
  flex-direction: row;
  gap: 12px;
  margin-bottom: 10px;
}
.help-needed-main-div .neededHelpOptiontxt {
  font-size: 14px;
  line-height: 18px;
  color: #4c4c4f;
}
.help-needed-main-div .helpNeededRootActive {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  height: 48px;
  margin: 0;
  width: 22%;
  text-wrap: balance;
}
.help-needed-main-div .helpNeededOptionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  height: 48px;
  margin: 0;
  width: 22%;
  text-wrap: balance;
}
@media only screen and (max-width: 920px) {
  .help-needed-main-div .helpNeededRootActive {
    width: 100%;
  }
  .help-needed-main-div .helpNeededOptionRoot {
    width: 100%;
  }
}
