.summary-header-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 0px 16px;
  width: calc(100% - 64px);
}

.summary-header-component .summary-header-part-1 {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 8px;
  width: calc(100% - 16px);
}

.summary-header-component .summary-header-part-1 .success-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 8px auto;
  width: fit-content;
}

.summary-header-component
  .summary-header-part-1
  .summary-header-part-1-description {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 8px;
  padding: 0px;
  width: calc(100% - 8px);
}

.summary-header-part-1-description .summary-header-part-1-description-success {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 8px;
  text-align: left;
}

.summary-header-part-1-description .summary-header-part-1-description-message {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px 0px 8px;
  text-align: left;
}

.summary-header-part-1-description .summary-header-part-1-description-message {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px 0px 8px;
  text-align: left;
}

.summary-header-part-1-description .summary-header-part-1-description-part-2 {
  display: flex;
  flex-direction: column;
  margin: 16px 0px 0px;
  width: 100%;
}

.summary-header-part-1-description
  .summary-header-part-1-description-part-2
  .summary-header-part-1-description-note {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.summary-header-part-1-description
  .summary-header-part-1-description-part-2
  .back-to-inventory-button {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 0px 40px 0px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  width: 237px;
}

.summary-header-component .summary-header-part-2 {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.summary-header-part-2 .summary-header-part-2-description-note {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin: 0px;
  width: 100%;
}

.summary-header-part-2 .back-to-inventory-button {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 8px 0px 16px;
  text-align: center;
  width: 237px;
}

.summary-header-component .separator-line {
  background-color: #f2f2f5;
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
  height: 2px;
  width: 100%;
}

@media only screen and (max-width: 926px) {
  .summary-header-component {
    padding: 16px 0px 0px;
    width: calc(100% - 32px);
  }
  .summary-header-part-2 .summary-header-part-2-description-note {
    margin: 16px 0px 0px;
  }
  .summary-header-part-2 .back-to-inventory-button {
    margin: 16px 0px 16px;
  }
  .summary-header-component .separator-line {
    margin: 16px;
    width: calc(100% - 32px);
  }
}
@media print {
  .summary-header-part-1-description
    .summary-header-part-1-description-part-2
    .back-to-inventory-button {
    display: none;
  }
  .summary-header-part-2 .back-to-inventory-button {
    display: none;
  }
}
