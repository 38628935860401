.authenticate-via-sms-modal {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0px 16px;
  width: calc(100% - 32px);
}

.authenticate-via-sms-modal .title {
  color: #323234;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 24px;
  width: 303px;
}

.authenticate-via-sms-modal .description-one {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-top: 0px;
  margin-bottom: 16px;
  width: calc(100% - 16px);
}

.authenticate-via-sms-modal .description-two {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 24px;
  width: calc(100% - 16px);
}

.authenticate-via-sms-modal .authenticate-via-sms-modal-grid-container {
  width: 100%;
}

.authenticate-via-sms-modal .authenticate-via-sms-modal-input {
  width: 100%;
}

.authenticate-via-sms-modal .authenticate-via-sms-modal-grid-container {
  width: 100%;
}

.authenticate-via-sms-modal .authenticate-via-sms-modal-input {
  width: 100%;
}

.authenticate-via-sms-modal .submit-btn {
  margin-top: 32px;
  width: 100%;
}

.authenticate-via-sms-modal .resend-btn {
  margin-bottom: 24px;
  margin-top: 8px;
  width: 100%;
}

.authenticate-via-sms-modal .loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 300px;
  width: 100%;
}

.authenticate-via-sms-modal .codeValidateError {
  color: #cb0000;
  font-size: 12px;
}
