.supplyOrderDressingKitInfo {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  padding: 16px;
  margin: 0px;
}

.supplyOrderDressingKitInfo .supplyOrderDressingKitBtnGrid {
  margin-bottom: 3px;
  margin-top: 22px;
}

.mainGridDressing {
  margin-bottom: 8px;
}
.supplyOrderDressingKitInfo .supplyOrder-dressingKit-add-button {
  color: #0049bd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}

.supplyOrderDressingKitInfo .dressingadd-icon {
  transform: scale(1.5) !important;
}

.supplyOrderDressingKitInfoError {
  border: 1px solid #d32f2f;
}
.supplydressingKitHeader {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 0px;
}

@media screen and (max-width: 920px) {
  .supplyOrderDressingKitInfo {
    width: calc(100% - 32px);
  }
}
