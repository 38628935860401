.review-therapy-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 36px;
  margin-bottom: 5px;
  margin-left: 32px;
}
.review-therapy-info-div {
  width: 100%;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  margin-left: 35px;
}
.therapy-request-title {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.therapy-request-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}
.review-therapy-title .review-therapy-edit-button {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  top: 1px;
  position: relative;
  margin-left: 4px;
  text-decoration-line: underline;
  text-transform: none;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .review-therapy-info-div {
    display: grid;
  }
}
