.previous-therapy-component {
  margin-top: 26px;
}
.woundinfo-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.woundinfo-title-error {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: red;
}

.woundinfo-desp {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  width: calc(100% - 16px);
}

.woundinfo-desp-error {
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cb0000;
}

.woundinfo-out {
  margin-top: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  width: calc(100% - 16px);
  margin-left: 10px;
}

.woundinfo-out-error {
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #cb0000;
  margin-left: 10px;
}

.previousTherapy-data {
  margin-top: 0px;
  width: 100%;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  -moz-column-gap: 3em;
  column-gap: 3em;
}
.previousTherapy-data .previous-therapies-options-container {
  width: 100%;
}
.previousTherapy-cause {
  margin-top: 6px;
}

.previousTherapiesCheckbox {
  margin-right: 10px;
}
.previous-therapy-others {
  margin-top: 0px;
}
.previousTherapiesCheckbox .previousTherapiesCheckboxRoot {
  margin-right: 10px;
}

.chkBoxDescriptionText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #656569;
  max-width: max-content;
  margin-top: 10px;
}

.chkBoxDescriptionText-active {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  max-width: max-content;
  margin-top: 10px;
}

.requiredStar {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: red;
  margin-left: 1.5px;
}
.labelRoot .previousTherapy-other-input-label {
  margin-top: 0px;
}
.labelRoot .previousTherapy-other-input {
  width: 100%;
  margin-left: 9px;
  margin-top: -12px;
}
.previous-thery-warning-error-message-div {
  margin-left: 5px;
}
.labelRoot .previousTherapy-otherCause-input {
  width: 50%;
  margin-left: 9px;
  margin-bottom: 0px;
}
.custom-waring-icon-div {
  margin-left: 6px;
}
@media only screen and (max-width: 920px) {
  .previousTherapy-data {
    column-count: 1;
  }
  .labelRoot .previousTherapy-other-input {
    width: calc(100% - 24px);
    margin: -16px 9px 0px;
    margin-left: 9px;
  }

  .labelRoot .previousTherapy-otherCause-input {
    width: calc(100% - 24px);
    margin: -16px 9px 0px;
    margin-left: 9px;
  }
  .woundinfo-out {
    width: calc(100% - 18px);
  }
  .woundinfo-out-error {
    width: calc(100% - 20px);
  }
}
