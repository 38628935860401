.show-title-and-value-div {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 8px 8px 8px 0;
  width: calc(100% - 16px);
}
.show-title-and-value-div .show-title-and-value-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  text-align: left;
  width: 100%;
}
.show-title-and-value-div .show-title-and-value-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0px 0px;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
