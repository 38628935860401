.assessmentDueAlertText {
  margin-left: 11px;
  height: 56px;
  margin-right: 15px;
  margin-top: 0px;
  display: flex;
  margin: 16px 16px 0px 16px;
}

.assessmentDueAlertText .assessmentDueAlertHeading {
  margin-top: 8px;
  margin-left: 15px;
  width: 100%;
}
.assessmentDueAlertHeading .assessmentDueAlertReasonText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.assessmentDueAlertHeading .assessmentDueAlertReasonText {
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
.assessmentDueAlertText .assessmentDueAlertBannerButton {
  display: flex;
  flex-direction: row;
}
.assessmentDueAlertBannerButton .assessmentDueAlertNavigationButton {
  padding: 0px;
  width: 200px;
  border-radius: 2px;
  margin: 8px 16px 8px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;
  text-transform: none;
}
.assessmentDueAlertText
  .assessmentDueAlertHeading
  .assessmentDueAlertReasonParentClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-transform: none;
  border: none;
}
.low {
  background: #e8f4f8;
  border-left: 5px solid #1e64d0;
  color: #1e64d0;
}
.medium {
  background: #fff0d4;
  border-left: 5px solid #915800;
  color: #915800;
}
.high {
  background: #fff0f0;
  border-left: 5px solid #cb0000;
  color: #cb0000;
}
.assessmentDueAlertBannerButton .low {
  border: 1px solid #1e64d0;
  color: #1e64d0;
  background: #ffffff;
}
.assessmentDueAlertBannerButton .medium {
  border: 1px solid #915800;
  color: #915800;
  background: #ffffff;
}
.assessmentDueAlertBannerButton .high {
  border: 1px solid #cb0000;
  color: #cb0000;
  background: #ffffff;
}

.assessmentDueAlertBannerButton .low:hover {
  border: 1px solid #1e64d0;
  color: #1e64d0;
}
.assessmentDueAlertBannerButton .medium:hover {
  border: 1px solid #915800;
  color: #915800;
}
.assessmentDueAlertBannerButton .high:hover {
  border: 1px solid #cb0000;
  color: #cb0000;
}
@media only screen and (max-width: 920px) {
  .assessmentDueAlertText {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
  }
  .assessmentDueAlertBannerButton .assessmentDueAlertNavigationButton {
    margin: auto;
    margin-left: 5px;
    padding: 8px;
    width: 95%;
  }
  .assessmentDueAlertText .assessmentDueAlertHeading {
    margin-bottom: unset;
    margin-top: 8px;
    width: unset;
  }
  .assessmentDueAlertText .assessmentDueAlertBannerButton {
    padding: 8px 0px 8px 8px;
  }
}
