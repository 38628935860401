.education-video-library {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 16px 16px;
  height: calc(100% - 32px);
  width: calc(100% - 72px);
}

.education-video-library .education-video-library-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
}

.education-video-library .education-video-library-route-section {
  margin: 26px 24px;
}

.education-video-library .education-video-library-main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 24px;
  position: relative;
  width: calc(100% - 48px);
}

.education-video-library-main-section .main-section-image-div {
  background: linear-gradient(224.2deg, #333333 -0.23%, #76767a 100.05%);
  position: relative;
  width: 100%;
}

.main-section-image-div .main-section-image {
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.main-section-image-div .main-section-image-fullframe {
  background: #000000;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  top: 0px;
  width: 100%;
}

.main-section-image-div .main-section-image-partialframe {
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 50.52%,
    rgba(0, 0, 0, 0.37) 100%
  );
  height: 75%;
  left: 0px;
  position: absolute;
  top: 25%;
  width: 100%;
}

.education-video-library-main-section .main-section-title {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
}

.education-video-library-main-section
  .main-section-title
  .main-section-watch-text {
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  margin: 16px;
  max-width: 82%;
  min-width: 50%;
}

.education-video-library-main-section
  .main-section-title
  .main-section-watch-button {
  align-items: center;
  background: #0049bd;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  height: 40px;
  margin: 16px;
  padding: 8px 14px 8px 10px;
  max-width: 216px;
}

.education-video-library-main-section
  .main-section-title
  .main-section-watch-button
  .main-section-watch-button-icon {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.education-video-library-main-section
  .main-section-title
  .main-section-watch-button
  .main-section-watch-button-text {
  color: #ffffff;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  text-align: left;
  width: fit-content;
}

.education-video-grid-item .education-video-library-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  min-width: 150px;
  padding: 0;
  margin: 0;
  width: 100%;
}

.education-video-grid-item .education-video-library-searchbar .search-icon-div {
  height: 100%;
  margin: 0;
}

.education-video-library-searchbar .search-icon-div .search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 13px;
}

.education-video-library-searchbar .education-video-library-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

.education-video-library .education-video-library-category {
  height: 40px;
  margin: 0 12px;
  width: calc(100% - 12px);
}

.education-video-library .education-video-library-category-input {
  height: 42px;
  width: 100%;
}

.education-video-library
  .education-video-library-category-input
  .education-video-library-category-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.education-video-library div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
  width: 100%;
}

.education-video-library .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}
.education-video-library .placeHolder .selectIcon {
  top: 10px;
}

.education-video-library .therapy-information-input {
  height: 42px;
  width: 100%;
}

.education-video-library .education-video-library-video-tutorials-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.education-video-library-video-tutorials-section
  .education-video-tutorials-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  padding: 24px 24px 0 24px;
  width: 100%;
}

.education-video-library-video-tutorials-section
  .education-video-library-container {
  padding: 15px 24px;
  width: 100%;
}

.education-video-library-container .education-video-grid-item {
  padding: 0;
  width: 100%;
}

.education-video-library-container
  .education-video-grid-item
  .education-video-box {
  align-items: center;
  background: #f2f2f5;
  border: 1px solid #ccccd0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: calc(100% - 26px);
}

.education-video-library-container
  .education-video-grid-item
  .education-video-box.left {
  margin: 13px 13px 13px 0px;
  width: calc(100% - 13px);
}

.education-video-library-container
  .education-video-grid-item
  .education-video-box.center {
  margin: 13px 14px 13px 13px;
  width: calc(100% - 27px);
}

.education-video-library-container
  .education-video-grid-item
  .education-video-box.right {
  margin: 13px 0px 13px 13px;
  width: calc(100% - 13px);
}

.education-video-library-container
  .education-video-grid-item
  .education-video-box
  .education-video-poster {
  aspect-ratio: 249.33/139.73;
  border: 1px solid #ccccd0;
  border-radius: 4px;
  height: inherit;
  margin-top: 16px;
  object-fit: fill;
  width: inherit;
}

.education-video-library-container
  .education-video-grid-item
  .education-video-text {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
  width: calc(100% - 32px);
}

.education-video-library-container
  .education-video-grid-item
  .education-video-text
  .education-video-product {
  align-items: flex-start;
  color: #76767a;
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  margin: 4px 0 0 0;
}

.education-video-library-container
  .education-video-grid-item
  .education-video-text
  .education-video-name {
  align-items: flex-start;
  color: #000000;
  cursor: pointer;
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 32px;
  line-height: 16px;
  margin-top: 4px;
}

@media only screen and (max-width: 1040px) {
  .education-video-library-container
    .education-video-grid-item
    .education-video-text
    .education-video-name {
    height: 46px !important;
  }
  .education-video-library .education-video-library-category {
    width: calc(100% - 0px);
  }

  .education-video-library-main-section
    .main-section-title
    .main-section-watch-text {
    margin: Auto 16px;
  }
}

@media only screen and (max-width: 920px) {
  .education-video-library {
    min-height: 750px;
  }
  .education-video-library-video-tutorials-section .container-mobile {
    flex-direction: column;
    gap: 10px;
  }
  .education-video-library-searchbar .education-video-library-search-input {
    font-size: 13px;
  }
  .education-video-library-video-tutorials-section
    .education-video-tutorials-header {
    padding: 0px;
    width: calc(100% - 40px);
    margin: 20px 20px 0px;
  }
  .education-video-library-searchbar .search-icon-div .search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 4px;
  }
  .education-video-library-container
    .education-video-grid-item
    .videoBoxMobile {
    flex-direction: row;
    margin-left: 0% !important;
    column-gap: 10px;
    width: calc(100% - 0px) !important;
    height: 100%;
  }
  .education-video-library-container
    .education-video-grid-item
    .education-video-box
    .education-video-poster {
    height: inherit;
    margin-top: 0px;
    object-fit: fill;
    width: 100px;
    margin-left: 11px;
  }
  .education-video-library .education-video-library-category-input {
    margin-left: -11px;
  }
  .education-video-library {
    align-items: flex-start;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 0px;
    height: calc(100% - 0px);
    width: 100%;
  }
  .education-video-library-video-tutorials-section
    .education-video-library-container {
    padding-right: 3%;
    padding-left: 4%;
    margin-left: -6px;
  }
  .education-video-library-container .education-video-grid-item {
    margin-left: 10px !important;
  }
  .education-video-text {
    margin: 0 0 0 0 !important;
    row-gap: 9px;
  }
  .education-video-library-container
    .education-video-grid-item
    .education-video-box
    .education-video-poster {
    height: 45% !important;
  }
}

@media only screen and (max-width: 912px) {
  .container-mobile {
    display: flex !important;
    flex-direction: row !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 820px) {
  .education-video-library-container
    .education-video-grid-item
    .videoBoxMobile {
    padding: 0px 63px 0px 0px;
    margin-left: -34px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .education-video-library-main-section
    .main-section-title
    .main-section-watch-button {
    margin-top: 0px;
    height: 35px;
  }
  .education-video-library-main-section
    .main-section-title
    .main-section-watch-text {
    font-size: 20px;
    line-height: 24px;
    margin: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .education-video-library-main-section .main-section-image-div {
    min-height: 180px;
  }
  .main-section-image-div .main-section-image {
    border-radius: 5px;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0px;
    width: 100%;
  }
  .education-video-library-main-section .main-section-title {
    margin: 25px 16px 0px;
    width: calc(100% - 32px);
  }

  .education-video-library-main-section
    .main-section-title
    .main-section-watch-text {
    font-size: 20px;
    line-height: 24px;
    margin: 16px;
  }

  .education-video-library-main-section
    .main-section-title
    .main-section-watch-button {
    height: 30px;
  }

  .education-video-library-container
    .education-video-grid-item
    .videoBoxMobile {
    flex-direction: row;
    margin-left: -6% !important;
    column-gap: 10px;
    width: calc(100% - 0px) !important;
    height: 100%;
    padding: 0px 37px 0px 0px;
  }
}

@media only screen and (max-width: 478px) and (min-width: 280px) {
  .education-video-library-container
    .education-video-grid-item
    .education-video-text
    .education-video-product {
    margin: 0 0 0 0;
  }

  .education-video-library-main-section .main-section-title {
    margin: 25px 8px;
    width: calc(100% - 16px);
  }

  .education-video-library-main-section
    .main-section-title
    .main-section-watch-text {
    font-size: 16px;
    line-height: 20px;
    margin: 16px;
  }

  .education-video-library-main-section
    .main-section-title
    .main-section-watch-button {
    height: 25px;
  }
}
