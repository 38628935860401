.order-supplies-detail-component {
  background: #ffffff;
  border: 1px solid #e4e4e8;
  display: flex;
  min-height: 100px;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  margin-left: 16px;
}

.order-supplies-detail-component .order-supplies-detail-container {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
  padding: 0;
  width: 100%;
}

.order-supplies-detail-container .supply-icon-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: auto;
  width: 40px;
}
.supply-icon-div .icon {
  margin: auto;
}
.order-supplies-detail-container .details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc(100% - 76px);
  margin-top: 14px;
}
.details .order-dressing-supply-order-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin: 0;
}
.details .order-supply-detail-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #323234;
  margin: 0;
}
.details .order-supply-detail-createdOn-label {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #76767a;
  margin: 0;
}

.order-supplies-detail-component .alert-banner-supply {
  margin-top: 0px;
  margin-bottom: 10px;
}
.order-supplies-detail-container .order-supplies-detail-navigate-icon-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 36px;
  margin-right: 26px;
}
.order-supplies-detail-navigate-icon-div .navigate-icon {
  cursor: pointer;
}
.therapy-status-section {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.status-banner {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.therapy-status-section .order-supply-detail-status-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  margin: 1.1px;
}
.oval {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 4px 4px 4px 9px;
}
.order-supplies-empty-pop-up .paperRoot {
  width: 200px;
  height: 56px;
}
@media only screen and (max-width: 600px) {
  .order-supplies-detail-component .alert-banner-supply {
    margin: 0px;
  }
}
