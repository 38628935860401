.create-service-request-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}

.create-service-request-component .short-form {
  margin: 0px 40px;
  width: calc(62.78% - 80px);
}

.create-service-request-component
  .short-form
  .create-service-request-route-section {
  margin: 42px 0px;
}

.create-service-request-component .short-form .create-service-request-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 8px;
  text-align: left;
}

.short-form .create-service-request-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px 32px;
  text-align: left;
}

.short-form .create-service-request-description .phone-value {
  color: #0049bd;
  margin: 0px;
  text-decoration-line: none;
  white-space: nowrap;
}

@media only screen and (max-width: 926px) {
  .create-service-request-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .create-service-request-component .short-form {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 430px) {
  .create-service-request-component {
    margin: 0px;
    width: 100%;
  }
}
