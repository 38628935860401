.empty-pop-up-pop .paperRoot {
  width: 375px;
  height: 288px;
}

.confirm-placement-popup {
  padding: 10px;
}

.confirm-placement-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 14px;
}

.confirm-placement-subheading {
  margin-bottom: 20px;
  margin-left: 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.confirm-placement-back-button {
  width: 100%;
}

.confirm-placement-back-button .confirm-page-backbutton {
  width: 93%;
  margin-top: 10px;
  margin-left: 14px;
  text-transform: none;
}

.confirm-cancel-container {
  width: 93%;
  margin-top: 10px;
  margin-left: 14px;
}

.confirm-cancel-container .confirm-cancel {
  background-color: #cb0000;
  width: 100%;
}

.confirm-cancel-container .confirm-cancel:hover {
  background-color: #cb0000;
  color: white;
  text-transform: none;
}
@media only screen and (max-width: 340px) {
  .confirm-placement-heading {
    font-size: 16px;
    width: 276px;
  }
  .confirm-placement-back-button .confirm-page-backbutton {
    margin-left: 12px;
  }
  .confirm-cancel-container {
    margin-left: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .empty-pop-up-pop .paperRoot {
    width: 349px;
  }
}
@media only screen and (width: 320px) {
  .empty-pop-up-pop .paperRoot {
    height: 295px;
    width: 297px;
  }
}
