.in-patient-order-first-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 101px 16px;
  width: calc(100% - 120px);
}

.in-patient-order-first-page-component .short-form {
  display: flex;
  flex-direction: column;
  margin: 40px;
  width: 80%;
}

@media only screen and (max-width: 926px) {
  .in-patient-order-first-page-component {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .in-patient-order-first-page-component .short-form {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 430px) {
  .in-patient-order-first-page-component {
    margin: 0px;
    width: 100%;
  }
}
