.exposed-structures-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.exposed-structures-main-container .exposed-tissue-grid-container {
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  width: calc(100% - 16px);
}

.exposed-tissue-grid-container .exposed-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0px 0px 20px;
  color: #323234;
}

.exposed-tissue-grid-container .exposed-tissue-grid-item {
  width: 100%;
}

.exposed-tissue-grid-container .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.exposed-tissue-grid-container .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}

.exposed-tissue-grid-container .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.exposed-tissue-grid-container .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.exposed-tissue-grid-container .optiontxt {
  color: #656569;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.exposed-tissue-grid-container .optiontxtSelect {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
