.in-patient-supply-order-container-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 25vh);
}
.in-patient-supply-order-container-component .inPatientsupplyOrderGrid {
  width: 100%;
  margin: 0;
}

.in-patient-supply-order-container-popup
  .in-patient-supply-order-container-popup-div {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: auto;
}
.in-patient-supply-order-container-popup .paperRoot {
  width: 430px;
  padding: 16px;
}

@media screen and (max-width: 450px) {
  .in-patient-supply-order-container-popup .paperRoot {
    margin: 16px;
    width: calc(100% - 32px);
  }

  .in-patient-supply-order-container-popup
    .in-patient-supply-order-container-popup-div {
    margin: 16px;
    max-width: 400px;
    width: calc(100% - 32px);
  }
  .product-availibility-supply-order-list-popup .paperRoot {
    margin: 0px 15px;
  }
}
@media screen and (max-width: 926px) {
  .in-patient-supply-order-container-component {
    height: 100%;
    margin: 0;
    width: 100%;
  }
}
