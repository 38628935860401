.pending-supply-order-header-title {
  background-color: #fff0d4;
  color: #704200;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 0;
  margin-right: 56px;
  padding: 8px 16px;
  width: max-content;
}

.pending-supply-order-component-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pending-supply-order-component-loader-pop-up
  .pending-supply-order-component-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 16px auto;
  width: 400px;
}
