.dischargeRequestSummary-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 104px 17px 16px;
  width: 100%;
}
.discharge-request-summary-header .summarypage-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-top: 10px;
  color: #323234;
  margin-left: 40px;
}

.saveDiv .discharge-request-save-order-btn {
  float: right;
  text-decoration-color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #0049bd;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
}
.dischargeRequestSummary-container .dischargeSummaryContent {
  margin-left: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.dischargeRequestSummary-container .dischargeSummaryDesp-labelPrintBtn {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 30px;
  margin-bottom: 16px;
}
.dischargeRequestSummary-container .button-print-label {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  min-height: 40px;
  min-width: 210px;
  text-transform: capitalize;
  width: 15%;
}
.dischargeRequestSummary-container .back-patient-btn {
  align-items: end;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 32px;
  line-height: 18px;
  min-height: 40px;
  text-transform: none;
  width: 171px;
  gap: 15px;
  text-decoration: underline;
  margin-top: 37px;
}
.shipmentLabelErrorPopupDR .paperRoot {
  width: 450px;
}
.summary-dischargeReq-comp {
  width: 600px;
  margin-left: 25px;
}
.dischargeRequestSummary-container .dischargeSummary-patientInfo {
  font-weight: 700;
  margin-left: 15px;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-top: 32px;
  margin-bottom: 7px;
}
.dischargeRequestSummary-container .dischargeSummary-patientInfo-grid {
  margin-left: 0px;
  margin-bottom: 11px;
}
.dischargeRequestSummary-container .dischargeSummary-productInfo-grid {
  margin-left: 15px;
  margin-bottom: 11px;
}

.dischargeRequestSummary-container .dischargeSummary-productInfo {
  font-weight: 700;
  margin-left: 15px;
  font-size: 16px;
  line-height: 20px;
  color: #323234;
  margin-bottom: 22px;
}

.dischargeRequestSummary-container
  .dischargeSummary-productInfo
  .dischargeSummary-gridItem {
  padding-top: 0px;
}
.dischargeRequestSummary-container .product-request-overview-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #0049bd;
}
.dischargeRequestSummary-container .dischargeSummary-Attestation {
  width: 600px;
  margin-left: 40px;
}
.summary-dischargeReq-comp
  .dischargeSummary-productInfo-grid
  .dischargeSummary-gridItem {
  padding-top: 0px;
  padding-left: 0px;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.getPdf-spinner-loader-pop-up {
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}
.getPdf-spinner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  margin: 0;
  padding: 0;
  width: 300px;
}
.summary-dischargeReq-comp .wound-assessment-summary {
  padding-left: 20px;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 2%;
  }
  .shipmentLabelErrorPopupDR .paperRoot {
    margin: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .dischargeRequestSummary-container {
    margin: 0px;
  }
  .summary-dischargeReq-comp {
    width: 90%;
  }
  .dischargeRequestSummary-container .dischargeSummary-Attestation {
    width: 80%;
  }
  .saveDiv {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  .dischargeRequestSummary-container .dischargeSummary-productInfo-grid {
    display: grid;
  }
  .dischargeRequestSummary-container .dischargeSummary-patientInfo-grid {
    display: grid;
  }
  .shipmentLabelErrorPopupDR .paperRoot {
    margin: 10px;
  }
}
