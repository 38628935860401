.confirm-placement-success-pop-up .success-confirm-placement-popup-div {
  margin: 0 0 24px;
  max-width: 600px;
}

.success-confirm-placement-popup-div .confirm-placement-success-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 24px 16px 8px;
  width: calc(100% - 32px);
}

.success-confirm-placement-popup-div .confirm-placement-success-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 32px;
  padding-right: 16px;
}

.success-confirm-placement-popup-div .confirmation-information-grid-container {
  background-color: #f8f8fa;
  margin: 16px;
  padding: 0px;
  width: calc(100% - 32px);
}

.success-confirm-placement-popup-div
  .confirmation-information-grid-container
  .confirmation-information-grid-item {
  margin: 0px;
  padding: 0 8px;
  width: calc(100% - 16px);
}

.confirmation-information-grid-item .confirmation-information-heading {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 8px;
  width: 100%;
}

.confirmation-information-grid-item .confirmation-information-grid-item-div {
  display: flex;
  flex-direction: column;
  margin: 0px 8px 16px;
  width: 100%;
}

.confirmation-information-grid-item
  .confirmation-information-grid-item-div
  .confirmation-information-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.confirmation-information-grid-item
  .confirmation-information-grid-item-div
  .confirmation-information-title-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.confirmation-information-grid-item
  .confirmation-information-grid-item-div
  .confirmation-information-title-value.rental-order-number {
  color: #0049bd;
  cursor: pointer;
  width: fit-content;
  font-weight: 700;
  text-decoration-line: underline;
}

.success-confirm-placement-popup-div .confirm-placement-success-note {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px 16px 16px;
  width: calc(100% - 32px);
}

.success-confirm-placement-popup-div
  .confirm-placement-success-note
  .bold-note {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.success-confirm-placement-popup-div .success-buttons-grid-container {
  margin: 0px 16px;
  padding: 0px;
  width: calc(100% - 32px);
}

.success-confirm-placement-popup-div
  .success-buttons-grid-container
  .success-buttons-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.success-buttons-grid-item .return-to-my-patients {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 8px 0 0;
  text-align: center;
  width: calc(100% - 8px);
}

.success-buttons-grid-item .complete-proof-of-delivery {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 0 0 8px;
  text-align: center;
  width: calc(100% - 8px);
}

@media only screen and (max-width: 450px) {
  .success-buttons-grid-item .return-to-my-patients {
    margin: 0 0 8px 0;
    width: 100%;
  }

  .success-buttons-grid-item .complete-proof-of-delivery {
    margin: 8px 0 0 0;
    width: 100%;
  }
}
