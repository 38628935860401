.srt-patient-summary-main {
  /* width: -webkit-max-content; */
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 0px;
  -moz-column-gap: 0px;
  column-gap: 0px;
  column-width: 255px;
}
.srt-patient-summary-data {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
}

.srt-patient-summary-title {
  color: var(--MSD-DS-Gray-54, #76767a);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.srt-patient-summary-value {
  color: var(--MSD-DS-Gray-80, #323234);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
