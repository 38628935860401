.enter-pickup-information-component {
  display: flex;
  flex-direction: column;
  margin: 40px 24px 40px 0;
  width: 100%;
}

.enter-pickup-information-component .enter-pickup-info-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
}

.enter-pickup-info-describtion .enter-pickup-info-date {
  color: #323234;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.enter-pickup-information-component .enter-pickup-info-describtion {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px 16px;
}
.enter-pickup-information-grid-container .enter-pickup-information-grid-item {
  width: 100%;
}
.enter-pickup-information-grid-item
  .enter-pickup-information-static-text-description {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #323234;
  margin-bottom: 4px;
}
.enter-pickup-information-grid-item .pickup-location-drop-down-label {
  width: 100%;
  margin-top: 4px;
}
.enter-pickup-information-grid-item .placeHolder {
  color: #76767a;
  border-radius: 2px;
  width: 100%;
}
.enter-pickup-information-grid-item .pickup-location-select {
  align-items: center;
  display: block;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.enter-pickup-information-grid-item .therapy-device-input {
  width: 100%;
}
.enter-pickup-information-component .pickup-location-special-instructions-div {
  margin-top: 16px;
  width: 100%;
}
.enter-pickup-information-grid-item .pickup-location-room-number-textBox {
  width: 100%;
}
.enter-pickup-information-grid-item
  .pickup-location-special-instructions-label {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.enter-pickup-information-grid-item
  .pickup-location-special-instructions-textBox {
  width: 100%;
}
.enter-pickup-information-grid-item .therapyDischargeDate {
  background-color: #fff;
}
.enter-pickup-information-grid-item .StopBillDate {
  background-color: #f2f2f5;
}
.therapyDischargeDate input.input {
  border-right: 0;
  background-color: #fff;
}
.StopBillDate input.input {
  border-right: 0;
  background-color: #f2f2f5;
}
.therapyDischargeDate .outline {
  border-radius: 2px;
}
.therapyDischargeDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}
.therapyDischargeDate.showError .outline {
  border: 1px solid #d32f2f;
}
.enter-pickup-information-grid-item .showSpecialInstructionError {
  border: 1px solid #d32f2f !important;
  height: 74px !important;
}
.enter-pickup-information-grid-item .noSpecialInstructionError {
  border: 1px solid #94949a;
  height: 74px !important;
}
