.patientadmissioninfo {
  width: 100%;
  margin-top: 2%;
}
.patientadmissioninfo .patientadmissioninfo-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
.patientadmissioninfo .radioRoot {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3%;
}
.patientadmissioninfo .optionRoot {
  border: none;
  box-sizing: border-box;
  height: 40px;
  margin: 0;
  width: calc(62% - 4px);
}
.patientadmissioninfo .optionRoot-active {
  border: none;
  box-sizing: border-box;
  height: 40px;
  margin: 0;
  width: calc(62% - 4px);
}
.patientadmissioninfo .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}
.patientadmissioninfo .optiontxtSelect {
  color: 76767a;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.patient-admissioninfo-label-item {
  margin-top: 2.5%;
}
