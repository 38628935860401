.wound-progress-tab-item {
  background: #f8f8fa;
}

.wound-progress-tab-item .wound-progress-tab-parent-div {
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: row;
  margin: 16px 55px 0px 16px;
}
.wound-progress-tab-item .wound-progress-tab-parent-div.bottom {
  margin: 16px 55px 16px 16px;
}
.wound-progress-tab-parent-div .wound-progress-wound-detail-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.wound-progress-tab-parent-div .navigation-div {
  display: flex;
  flex-direction: column;
  margin: auto 16px auto auto;
}
.navigation-div .navigate-icon-right {
  cursor: pointer;
}
.wound-progress-tab-parent-div .wound-progresss-tab-wound-number-heading {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 16px 0 0 16px;
}
.wound-progress-tab-parent-div .wound-progresss-tab-wound-location {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 16px;
}
.wound-progress-tab-parent-div .wound-progresss-tab-wound-type {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 16px;
}
.wound-progress-tab-parent-div .wound-progresss-tab-wound-assessed-on {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 16px 16px;
}
.order-supplies-empty-pop-up .paperRoot {
  width: 200px;
  height: 56px;
}

@media only screen and (max-width: 920px) {
  .wound-progress-tab-item .wound-progress-tab-parent-div {
    margin: 16px 3px 0px 16px;
  }
  .wound-progress-tab-item .wound-progress-tab-parent-div.bottom {
    margin: 16px 3px 16px 16px;
  }
}
