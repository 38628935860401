.supply-add-accessories {
  border: 1px solid #e4e4e8;
  margin-top: 8px;
  padding: 16px;
  border-radius: 4px;
}
.supply-add-accessoriesError {
  border: 1px solid #d32f2f;
}
.supply-add-accessories .supply-add-accessories-grid-container {
  width: 100%;
}

.supply-add-accessories .supply-add-accessories-grid-item {
  margin-top: -12px;
  width: 100%;
}

.supply-add-accessories .supply-add-accessories-header-div {
  display: flex;
  flex-direction: column;
}

.supply-add-accessories-header-div .supply-add-accessories-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.supply-add-accessories .supply-add-accessory-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin: 16px 16px 0px 0px;
  text-decoration: underline;
  text-transform: none;
}

.supply-add-accessories .supply-add-accessory-icon {
  transform: scale(1.5) !important;
}
.supply-add-accessories-grid-container
  .supply-add-accessories-grid-item
  .supply-order-accessories-list
  .reomove-accessory-button {
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-left: 12px;
  margin-top: 20px;
}
.supply-add-accessories-grid-container
  .supply-add-accessories-grid-item
  .supply-order-accessories-list
  .custom-removable-dropdown {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 24px;
  width: 100%;
}
.supply-order-accessories-list .add-accessories-input {
  width: 100%;
}
.supply-order-accessories-list .add-accessories-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.supply-order-accessories-list .accessory-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
}

@media only screen and (max-width: 920px) {
  .supply-add-accessories {
    width: calc(100% - 32px);
  }
  .supply-add-accessories-grid-container
    .supply-add-accessories-grid-item
    .supply-order-accessories-list
    .reomove-accessory-button {
    margin-left: 0px;
  }
}
