.contact-3m-order {
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e4e8;
  background: #ffffff;
  height: 136px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: -2px;
}

.contact-3m-order .contact-3m-order-page-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
}

.contact-3m-order .contact-3m-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px 0 0 0;
  width: 100%;
}

.contact-3m-order .contact-3m-title .contact-3m-order-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: -16px;
  margin-left: 16px;
}

.contact-3m-order .all-content-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -6px;
  width: 100%;
}

.contact-3m-order .content-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}

.contact-3m-order .content-div-last {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}

.contact-3m-order .sub-content-div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin-left: 16px;
  width: 100%;
}
.contact-3m-order .no-content-div {
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: 18px;
  min-height: 16px;
}

.sub-content-div .contact-3m-order-content-title {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
  width: max-content;
}

.sub-content-div .contact-3m-order-content-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  min-height: 18px;
  width: max-content;
}
.sub-content-div .contact-value-email-phone {
  color: #0049bd;
  text-decoration-line: underline;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  width: max-content;
}
.grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.grid-conatiner .tab-grid-item {
  width: 100%;
}
@media (max-width: 420px) {
  .contact-3m-order {
    height: 100%;
  }
  .sub-content-div .contact-value-email-phone {
    width: 107px;
    font-size: 12px;
  }
}

@media (max-width: 920px) {
  .contact-3m-order {
    height: 100%;
  }
}
