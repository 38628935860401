.no-patient-main-div {
  background: #f8f8fa;
  margin-block: 15px;
  border: 1px solid #e4e4e8;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 16px;
}
.filter-card-opened {
  height: auto;
}
.no-patient-main-div .no-patient-heading1 {
  color: #323234;
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 12px;
}
.no-patient-main-div .no-patient-add-patient-btn {
  padding-bottom: 12px;
}
.no-patient-main-div .no-patient-heading2 {
  color: #76767a;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  width: 60%;
  padding: 12px;
}

.no-patient-main-div .no-patient-add-btn {
  padding: 8px 14px;
  width: 133px;
  height: 40px;
}

.no-patient-main-div .no-patient-content-div {
  display: flex;
  flex-direction: column;
}

.no-patient-main-div .no-patient-content-div .no-patient-content-1 {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 8px;
  text-align: left;
}

.no-patient-main-div .no-patient-content-div .no-patient-content-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #76767a;
}

.no-patient-content-div .facility-data-div {
  display: flex;
  flex-direction: column;
  padding-inline: 85px;
  padding-block: 6px;
}

.no-patient-main-div .no-patient-content-div .no-patient-admin-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 1;
}

.no-patient-main-div .no-patient-content-div .no-patient-admin-emailId {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: underline;
}

.no-patient-main-div .no-patient-content-div .no-patient-admin-phone {
  color: #0049bd;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: underline;
}
@media only screen and (max-width: 600px) {
  .no-patient-main-div {
    height: calc(100% - 120px);
    padding: 16px;
  }
  .no-patient-main-div .no-patient-heading2 {
    width: 90%;
  }
  .no-patient-content-div .no-patient-content {
    padding: 16px 16px 16px 12px;
  }
  .no-patient-content-div .facility-data-div {
    padding-inline: 32px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 920px) {
  .no-patient-main-div .no-patient-heading2 {
    width: 70%;
  }
}
