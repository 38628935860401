.return-stored-product-popup {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .return-stored-product-popup .paperRoot {
    width: 486px;
  }
  
  .end-therapy-details-component {
    background: #f8f8fa;
    border: 1px solid #e4e4e8;
    min-height: 100px;
    margin-top: 12px;
  }
  
  .end-therapy-details-component .end-therapy-details-grid-container {
    display: flex;
    flex-grow: 1;
    width: 100%;
    margin-left: 0px;
    margin-top: 3px;
  }
  
  .end-therapy-details-component
    .end-therapy-details-grid-container
    .end-therapy-details-grid-item {
    display: flex;
    padding-top: 9px;
    width: 100%;
  }
  
  .end-therapy-details-component .end-therapy-name {
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: 100%;
  }
  
  .end-therapy-details-component .end-therapy-serial {
    margin-top: 0px;
  }
  
  .end-therapy-details-component .current-location {
    width: 100%;
    margin-top: 0px;
  }
  
  .end-therapy-details-component .product-name {
    margin-bottom: 16px;
    margin-left: 8px;
  }
  
  .end-therapy-details-component .therapy-start-date {
    margin-bottom: 16px;
  }
  
  .end-therapy-details-component .end-therapy-details-title {
    color: #76767a;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
    width: 100%;
  }
  
  .end-therapy-details-component .end-therapy-details-value {
    color: #323234;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 0px;
    width: 100%;
  }
  
  .end-therapy-details-component .end-therapy-current-loc-value {
    color: #323234;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  @media only screen and (max-width: 500px) {
    .end-therapy-details-component {
      width: 100%;
    }
    .end-therapy-details-component
      .end-therapy-details-grid-container
      .end-therapy-details-grid-item {
      padding-top: 4px;
    }
    .end-therapy-details-component .end-therapy-details-grid-container {
      margin-top: 9px;
    }
    .end-therapy-details-component .end-therapy-current-loc-value {
      width: 95%;
      margin-bottom: 10px;
    }
  }
  
  @media only screen and (min-width: 319px) and (max-width: 418px) {
    .end-therapy-details-component {
      width: 100%;
    }
  }
  