.osteomyelitis {
  margin: 37px 0 40px 0;
  width: 100%;
}

.osteomyelitis .osteomyelitis-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
}

.osteomyelitis .osteomyelitis-isOsteomyelitisPresent {
  margin-top: 16px;
}

.osteomyelitis .osteomyelitis-box-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.osteomyelitis .osteomyelitis-grid-container {
  width: 100%;
}

.osteomyelitis-grid-container .osteomyelitis-grid-item {
  width: 100%;
}

.osteomyelitis .isOsteomyelitisPresent {
  white-space: inherit;
  width: 100%;
}

.osteomyelitis .osteomyelitis-treatment-regimen-header {
  white-space: inherit;
  margin-top: 16px;
  width: 100%;
}

.osteomyelitis .osteomyelitis-treatment-regimen {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}

.osteomyelitis .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.osteomyelitis .optionRoot {
  border: 1px solid #b4b4b8;
  box-sizing: border-box;
  height: 48px;
  margin: 0px;
  width: calc(49% - 4px);
}

.osteomyelitis .optionRoot-active {
  border: 1px solid #0049bd;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.osteomyelitis .optionRoot-error {
  border: 1px solid #cb0000;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  width: calc(49% - 4px);
}
.osteomyelitis .optiontxt {
  color: #4c4c4f;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.osteomyelitis .optiontxtSelect {
  color: #18181a;
  font-family: "3MCircularTT";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.osteomyelitis-checkbox-with-textbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  height: 40px;
  margin-left: 16px;
  margin-bottom: 4px;
  max-width: max-content;
}

.osteomyelitis-checkbox {
  margin-right: 10px;
  margin-top: 20px !important;
  padding: 0;
  width: 100%;
}

.osteomyelitis-checkbox .osteomyelitis-checkbox-root {
  margin-right: 10px;
  margin-top: 20px !important;
  padding: 0;
  width: 100%;
}

.osteomyelitis-checkbox-description-text {
  color: #656569;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 22px auto;
  min-width: 200px;
  width: max-content;
}

.osteomyelitis-checkbox-description-text-active {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 22px auto;
  min-width: 200px;
  width: max-content;
}

.osteomyelitis-input {
  height: 25px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-top: -16px;
  width: 100%;
}
.osteomyelitis-custom-waring-icon-div {
  margin-bottom: 15px;
  margin-left: 5px;
}
@media only screen and (max-width: 920px) {
  .labelRoot .is-treatement-for-resolve-underlying-bone-infection {
    white-space: pre-wrap !important;
    width: 100%;
  }
  .osteomyelitis .osteomyelitis-treatment-regimen {
    zoom: 94%;
    margin-bottom: unset;
    margin-top: 10px;
  }
  .osteomyelitis-checkbox-with-textbox {
    max-width: unset;
    width: calc(100% - 24px);
    margin-bottom: 10px;
  }
  .osteomyelitis .osteomyelitis-question-div {
    margin-top: 12px;
  }
  .osteomyelitis-checkbox-description-text-active {
    min-width: 150px;
  }
}

@media only screen and (min-width: 377px) and (max-width: 713px) {
  .osteomyelitis .radioRoot.is-treatemen-for-bone-infection {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 396px) {
  .osteomyelitis .radioRoot.is-treatemen-for-bone-infection {
    margin-top: 30px;
  }
}
