.in-patient-order-billing-address-component {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div {
  margin: 0px;
  width: 100%;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .radioRoot {
  flex-direction: row;
  justify-content: space-between;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .optionRoot {
  min-height: 30px;
  margin: 0 0 0 16px;
  width: calc(100% - 16px);
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .optiontxt {
  color: #4c4c4f;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .billing-address-header-title {
  color: #323234;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .billing-address-header-title.error-label {
  color: #cb0000;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .billing-address-title {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  width: 100%;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .billing-address-title
  .billing-address-name {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  text-align: left;
}

.in-patient-order-billing-address-component
  .in-patient-order-billing-address-div
  .in-patient-order-billing-address-radio-div
  .billing-address-title
  .billing-address-name
  .billing-address-details {
  font-weight: 400;
}

@media only screen and (max-width: 365px) {
  .in-patient-order-billing-address-component
    .in-patient-order-billing-address-div
    .in-patient-order-billing-address-radio-div {
    margin: 8px 0px;
    width: 100%;
  }
}
