.customFooterStyle {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14),
    0px 3px 3px rgba(0, 0, 0, 0.12);
  height: 72px;
  left: 0;
  position: fixed;
  text-align: right;
  top: auto;
  width: 100%;
  z-index: 1;
  margin-bottom: 60px;
}
.footer-btn-group .footerMainDiv {
  position: absolute;
  width: 100%;
  bottom: 68px;
}
.footer-btn-group .addWoundToolBar {
  width: 100%;
  padding: 0;
  min-height: 40px;
}
.addWoundMaincontainer {
  width: 96%;
  flex-wrap: wrap;
  height: 40px;
}
.popUpStyles .paperRoot {
  width: 403px;
  height: auto;
}
.addWoundLeftContainer {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  margin-top: 8px;
  left: 15%;
  position: relative;
  width: 40%;
}
.addWoundRightContainer {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  margin-top: 8px;
  width: 60%;
  gap: 15px;
}
.boxAddWoundStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-right: -8px;
}

.boxAddWoundStyle .deleteOrderClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration: underline;
  display: inline-block;
}
.deleteOrderClass:hover {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #cb0000;
  text-decoration-color: #cb0000;
  text-decoration: underline;
  display: inline-block;
}
.boxAddWoundStyle .reviewOrderClass {
  width: 192px;
  margin-right: 52px;
}
.addWound-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}

.saveWoundAssess-spinner {
  width: 400px;
  height: 190px;
  margin-top: 31px;
}

.saveWoundAssess-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  padding: 16px;
}
.addWoundMobileMaincontainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
  margin-top: 18px;
}
.addWoundMobileRightContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media only screen and (min-width: 600px) and (max-width: 1100px) {
  .addWoundLeftContainer {
    position: unset;
    left: 0px;
  }
  .addWoundRightContainer {
    gap: 15px;
    margin-top: 10px;
  }
  .boxAddWoundStyle .reviewOrderClass {
    margin: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .custom-height {
    height: 110px;
  }
  .boxAddWoundStyle {
    margin-right: unset;
  }
  .boxAddWoundStyle .reviewOrderClass {
    width: 100%;
    margin-right: unset;
  }
}
@media only screen and (max-width: 920px) {
  .popUpStyles .paperRoot {
    width: 380px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
