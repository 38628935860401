.order-overview-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
.order-overview-component-unlinked {
  width: auto;
  margin-left: 10%;
  margin-right: 10%;
}
.order-overview-component .header-first {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto 0;
  width: 100%;
}

.header-first .back-button {
  border: none;
  cursor: pointer;
  margin: 18px 0px auto 17px;
  height: 19px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  margin-left: 25px;
}

.header .header-name {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
  margin-right: 16px;
  width: max-content;
}
.header-unlinked {
  margin-left: 50px;
}
.header .header-dob {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  margin-top: 0px;
  min-width: 117px;
}

.header-first .dropdown-container {
  display: flex;
  flex-direction: row;
  height: 36px;
  width: 250px;
  justify-content: center;
  margin: auto auto auto 16px;
}

.dropdown-info-input {
  border: 1px solid #0049bd;
  height: 36px;
  width: 200px;
  z-index: 1;
  margin-right: 2px;
  margin-left: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dropdown-info-input .dropdown-info-select {
  font-weight: 700;
  font-size: 14px;
  color: #0049bd;
  width: 200px;
}
.tab-order-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
}

.dropdown-container .drop-down-button {
  border-left: 1px solid #0049bd;
  height: 36px;
  left: -50px;
  margin: 0;
  z-index: 0;
}
.order-details1 {
  margin: 15px 15px !important;
  border: 1px solid #e4e4e8;
}
.order-details {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

@media only screen and (max-width: 920px) {
  .header-first .header-back-icon {
    max-width: 10%;
    min-width: 6%;
  }
  .dropdown-info-input {
    height: 28px;
    width: 178px;
    border: none;
    justify-content: end !important;
  }
  .dropdown-info-input .dropdown-info-select {
    text-overflow: unset !important;
    width: 80px;
  }
  .header {
    margin: 0px;
    max-width: 40%;
    min-width: 40%;
  }
  .order-overview-component-unlinked {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
}
@media only screen and (max-width: 620px) {
  .order-overview-component-unlinked {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
  .order-overview-component .header-first {
    height: auto;
    align-items: start;
    padding-top: 6px;
  }
  .header {
    margin: 0px;
    max-width: 40%;
    min-width: 40%;
  }
  .header-first .dropdown-container {
    margin: 0px;
  }
  .dropdown-info-input {
    height: 28px;
    width: 178px;
    border: none;
    justify-content: end !important;
  }
  .header .header-name {
    line-height: 0px;
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    width: 30%;
    display: contents;
  }
  .header .header-dob {
    color: #323234;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .dropdown-container .drop-down-button {
    height: 30px;
    border: none;
  }
  .header-first .back-button {
    margin: 1px 0px auto 8px;
    height: 30px;
  }
  .dropdown-info-input {
    padding: 0px !important;
    margin: 0px !important;
    padding-right: 0px !important;
  }
  .dropdown-info-input-root {
    padding: 0px;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  .dropdown-info-input .dropdown-info-select {
    text-overflow: unset !important;
    width: 80px;
  }
  .header-first .header-back-icon {
    max-width: 10%;
    min-width: 10%;
  }
  .order-overview-component .header-dropdown-div {
    max-width: 40%;
    min-width: 45%;
  }
}
@media print {
  .order-overview-component
    .tabview-page
    .inPatient-order-detail-sections
    .selected-supplies-print-wrapper-iPadMini {
    width: 950px;
  }
  .order-overview-component .dropdown-container {
    display: none;
  }
  .order-overview-component .tabview-page {
    visibility: visible;
    position: absolute;
  }
}
