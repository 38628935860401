.prescriber-search-result-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
}
.prescriber-search-result-component
  .prescriber-search-result-no-prescribers-present {
  color: #323234;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0px 0px 16px;
  width: 100%;
}

.prescriber-search-result-component
  .prescriber-search-result-no-prescribers-present
  .no-match-text {
  color: #cb0000;
}

.prescriber-search-result-component
  .prescriber-search-result-no-prescribers-present
  .prescriber-national-serach-button {
  color: #0049bd;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.prescriber-search-result-component .my-prescriber-list-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.prescriber-search-result-component
  .my-prescriber-list-grid-container
  .my-prescriber-list-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.prescriber-search-result-component
  .my-prescriber-list-grid-container
  .my-prescriber-list-grid-item
  .my-prescriber-list-title {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.prescriber-search-result-component
  .my-prescriber-list-grid-container
  .my-prescriber-list-grid-item
  .search-in-national-registry-instead-button {
  color: #0049bd;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
  text-align: right;
  text-decoration: underline;
  text-transform: none;
  width: 100%;
}

.prescriber-search-result-component .filter-prescriber-div {
  border: 1px solid #94949a;
  display: flex;
  flex-direction: row;
  height: 40px;
  margin: 16px 0px;
  width: 100%;
}

.prescriber-search-result-component .filter-prescriber-div .search-icon {
  height: 24px;
  margin: 8px 10px;
  width: 24px;
}

.prescriber-search-result-component
  .filter-prescriber-div
  .filter-prescriber-input {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .prescriber-search-result-component
    .my-prescriber-list-grid-container
    .my-prescriber-list-grid-item
    .search-in-national-registry-instead-button {
    padding: 16px 0px 0px;
    justify-content: flex-start;
    text-align: left;
  }
}
