.attest-container {
  margin-top: 30px;
}

.attest-container .attest-title {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #323234;
}

.attest-container .attest-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
  gap: 24px;
  width: 560px;
  height: auto;
  background: #f8f8fa;
  border: 1px solid #e4e4e8;
}
.attest-container .attest-sale-desp {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.attest-container .address-line-container {
  width: 100%;
}
.attest-container .attest-container-component {
  width: 100%;
}
.attest-container .attest-line1-input {
  width: 100%;
}

.confirmationData input.input {
  border-right: 0;
}

div.confirmationData {
  padding-right: 0;
}

.confirmationData .outline {
  border-radius: 2px;
}

.confirmationData.showError .outline {
  border: 1px solid #d32f2f;
  color: #76767a;
}

.confirmationData.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.attest-container .rep-desp {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.attest-container .accountName {
  color: #76767a;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 11px;
  margin-top: 9px;
}
.attest-container .attestationDate-div {
  display: flex;
  flex-direction: column;
  margin: 12px 8px;
}

.attest-container .attestationDate-div .attestationDate {
  color: #808084;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 45px;
  margin-top: 5px;
}
.attest-container .attestationDate-div .attestationCurrentDate {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 45px;
  margin-top: 13px;
}
@media only screen and (max-width: 920px) {
  .attest-container .attest-box {
    width: 85%;
  }
}

@media only screen and (max-width: 600px) {
  .attest-container .attestationDate-div {
    margin: 0px;
  }
  .attest-container .attestationDate-div .attestationDate {
    margin-left: 0px;
  }
  .attest-container .attestationDate-div .attestationCurrentDate {
    margin-left: 0px;
  }
  .attest-container .attest-box {
    padding: 8px;
  }
}
