.footer-btn-group .user-profile-tool-bar {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 12px;
  width: 100%;
}

.user-profile-main-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  margin-left: calc(15% - 32px);
  margin-right: 56px;
  width: 100%;
}

.user-profile-left-container {
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}

.user-profile-right-container {
  display: flex;
  flex-direction: row;
  float: right;
  justify-content: flex-end;
  position: relative;
  right: 25px;
  width: 50%;
}

.boxOrderStyle {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-right: 0px;
}

.boxOrderStyle .firstButton {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 76px;
  line-height: 18px;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle .firstButton:hover {
  background-color: unset;
  text-decoration: underline;
  text-decoration-color: #cb0000;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}

.boxOrderStyle .firstButton:disabled {
  color: #76767a;
  text-decoration-color: #76767a;
}

.boxOrderStyle .secondButton {
  min-width: 140px;
}

.boxOrderStyle .thirdButton {
  min-width: 192px;
}

.boxOrderStyle .thirdButton-disabled {
  background: #e4e4e8;
  color: #76767a;
  min-width: 192px;
}

@media screen and (max-width: 600px) {
  .boxOrderStyle {
    justify-content: center;
    bottom: 20px;
  }
  .boxOrderStyle .secondButton {
    min-width: 130px;
  }
  .boxOrderStyle .thirdButton {
    min-width: 130px;
  }
  .user-profile-main-container {
    margin: 0px;
  }
}
@media only screen and (max-width: 920px) {
  .user-profile-right-container {
    right: 0px;
  }
}
