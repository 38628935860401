.inventory-custom-header-div {
  display: flex;
  flex-direction: row;
}
.inventory-custom-header-div .inventory-sp-customHeaderLabel {
  color: #76767a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.inventory-custom-header-div .inventory-sp-sort-div {
  display: inline-block;
  padding: 3px 12px;
}
.inventory-sp-popper .inventory-sp-tooltip {
  background-color: #fff;
  color: #000;
  padding: 14px 32px 16px 16px;
  font-size: 14px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px rgba(0, 0, 0, 0.12);
  border-radius: 0;
  width: auto;
  height: auto;
}
.inventory-sp-div .tooltip-content {
  font-weight: 700;
  font-size: 16px;
}
.inventory-sp-div .inventory-tooltip-header .tooltip-heading {
  font-weight: 700;
}
.inventory-sp-div
  .inventory-tooltip-header
  .tooltip-heading
  .tooltip-heading-data {
  font-weight: 400;
}

.inventory-sp-sort-div {
  cursor: pointer;
}

.inventory-sp-sort-div .customSortDownLabel {
  height: 16px;
  width: 16px;
}
.inventory-sp-sort-div .customSortUpLabel {
  height: 16px;
  width: 16px;
}
.inventory-sp-sort-div .customSortRemoveLabel {
  height: 16px;
  width: 16px;
}
svg.inventory-tooltipRoot {
  width: 18.5px;
  height: auto;
  cursor: pointer;
  margin: 0px 0px 4px 6px;
}
@media only screen and (max-width: 920px) {
  .inventory-sp-popper .inventory-sp-tooltip {
    width: 75%;
  }
}
