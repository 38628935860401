.manage-profile .MuiPaper-root {
  box-shadow: none;
  border-radius: 0;
}

.manage-profile .helperText {
  margin: 5px 0 0;
  text-align: right;
}

.manage-profile .selectIcon {
  top: 10px;
}

.manage-profile .contact-information {
  margin-bottom: 40px;
}

.manage-profile .manage-profile-contact-information-component {
  width: 100%;
}

.manage-profile {
  background-color: #fff;
  width: 77.779%;
}

.manage-profile .manage-profile-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 40px 0;
}

.manage-profile-header-more-margin {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0 16px 0;
}

.communication-preferences {
  margin-top: 40px;
  width: 100%;
}

#mp-recaptcha-container {
  display: none;
}

.manageProfile-loading {
  padding: 16px;
  margin-bottom: 24px;
}

.manageProfile-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 370px);
}

/* mobile css */
@media only screen and (max-width: 920px) {

}

@media only screen and (max-width: 600px) {
  .manage-profile .communication-preferences {
    padding-left: 8px;
  }
  .manage-profile {
    width: 100%;
  }
}
