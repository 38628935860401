.footer-btn-group .saveBtn {
  margin-top: 16px;
  margin-right: 104px;
  padding-left: 65px;
  padding-right: 65px;
  height: 40px;
}

.footer-btn-group .cancelBtn {
  margin-top: 16px;
  margin-right: 8px;
  padding-right: 57px;
  padding-left: 57px;
  height: 40px;
}

.footer-btn-group .backBtn {
  margin-top: 16px;
  margin-right: 8px;
  padding-right: 57px;
  padding-left: 57px;
  height: 40px;
}

.save-registration-dialog .paperRoot {
  width: 400px;
}

.cancel-dialog .paperRoot {
  width: 403px;
}

.manage-profile-save {
  padding: 16px 16px 32px 16px;
}

.manage-profile-save .header {
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  margin-top: 0;
  margin-bottom: 16px;
}

.manage-profile-save .sub-text {
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  margin-bottom: 16px;
}

.manage-profile-save .sendSms,
.manage-profile-save .sendEmail {
  width: 100%;
  text-transform: none;
}

.manage-profile-save .sendEmail {
  margin-bottom: 16px;
}

.auth-verify-dialog .paperRoot {
  width: 400px;
  height: 370px;
}

/* mobile css */
@media only screen and (max-width: 920px) {
  .footer-btn-group .saveBtn {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-btn-group {
    display: flex;
    justify-content: space-between;
    text-align: unset;
  }

  .footer-btn-group .cancelBtn {
    margin-left: 15px;
    border: 0;
    text-decoration: underline;
    color: #cb0000;
  }

  .footer-btn-group .backBtn {
    margin-left: 15px;
    border: 0;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 600px) {
  .footer-btn-group .cancelBtn:hover {
    border: 0;
    background-color: unset;
  }

  .footer-btn-group .backBtn:hover {
    border: 0;
    background-color: unset;
  }
}

.sendCodeError {
  font-size: 12px;
  color: red;
}

.registerationSaveToast {
  position: fixed;
  z-index: 1;
  left: 520px;
  top: 0;
  margin-top: 6px;
}

.authenticationCodeSent-loader {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  min-height: 148px;
}
