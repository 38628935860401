.inventory-main-component {
  background-color: #f8f8fa;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  width: 100%;
}
.inventory-main-component .inventory-sub-subcomponent {
  background-color: #fff;
}
.inventory-sub-subcomponent .inventory-subcomponent {
  margin: 24px;
}

.inventory-subcomponent .inventory-button-container {
  text-align: right;
  margin-bottom: 10px;
}
.inventory-button-container .inventory-button {
  margin-top: 5px;
  margin-right: 5px;
}
.inventory-button-container .start-ready-care-order {
  margin-top: 5px;
}
.inventory-spinner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}
.inventory-error-pop-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 165px;
  margin: 0px 16px;
  width: 406px;
}
.inventory-sub-subcomponent .inventory-btn-div {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 16px;
}
.inventory-ready-care-location {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.ag-theme-quartz .inventory-ready-care-days {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ag-theme-quartz .inventory-ready-care-missing-asset {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inventory-pop-up-container .paperRoot {
  display: flex;
  max-width: 480px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .inventory-pop-up-container .paperRoot {
    margin: 0px 16px;
    padding: 0px;
    width: calc(100% - 32px);
  }
}

@media screen and (max-width: 400px) {
  .inventory-pop-up-container .paperRoot {
    margin: 0px 8px;
    padding: 0px;
    width: calc(100% - 16px);
  }
}

@media only screen and (max-width: 1024px) {
  .inventory-main-component {
    min-height: auto;
  }
  .inventory-subcomponent .inventory-button-container {
    text-align: left;
  }
  .inventory-main-component .inventory-sub-subcomponent {
    margin: 0px;
  }
  .inventory-button-container .inventory-button {
    width: 100%;
  }
  .inventory-button-container .start-ready-care-order {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .inventory-error-pop-up {
    width: 280px;
  }
  .inventory-subcomponent .inventory-button-container {
    margin-bottom: 20px;
  }
  .inventory-sub-subcomponent .inventory-btn-div {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    height: 42px;
  }
  .inventory-sub-subcomponent .inventory-btn-div .ready-care-download-btn {
    width: 40%;
  }
  .inventory-sub-subcomponent .inventory-btn-div .manage-storage-locations-btn {
    height: 42px;
    width: 60%;
  }
}
