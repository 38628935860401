.success-return-unit-to-solventum-popup-div {
  margin: 0 0 24px;
  max-width: 448px;
  padding-left: 25px;
}

.success-return-unit-to-solventum-popup-div
  .return-unit-to-solventum-success-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  width: calc(100% - 32px);
}

.success-return-unit-to-solventum-popup-div
  .return-unit-to-solventum-success-description {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 16px;
}

.success-return-unit-to-solventum-popup-div
  .confirmation-information-grid-container {
  padding: 20px 0 20px 0;
  width: calc(100% - 32px);
  display: flex;
  flex-grow: 1;
}

.success-return-unit-to-solventum-popup-div
  .confirmation-information-grid-container
  .return-inventory-success-confirmation {
  padding: 0px;
  padding-left: 10px;
  width: calc(100% - 16px);
}

.return-inventory-success-confirmation .confirmation-information-heading {
  color: #323234;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 16px 8px;
  width: 100%;
}

.return-inventory-success-confirmation
  .return-inventory-success-confirmation-div {
  margin-left: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.return-inventory-success-confirmation
  .return-inventory-success-confirmation-div
  .confirmation-information-title {
  color: #76767a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.return-inventory-success-confirmation
  .return-inventory-success-confirmation-div
  .confirmation-information-title-value {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px;
}

.return-inventory-success-confirmation
  .return-inventory-success-confirmation-div
  .confirmation-information-title-value.rental-order-number {
  color: #0049bd;
  cursor: pointer;
  width: fit-content;
  font-weight: 700;
  text-decoration-line: underline;
}

.success-return-unit-to-solventum-popup-div
  .return-unit-to-solventum-success-note {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0px 0px 24px;
  width: calc(100% - 32px);
}

.success-return-unit-to-solventum-popup-div .success-buttons-grid-container {
  margin: 0px 0px 16px;
  padding: 0px;
  width: 98%;
}

.success-return-unit-to-solventum-popup-div
  .success-buttons-grid-container
  .success-buttons-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.success-buttons-grid-item .return-to-inventory {
  color: #fafafc;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 8px 0 0;
  text-align: center;
  width: calc(100% - 8px);
}

@media only screen and (max-width: 345px) {
  .success-buttons-grid-item .return-to-inventory {
    width: 96%;
  }
  .success-return-unit-to-solventum-popup-div {
    padding-left: 16px;
  }
}
@media only screen and (min-width: 346px) and (max-width: 480px) {
  .success-return-unit-to-solventum-popup-div {
    padding-left: 9px;
  }
  .success-return-unit-to-solventum-popup-div .success-buttons-grid-container {
    width: 100%;
  }
}
