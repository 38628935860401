.patient-info-review-order {
  margin: -16px 0px 0px 0px;
  width: calc(100% - 8px);
}

.patient-info-review-order .patient-info-review-order-page-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
}

.patient-info-review-order .patient-info-component-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px 0 0 0;
  width: 100%;
}

.patient-info-review-order
  .patient-info-component-title
  .patient-info-review-order-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}

.patient-info-review-order
  .patient-info-component-title
  .patient-info-review-order-edit-button {
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  min-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
}
.patient-info-review-order
  .patient-info-component-title
  .patient-info-review-order-edit-button-disabled {
  color: #808084;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  min-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
}
.patient-info-review-order .all-content-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -6px;
  width: 100%;
}

.patient-info-review-order .content-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
}

.patient-info-review-order .content-div-last {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}

.patient-info-review-order .sub-content-div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}

.sub-content-div .patient-info-review-order-content-title {
  color: #808084;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  min-height: 16px;
}

.sub-content-div .patient-info-review-order-content-value {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  min-height: 18px;
}
@media only screen and (max-width: 600px) {
  .patient-info-review-order .content-div {
    display: contents;
  }

  .patient-info-review-order .content-div .sub-content-div {
    margin: 4px 0px;
  }

  .patient-current-address-review-order .content-div {
    display: contents;
  }

  .patient-current-address-review-order .content-div .sub-content-div {
    margin: 4px 0px;
  }

  .emergency-contact-review-order .content-div {
    display: contents;
  }

  .emergency-contact-review-order .content-div .sub-content-div {
    margin: 4px 0px;
  }

  .insurance-review-order .content-div-last {
    display: contents;
  }

  .insurance-review-order .content-div .sub-content-div {
    margin: 4px 0px;
  }
}
