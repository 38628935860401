.srtPatientStatus-info {
  margin-top: 40px;
  width: 100%;
}

.srtPatientStatus-info .srtPatientStatus-info-title {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: 22px;
}

.srtStatusInformationMain .secondaryBtnGrid {
  margin-bottom: 40px;
  margin-top: 16px;
}

.srtPatientStatus-info .srtPatientStatus-info-grid-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.srtPatientStatus-info-grid-container .srtPatientStatus-info-item {
  margin: 0px;
  padding: 8px 0px;
  width: calc(100% - 8px);
}

.srtPatientStatus-info-item .srtStatus-type-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.srtPatientStatus-info-grid-container .srtPatientStatus-info-input {
  width: 100%;
}


.srtPatientStatus-info-input .srtPatientStatus-info-select {
    
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}
.srtPatientStatus-info-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}
.srtPatientStatus-info div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.srtPatientStatus-info .placeHolder div.placeHolder {
  border-right: unset;
  width: 100%;
}
 .srtPatientStatus-info-item
  .srt-other-info-input {
  width: 100%;
}

.srt-other-info-input .showCommentError {
  border: 1px solid #d32f2f !important;
}

.srtPatientStatus-info-item .noCommentError {
  width: 100%;
}
