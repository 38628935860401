.facilitydatabasesearch .facility-search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.facility-search-container .first-conatiner {
  display: flex;
  flex-direction: row;
}
.facilitydatabasesearch .paperRoot {
  width: 100%;
  max-width: 574px;
  padding: 16px;
}
.facility-search-container .hc-provider-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.facility-search-container .fs-title-container {
  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
  justify-content: space-between;
  gap: 8px;
}
.facility-search-container .fs-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.facility-search-container .search-provider-root.search-provider-btn {
  text-transform: none;
  padding: 0;
  text-decoration: underline;
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.first-conatiner .fs-facility-name-container {
}
.facility-search-container .fs-facility-name,
.fs-facility-id {
  width: 100%;
}
.first-conatiner .fs-facility-state-container {
  margin-bottom: 12px;
}
.facility-search-container .patient-info-input {
  width: 100%;
}

.facility-search-container .patient-info-input .patient-info-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}
.facility-search-container div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  width: 100%;
}

.facility-search-container .fs-search-btn-container {
  margin-top: 20px;
  width: 100%;
}
.fs-search-btn-container .search-btn {
  width: 100%;
}
.facility-search-container .fs-facility-id-container {
  width: 100%;
  gap: 5px;
  display: flex;
  flex-direction: row;
}
.facility-search-container .fs-separator {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.facility-search-container .name-id-separator {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4c4c4f;
  margin-top: 24px;
}
/*------------------------------facility list----------------------------------*/
.facilitylist .paperRoot {
  width: 100%;
  max-width: 574px;
  padding: 16px;
}
.facilitylist .fl-even-card {
  background: #f8f8fa;
  display: flex;
  padding: 11px 16px;
}
.facilitylist .fl-odd-card {
  background: #ffffff;
  display: flex;
  padding: 11px 16px;
}
.facilitylist .fl-select {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #0049bd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.facilitylist .fl-facility-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fl-facility-info .fl-facility-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-bottom: 4px;
}
.fl-facility-info .fl-facility-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fl-facility-info .fl-facility-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
}
.fl-facility-info .fl-type-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.fl-type-container .fl-type-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  margin-right: 17px;
}
.fl-facility-info .fl-type-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.fl-list-container .fl-list-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}
.fl-list-container .fl-back-to-search-btn {
  width: 100%;
  margin-top: 24px;
}
.fl-list-container .fl-back-to-search-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #0049bd;
  text-transform: none;
}
.fl-list-container .fl-phone-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.fl-list-container .add-new-homecare-provider-box-container {
  background: #ffffff;
  margin-left: 0%;
  width: 100%;
}

.fl-list-container
  .add-new-homecare-provider-box-container
  .add-new-homecareprovider-button {
  border: 1px solid;
  border-radius: 1px;
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  font-weight: 700;
  height: 40px;
  line-height: 18px;
  text-transform: none;
}
/*---------------------no results-----------------------*/
.facilitynotfound .paperRoot {
  width: 100%;
  max-width: 574px;
  padding: 16px;
}
.fl-no-results-container .result-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.fl-no-results-container .fl-facility-not-found {
  display: flex;
  flex-direction: column;
}
.fl-facility-not-found .fl-facility-nf-msg {
  background: #f8f8fa;
  padding: 11px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 24px 0;
}
.fl-facility-not-found .fl-nf-btns-container {
  display: flex;
  justify-content: space-between;
}
.fl-facility-not-found .search-again-btn-container {
  width: 100%;
  max-width: 267px;
}
.fl-facility-not-found .fl-facility-nf-btns {
  width: 100%;
  min-height: 44px;
}

.fl-facility-details-block {
  width: 50%;
}

.caregiverfacility-state-input {
  height: 42px;
}

.caregiverfacility-state-input .MuiOutlinedInput-notchedOutline {
  border-color: #94949a;
}
.caregiverfacility-state-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.caregiverfacility-state-input .caregiverfacility-state-root {
  height: 12px;
  border: none !important;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

@media only screen and (max-width: 600px) {
  .fl-facility-not-found .fl-nf-btns-container {
    justify-content: center;
    gap: 9px;
  }
}
@media only screen and (max-width: 920px) {
  .facilitydatabasesearch .paperRoot {
    margin: 0 10px 0 10px;
  }
  .facilitylist .paperRoot {
    margin: 0 10px 0 10px;
  }
  .facilitynotfound .paperRoot {
    margin: 0 10px 0 10px;
  }
}
