.order-details-component {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
  overflow-x: hidden;
}
.order-details-component .order-details-navigator-main {
  margin-top: 16px;
}
.order-details-component
  .order-details-navigator-main
  .order-details-navigator {
  margin-left: 16px;
  margin-bottom: 10px;
}

.order-details-component .order-detailsDiv {
  border: 0px solid #e4e4e8;
  display: flex;
  flex-direction: column;
}

.order-details-component .order-detailsDiv .order-details-div-title {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  height: 56px;
  width: 100%;
}

.order-details-div-title .order-details-grid-container {
  margin: 0px;
  padding: 0px;
  width: calc(100% - 40px);
}

.order-details-div-title
  .order-details-grid-container
  .order-details-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.order-details-grid-item .orderDetail {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0px;
  width: 100%;
}

.order-details-grid-item .orderDetailsSummaryPdfBtn-div {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
  width: 100%;
}

.orderDetailsSummaryPdfBtn-div .orderDetailsSummaryPdfBtn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #0049bd;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
}
.orderDetailsSummaryPdfBtn-div .orderDetailsSummaryPdfBtn:hover {
  text-decoration: underline;
  text-decoration-color: #0049bd;
}

.container {
  display: flex;
  flex-direction: row;
  border: 1px solid #e4e4e8;
  background: #ffffff;
  height: 20%;
  margin: 0px 16px;
}
.column {
  width: 35%;
  padding: 16px;
  box-sizing: border-box;
  margin-top: -18px;
}

.container p {
  margin: 1px;
  color: #76767a;
  font-size: 12px;
  line-height: 16px;
}

.order-status-div {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 16px;
}

.container .leftsidecolumnvalue {
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  color: #323234;
}

.order-status-div .oval {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 2px 4px 4px 1px;
}

.orderStatus {
  position: relative;
  top: 10px;
}

.orderDate {
  position: relative;
  right: 48%;
  bottom: 18px;
  font-style: normal;
  font-size: 14px;
}
.column .device-details-order-overview {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 10px;
}
.device-details-order-overview .device-image {
  height: 56px;
  width: 56px;
}
.device-details-order-overview .device-descriptiopn {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 17px 8px;
  line-height: 22px;
}
.vline {
  border-left: 2px solid #0049bd;
  height: 50px;
  position: relative;
  margin-left: 3.5%;
}
.h4 {
  position: relative;
  right: 119px;
  bottom: 10px;
}
.ordertracking {
  margin-left: 100px !important;
}
.ordertracking1 {
  margin-left: 15px;
}
.trackingLink {
  position: relative;
  top: 5px;
  right: 0px;
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.trackingLinkp {
  text-decoration: none;
  position: relative;
  top: 5px;
  right: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.trackingLinkpActive {
  text-decoration: none;
  position: relative;
  top: 5px;
  right: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #323234;
}

.track {
  margin-left: 120px;
  margin-bottom: 30px;
}
.error {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 15px;
}
.milestone {
  margin-left: 0px;
}
.iconStyle {
  font-size: "16px" !important;
  position: "relative" !important;
}
.grayDisabledStyle {
  height: "1px";
  width: "19px";
}
.enteredIconStyles {
  display: flex;
}
.disabledStyle {
  display: flex;
}
.order-detailsabove {
  padding: 16px;
  background: #f8f8fa;
  border: none;
}
.stepperMain .activeLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 5px;
  margin-left: 9px;
}
.stepperMain .notActiveLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  margin-top: 5px;
  margin-left: 9px;
}
.trackMain {
  position: absolute;
}
.orderDetailsContent {
  margin-top: -18px;
  font-weight: 400;
}
.ShippedDate {
  margin-left: 71px;
}

.stepperDate {
  margin-left: 25px;
}
.stepperDateDelivered {
  padding-right: 72px;
}

.PreparingShipmentDate {
  margin-left: 21px;
}
.OrderReceivedDate {
  margin-left: 47px;
}
.OrderReceivedDateReadyCare {
  margin-left: 66px;
}
.DeliveredDate {
  margin-left: 85px;
}
.TherapyDate {
  margin-left: 94px;
}
.TherapyDateReadyCare {
  margin-left: 113px;
}
.OutforDeliveryDate {
  margin-left: 44px;
}
.BenefitsVerifiedDate {
  margin-left: 42px;
}
.BenefitsVerifiedDateReadyCare {
  margin-left: 61px;
}
.PlacementApprovedDateReadyCare {
  margin-left: 31px;
}

.grid-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.grid-container .tab-grid-item {
  width: 100%;
}
.orderDetailsTrackingToast {
  position: fixed;
  z-index: 1;
  left: 35%;
  top: 0;
  max-width: 30%;
  margin-top: 6px;
}
.orderDetailsTrackingToastMobile {
  position: fixed;
  z-index: 1;
  left: 15%;
  top: 0;
  max-width: 70%;
  margin-top: 6px;
}

.order-overview-route-section {
  margin: 0 0 0 0;
}
.alert-banner {
  margin-top: -15px;
}
.serviceTypeTagDiv {
  width: fit-content;
  margin-top: 3px;
}
.orderDetailsTrackingDataContent .location-type-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.location-type-div .is-customer-plus {
  border-radius: 15px;
  background: #e3f7e8;
  color: #007713;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
.location-type-div .is-stored-product-orderOverView {
  border-radius: 15px;
  background: #fff0d4;
  color: #915800;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  height: 15px;
  line-height: 15px;
  padding: 4px 8px;
  white-space: pre;
  width: max-content;
}
@media only screen and (min-width: 320px) and (max-width: 620px) {
  .BenefitsVerifiedDate {
    display: block;
    margin-left: 0px;
  }
  .BenefitsVerifiedDateReadyCare {
    display: block;
    margin-left: 0px;
  }
  .OrderReceivedDate {
    display: block;
    margin-left: 0px;
  }
  .OrderReceivedDateReadyCare {
    display: block;
    margin-left: 0px;
  }
  .OutforDeliveryDate {
    display: block;
    margin-left: 0px;
  }
  .PreparingShipmentDate {
    display: block;
    margin-left: 0px;
  }
  .PlacementApprovedDate {
    display: block;
    margin-left: 0px;
  }
  .PlacementApprovedDateReadyCare {
    display: block;
    margin-left: 0px;
    white-space: normal;
    width: 100%;
  }
  .TherapyDate {
    margin-left: 0px;
    display: block;
  }
  .TherapyDateReadyCare {
    display: block;
    margin-left: 0px;
  }
  .stepperMain .activeLabel {
    line-height: 16px;
  }
  .stepperMain .notActiveLabel {
    line-height: 16px;
  }
  .DeliveredDate {
    margin-left: 0px;
    display: block;
  }
  .orderDetailsContent {
    width: 203px;
  }
  .column {
    width: 42%;
    padding: 16px 0px 16px 16px;
  }
  .column.h4 {
    width: 111px;
  }
  .stepperMain .activeLabel {
    margin-top: 10px;
    font-size: 12px;
  }
  .stepperMain .notActiveLabel {
    margin-top: 10px;
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .order-overview .sub-content-div-orderoverview {
    overflow: hidden;
  }
  .container {
    margin-top: 29px;
  }
  .milestone {
    margin-top: 31px;
  }
  .ordertracking1 {
    width: 116px;
  }
  .vac-header {
    width: 100px;
  }
}
.stepperMain .activeLabel {
  white-space: nowrap;
}
.stepperMain .notActiveLabel {
  white-space: nowrap;
}

@media (max-width: 360px) {
  .trackingLink {
    font-size: 9px;
  }
  .stepperMain .activeLabel {
    line-height: 16px;
    zoom: 82%;
  }
  .stepperMain .notActiveLabel {
    line-height: 16px;
    zoom: 82%;
  }
}
@media (max-width: 400px) {
  .trackingLink {
    font-size: 10px;
  }
}
@media only screen and(min-width:1260) {
  .ShippedDate {
    margin-left: 27px;
  }

  .DeliveredDate {
    margin-left: 22px;
  }
}
