.facilityBanner {
  padding: 4px 12px;
  background: #f2f2f5;
}

.facilityBanner .facilityBanner-input {
  background: #ffffff;
  width: 100%;
}
.facilityBanner .facilityBanner-input .facilityBanner-select {
  min-height: 12px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  padding: 10px 4px;
  line-height: 20px;
  font-size: 14px;
}
.facilityBanner .facilityBanner-label {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.facility-banner-id .facility-banner-id-details {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.facility-banner-id
  .facility-banner-id-details
  .facility-id-details-account-number {
  font-weight: 700;
  margin-left: 4px;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.facilityPaperProps {
  max-height: 316px;
  width: 150px;
  z-index: 0;
  border: 1px solid #d0d0d3;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.5) !important;
  border-radius: 2px !important;
  overflow: auto !important;
}
@media screen and (max-width: 600px) {
  .facilityPaperProps {
    left: 4px !important;
  }
  .facilityBanner {
    padding: 0 8px;
  }
}
@media print {
  .facilityBanner {
    display: none;
  }
}
