.hcp-table {
  border: 1px solid #e4e4e8;
  height: 300px;
  width: 100%;
  margin-bottom: 15px;
}
.handle-select-link-hcp {
  color: rgba(0, 73, 189, 1);
  text-decoration: underline;
  padding-bottom: 5px;
  font-weight: 700;
  cursor: pointer;
}
.addmanualHCPBtn {
  text-align: end;
}
.addmanualHCPBtn .hcp-manualAdd-btn {
  margin-top: 5px;
  text-transform: none;
  width: 50%;
}
.hcp-table .ag-root-wrapper {
  border-radius: unset;
}
@media only screen and (max-width: 920px) {
  .hcp-table .addmanualHCPBtn {
    align-self: flex-start;
    margin-left: unset;
    width: 100%;
  }
  .addmanualHCPBtn .hcp-manualAdd-btn {
    background: none;
    margin-top: unset;
    width: 100%;
  }
}
.hcplist-result {
  padding: 16px;
}

.hcplist-result .hcplist-header {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
@media screen and (max-width: 400px) {
  .hcplist-header {
    width: 90%;
  }
}

.hcplist-spinner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 15%;
}

.hcplist-backButton {
  min-height: 40px;
  width: 100%;
  margin-top: 24px;
}

.hcplist-result .MuiOutlinedInput-root {
  max-height: 42px;
}

.hcplist-result .select-patient-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}

.hcplist-result .select-patient-link {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
}

.hcplist-result .table-static-data {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #323234;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.hcplist-table {
  border: 1px solid #e4e4e8;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 24px;
}

.hcplist-no-data {
  display: flex;
  place-content: center;
  flex-direction: column;
  min-height: 320px;
  max-height: 320px;
  text-align: center;
}
