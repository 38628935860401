.private-pay-box-main {
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px);
}

.private-pay-box-main .private-pay-box {
  align-items: flex-start;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: row;
  height: 52px;
  margin: 16px 0 0 0;
  padding: 16px;
}

.private-pay-box.error {
  border: 1px solid #d32f2f;
}

.private-pay-box-main .private-pay-warning-div {
  display: flex;
  flex-direction: row;
  margin: 4px 0 0;
  width: 100%;
}

.private-pay-box-main .private-pay-warning-div .error-with-warning-icon {
  color: #cb0000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
}

.private-pay-box-main .private-pay-warning-div .warning-icon {
  margin: 0 4px 0 0;
  width: 16px;
}

.private-pay-box-main
  .private-pay-warning-div
  .warning-icon
  .warning-icon-image {
  height: 16px;
  width: 16px;
}

.patientName-box {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.patientDetailTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.patientDetailsValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

@media screen and (min-width: 1100px) {
  .private-pay-box {
    width: 558px;
  }
}
