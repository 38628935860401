.discharge-main-component {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 16px 180px 16px;
  width: calc(100% - 107px);
}

.discharge-main-component .discharge-main-component-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  height: 100%;
  width: 100%;
}

.discharge-main-component .discharge-request-component {
  margin: 40px 40px 26px 40px;
}

.discharge-request-component .discharge-request-route-section {
  margin-bottom: 26px;
}

.discharge-request-component .discharge-request-loader {
  display: flex;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.discharge-request-component .discharge-request-error-msg {
  display: flex;
  justify-content: center;
  margin: 240px 16px;
  width: 100%;
}

.discharge-request-component .short-form {
  display: flex;
  flex-direction: column;
  width: 62.78%;
}

.discharge-request-component .discharge-request-component-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 24px;
  line-height: 24px;
  width: calc(100% - 16px);
}

.discharge-main-component .exit-discharge-pop-up-container {
  align-items: flex-start;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: 206px;
  padding: 0px;
  max-width: 491px;
}

@media only screen and (max-width: 920px) {
  .discharge-main-component {
    margin: 0px;
    width: 100%;
  }

  .discharge-request-component .short-form {
    width: calc(100% - 16px);
  }

  .discharge-main-component .discharge-request-component {
    margin: 0px 0px 230px;
    padding: 40px 0px 0px 16px;
  }

  .discharge-request-component .discharge-request-component-header {
    width: 100%;
  }
}

@media only screen and (max-width: 445px) {
  .discharge-main-component .discharge-request-component {
    margin: 0px 0px 250px;
    padding: 40px 0px 0px 16px;
  }
}

@media only screen and (max-width: 350px) {
  .discharge-main-component .discharge-request-component {
    margin: 0px 0px 355px;
    padding: 40px 0px 0px 16px;
  }
}
