.mainGridDressing {
  margin-bottom: 8px;
}

.cannisterKitInfo {
  border: 1px solid #e4e4e8;
  border-radius: 4px;
  max-height: 200px;
  padding: 16px;
  margin-top: 8px;
}

.cannisterQuantityLabel {
  position: relative;
  top: 20px;
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.cannisterBtnGrid {
  color: #0049bd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}
.cannisteradd-icon {
  transform: scale(1.5) !important;
  color: #0049bd;
}

.cannisterSubLabel {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
  margin-top: 18px;
}

.cannisterTypeHeader {
  margin-top: 23px;
}

.currentCannisterMainGrid {
  flex-direction: row;
  margin: -24px 0 0;
  padding: 0px;
  width: 100%;
}

.currentCannisterMainGridQuantity {
  display: flex;
  flex-direction: row;
  margin: -24px 0 0 0px;
  padding: 0px;
  width: 100%;
}

.currentCannisterMainGridQuantity .canisterQuantity{
  margin-left: 12px;
}

.cannisterKitInfo .currentCannisterMainGrid .cannisterTypeLabel {
  margin-left: 0px;
}

.cannisterKitHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.cannisterType {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}

.cannisterBody {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #323234;
  margin-top: 4px;
  margin-bottom: 16px;
}

.dressing-supplies-review-order-content-value-title {
  margin-top: 0px;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: #323234;
  margin-bottom: 2px;
  margin-bottom: 8px;
}
.canister-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cannisterCloseBtn {
  cursor: pointer;
  position: absolute;
  margin-left: 20px;
  margin-top: 27px;
}
.cannisterdiv {
  margin-top: 2px;
}
.imgDiv {
  margin-left: 1px;
}

.cannisterKitInfo .cannisterBtnGrid {
  margin-bottom: 40px;
  margin-top: 16px;
}

.cannisterKitInfo .canister-add-button {
  color: #0049bd;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  text-decoration: underline;
  text-transform: none;
}

.cannisterKitInfo .cannisteradd-icon {
  transform: scale(1.5) !important;
}

@media screen and (max-width: 1024px) {
  .currentCannisterMainGridQuantity {
    flex-direction: row !important;
    margin: -24px 0 0 0;
  }
}
@media only screen and (max-width: 600px) {
  .cannisterKitInfo {
    max-height: unset;
  }
  .cannisterCloseBtn {
    margin-left: 8px;
  }
}
@media only screen and (max-width: 920px) {
  .cannisterKitInfo {
    max-height: unset;
  }
  .currentCannisterMainGridQuantity .canisterQuantity{
    margin-left: 0px;
  }
}
