.alert-detail {
  display: flex;
  flex-direction: column;
  min-width: 574px;
  width: 100%;
  max-width: 100%;
}

.alert-header {
  margin: 12px 0px;
  height: 32px;
}

.alert-body {
  margin: 16px;
  width: calc(100% - 32px);
}

.alert-detail .alert-body-title {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.alert-detail .alert-body-sub-section {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  width: 100%;
}

.alert-detail .alert-body-sub-section .alert-body-description {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.alert-body .alert-body-sub-section .alert-body-phone-value {
  color: #0049bd;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-left: 0px;
  text-decoration: none;
  white-space: nowrap;
}

.alert-detail .alert-footer {
  margin: 0px 8px 16px 8px;
  width: calc(100% - 16px);
}

.alert-detail .alert-footer-outlined-button {
  align-items: center;
  border: 1px solid;
  border-color: #0049bd;
  border-radius: 2px;
  color: #0049bd;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  height: 32px;
  line-height: 16px;
  margin: 8px;
  min-height: 40px;
  width: calc(100% - 16px);
  text-transform: none;
}

@media only screen and (max-width: 767px) {
  .alert-detail {
    min-width: unset;
  }
}

@media only screen and (max-width: 600px) {
  .alert-detail {
    min-width: unset;
  }
  .alert-detail .alert-body-title {
    margin: 0;
  }
  .alert-body {
    margin: 16px;
  }
  .alert-detail .alert-body-sub-section .alert-body-description {
    margin: 0px;
  }
  .alert-body .alert-body-sub-section .alert-body-phone-value {
    margin-left: 0px;
  }
  .alert-body .alert-body-sub-section {
    padding-top: 10px;
    margin: 0px;
    width: 100%;
  }
}
