.proofOfServiceOptions {
  width: calc(100% - 16px);
}

.proofOfServiceOptions .pos-option-container {
  width: 100%;
}

.pos-option-container .pos-option-title {
  width: 100%;
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 5px;
}

.pos-option-container .pos-option-icon {
  cursor: pointer;
}

.pos-option-container .pos-option-title-grid {
  margin-top: 8px;
}

.pos-option-container .pos-option-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-left: 42px;
}

@media only screen and (max-width: 920px) {
  .pos-option-container .pos-option-desc {
    margin-left: 10px;
    width: 100%;
  }
  .proofOfServiceOptions {
    width: 96%;
  }
  .proofOfServiceOptions .pos-option-container .pos-options {
    margin: 10px;
  }
}
