.inpatient-wound-info-main-div {
  margin-bottom: 40px;
  width: 100%;
}
.inpatient-wound-info-main-div .inpatient-wound-info-card-header {
  font-size: 18px;
  font-weight: 700;
}
.inpatient-wound-info-main-div
  .inpatient-wound-info-counter-div
  .inpatient-wound-info-card-sub-header {
  font-size: 14px;
}
.inpatient-wound-info-main-div .inpatient-wound-info-counter-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inpatient-wound-info-main-div .inpatient-wound-info-qty-btn-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inpatient-wound-info-main-div .inpatient-wound-info-content-div {
  border: 1px #e4e4e8 solid;
  width: 77.7%;
  border-radius: 2px;
  margin-left: 2px;
  margin-top: 10px;
  padding: 16px 16px;
}
.inpatient-wound-info-main-div
  .inpatient-wound-info-content-div
  .inpatient-wound-info-content-select {
  width: 100%;
}
.inpatient-wound-info-main-div
  .inpatient-wound-info-content-div
  .inpatient-wound-info-content-input-grid {
  margin-bottom: 10px;
}
.inpatient-wound-info-main-div
  .inpatient-wound-info-content-div
  .inpatient-wound-info-content-header {
  font-size: 14px;
  font-weight: 700;
}
.inpatient-wound-info-main-div .inpatient-wound-info-content-div .margin-right {
  margin-right: 8px;
}
.inpatient-wound-info-main-div
  .inpatient-wound-info-counter-div
  .inpatient-wound-info-qty-btn-div
  .in-patient-qty-input {
  width: 40px;
}
@media screen and (max-width: 926px) {
  .inpatient-wound-info-main-div .inpatient-wound-info-content-div {
    width: 100%;
  }
}
