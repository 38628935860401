.active {
  display: block;
}

.inactive {
  display: none;
}

.modal-content {
  display: flex;
  width: 100%;
  height: 72px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border: 1px solid #888;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);
}

.codeSentText {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #323234;
  margin: 16px;
  width: calc(100% - 58px);
}

.codeSentText .phoneNumber {
  display: contents;
  white-space: nowrap;
}

.codeSentAlertClose {
  cursor: pointer;
  height: 20px;
  margin: 8px;
  width: 20px;
}

@media screen and (max-width: 920px) {
  .modal-content {
    height: unset;
  }
}

@media screen and (max-width: 600px) {
  .modal-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: unset;
    justify-content: center;
    margin: auto;
    width: fit-content;
  }

  .codeSentText {
    margin: 4px;
    width: fit-content;
  }
}
