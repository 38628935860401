.additional-info-component {
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  width: 100%;
}

.additional-info-component .additional-info-input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0px;
}
.additional-info-grid-container {
  width: 100%;
}

.additional-info-grid-container .additional-info-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.additional-info-grid-item .additional-info-input {
  width: 100%;
}

.additional-info-input .showCommentError {
  border: 1px solid #d32f2f !important;
}

.noCommentError {
  border: none;
}
