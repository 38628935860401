.alertText {
  margin-left: 15px;
  height: 56px;
  margin-right: 15px;
  margin-top: 11px;
  display: flex;
  margin: 16px 16px 0px 16px;
}
.alertText .alertHeading {
  margin-top: 8px;
  margin-left: 15px;
  width: 100%;
}
.alertHeading .alertHeadingText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.alertHeading .alertReasonText {
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
.alertText .alertBannerButton {
  display: flex;
  flex-direction: row;
}
.alertBannerButton .alertNavigationButton {
  width: 100%;
  border-radius: 2px;
  margin: 8px 16px 8px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;
  text-transform: none;
}
.alertText .alertHeading .alertReasonParentClass {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-transform: none;
  border: none;
}
.ConfirmPlacement {
  background: #fff0f0;
  border-left: 5px solid #cb0000;
  color: #cb0000;
}
.MissingRx {
  background: #fff0f0;
  border-left: 5px solid #cb0000;
  color: #cb0000;
}
.ProofofDeliveryNeeded {
  background: #fff0f0;
  border-left: 5px solid #cb0000;
  color: #cb0000;
}
.PendingSupplyOrder {
  background: #fff0d4;
  border-left: 5px solid #915800;
  color: #915800;
}
.ExcessiveSupply {
  background: #fff0d4;
  border-left: 5px solid #915800;
  color: #915800;
}
.IncompleteWoundDetails {
  background: #fff0f0;
  border-left: 5px solid #cb0000;
  color: #cb0000;
}
.alertBannerButton .ConfirmPlacementBtn {
  border: 1px solid #cb0000;
  color: #cb0000;
}
.alertBannerButton .MissingRxBtn {
  border: 1px solid #cb0000;
  color: #cb0000;
}
.alertBannerButton .ProofofDeliveryNeededBtn {
  border: 1px solid #cb0000;
  color: #cb0000;
}
.alertBannerButton .PendingSupplyOrderBtn {
  border: 1px solid #915800;
  color: #915800;
}
.alertBannerButton .ExcessiveSupplyBtn {
  border: 1px solid #915800;
  color: #915800;
}
.alertBannerButton .IncompleteWoundDetailsBtn {
  background: #fff0f0;
  border-color: #cb0000;
  color: #cb0000;
}
.alertBannerButton .ExcessiveSupplyBtn:hover {
  border: 1px solid #915800;
  background: #ffff;
}
.alertBannerButton .PendingSupplyOrderBtn:hover {
  border: 1px solid #915800;
  background: #ffff;
}
.alertBannerButton .ProofofDeliveryNeededBtn:hover {
  border: 1px solid #cb0000;
  background: #ffff;
}
.alertBannerButton .MissingRxBtn:hover {
  border: 1px solid #cb0000;
  background: #ffff;
}
.alertBannerButton .ConfirmPlacementBtn:hover {
  border: 1px solid #cb0000;
  background: #ffff;
}
.alertBannerButton .IncompleteWoundDetailsBtn:hover {
  border: 1px solid #cb0000;
  background: #ffff;
}
.healogics {
  color: #0049bd;
  background: #e8f4ff;
  border-left: 5px solid #0049bd;
  margin-top: 24px;
}

@media only screen and (max-width: 920px) {
  .alertText .alertHeading .alertReasonParentClass {
    height: fit-content;
  }
  .alertBannerButton .alertNavigationButton {
    margin: 0px 5px 10px 4px;
    height: 45px;
  }
}
@media only screen and (max-width: 600px) {
  .alertText {
    margin: 16px 0px 0px 0px;
  }
}
