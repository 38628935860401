.sales-rounding-tool-patient-information-component {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.sales-rounding-tool-patient-information-component
  .sales-rounding-tool-patient-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 8px 0px;
  text-align: left;
  width: 100%;
}

.sales-rounding-tool-patient-information-component
  .sales-rounding-tool-patient-information-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.sales-rounding-tool-patient-information-component
  .sales-rounding-tool-patient-information-grid-container
  .sales-rounding-tool-patient-information-grid-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.sales-rounding-tool-patient-information-component
  .sales-rounding-tool-patient-information-grid-container
  .sales-rounding-tool-patient-information-grid-item
  .patient-information-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: calc(100% - 8px);
}

.sales-rounding-tool-patient-information-component
  .sales-rounding-tool-patient-information-grid-container
  .sales-rounding-tool-patient-information-grid-item
  .patient-information-input {
  width: calc(100% - 8px);
}

.sales-rounding-tool-patient-information-date-of-birth input.input {
  border-right: 2px;
  width: calc(100% - 8px);
}

div.sales-rounding-tool-patient-information-date-of-birth {
  width: calc(100% - 8px);
}

div .sales-rounding-tool-patient-information-date-of-birth .adornedRoot {
  position: absolute;
  right: 18px;
}

.sales-rounding-tool-patient-information-date-of-birth .outline {
  border-radius: 1px;
  border: 1px solid #94949a;
  width: calc(100% - 18px);
}

.sales-rounding-tool-patient-information-date-of-birth.show-error .outline {
  border: 1px solid #cb0000;
}

.sales-rounding-tool-patient-information-date-of-birth.no-error.Mui-error
  .outline {
  border: 1px solid #94949a;
}

@media only screen and (max-width: 926px) {
  .sales-rounding-tool-patient-information-component {
    width: 100%;
  }
}
