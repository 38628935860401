.charity-care-container {
  width: calc(100% - 16px) !important;
  margin-top: 16px;
}
.charity-care-container .charityCarePdfLink {
  display: flex;
  width: 100%;
  margin: 15px 0px 0px 8px;
}

.charity-care-container ul {
  padding-inline-start: 0;
  padding-left: 25px;
  font-size: 18px;
  font-weight: 700;
}
.charity-care-container .charity-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
.charity-care-container .charity-pdf-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: #0049bd;
  text-underline-offset: 2px;
  text-decoration-skip-ink: none;
}
.charity-care-container .charity-instruction {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  margin-top: 15px;
}
.charity-care-container .pdf-size {
  display: inline;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #76767a;
  margin-left: 5px;
}
.charity-care-container .charity-instruction .upload-doc {
  display: inline;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
  width: calc(100% - 16px);
}
.charityCarePdfLink .bullet-with-space{
  margin-right: 8px;
  font-size: 12px;
}
@media only screen and (min-width:300px) and (max-width: 397px) {
.charity-care-container .charity-pdf-link {
  font-size: 13px;
}
.charity-care-container .pdf-size {
  font-size: 13px;
}
.charity-care-container .charityCarePdfLink{
flex-wrap: wrap;
align-items: center;
}
}
