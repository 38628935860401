.territory-detail-component-container {
  width: 100%;
  margin-right: 35px;
}

.territory-detail-component-container .territory-detail-component {
  align-items: flex-start;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px 56px 101px 16px;
  min-height: calc(100vh - 244px);
  width: 100%;
  padding-bottom: 60px;
}

.territory-detail-component .territory-detail-main-section {
  margin: 0 0 40px 0;
  width: 100%;
}

.territory-detail-component .territory-detail-main-section-header {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 12px 40px 0px;
}

.territory-detail-component .territory-detail-component-route-section {
  margin: 26px 40px;
}
.territory-detail-component-data {
  margin: 0px 40px 26px 40px;
  width: 98%;
}
.territory-detail-component-container .territory-detail-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.territory-detail-component-container .territory-detail-name-code {
  color: var(--msdds-gray-80, #323234);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: break-spaces;
}
.add-sales-container {
  margin-top: -7px;
}
.territory-detail-component-container .territory-detail-salePerson {
  color: var(--msdds-gray-54, #76767a);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.territory-detail-component-container .territory-detail-add-salePerson {
  color: var(--msdds-gray-54, #76767a);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.territory-detail-component-container .territory-detail-add-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 25px;
}

.territory-detail-component-container .territory-detail-sale-profile {
  width: 300px;
  margin-bottom: 20px;
}

.territory-detail-component-container .territory-detail-sale-personName {
  color: #0049bd;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
  margin-bottom: 10px;
  cursor: pointer;
}
.territory-detail-component-container .click-sale-person {
  cursor: pointer;
  width: 60%;
}
.territory-detail-component-container .territory-detail-sale-personName-open {
  color: #cb0000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.territory-detail-component-container .territory-detail-fac-title {
  color: #323234;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 15px;
}
.territory-detail-component-data .territory-detail-users-table {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  margin-top: 15px;
}

.manage-territory-component-container .territory-fetch-users-loader-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.territory-fetch-users-loader-pop-up .territory-fetch-users-loader {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 400px;
}

.territory-fetch-users-loader-pop-up .territory-fetch-users-failure-pop-up {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.territory-fetch-users-failure-pop-up
  .territory-fetch-users-failure-pop-up-div {
  height: 165px;
  width: 432px;
}

@media screen and (max-width: 920px) {
  .territory-detail-component-container {
    margin-right: 0px;
  }
  .territory-detail-component-container .territory-detail-component {
    margin: 0;
  }
  .territory-detail-component-container .territory-detail-component {
    margin: 0;
  }
  .territory-detail-component .territory-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .territory-detail-component .territory-filter-mng-usr {
    flex-direction: column;
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .territory-filter-mng-usr div.placeHolder {
    width: calc(100% - 32px);
  }
  .territory-detail-component .territory-user-searchbar {
    margin: 0 16px;
    width: calc(100% - 32px);
  }

  .territory-detail-component .territory-search-icon-div {
    height: 100%;
    margin: 0;
  }
  .territory-detail-component .territory-search-icon {
    color: #76767a;
    height: 100%;
    margin: auto 13px;
  }

  .territory-detail-component .territory-user-search-input {
    width: calc(100% - 32px);
  }

  .territory-detail-component-container .territory-detail-component-data {
    width: 100%;
  }
  .territory-detail-component-data .territory-detail-users-table {
    width: calc(100% - 27px);
  }
  .territory-detail-component-data {
    margin: 0px 40px 26px 12px;
  }
  .territory-detail-component .territory-detail-component-route-section {
    margin: 26px 12px;
  }
  .territory-fetch-users-loader-pop-up .territory-fetch-users-loader {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;
    width: auto;
    padding: 1px 50px;
  }
}
@media screen and (max-width: 500px) {
  .territory-detail-component-container {
    margin-right: 0px;
  }
  .territory-detail-component-container .territory-detail-component {
    margin: 0;
    overflow-x: hidden;
  }
  .territory-detail-component .territory-users-table {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .territory-detail-component-data .territory-detail-users-table {
    width: calc(100% - 27px);
  }
  .territory-detail-component-container .territory-detail-sale-profile {
    width: 175px;
    margin-bottom: 20px;
  }
  .territory-detail-component-container .territory-detail-add-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
