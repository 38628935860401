.prescriber-found-component {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: calc(100% - 32px);
}

.prescriber-found-component .prescriber-found-header {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
}

.prescriber-found-component .prescriber-details {
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
  min-height: 80px;
  width: 100%;
}

.prescriber-found-component .prescriber-details .back-to-search-button {
  margin: 24px 0px 0px;
  text-transform: none;
}

.prescriber-found-component .prescriber-details-grid-container {
  align-items: center;
  background-color: #f8f8fa;
  min-height: 80px;
  margin: 0px;
  padding: 16px;
  width: 100%;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item
  .prescriber-select {
  color: #0049bd;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item
  .prescriber-name {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item
  .prescriber-address {
  color: #76767a;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item
  .prescriber-name
  .prescriberfoundSubHeader {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-right: 8px;
  width: calc(100% - 8px);
}

.prescriberFax {
  margin-left: 16px;
}

.prescriber-found-component
  .prescriber-details-grid-container
  .prescriber-details-grid-item
  .prescriber-name.prescriber-found-name-change {
  margin-bottom: 8px;
}

@media only screen and (max-width: 350px) {
  .prescriber-found-component .prescriber-found-header {
    font-size: 16px;
    line-height: 20px;
  }
}
