.in-patient-supply-order-summary-page-component {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 104px 25px 16px;
  padding-bottom: 24px;
  width: calc(100% - 120px);
}
.in-patient-supply-order-summary-page-component
  .in-patient-supply-summary-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 0px 0px 40px;
  width: calc(100% - 80px);
}

.in-patient-supply-order-summary-page-component
  .in-patient-supply-summary-header
  .in-patient-supply-summary-title {
  color: #323234;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin: 8px 0px;
  text-align: left;
  width: fit-content;
}

.in-patient-supply-order-summary-page-component
  .in-patient-supply-summary-header
  .in-patient-supply-summary-header-button {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.in-patient-supply-order-summary-page-component
  .in-patient-supply-summary-header
  .in-patient-supply-summary-header-button
  .in-patient-supply-summary-download-button {
  color: #0049bd;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-decoration-color: #0049bd;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
  text-decoration: underline;
  text-transform: none;
  width: fit-content;
}

.in-patient-supply-order-summary-page-component
  .inPatient-order-summary-sections {
  margin: 16px 40px 0px;
}

@media print {
  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-header-button {
    display: none;
  }
}

@media only screen and (max-width: 926px) {
  .in-patient-supply-order-summary-page-component {
    margin: 16px;
    width: calc(100% - 32px);
  }
  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-title {
    margin: 16px 0px;
  }
  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-header-button {
    margin: 8px 0px;
  }
  .in-patient-supply-order-summary-page-component
    .inPatient-order-summary-sections {
    margin: 0px 16px;
    width: calc(100% - 32px);
  }
}
@media only screen and (max-width: 475px) {
  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header {
    flex-direction: column;
    margin: 0px 16px 16px;
  }

  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-title {
    margin: 0px;
    width: 100%;
  }

  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-header-button {
    margin: 8px 0px;
    justify-content: center;
    width: 100%;
  }

  .in-patient-supply-order-summary-page-component
    .in-patient-supply-summary-header
    .in-patient-supply-summary-header-button
    .in-patient-supply-summary-download-button {
    text-align: center;
    width: 100%;
    padding: 0px;
    margin: 16px 0px;
  }
  .in-patient-supply-order-summary-page-component
    .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .in-patient-supply-order-summary-page-component {
    margin: 0px;
    width: 100%;
  }
}
@media print {
  .ag-pinned-left-header,
  .ag-pinned-left-cols-container,
  .ag-pinned-right-header,
  .ag-pinned-right-cols-container {
    box-shadow: none !important;
  }
  .footerForInpatientOrder {
    display: none !important;
  }
  .in-patient-supply-order-summary-page-component .ag-root-wrapper-body {
    zoom: 66% !important;
  }
  .in-patient-supply-order-summary-page-component {
    margin: unset !important;
    width: 100% !important;
  }
  .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 100% !important;
    overflow: hidden !important;
  }
  .in-patient-supply-order-summary-page-component * {
    overflow: hidden !important;
    scrollbar-width: none;
    padding: unset !important;
  }
  .in-patient-supply-order-summary-page-component::-webkit-scrollbar {
    display: none;
    padding: unset !important;
  }
  .in-patient-supply-order-summary-page-component
    .shipping-address-review-component {
    page-break-inside: avoid;
    orphans: 0;
    widows: 0;
  }
}

@media only screen and (min-width: 1451px) {
  .in-patient-supply-order-summary-page-component
    .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 80%;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1450px) {
  .in-patient-supply-order-summary-page-component
    .inpatient-supplyorder-selectedsupplies-main-review-div {
    width: 100%;
    zoom: 90%;
  }
}
