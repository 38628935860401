.facilityinformation {
  width: 100%;
  margin-top: 47px;
}

.manage-profile-facilityinformation-header-more-margin {
  color: #323234;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin: 8px 0 16px 0;
}

.addfacility {
  margin: 16px 0;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 12px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #0049bd;
  border-radius: 2px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.addfacilitybuttonicon {
  width: 28px;
  height: 28px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.addfacilitybuttontext {
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0049bd;
  margin-top: 2.5px;
}

.all-facilities {
  margin-right: 8px;
}

.all-facilities .search-description {
  color: #323234;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 16px 0;
}

.all-facilities .search-description-error {
  color: red;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 16px 0;
}

.search-description .search-description-astrick {
  color: red;
}

.all-facilities .facilitywrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: fff;
  border: 1px solid #e4e4e8;
  display: flex;
  flex-direction: column;
  flex: none;
  flex-grow: 1;
  margin: 0 8px 15px 0;
  order: 0;
  overflow: hidden;
  width: 100%;
}

.facilitywrapper .facility-detail-div {
  width: 100%;
  display: flex;
}

.facilitywrapper .facility-detail-div .facility-detail {
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
}

.facility-detail .leftfaciltydetails {
  margin: 15px 16px;
  width: calc(50% - 15px);
}

.facility-detail .rightfaciltydetails {
  margin: 15px 16px;
  width: calc(50% - 15px);
}

.facilitywrapper .facility-detail-div .lastfaciltydetails {
  margin: 10px;
  width: 30px;
}

.facilityname {
  color: #323234;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.facilitywrapper .facility-address {
  display: flex;
  flex-direction: row;
}

.facilitywrapper .facilitytitle {
  color: #808084;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 11px;
  text-transform: capitalize;
}

.facilitywrapper .facility-address .facilityvalue {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.facilitywrapper .facilityvalue {
  color: #323234;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.trash {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 10px 10px 0 0;
}

.trachdisplay {
  display: none;
}

.facilitywrapper .facility-detail-warning {
  color: #9c0000;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  margin: 0 16px 16px 16px;
  max-width: calc(100% - 62px);
}

.confirmationTextRoot.confirmationText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}

.confirmBack {
  cursor: pointer;
  margin-top: 16px;
  color: #0049bd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 14px;
  gap: 4px;
  font-weight: 700;
  background: #ffffff;
  border: 1px solid #0049bd;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
}

.confirmRemove {
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 14px;
  gap: 4px;
  background: #cb0000;
  border-radius: 2px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.dailogClass .dailogPaper {
  max-width: 403px;
  min-width: 403px;
  min-height: 264px;
  background-color: white;
}

.dialogCloseIcon {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.comfirmationTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}

.dialogTitleRoot.dialogTitle {
  color: black;
  display: flex;
  padding: 16px;
}

.dialogContentRoot.dialogContent {
  padding: 16px;
}

.add-facility-popup .paperRoot {
  display: flex;
  max-width: 574px;
  width: 100%;
  padding: 16px 16px 40px 16px;
}

.search-facility-popup .facility-search-component {
  display: flex;
  width: 542px;
  min-height: 258px;
  padding: 16px;
}

.facility-search-component .facility-search-title {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  width: 100%;
}

.add-facility-manually-dialog {
  width: 47.835%;
  margin: 0 auto;
}

.facility-search-component .facility-search-title {
  font-family: "3MCircularTT";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
  width: 100%;
}

/* mobile css */
@media only screen and (max-width: 600px) {
  .all-facitlies {
    margin: 0;
  }
  .facilitywrapper .facilityvalue {
    width: 60%;
  }

  .all-facitlies .facilitywrapper {
    margin: 0;
    width: 100%;
    flex-direction: column;
    height: unset;
    padding: 15px;
    box-sizing: border-box;
    margin: 0 0 15px 0;
    width: 100%;
  }

  .facilitywrapper .facility-detail-div .facility-detail {
    flex-direction: column;
  }

  .facility-detail-div .facility-detail .leftfaciltydetails {
    width: 100%;
    margin: 8px;
  }

  .facility-detail-div .facility-detail .rightfaciltydetails {
    width: 100%;
    margin: 8px;
    display: flex;
    flex-direction: row;
  }
  .facilitywrapper .facility-detail-warning {
    margin: 16px 8px;
  }

  .addfacility {
    margin: 0;
    width: 100%;
  }

  .facilityinformation {
    padding-left: 8px;
    box-sizing: border-box;
  }

  .all-facilities {
    margin: 0;
  }

  .add-facility-popup .paperRoot {
    max-width: 300px;
  }
  .facilitywrapper .facility-detail-div .lastfaciltydetails {
    width: auto;
  }
}

@media only screen and (max-width: 450px) {
  .facilitywrapper .facility-detail {
    flex-direction: column;
  }

  .facilitywrapper .facility-address {
    flex-direction: row;
  }

  .dailogClass .dailogPaper {
    max-width: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }
}
@media only screen and (max-width: 920px) {
  .facility-detail-div .facility-detail .rightfaciltydetails {
    display: flex;
    flex-direction: row;
  }
  .rightfaciltydetails .facility-type-and-value {
    width: 100%;
  }
  .rightfaciltydetails .facility-id-and-value {
    width: 50%;
  }
  .facilitywrapper .facilitytitle {
    margin-bottom: 4px;
  }
}
