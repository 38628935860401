 .admissionwoundinfo {
    width: 100%;
    margin-top: 2%;
    font-weight: 400;
  }
  .admissionwoundinfo .radioRoot {
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1%;
  }
  .admissionwoundinfo .optionRoot {
    border: none;
    box-sizing: border-box;
    height: 40px;
    margin: 0;
    width: calc(62% - 4px);
  }
  .admissionwoundinfo .optionRoot-active {
    border: none;
    box-sizing: border-box;
    height: 40px;
    margin: 0;
    width: calc(62% - 4px);
  }
  .admissionwoundinfo .optiontxt {
    color: #4c4c4f;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .admissionwoundinfo .optiontxtSelect {
    color: 76767A;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  