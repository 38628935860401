.supplyOrderReview-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 16px 104px 121px 16px;
}
.supplyOrderReview-container .supply-order-page-review {
  margin-top: 40px;
  margin-left: 40px;
  height: auto;
  padding-bottom: 80px;
}
.supplyOrderReview-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #323234;
}
.supplyOrderReview-subtitle {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #323234;
}
@media screen and (max-width: 920px) {
  .table_container_review {
    width: 100%;
  }
  .supplyOrderReview-container {
    margin: 0;
  }
}
@media screen and (max-width: 550px) {
  .supplyOrderReview-title {
    width: 100%;
  }
}
@media screen and (max-width: 920px) {
  .supplyOrderReview-container .supply-order-page-review {
    margin-top: unset;
    margin-left: unset;
  }
  .supplyOrderReview-container .supply-order-review-subMainContainer {
    margin: 16px;
  }
  .supplyOrderReview-subtitle {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 600px) {
  .supplyOrderReview-container .supply-order-review-subMainContainer {
    overflow-x: hidden;
  }
}
