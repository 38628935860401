.site-status-content-alert-div .site-status-aler-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}
.site-status-content-alert-div .site-status-alert-component {
  border-left: 8px solid;
}
.site-status-alert-component .site-status-alert-message {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.site-status-title-and-description .site-status-details-of-msg {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.site-status-title-and-description .custom-siteStatus-deatil-and-LinkText {
  display: flex;
  flex-direction: row;
}
.custom-siteStatus-deatil-and-LinkText .site-status-custom-url-link {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
  margin-left: 4px;
}
.site-status-content-alert-div .site-status-info-css {
  color: #0049bd;
}
.site-status-content-alert-div .site-status-warning-css {
  color: #915800;
}
.site-status-content-alert-div .site-status-error-css {
  color: #cb0000;
}
.site-status-content-alert-div .site-status-default-css {
  color: #76767a;
  background-color: #ffffff;
}
@media only screen and (max-width: 920px) {
  .custom-siteStatus-deatil-and-LinkText {
    display: flex;
    flex-direction: column;
  }
  .custom-siteStatus-deatil-and-LinkText .site-status-custom-url-link {
    margin-left: 0px;
  }
}
