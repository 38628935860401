.medicare-container {
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 16px;
}
.medicare-container .medicare-grid {
  width: 100%;
}
.medicare-container .insurance-informantion-input {
  width: 100%;
}
.medicare-container
  .insurance-informantion-input
  .insurance-informantion-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
}

.medicare-container div.placeHolder {
  border-radius: 2px;
  width: 100%;
  color: #76767a;
}

.medicare-container .placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.medicare-information-input {
  width: 100%;
}
