.sales-rounding-tool-therapy-information-component {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-title {
  color: #323234;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin: 18px 0px;
  text-align: left;
  width: 100%;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item {
  margin: 0px;
  padding: 8px 0px;
  width: 100%;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .srtLeadGeneration-type-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0px;
  width: calc(100% - 8px);
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .srtLeadGeneration-info-input {
  width: calc(100% - 8px);
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .srtLeadGeneration-info-select {
  width: calc(100% - 8px);
  padding: 10px;
  font-size: 14px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #94949a;
}

.sales-rounding-tool-therapy-information-component
  .srtLeadGeneration-info-select:focus {
  width: 100%;
  border: 1px solid #323234;
  outline: none;
}

.sales-rounding-tool-therapy-information-component .placeHolder {
  width: calc(100% - 8px);
  color: #323234;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .transitionTherapyStartDate {
  width: calc(100% - 8px);
}

.inpatient-therapy-start-date-sub-div .therapy-start-date-header-desc {
  color: #323234;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.transitionTherapyStartDate input.input {
  border-right: 0;
}

div.transitionTherapyStartDate {
  padding-right: 0;
}

.transitionTherapyStartDate .outline {
  border-radius: 2px;
}

.transitionTherapyStartDate.showError .outline {
  border: 1px solid #d32f2f;
}

.transitionTherapyStartDate.noError.Mui-error .outline {
  border: 1px solid #94949a;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .input {
  width: 100%;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .input.Mui-focused {
  border-color: #323234;
  width: calc(100% - 8px);
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .transitiontransitionTherapyStartDate.showError
  .outline {
  border: 1px solid #cb0000;
}

.sales-rounding-tool-therapy-information-component
  .sales-rounding-tool-therapy-information-grid-container
  .sales-rounding-tool-therapy-information-grid-item
  .srtLeadGeneration-info-input.Mui-error {
  border: 1px solid #cb0000;
}

@media only screen and (max-width: 926px) {
  .sales-rounding-tool-therapy-information-component {
    width: 100%;
  }
}
