.addSTForSaleManager {
  height: 514px;
  padding: 16px;
}
.addSTForSaleManagerSelected {
  height: auto;
  padding: 16px;
}
.addSTForSaleManagerTitle {
  color: #323234;
  flex: 0 1 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  text-align: left;
  margin-top: 0px;
}

.sale-user-searchbar {
  border: 1px groove #b4b4b8;
  border-radius: 2px;
  background: #fff;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0;
  margin: 0;
}
.sale-user-searchbar .sale-search-icon-div {
  height: 100%;
  margin: 0;
}

.sale-user-searchbar .sale-search-icon-div .sale-search-icon {
  color: #76767a;
  height: 100%;
  margin: auto 4px;
}

.sale-user-searchbar .sale-user-search-input {
  color: #76767a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}
.sale-user-status-input {
  height: 42px;
  width: 100%;
}

.sale-user-status-input .sale-user-status-select {
  height: 12px;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  width: 100%;
}

div.placeHolder {
  border-radius: 2px;
  color: #76767a;
  height: 42px;
}

.placeHolder div.placeHolder {
  border-right: unset;
  height: 20px;
  width: 100%;
}

.placeHolder .sale-selectIcon {
  top: 10px;
}
.checkboxLabel {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.addSTForSaleManager-list {
  margin-top: 12px;
  overflow-x: scroll;
  max-height: 80%;
}
.addSTterritoryList {
  background-color: #fff;
  border: 1px solid #f2f2f5;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}
.addSTterritoryList.gray {
  background-color: #f8f8fa;
}
.addSTterritoryList .selectButton {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0049bd;
  cursor: pointer;
  padding: 10px;
  text-transform: capitalize;
}
.addSTterritoryRegion {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #76767a;
}
.addSTterritoriesDetails {
  font-size: 14px;
}
.addSTterritoryRegionBlock {
  width: 60%;
  flex-direction: column;
}
.salesPersonTitle {
  color: #808084;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.salesPersonValue {
  color: #323234;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.addSTDataGrid {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.addSTLoadingSpinner {
  margin-top: 75px;
}
.addSTNoRecords {
  margin-top: 25%;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .addSTForSaleManager {
    height: 470px;
    padding: 16px;
  }
  .addSTterritoryRegionBlock {
    width: 100%;
  }
  .addSTDataGrid {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .addSTForSaleManager-list {
    margin-top: 12px;
    overflow-x: scroll;
    max-height: 65%;
  }
}
