.facility-permissions-category-component {
  margin: 0px;
  padding: 0px;
  width: 100%;
}

.facility-permissions-category-component .permission-category-title {
  color: #323234;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin: 16px 0px 0px;
  text-align: left;
}


