.create-service-request-container-loader-pop-up {
  height: 100%;
  width: 100%;
}

.create-service-request-container-loader-pop-up-div {
  display: flex;
  justify-content: center;
  height: 250px;
  margin: 0;
  width: 350px;
}
